import { API } from "../axios";

export const fetchReportTopRowDataAPI = async (collegeInput, daysInput) => {
  try {
    const { status, data } = await API.get(
      `/admin/reports/fetch-total-commission/${collegeInput}/${daysInput}`
    );
    return { status, data };
  } catch (error) {
    return error?.response;
  }
};

export const fetchReportTableDataAPI = async (option, daysInput) => {
  try {
    const { status, data } = await API.get(
      `/admin/reports/fetch-report-table/${option}/${daysInput}`
    );
    return { status, data };
  } catch (error) {
    return error?.response;
  }
};
