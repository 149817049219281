import { createSlice } from '@reduxjs/toolkit';

const adminAuth = createSlice({
  name: 'admin',
  initialState: {
    adminToken: '',
  },
  reducers: {
    addAdminToken(state, actions) {
      const admin = actions.payload;
      state.adminToken = admin;
    },
    adminLogout(state) {
      state.adminToken = '';
    },
  },
});

export const {addAdminToken,adminLogout} = adminAuth.actions;

export default adminAuth;