import React from "react";

const ReportTh = ({ children }) => {
  return (
    <>
      <table className="w-[100%] border-collapse">
        <thead>
          <tr className="">
            {/* <th className="text-gray-800 text-[15px]  py-[10px] border border-1 border-solid text-left p-[8px] whitespace-nowrap">
              TRANSACTION ID
            </th> */}
            <th className="text-gray-800 text-[15px]  py-[10px] border border-1 border-solid text-left p-[8px] whitespace-nowrap">
              NAME & EMAIL
            </th>
            <th className="text-gray-800 text-[15px]  py-[10px] border border-1 border-solid text-left p-[8px] whitespace-nowrap">
              Total Commission
            </th>
            <th className="text-gray-800 text-[15px]  py-[10px] border border-1 border-solid text-left p-[8px] whitespace-nowrap">
              Commission Earned
            </th>
            <th className="text-gray-800 text-[15px]  py-[10px] border border-1 border-solid text-left p-[8px] whitespace-nowrap">
              Commission Pending 
            </th>
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </>
  );
};

export default ReportTh;
