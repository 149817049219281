import React, { useEffect, useState } from "react";
import {
  AddAgentsAPI,
  fetchCollegesAPI,
  // fetchCountriesAPI,
  fetchRepNamesAPI,
} from "../../../API/admin/AdminAPI";
import Loader from "./Loader";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";

const AddAgents = ({ handleCloseModal, setAgentsData, agentsData }) => {
  const [formErrors, setFormErrors] = useState({});
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [agentExists, setAgentExists] = useState(false);
  const [isNoRep, setIsNoRep] = useState(false);
  const [repNames, setRepNames] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [collegeNames, setCollegeNames] = useState();
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    representative: "",
    colleges: "",
    state: "",
    country: "",
  });
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(formData.email);
    setIsEmailValid(isValidEmail);
  };
  const fetchRepNames = async () => {
    try {
      const fetchRep = await fetchRepNamesAPI();
      if (fetchRep.status === 200) {
        setRepNames(fetchRep.data);
      } else {
        setRepNames([]);
      }
    } catch (error) {
      setRepNames([]);
    }
  };
  useEffect(() => {
    fetchRepNames();
    // fetchCountries();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const errors = validateForm();
    if (isEmailValid && Object.keys(errors).length === 0) {
      setIsLoader(true);
      try {
        const response = await AddAgentsAPI(formData);
        setIsLoader(false);
        if (response.status === 201) {
          setIsLoader(false);
          // Reset form after successful submission
          setFormData({
            fullName: "",
            email: "",
            phone: "",
            representative: "",
            colleges: "",
            // addressLine1: "",
            // addressLine2: "",
            state: "",
            country: "",
            // zip: "",
          });
          setAgentsData([...agentsData, response?.data]);
          handleCloseModal();
        } else if (response.status === 409) {
          setAgentExists(true);
        }
      } catch (error) {
        setIsLoader(false);
        console.log(error);
      }
    } else {
      setIsLoader(false);
      setFormErrors(errors);
    }
  };
  const validateForm = () => {
    const errors = {};
    // Validate each input here
    if (formData.fullName.trim() === "") {
      errors.fullName = "Full Name is required";
    }
    if (formData.email.trim() === "") {
      errors.email = "Email is required";
    }
    if (formData.phone.trim() === "") {
      errors.phone = "Phone is required";
    }
    if (isNoRep) {
      if (formData.colleges.trim() === "") {
        errors.colleges = "colleges is required";
      }
    } else {
      if (formData.representative.trim() === "") {
        errors.representative = "representative is required";
      }
    }
    if (formData.state.trim() === "") {
      errors.state = "State is required";
    }
    if (formData.country.trim() === "") {
      errors.country = "Country is required";
    }
    return errors;
  };

  const handleNoRepFunction = () => {
    try {
      if (isNoRep) {
        setFormData((prevState) => ({
          ...prevState,
          colleges: "none",
        }));
      } else {
        setFormData((prevState) => ({
          ...prevState,
          representative: "none",
        }));
      }
      setIsNoRep(!isNoRep);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCollegeNames = async () => {
    const response = await fetchCollegesAPI();
    if (response.status === 200) {
      setCollegeNames(response?.data);
    } else {
      setCollegeNames([]);
    }
  };

  useEffect(() => {
    if (isNoRep) {
      fetchCollegeNames();
    }
  }, [isNoRep]);

  return (
    <div className="flex justify-center items-center md:flex py-[20px] bg-white">
      <div className="w-full py-[10px] rounded-2xl shadow-lg shadow-gray-500">
        <div className="w-[95%] mx-auto">
          {/* heading */}
          <div className=" text-center md:py-[20px] text-[25px] md:text-left">
            <p className="underline underline-offset-8">Add Agents</p>
          </div>
          <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-3 justify-between">
            {/* name */}
            <div>
              <label
                className="block mb-2 font-medium text-sm"
                htmlFor="fullName"
              >
                Full Name:
              </label>
              <input
                className="block px-4 py-2 w-full border placeholder:text-gray-500  border-gray-300 rounded-lg"
                type="text"
                id="fullName"
                name="fullName"
                placeholder="Full Name"
                required
                value={formData.fullName}
                onChange={handleInputChange}
              />
              {formErrors.fullName && (
                <span className="text-xs text-red-600">
                  {formErrors.fullName}
                </span>
              )}
            </div>
            {/* mail id */}
            <div>
              <label
                className="block mb-2 font-medium text-sm"
                htmlFor="emailAddress"
              >
                Email Address:
              </label>
              <input
                className="block px-4 py-2 w-full border placeholder:text-gray-500  border-gray-300 rounded-lg"
                type="email"
                id="emailAddress"
                name="email"
                placeholder="Email Address"
                required
                value={formData.email}
                onChange={handleInputChange}
                onBlur={validateEmail}
              />
              {!isEmailValid && (
                <span className="text-xs text-red-600">
                  Please enter a valid email address.
                </span>
              )}
              {agentExists && (
                <span className="text-xs text-red-600">
                  Email already exist !
                </span>
              )}
              {formErrors.email && (
                <span className="text-xs text-red-600">{formErrors.email}</span>
              )}
            </div>
            <div>
              <label
                className="block mb-2 font-medium text-sm"
                htmlFor="contactNumber"
              >
                Phone:
              </label>
              <input
                className="block px-4 py-2 w-full border placeholder:text-gray-500  border-gray-300 rounded-lg"
                type="phone"
                id="phone"
                name="phone"
                placeholder="phone"
                value={formData.phone}
                onChange={handleInputChange}
                required
              />
              {formErrors.phone && (
                <span className="text-xs text-red-600">{formErrors.phone}</span>
              )}
            </div>
            {isNoRep ? (
              <div className="flex">
                <div>
                  <label
                    className="block mb-1 font-medium text-sm"
                    htmlFor="courses"
                  >
                    Colleges
                  </label>
                  <select
                    className="block w-11/12 px-4 py-2 border placeholder:text-gray-500  border-gray-300 rounded-lg"
                    id="colleges"
                    name="colleges"
                    value={formData.colleges}
                    onChange={handleInputChange}
                    required
                  >
                    <option className="text-black" value="none">
                      Select a college for agent
                    </option>
                    {collegeNames?.length > 0 &&
                      collegeNames?.map((collegeNm) => (
                        <option
                          className="text-black"
                          value={collegeNm?._id}
                          key={collegeNm?._id}
                        >
                          {collegeNm?.name}
                        </option>
                      ))}
                  </select>
                  {formErrors.colleges && (
                    <span className="text-xs text-red-600">
                      {formErrors.colleges}
                    </span>
                  )}
                </div>
                <div>
                  <button
                    className="right-0 text-black border border-gray-300 hover:bg-black hover:text-white font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center mt-5"
                    onClick={handleNoRepFunction}
                  >
                    <ChangeCircleIcon />
                  </button>
                </div>
              </div>
            ) : (
              <div className="flex">
                <div>
                  <label
                    className="block mb-1 font-medium text-sm"
                    htmlFor="courses"
                  >
                    Representative
                  </label>
                  <select
                    className="block w-11/12 px-4 py-2 border placeholder:text-gray-500  border-gray-300 rounded-lg"
                    id="representative"
                    name="representative"
                    value={formData.representative}
                    onChange={handleInputChange}
                    required
                  >
                    <option className="text-black" value="">
                      Select a representative
                    </option>
                    {repNames.length > 0 &&
                      repNames.map((repNames) => (
                        <option
                          className="text-black"
                          value={repNames._id}
                          key={repNames._id}
                        >
                          {repNames.name}
                        </option>
                      ))}
                  </select>
                  {formErrors.representative && (
                    <span className="text-xs text-red-600">
                      {formErrors.representative}
                    </span>
                  )}
                </div>
                <div>
                  <button
                    className="right-0 text-black border border-gray-300 hover:bg-black hover:text-white font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center mt-5"
                    onClick={handleNoRepFunction}
                  >
                    <ChangeCircleIcon />
                  </button>
                </div>
              </div>
            )}
          </div>
          <p className="block my-3 font-medium text-sm" htmlFor="Address">
            Address:
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-3 my-[10px]">
            <div>
              <input
                className="block w-full px-4 py-2 border placeholder:text-gray-500  border-gray-300 rounded-lg"
                type="text"
                id="state"
                name="state"
                placeholder="State"
                value={formData.state}
                onChange={handleInputChange}
                required
              />
              {formErrors.state && (
                <span className="text-xs text-red-600">{formErrors.state}</span>
              )}
            </div>
            <div>
              <input
                className="block w-full px-4 py-2 border placeholder:text-gray-500  border-gray-300 rounded-lg"
                type="text"
                id="country"
                name="country"
                placeholder="country"
                value={formData.country}
                onChange={handleInputChange}
                required
              />
              {formErrors.country && (
                <span className="text-xs text-red-600">
                  {formErrors.country}
                </span>
              )}
            </div>
          </div>
          {/* section six */}
          <div className="flex justify-end gap-x-[20px]">
            <button
              onClick={handleCloseModal}
              className=" py-[8px] md:ease-in duration-300 mt-4 bg-black hover:bg-red-600  text-sm text-white font-bold px-2  rounded"
              type="submit"
            >
              cancel
            </button>
            <button
              onClick={handleSubmit}
              className=" ease-in duration-300 mt-4 bg-red-500 hover:bg-green-600  text-sm text-white font-bold  py-2 px-3  rounded"
              type="button"
              disabled={isLoader}
            >
              {!isLoader ? <span>save</span> : <Loader />}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAgents;
