import React, { useEffect, useState } from "react";
import Layout from "../../../components/AdminComponents/Layout/Layout";
import { useLocation } from "react-router-dom";
import { fetchSingleRecruitmentApplicationsAPI } from "../../../API/admin/AgentRecruitment";
import AgentRecruitmentProfile from "../../../components/AdminComponents/AgentRecruiter/AgentRecruitmentProfile";
import ComponentLoading from "../../CommonPreloader/ComponentLoading";

const AgentSingleProfile = () => {
  const [agentData, setAgentData] = useState({});
  const [isEditAgent, setIsEditAgent] = useState(false);

  const [componentLoader, setComponentLoader] = useState(true);
  useEffect(() => {
    if (componentLoader) {
      // Set a delay of 3 seconds
      const timer = setTimeout(() => {
        setComponentLoader(false);
      }, 500);

      // Cleanup function to clear the timer if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [componentLoader]);

  const { state } = useLocation();
  const handleCloseModal = () => {
    setIsEditAgent(false);
  };
  useEffect(() => {
    const fetchSingleAgents = async () => {
      try {
        const response = await fetchSingleRecruitmentApplicationsAPI(
          state?.agentId
        );

        if (response?.status === 200) {
          setAgentData(response?.data);
        } else {
          setAgentData([]);
        }
      } catch (error) {
        setAgentData([]);
      }
    };
    fetchSingleAgents(state.agentId);
  }, [state]);

  return (
    <>
      <Layout>
        {!componentLoader ? (
          <div className="relative">
            {Object.keys(agentData).length > 0 ? (
              <AgentRecruitmentProfile
                agentData={agentData}
                setIsEditAgent={setIsEditAgent}
                handleCloseModal={handleCloseModal}
              />
            ) : (
              <p>no agent</p>
            )}
            {/* {isEditAgent && (
            <div className="absolute top-0 w-full">
              <EditAgents
                agentData={agentData}
                setAgentData={setAgentData}
                setIsEditAgent={setIsEditAgent}
                handleCloseModal={handleCloseModal}
              />
            </div>
          )} */}
          </div>
        ) : (
          <ComponentLoading />
        )}
      </Layout>
    </>
  );
};

export default AgentSingleProfile;
