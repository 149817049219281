import React from "react";
import { useState } from "react";
import RemoveRedEyeSharpIcon from "@mui/icons-material/RemoveRedEyeSharp";
import VisibilityOffSharpIcon from "@mui/icons-material/VisibilityOffSharp";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Loader from "../Loader/Loader";
import { addAgentToken } from "../../../redux/Auth/AgentAuth";
import { AgentAPI } from "../../../API/Agent/AuthApi";
import { Link } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [message, setMessage] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [isNullPass, setIsNullPass] = useState(false);
  const [isErrorPass, setIsErrorPass] = useState(false);
  const [isErrorEmail, setIsErrorEmail] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(email);
    setIsEmailValid(isValidEmail);
  };
  const handlePassChange = (event) => {
    setIsNullPass(true);
    setIsErrorPass(false);
    const value = event.target.value;
    setPassword(value);
  };
  const validatePassword = () => {
    // Validate password
    if (password.length === 0) {
      setIsNullPass(true);
      setIsPasswordValid(true);
    } else {
      setIsNullPass(false);
      const isValidPassword = password.length >= 8;
      setIsPasswordValid(isValidPassword);
    }
  };
  // function for handle button click
  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    setIsErrorEmail(false);
    setIsErrorPass(false);
    try {
      if (
        email === "" ||
        password === "" ||
        !isEmailValid ||
        !isPasswordValid
      ) {
        setMessage("can't submit");
        return;
      }
      setIsLoader(true);
      const response = await AgentAPI({ email, password });
      console.log(response);
      if (response.status === 200) {
        setIsLoader(false);
        // setting data in redux
        dispatch(addAgentToken({ token: response?.token }));
        // setting admin token in local storage
        localStorage.setItem("agent-auth-token", response?.data?.token);
        // navigate to admin dashboard

        navigate("/agent/dashboard");
      } else if (response?.status === 401) {
        if (response?.data.message === "Invalid password") {
          setIsErrorPass(true);
          setIsLoader(false);
        }
        if (response?.data.message === "Invalid email") {
          setIsErrorEmail(true);
          setIsLoader(false);
        }
        // setMessage("Login failed !");
      }
    } catch (error) {
      console.log(error);
      setIsLoader(false);
    }
  };
  const commonStyle =
    "outline-none mt-[10px] mb-[25px]  text-[13px]  h-[50px]  border-b-2  border-opacity-50 sm:w-[250px] md:w-[305px] md:text-[13px] md:mb-[10px] md:h-[45px] lg:w-[300] lg:text-[15px] xl:w-[350px] xl:text-[15px] xl:h-[50px] xl: xl:mt-[15px]";
  return (
    <div>
      <div className=" my-0 mx-auto px-10 lg:w-[90%] xl:w-[90%]">
        <div className="flex h-[100vh] items-center justify-center">
          <div className=" flex justify-start items-center">
            <div className="flex  justify-center items-center gap-x-[0] md:gap-x-[100px] sm:justify-center h-full">
              {/* <div className="w-20">
                <img
                  className="w-full h-full object-contain"
                  src="/assets/img/superio-logo-01.png"
                  alt="bg"
                />
              </div> */}
              <div>
                <div className="flex flex-col justify-start items-start">
                  <h3 className="text-[26px] font-semibold md:text-[20px] lg:text-[25px] xl:text-[30px] ">
                    Login
                  </h3>
                  <p className="text-sm whitespace-nowrap opacity-45">
                    Agent can login here
                  </p>
                  {/* <p className="text-gray-600 text-[12px] sm:text-[12px] md:text-[12px] lg:text-[15px] xl:text-[16px] whitespace-nowrap">
                    lorem ipsum dolor sit Amer consectetur
                  </p> */}
                  <input
                    className={
                      isEmailValid
                        ? `border-black placeholder-black text-black w-full ${commonStyle}`
                        : `border-red-600 placeholder-red-600 text-red-600 w-full ${commonStyle}`
                    }
                    type="text"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onBlur={validateEmail}
                  />
                  {!isEmailValid && (
                    <p className="text-red-500 text-xs">
                      Please enter a valid email address.
                    </p>
                  )}
                  {isErrorEmail && (
                    <p className="text-red-500 text-xs text-center w-full">
                      Invalid Email
                    </p>
                  )}
                  <div className="flex relative">
                    <input
                      className={
                        isPasswordValid
                          ? `border-black placeholder-black text-black md:w-2/12 ${commonStyle}`
                          : !isNullPass
                          ? `border-red-600 placeholder-red-600 text-red-600 w-2/12 ${commonStyle}`
                          : `border-red-600 placeholder-red-600 text-red-600 w-2/12 ${commonStyle}`
                      }
                      type={isVisible ? "text" : "password"}
                      placeholder="Password"
                      value={password}
                      onChange={handlePassChange}
                      onBlur={validatePassword}
                    />
                    <button
                      className="absolute right-0 top-7 bg-white"
                      onClick={() => setIsVisible(!isVisible)}
                    >
                      {!isVisible ? (
                        <RemoveRedEyeSharpIcon />
                      ) : (
                        <VisibilityOffSharpIcon />
                      )}
                    </button>
                  </div>
                  {!isPasswordValid && (
                    <p className="text-red-500 text-xs">
                      Password must be at least 8 characters long.
                    </p>
                  )}
                  {isNullPass && (
                    <p className="text-red-500 text-xs">
                      Must be enter password
                    </p>
                  )}
                  {isErrorPass && (
                    <p className="text-red-500 text-xs text-center w-full">
                      Invalid password
                    </p>
                  )}
                  {message && (
                    <p className="text-red-500 text-xs text-center w-full">
                      {message}
                    </p>
                  )}
                  <button
                    onClick={handleLoginSubmit}
                    className=" bg-black opacity-85  w-[100%] rounded-[30px] shadow-lg shadow-gray-500/50 mt-2"
                    disabled={isLoader}
                  >
                    <a
                      className="text-white text-[13px] p-[5px]  flex items-center justify-center font-medium md:text-[13px] md:p-[3px] xl:text-[17px] xl:p-[5px] xl:font-medium"
                      href
                    >
                      {!isLoader ? <span>LOGIN</span> : <Loader />}
                    </a>
                  </button>

                  <Link to={"/agent/registration"}>
                    <p className="text-sm pt-4">
                      Register?{" "}
                      <span className="text-blue-600 hover:underline">
                        Click Here
                      </span>
                    </p>
                  </Link>
                </div>
              </div>
              <div className="w-1/2">
                <div className="hidden md:block">
                  <div className="w-[700px] h-[700px] overflow-hidden flex justify-center items-center rounded-[30px] md:w-[354px]  md:h-[300px]  lg:w-[405px]  lg:h-[350px] xl:w-[700px]  xl:h-[700px] ">
                    <img
                      className=" w-[100%] h-[100%] object-cover"
                      src="/assets/img/image.png"
                      alt="img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
