import React from "react";
import { Link } from "react-router-dom";

const CollegeCard = ({ college }) => {
  return (
    <Link to={`${college?.name}`} state={{ collegeId: college?._id }}>
      {/* <div className="cursor-pointer border border-1 border-gray-100 p-[30px] m-[10px] min-h-[130px] flex flex-col shadow-md hover:shadow-sm shadow-gray-300 rounded-md hover:ring ring-gray-300 transition">
        <p className="font-bold">{college?.name}</p>
        <p className="text-[12px] text-black">{college?.address?.city},{college?.address?.state},{college?.address?.country}</p>
      </div> */}
      <div class="relative p-[30px] m-[10px]  min-h-[130px] overflow-hidden font-medium border  group shadow-md group rounded-md ">
        <span class="absolute top-0 left-0 w-0 h-0 transition-all duration-200 border-t-2 border-gray-300 group-hover:w-full ease"></span>
        <span class="absolute bottom-0 right-0 w-0 h-0 transition-all duration-200 border-b-2 border-gray-300 group-hover:w-full ease"></span>
        <span class="absolute top-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-black group-hover:h-full ease"></span>
        <span class="absolute bottom-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-black group-hover:h-full ease"></span>
        <span class="absolute inset-0 w-full h-full duration-300 delay-300 bg-black opacity-0 group-hover:opacity-100"></span>
        <span class="relative transition-colors duration-300 delay-200 text-black group-hover:text-white ease">
          <p className="font-bold">{college?.name}</p>
          <p className="text-[12px] ">
            {college?.address?.state},{college?.address?.country}
          </p>
        </span>
      </div>
    </Link>
  );
};

export default CollegeCard;
