import React, { useEffect, useState } from "react";
import LogTabTh from "./LogTabTh";
import LogTabTr from "./LogTabTr";
import { fetchPaymentHistoryAPI } from "../../../API/admin/PaymentsAPI";
import ComponentLoading from "../../../pages/CommonPreloader/ComponentLoading";

const PaymentLogs = () => {
  const [history, setHistory] = useState([]);
  useEffect(() => {
    const handelFetchPaymentHistory = async () => {
      try {
        const response = await fetchPaymentHistoryAPI();
        if (response.status === 200) {
          setHistory(response?.data);
        } else {
        }
      } catch (error) {
        console.log(error);
      }
    };
    handelFetchPaymentHistory();
  }, []);
  return (
    <div>
      <div
        className={
          "p-3 rounded-2xl shadow-lg shadow-gray-300 bg-gray-50 max-h-[80vh] overflow-y-scroll"
        }
      >
        <LogTabTh>
          {history.length > 0 ? (
            history.map((data, index) => (
              <LogTabTr paymentLog={data} key={index} />
            ))
          ) : (
            <>
             History is empty ...
            </>
          )}
        </LogTabTh>
      </div>
    </div>
  );
};

export default PaymentLogs;
