import { API } from "../axios";

export const fetchAgentRecruitmentApplicationsAPI = async () => {
    try {
        const { data, status } = await API.get("/admin/recruitment-applications/all-recruitment-applications");
        return { data, status };
    } catch (error) {
        return error?.response;
    }
}

export const fetchSingleRecruitmentApplicationsAPI = async (appId) => {
    try {
        const { data, status } = await API.get(`/admin/recruitment-applications/single-recruitment-applications/${appId}`);
        return { data, status };
    } catch (error) {
        return error?.response;
    }
}

export const approveDocumentAPI = async (agentId, reference, formData) => {
    try {
        const { data, status } = await API.post(`/admin/recruitment-applications/approve-application/${reference}+${agentId}`, formData)
        return { data, status };
    } catch (error) {
        return error?.response;
    }
}

export const rejectDocumentsApi = async (agentId, reference, formData) => {
    try {
        const { data, status } = await API.post(`/admin/recruitment-applications/reject-application/${reference}+${agentId}`, formData)
        return { data, status };
    } catch (error) {
        return error?.response;
    }
}

export const approveSupportingDocumentsAPI = async (agentId, reference, formData) => {
    try {
        const { data, status } = await API.post(`/admin/recruitment-applications/approve-supporting-documents/${reference}+${agentId}`, formData)
        return { data, status };
    } catch (error) {
        return error?.response;
    }
}


export const rejectSupportingDocumentsAPI = async (agentId, reference, formData) => {
    try {
        const { data, status } = await API.post(`/admin/recruitment-applications/reject-supporting-documents/${reference}+${agentId}`, formData)
        return { data, status };
    } catch (error) {
        return error?.response;
    }
}

export const finalApprovalOfAgentAPI = async (agentId) => {
    try {
        const { data, status } = await API.post(`/admin/recruitment-applications/final-approve-agent/${agentId}`)
        return { data, status };
    } catch (error) {
        return error?.response;
    }
}

export const finalRejectionOfAgentAPI = async (agentId) => {
    try {
        const { data, status } = await API.post(`/admin/recruitment-applications/final-rejection-agent/${agentId}`)
        return { data, status };
    } catch (error) {
        return error?.response;
    }
}