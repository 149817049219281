import { API } from "../axios";

export const fetchALlPaymentsAPI = async () => {
  try {
    const { data, status } = await API.get("/admin/payments/get-all-payments");
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

export const fetchPendingAndPaidPaymentsAPI = async (paymentStatus) => {
  try {
    const { data, status } = await API.get(
      `/admin/payments/get-paid-payments/${paymentStatus}`
    );
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

export const latestPaymentsAPI = async () => {
  try {
    const { data, status } = await API.get(
      `/admin/payments/get-latest-payments/`
    );
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

export const editAgentCommission = async (formData) => {
  try {
    const { data, status } = await API.patch(
      "/admin/payments/update-agent-commission",
      formData
    );
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

export const quickEditAgentRepAndAdminCommission = async (formData) => {
  try {
    const { data, status } = await API.patch(
      "/admin/payments/quick-update-commission",
      formData
    );
    return { data, status };
  } catch (error) {
    console.log(error);
    return error?.response;
  }
};

export const fetchSinglePaymentAPI = async (paymentId) => {
  try {
    const { data, status } = await API.get(
      `/admin/payments/get-single-payment/${paymentId}`
    );
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

export const settlePaymentAPI = async (formData) => {
  try {
    const { data, status } = await API.patch(
      "/admin/payments/settle-payments",
      formData
    );
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

export const deactivateRepresentativeAPI = async (repId) => {
  try {
    const { data, status } = await API.patch(`/admin/deactivate-rep/${repId}`);
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

export const fetchInvoiceAPI = async (paymentId) => {
  try {
    const { data, status } = await API.get(
      `/admin/payments/payment-invoice/${paymentId}`
    );
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

export const fetchCompletedInvoice = async (studentId) => {
  try {
    const { status, data } = await API.get(`/complete-invoice/${studentId}`);
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

export const fetchPaymentHistoryAPI = async (studentId) => {
  try {
    const { status, data } = await API.get(`/admin/payments/payment-history`);
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};
