import React, { useState } from "react";
import RepCommissionTableRow from "./RepCommissionTableRow";

const RepCommissionTable = ({ personData }) => {
  const [data, setData] = useState(personData?.courseCommission);

  return (
    <div className="max-w-3xl  py-5">
      <div className="overflow-x-auto">
        <div>
          <h3 className="py-2 text-xl font-bold ">Setup commission</h3>
        </div>
        <table className="overflow-hidden border rounded bg-gray-200">
          <thead className="text-black">
            <tr>
              <th className="py-3 px-4 text-left">Course</th>
              <th className="py-3 px-4 text-left">Fees</th>
              <th className="py-3 px-4 text-left">Commission</th>
              <th className="py-3 px-4 text-left">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {data?.map((item) => (
              <>
                <RepCommissionTableRow
                  item={item}
                  data={personData}
                  setData={setData}
                />
              </>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RepCommissionTable;
