import React, { useState } from "react";
import { LoginAPI } from "../../../API/admin/AuthApi";
import RemoveRedEyeSharpIcon from "@mui/icons-material/RemoveRedEyeSharp";
import VisibilityOffSharpIcon from "@mui/icons-material/VisibilityOffSharp";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addAdminToken} from "../../../redux/Auth/AdminAuth";
import Loader from "../CreateForms/Loader";
import { World } from "../../../pages/CommonPreloader/GlobFramer";

const globeConfig = {
  pointSize: 4,
  globeColor: "#062056",
  showAtmosphere: true,
  atmosphereColor: "#FFFFFF",
  atmosphereAltitude: 0.1,
  emissive: "#062056",
  emissiveIntensity: 0.1,
  shininess: 0.9,
  polygonColor: "rgba(255,255,255,0.7)",
  ambientLight: "#38bdf8",
  directionalLeftLight: "#ffffff",
  directionalTopLight: "#ffffff",
  pointLight: "#ffffff",
  arcTime: 1000,
  arcLength: 0.9,
  rings: 1,
  maxRings: 3,
  initialPosition: { lat: 22.3193, lng: 114.1694 },
  autoRotate: true,
  autoRotateSpeed: 0.5,
};

const sampleArcs = [
  {
    startLat: 40.7128,
    startLng: -74.006,
    endLat: 34.0522,
    endLng: -118.2437,
    color: "#06b6d4",
    order: 1,
    arcAlt: 0.3,
  },
  {
    startLat: 51.5074,
    startLng: -0.1278,
    endLat: 48.8566,
    endLng: 2.3522,
    color: "#3b82f6",
    order: 2,
    arcAlt: 0.3,
  },
  {
    startLat: 35.6895,
    startLng: 139.6917,
    endLat: -33.8688,
    endLng: 151.2093,
    color: "#6366f1",
    order: 3,
    arcAlt: 0.3,
  },
  {
    startLat: 22.3193,
    startLng: 114.1694,
    endLat: -37.8136,
    endLng: 144.9631,
    color: "#06b6d4",
    order: 4,
    arcAlt: 0.3,
  },
  {
    startLat: -23.5505,
    startLng: -46.6333,
    endLat: 19.4326,
    endLng: -99.1332,
    color: "#3b82f6",
    order: 5,
    arcAlt: 0.3,
  },
  {
    startLat: 55.7558,
    startLng: 37.6173,
    endLat: 39.9042,
    endLng: 116.4074,
    color: "#6366f1",
    order: 6,
    arcAlt: 0.3,
  },
  {
    startLat: 52.52,
    startLng: 13.405,
    endLat: -34.6037,
    endLng: -58.3816,
    color: "#06b6d4",
    order: 7,
    arcAlt: 0.3,
  },
  {
    startLat: 59.9343,
    startLng: 30.3351,
    endLat: 41.9028,
    endLng: 12.4964,
    color: "#3b82f6",
    order: 8,
    arcAlt: 0.3,
  },
  {
    startLat: 28.6139,
    startLng: 77.209,
    endLat: 1.3521,
    endLng: 103.8198,
    color: "#6366f1",
    order: 9,
    arcAlt: 0.3,
  },
  {
    startLat: -1.2921,
    startLng: 36.8219,
    endLat: 13.7563,
    endLng: 100.5018,
    color: "#06b6d4",
    order: 10,
    arcAlt: 0.3,
  },
  {
    startLat: 37.7749,
    startLng: -122.4194,
    endLat: 51.1657,
    endLng: 10.4515,
    color: "#3b82f6",
    order: 11,
    arcAlt: 0.3,
  },
  {
    startLat: -33.8688,
    startLng: 151.2093,
    endLat: 55.7558,
    endLng: 37.6173,
    color: "#6366f1",
    order: 12,
    arcAlt: 0.3,
  },
  {
    startLat: 19.076,
    startLng: 72.8777,
    endLat: 40.7128,
    endLng: -74.006,
    color: "#06b6d4",
    order: 13,
    arcAlt: 0.3,
  },
  {
    startLat: 35.6895,
    startLng: 139.6917,
    endLat: 55.7558,
    endLng: 37.6173,
    color: "#3b82f6",
    order: 14,
    arcAlt: 0.3,
  },
  {
    startLat: 48.8566,
    startLng: 2.3522,
    endLat: 35.6895,
    endLng: 139.6917,
    color: "#6366f1",
    order: 15,
    arcAlt: 0.3,
  },
  {
    startLat: -22.9068,
    startLng: -43.1729,
    endLat: 51.5074,
    endLng: -0.1278,
    color: "#06b6d4",
    order: 16,
    arcAlt: 0.3,
  },
  {
    startLat: 39.9042,
    startLng: 116.4074,
    endLat: 40.7128,
    endLng: -74.006,
    color: "#3b82f6",
    order: 17,
    arcAlt: 0.3,
  },
  {
    startLat: 37.7749,
    startLng: -122.4194,
    endLat: -33.8688,
    endLng: 151.2093,
    color: "#6366f1",
    order: 18,
    arcAlt: 0.3,
  },
  {
    startLat: 51.1657,
    startLng: 10.4515,
    endLat: 55.7558,
    endLng: 37.6173,
    color: "#06b6d4",
    order: 19,
    arcAlt: 0.3,
  },
  {
    startLat: 52.52,
    startLng: 13.405,
    endLat: 48.8566,
    endLng: 2.3522,
    color: "#3b82f6",
    order: 20,
    arcAlt: 0.3,
  },
];

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [message, setMessage] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [isNullPass, setIsNullPass] = useState(false);
  const [isErrorPass, setIsErrorPass] = useState(false);
  const [isErrorEmail, setIsErrorEmail] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(email);
    setIsEmailValid(isValidEmail);
  };
  const handlePassChange = (event) => {
    setIsNullPass(true);
    setIsErrorPass(false);
    const value = event.target.value;
    setPassword(value);
  };
  const validatePassword = () => {
    // Validate password
    if (password.length === 0) {
      setIsNullPass(true);
      setIsPasswordValid(true);
    } else {
      setIsNullPass(false);
      const isValidPassword = password.length >= 8;
      setIsPasswordValid(isValidPassword);
    }
  };
  // function for handle button click
  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    setIsErrorEmail(false);
    setIsErrorPass(false);
    try {
      if (
        email === "" ||
        password === "" ||
        !isEmailValid ||
        !isPasswordValid
      ) {
        setMessage("can't submit");
        return;
      }
      setIsLoader(true);
      const response = await LoginAPI({ email, password });
      if (response.status === 200) {
        setIsLoader(false);
        // setting data in redux
        dispatch(addAdminToken({ token: response?.token }));
        // setting admin token in local storage
        localStorage.setItem("admin-token", response?.data?.token);
        // navigate to admin dashboard
        navigate("/admin/dashboard");
      } else if (response?.status === 401) {
        if (response?.data.message === "Invalid password") {
          setIsErrorPass(true);
          setIsLoader(false);
        }
        if (response?.data.message === "Invalid email") {
          setIsErrorEmail(true);
          setIsLoader(false);
        }
        // setMessage("Login failed !");
      }
    } catch (error) {
      console.log(error);
      setIsLoader(false);
    }
  };
  const commonStyle =
    "outline-none mt-[10px] mb-[25px]  text-[13px]  h-[50px]  border-b-2  border-opacity-50 sm:w-[250px] md:w-[305px] md:text-[13px] md:mb-[10px] md:h-[45px] lg:w-[300] lg:text-[15px] xl:w-[350px] xl:text-[15px] xl:h-[50px] xl: xl:mt-[15px]";
  console.log("sdfsda");
  return (
    <>
      <div className=" my-0 mx-auto px-10 lg:w-[90%] xl:w-[90%]">
        <div className="flex h-[100vh] items-center justify-center">
          <div className=" flex justify-start items-center">
            <div className="flex  justify-center items-center gap-x-[0] md:gap-x-[100px] sm:justify-center h-full">
              {/* <div className="w-20">
                <img
                  className="w-full h-full object-contain"
                  src="/assets/img/superio-logo-01.png"
                  alt="bg"
                />
              </div> */}
              <div>
                <div className="flex flex-col justify-start items-start">
                  <h3 className="text-[26px] font-semibold md:text-[20px] lg:text-[25px] xl:text-[30px] ">
                    Login
                  </h3>
                  {/* <p className="text-gray-600 text-[12px] sm:text-[12px] md:text-[12px] lg:text-[15px] xl:text-[16px] whitespace-nowrap">
                    lorem ipsum dolor sit Amer consectetur
                  </p> */}
                  <input
                    className={
                      isEmailValid
                        ? `border-black placeholder-black text-black w-full ${commonStyle}`
                        : `border-red-600 placeholder-red-600 text-red-600 w-full ${commonStyle}`
                    }
                    type="text"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onBlur={validateEmail}
                  />
                  {!isEmailValid && (
                    <p className="text-red-500 text-xs">
                      Please enter a valid email address.
                    </p>
                  )}
                  {isErrorEmail && (
                    <p className="text-red-500 text-xs text-center w-full">
                      Invalid Email
                    </p>
                  )}
                  <div className="flex relative">
                    <input
                      className={
                        isPasswordValid
                          ? `border-black placeholder-black text-black md:w-2/12 ${commonStyle}`
                          : !isNullPass
                          ? `border-red-600 placeholder-red-600 text-red-600 w-2/12 ${commonStyle}`
                          : `border-red-600 placeholder-red-600 text-red-600 w-2/12 ${commonStyle}`
                      }
                      type={isVisible ? "text" : "password"}
                      placeholder="Password"
                      value={password}
                      onChange={handlePassChange}
                      onBlur={validatePassword}
                    />
                    <button
                      className="absolute right-0 top-7 bg-white"
                      onClick={() => setIsVisible(!isVisible)}
                    >
                      {!isVisible ? (
                        <RemoveRedEyeSharpIcon />
                      ) : (
                        <VisibilityOffSharpIcon />
                      )}
                    </button>
                  </div>
                  {!isPasswordValid && (
                    <p className="text-red-500 text-xs">
                      Password must be at least 8 characters long.
                    </p>
                  )}
                  {isNullPass && (
                    <p className="text-red-500 text-xs">
                      Must be enter password
                    </p>
                  )}
                  {isErrorPass && (
                    <p className="text-red-500 text-xs text-center w-full">
                      Invalid password
                    </p>
                  )}
                  {message && (
                    <p className="text-red-500 text-xs text-center w-full">
                      {message}
                    </p>
                  )}
                  <button
                    onClick={handleLoginSubmit}
                    className=" bg-black opacity-85  w-[100%] rounded-[30px] shadow-lg shadow-gray-500/50 mt-2"
                    disabled={isLoader}
                  >
                    <a
                      className="text-white text-[13px] p-[5px]  flex items-center justify-center font-medium md:text-[13px] md:p-[3px] xl:text-[17px] xl:p-[5px] xl:font-medium"
                      href
                    >
                      {!isLoader ? <span>LOGIN</span> : <Loader />}
                    </a>
                  </button>
                </div>
              </div>
              <div className="w-1/2">
                <div className="hidden md:block">
                  <div className="w-[700px] bg-gray-200 h-[700px] overflow-hidden flex justify-center items-center rounded-[30px] md:w-[354px]  md:h-[300px]  lg:w-[405px]  lg:h-[350px] xl:w-[700px]  xl:h-[700px] ">
                    <img
                      className=" w-[100%] h-[100%] object-cover"
                      src="/assets/img/image.png"
                      alt="img"
                    />
                    {/* <World globeConfig={globeConfig} data={sampleArcs} /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
