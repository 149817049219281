import React, { useEffect, useState } from "react";
import Layout from "../../../components/AdminComponents/Layout/Layout";
import RepTable from "../../../components/AdminComponents/RepTable/RepTable";
import RepTableRows from "../../../components/AdminComponents/RepTable/RepTableRows";
import { fetchAllRepresentativesAPI } from "../../../API/admin/AdminAPI";
import AddRepresentative from "../../../components/AdminComponents/CreateForms/AddRepresentative";
import ComponentLoading from "../../CommonPreloader/ComponentLoading";

const Representatives = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [representatives, setRepresentatives] = useState([]);

  const [componentLoader, setComponentLoader] = useState(true);
  useEffect(() => {
    if (componentLoader) {
      // Set a delay of 3 seconds
      const timer = setTimeout(() => {
        setComponentLoader(false);
      }, 500);

      // Cleanup function to clear the timer if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [componentLoader]);

  const fetchRepresentatives = async () => {
    const response = await fetchAllRepresentativesAPI();
    if (response.status === 200) {
      setRepresentatives(response?.data);
    } else {
      setRepresentatives([]);
    }
  };
  useEffect(() => {
    fetchRepresentatives();
  }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  return (
    <Layout>
      {!componentLoader ? (
        <div className="relative">
          <div
            className={
              isModalOpen
                ? "blur-sm relative pt-[30px] p-3 rounded-2xl  shadow-lg shadow-gray-500 bg-gray-50 mt-[30px] max-h-[90vh] overflow-y-scroll cursor-not-allowed"
                : "pt-[30px] p-3 rounded-2xl  shadow-lg shadow-gray-500 bg-gray-50 mt-[30px] max-h-[90vh] overflow-y-scroll"
            }
          >
            <RepTable setIsModalOpen={setIsModalOpen}>
              {representatives.length > 0 &&
                representatives.map((rep, i) => (
                  <RepTableRows rep={rep} key={rep?._id} />
                ))}
            </RepTable>
          </div>
          {isModalOpen && (
            <div className="absolute top-0 z-10 w-full">
              <AddRepresentative
                handleCloseModal={handleCloseModal}
                setRepresentatives={setRepresentatives}
                representatives={representatives}
              />
            </div>
          )}
        </div>
      ) : (
        <ComponentLoading />
      )}
    </Layout>
  );
};

export default Representatives;
