import React, { useEffect, useState } from "react";
import Layout from "../../../components/AdminComponents/Layout/Layout";
import { useLocation } from "react-router-dom";
import { fetchRepDataAPI } from "../../../API/admin/AdminAPI";
import RepProfileCompo from "../../../components/AdminComponents/Profiles/RepProfileCompo";
import EditRep from "../../../components/AdminComponents/EditForms.jsx/EditRep";
import ComponentLoading from "../../CommonPreloader/ComponentLoading";

const RepProfile = () => {
  const [repData, setRepData] = useState({});
  const [isEditRep, setIsEditRep] = useState(false);
  const { state } = useLocation();
  const handleCloseModal = () => {
    setIsEditRep(false);
  };

  const [componentLoader, setComponentLoader] = useState(true);
  useEffect(() => {
    if (componentLoader) {
      // Set a delay of 3 seconds
      const timer = setTimeout(() => {
        setComponentLoader(false);
      }, 500);

      // Cleanup function to clear the timer if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [componentLoader]);

  useEffect(() => {
    const fetchRepData = async () => {
      try {
        const response = await fetchRepDataAPI(state.repId);
        if (response.status === 200) {
          setRepData(response?.data);
        } else {
          setRepData({});
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchRepData();
  }, [state]);

  return (
    <Layout>
      {!componentLoader ? (
        <div className="relative">
          {Object.keys(repData).length !== 0 ? (
            <RepProfileCompo repData={repData} setIsEditRep={setIsEditRep} />
          ) : <ComponentLoading />}
          {isEditRep && (
            <div className="absolute top-0 w-full">
              <EditRep
                repData={repData}
                setRepData={setRepData}
                setIsEditRep={setIsEditRep}
                handleCloseModal={handleCloseModal}
              />
            </div>
          )}
        </div>
      ) : (
        <ComponentLoading />
      )}
    </Layout>
  );
};

export default RepProfile;
