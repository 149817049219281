import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
// import { useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import { fetchAgentProfileForSideBarAPI } from "../../../API/Agent/AgentAPI";

const AgentSideBar = ({ setNavButton, navButton }) => {
  const location = useLocation();
  const pathname = location.pathname;
  const parts = pathname.split("/");
  const active = "text-[20px] font-bold";
  const inActive = "text-[18px] hover:font-bold transition";
  // const adminData = useSelector((state) => state.adminData.adminData);
  const [agentData, setAgentData] = useState({});
  const agentToken = localStorage.getItem("agent-auth-token");
  const [agentId, setAgentId] = useState("");
  useEffect(() => {
    // Check if the token exists before attempting to decode
    if (agentToken) {
      // Decode the JWT
      const decodedToken = jwt_decode(agentToken);

      // Access the decoded information
      console.log(decodedToken);

      // Do something with the decoded information if needed
      // For example, update state or make an API request using the decoded information
      setAgentId(decodedToken.agentId);
    }
  }, [agentToken]); // Run the effect when agentToken changes

  useEffect(() => {
    const fetchSingleAgents = async () => {
      try {
        // Add a null check for state before accessing state.agentId
        if (agentId.length !== 0) {
          const response = await fetchAgentProfileForSideBarAPI(agentId);
          if (response?.status === 200) {
            setAgentData(response?.data);
          } else {
            setAgentData([]);
          }
        } else {
          setAgentData([]); // Handle the case when state or state.agentId is null or undefined
        }
      } catch (error) {
        setAgentData([]);
      }
    };

    fetchSingleAgents(); // Remove the unnecessary argument from fetchSingleAgents
  }, [agentId]);
  return (
    <div>
      <div className="">
        <div className="h-[100vh] relative">
          <div
            onClick={() => setNavButton(!navButton)}
            className="md:hidden absolute right-0 px-5 py-6 "
          >
            <CloseIcon sx={{ fontSize: 35 }} />
          </div>
          <div className="flex flex-col justify-center items-center">
            {/* dashboardNavbar */}
            <div className="w-full h-[70px] overflow-hidden mb-[50px]">
              {/* logo */}
              <img
                className="w-[100%] h-[100%] object-contain"
                src="/assets/img/logo-01.png"
                alt="logo"
              />
            </div>
            {/* profilePhoto */}
            <div className="relative">
              <Link to="/agent/profile">
                <div className="w-[100px] h-[100px] overflow-hidden rounded-[50%] border-2 border-red-600">
                  {agentData?.type === "representative" ? (
                    <img
                      className="w-[100%] h-[100%] object-contain"
                      src="https://beta.acetek.edu.sg/wp-content/themes/acetek/assets/images/favicon.png"
                      alt="profile"
                    />
                  ) : (
                    <img
                      className="w-[100%] h-[100%] object-contain"
                      // src="/assets/img/user.png"
                      src="https://beta.acetek.edu.sg/wp-content/themes/acetek/assets/images/favicon.png"
                      alt="profile"
                    />
                  )}
                </div>
              </Link>
              {/* <div className="absolute right-0 bottom-0 bg-white rounded-full p-1">
              <CameraAltIcon  />
            </div> */}
            </div>
            {/* name */}
            <div className="pb-[60px]">
              <Link to="/agent/profile">
                <p className="text-lg sm:text-md font-bold hover:underline text-center">
                  {agentData?.name ? agentData?.name : "Name"}
                </p>
              </Link>
              <p className="text-red-600 cursor-default text-center">
                {agentData?.type ? agentData?.type : "Type"}
              </p>
            </div>
            {/* dashboard */}
            <div className="pb-[10px]">
              <Link to={"/agent/dashboard"}>
                <p
                  className={
                    parts[parts.length - 1] === "dashboard" ? active : inActive
                  }
                >
                  Dashboard
                </p>
              </Link>
            </div>
            {/* college */}
            <div className="pb-[10px]">
              <Link to={"/agent/colleges"}>
                <p
                  className={
                    parts[parts.length - 1] === "colleges" ? active : inActive
                  }
                >
                  Colleges
                </p>
              </Link>
            </div>
            {/* payments */}
            {/* <div className="pb-[10px]">
              <Link to={"/agent/payments"}>
                <p
                  className={
                    parts[parts.length - 1] === "payments" ? active : inActive
                  }
                >
                  Payments
                </p>
              </Link>
            </div> */}
            {/* admins */}
            {/* <div className="pb-[10px]">
              <Link to={"/agent/reports"}>
                <p
                  className={
                    parts[parts.length - 1] === "reports" ? active : inActive
                  }
                >
                  Reports
                </p>
              </Link>
            </div> */}
            {/* horizontalline */}
            <div className="border border-black opacity-50 w-[200px] mt-32 mb-2" />
            {/* copy right */}
            <div className="max-w-[220px] flex-col items-center justify-center text-[13px] px-3 text-center">
              <p className="flex flex-col">
                <span>@2023</span>
                <Link
                  to={"https://superiodigital.com/"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="font-semibold hover:underline cursor-pointer hover:text-orange-600">
                    Superio Digital Solution
                  </span>
                </Link>
                <span> All rights reserved.</span>
              </p>
              {/* Developed by {""}
            <span className="font-semibold">Superio Digital Solution</span>{" "} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentSideBar;
