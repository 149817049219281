import "./App.css";
import Home from "./components/AdminComponents/Home/Home";
import Admin from "./routes/Admin/Admin";
import { Route, Routes } from "react-router-dom";
import Agent from "./routes/Agent/Agent";
import LandingPage from "./pages/CommonLandingPage/LandingPage";
import { useEffect } from "react";
import { useState } from "react";
import CommonPreloader from "./pages/CommonPreloader/CommonPreloader";
import LetterOfOffer from "./pages/Pdf Templates/LetterOfOffer";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate an asynchronous data fetching process
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  return (
    <div className="">
      {loading ? (
        <CommonPreloader />
      ) : (
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/admin" element={<Home />} />
          <Route path="/admin/*" element={<Admin />} />
          {/* <Route path="/agent/" element={<Agent />} /> */}
          <Route path="/agent/*" element={<Agent />} />
        </Routes>
      )}
    </div>
  );
}

export default App;
