import React, { useEffect, useState } from "react";
import Layout from "../../../components/AdminComponents/Layout/Layout.jsx";
import {
  fetchALlPaymentsAPI,
  latestPaymentsAPI,
} from "../../../API/admin/PaymentsAPI.js";
import PaymentTable from "../../../components/AdminComponents/PaymentsTable/PaymentTable.jsx";
import PaymentTableRow from "../../../components/AdminComponents/PaymentsTable/PaymentTableRow.jsx";
import LatestPaymentCard from "../../../components/AdminComponents/PaymentsTable/LatestPaymentCard.jsx";
// import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import { Link } from "react-router-dom";
import Groups2Icon from "@mui/icons-material/Groups2";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ComponentLoading from "../../CommonPreloader/ComponentLoading.jsx";

const Payments = () => {
  const [payments, setPayments] = useState([]);
  const [latestPayments, setLatestPayments] = useState([]);
  const [openInvoice, setIsInvoiceModal] = useState(false);

  const [componentLoader, setComponentLoader] = useState(true);
  useEffect(() => {
    if (componentLoader) {
      // Set a delay of 3 seconds
      const timer = setTimeout(() => {
        setComponentLoader(false);
      }, 500);

      // Cleanup function to clear the timer if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [componentLoader]);

  useEffect(() => {
    const fetchLatestPayments = async () => {
      try {
        const response = await latestPaymentsAPI();
        if (response?.status === 200) {
          setLatestPayments(response?.data);
        } else {
          setLatestPayments([]);
        }
      } catch (error) {
        setLatestPayments([]);
      }
    };
    fetchLatestPayments();
  }, []);

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const response = await fetchALlPaymentsAPI();
        if (response.status === 200) {
          setPayments(response?.data);
        }
      } catch (error) {
        setPayments([]);
      }
    };
    fetchPayments();
  }, []);

  return (
    <Layout>
      {!componentLoader ? (
        <div className="relative">
          <div className="md:flex gap-2 justify-end">
            <div>
              <Link
                to={"/admin/payment-history"}
                className="gap-4 relative inline-flex items-center justify-start py-2 pl-4 pr-12 overflow-hidden font-semibold text-black transition-all duration-150 ease-in-out  hover:pl-10 hover:pr-6 bg-green-50 group w-full"
              >
                <span class="absolute bottom-0 left-0 w-full h-[1px] transition-all duration-150 ease-in-out bg-green-600 group-hover:h-full"></span>
                <span class="absolute right-0 pr-4 duration-200 ease-out group-hover:translate-x-12">
                  <div className="">
                    <ReceiptLongIcon />
                  </div>
                </span>
                <span class="absolute left-0 pl-2.5 -translate-x-12 group-hover:translate-x-0 ease-out duration-200">
                  <div className="text-white">
                    <KeyboardArrowRightIcon />
                  </div>
                </span>
                <span class="relative w-full text-left transition-colors duration-200 ease-in-out group-hover:text-white text-sm">
                  Click to see payment history
                </span>
              </Link>
            </div>
          </div>
          <div
            className={
              "bg-gray-50 shadow-md shadow-gray-200 mb-2 justify-center items-center grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 mt-[20px] md:mt-[20px] md:p-[25px] rounded-xl"
            }
          >
            {latestPayments.length > 0
              ? latestPayments.map((payment) => (
                  <LatestPaymentCard
                    key={payment._id}
                    payment={payment}
                    setIsInvoiceModal={setIsInvoiceModal}
                    openInvoice={openInvoice}
                  />
                ))
              : null}
          </div>
          {/* table */}
          <div
            className={
              "pt-[30px] mt-10 p-3 rounded-2xl shadow-lg shadow-gray-500 bg-gray-50 max-h-[400px] overflow-y-scroll"
            }
          >
            <PaymentTable>
              {payments.length > 0
                ? payments.map((payment) => (
                    <PaymentTableRow key={payment._id} payment={payment} />
                  ))
                : null}
            </PaymentTable>
          </div>
        </div>
      ) : (
        <ComponentLoading />
      )}
    </Layout>
  );
};

export default Payments;
