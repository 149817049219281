import React from "react";

import DashboardContent from "../../components/AgentComponents/Dashboard/DashboardContent";
import AgentLayout from "../../components/AgentComponents/AgentLayout/AgentLayout";

const Dashboard = () => {
  return (
    <div>
      <AgentLayout>
        <DashboardContent />
      </AgentLayout>
    </div>
  );
};

export default Dashboard;
