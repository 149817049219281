import React from "react";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { businessInfoaApi } from "../../../API/Agent/AgentAPI";
import Loader from "../../AgentComponents/Loader/Loader";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import ErrorIcon from "@mui/icons-material/Error";
import VerifiedIcon from "@mui/icons-material/Verified";

const BusinessInformation = ({
  applicationSteps,
  setApplicationSteps,
  formfilledBank,
  setformfilledBank,

}) => {
  const agentTokens = localStorage.getItem("agentToken");
  const [isOpenBank, setIsOpenBank] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [formBankData, setFormBankData] = useState({
    agentTokens: agentTokens,
    numberofEmployees: "",
    whereisyourofficelocated: "",
    listofcountriesthatyourecruitstudentsto: "",
    listofmajoreducationinstitutethatyoucurrentlyrepresentandtheirlocation: "",
    numberofstudentsrecruitedbyyoulast12months: "",
    listofEducationinstitutethatyourepresentinMauritius: "",
  });
  const [formBankErrors, setFormBankErrors] = useState({});
  const handleBankInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "listofcountriesthatyourecruitstudentsto") {
      // Split the value into an array for the specified input
      const arrayValue = value.split(",");

      // Update the form data
      setFormBankData((prevData) => ({
        ...prevData,
        [name]: arrayValue,
      }));
    } else if (name === "listofEducationinstitutethatyourepresentinMauritius") {
      // Split the value into an array for the specified input
      const arrayValue = value.split(",");

      // Update the form data
      setFormBankData((prevData) => ({
        ...prevData,
        [name]: arrayValue,
      }));
    } else if (
      name ===
      "listofmajoreducationinstitutethatyoucurrentlyrepresentandtheirlocation"
    ) {
      // Split the value into an array for the specified input
      const arrayValue = value.split(",");

      // Update the form data
      setFormBankData((prevData) => ({
        ...prevData,
        [name]: arrayValue,
      }));
    } else {
      // For other inputs, update the form data directly
      setFormBankData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const handleBankSubmit = async (e) => {
    e.preventDefault();
    const errors = {};

    // Validation logic

    if (!formBankData.numberofEmployees) {
      errors.numberofEmployees = "This Field  is required";
    }
    if (!formBankData.whereisyourofficelocated) {
      errors.whereisyourofficelocated = "This Field  is required";
    }
    if (!formBankData.listofcountriesthatyourecruitstudentsto) {
      errors.listofcountriesthatyourecruitstudentsto =
        "This Field  is required";
    }
    if (
      !formBankData.listofmajoreducationinstitutethatyoucurrentlyrepresentandtheirlocation
    ) {
      errors.listofmajoreducationinstitutethatyoucurrentlyrepresentandtheirlocation =
        "This Field  is required";
    }

    if (!formBankData.numberofstudentsrecruitedbyyoulast12months) {
      errors.numberofstudentsrecruitedbyyoulast12months =
        "This Field  is required";
    }
    if (!formBankData.listofEducationinstitutethatyourepresentinMauritius) {
      errors.listofEducationinstitutethatyourepresentinMauritius =
        "This Field  is required";
    }

    if (Object.keys(errors).length === 0) {
      try {
        setIsLoader(true);
        const response = await businessInfoaApi(formBankData);
        if (response.status === 201) {
          console.log(response, "response");
          setIsLoader(false);

          setSubmitted(true);

          setApplicationSteps((prevData) => ({
            ...prevData,
            isBusinessInformation: true,
          }));
          {
            submitted && (
              <Stack sx={{ width: "100%" }} spacing={2}>
                <Alert severity="success">
                  This is a success alert — check it out!
                </Alert>
              </Stack>
            );
          }
          setformfilledBank({
            agentTokens: agentTokens,
            numberOfEmployees: response.data.data.numberOfEmployees,
            officeLocation: response.data.data.officeLocation,
            listOfCountries: response.data.data.listOfCountries,
            numberOfStudentsInLastYear:
              response.data.data.numberOfStudentsInLastYear,
            listOfInStituteInMauritius:
              response.data.data.listOfInStituteInMauritius,
            majorEducationInstitute: response.data.data.majorEducationInstitute,
          });
        } else {
          alert("Failed to submit form data.");
        }
      } catch (error) {
        console.error("API request failed:", error);
      } finally {
        setIsLoader(false);
      }
    } else {
      // If there are errors, set them in the state
      setFormBankErrors(errors);
      setIsLoader(false);
    }
  };

  const toggleAccordionBank = () => {
    setIsOpenBank(!isOpenBank);
  };
  return (
    <div>
      {applicationSteps.isBusinessInformation ? (
        <div>
          <div
            className={`accordion ${
              isOpenBank ? "open" : ""
            }mb-6 rounded-none `}
          >
            <div
              className="accordion-header w-[374px] md:w-[814px] rounded shadow-lg"
              onClick={toggleAccordionBank}
            >
              <div className="flex justify-center items-center gap-x-4">
               
                {formfilledBank.isRejected ? (
                        <div className="    flex  items-center gap-x-2 bg-red-500 px-1 py-1 rounded ">
                        <p className="text-white font-bold text-sm">STEP 2</p>
                      </div>
                      ) : (
                        <div className="    flex  items-center gap-x-2 bg-green-500 px-1 py-1 rounded ">
                        <p className="text-white font-bold text-sm">STEP 2</p>
                      </div>
                      )}

                <div>
                  <p className="text-md font-semibold ">BUSINESS INFORMATION</p>
                </div>
              </div>
              <div className="flex justify-end items-end">
                <i className="fa-solid fa-angle-up" />
                <span className="icon">
                  {isOpenBank ? <RemoveIcon /> : <AddIcon />}
                </span>
              </div>
            </div>

            {isOpenBank && (
              <form>
                <div className="accordion-content text-black">
                  <div className="panel w-full sm:w-full md:w-[814px]  flex justify-center sm:flex sm:justify-content  md:flex-row  md:justify-center md:item-center shadow-lg ">
                    <div className="py-10 flex flex-col justify-between">
                      <div className=" flex justify-between items-center flex-col md:flex-row gap-x-6 ">
                        <div className="mb-4">
                          <label
                            htmlFor="name"
                            className="block text-black  text-sm mb-2"
                          >
                            Number of Employees
                          </label>
                          <input
                            type="number"
                            id="numberofEmployees"
                            name="numberofEmployees"
                            value={formfilledBank?.numberOfEmployees || ""}
                            onChange={handleBankInputChange}
                            placeholder="Number of Employees"
                            disabled={submitted && !formfilledBank.isRejected}
                            className="border border-gray-300 rounded-md w-[350px] text-[13px]  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          {formBankErrors.numberofEmployees && (
                            <p className="text-red-500 text-sm">
                              {formBankErrors.numberofEmployees}
                            </p>
                          )}
                        </div>
                        <div className="mb-4">
                          <label
                            htmlFor="email"
                            className="block text-gray-700 text-sm mb-2"
                          >
                            Where is your office located
                          </label>
                          <input
                            type="text"
                            id="whereisyourofficelocated"
                            name="whereisyourofficelocated"
                            value={formfilledBank?.officeLocation || ""}
                            onChange={handleBankInputChange}
                            placeholder=" Where is your office located"
                            disabled={submitted && !formfilledBank.isRejected}
                            className="border border-gray-300 rounded-md w-[350px] text-[13px]  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          {formBankErrors.whereisyourofficelocated && (
                            <p className="text-red-500 text-sm">
                              {formBankErrors.wereisyourofficelocated}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className=" flex-col md:flex-row flex justify-between items-center gap-x-6 ">
                        <div className="mb-4">
                          <label
                            htmlFor="email"
                            className="block text-gray-700 text-sm mb-2"
                          >
                            List of countries that you recruit students to
                          </label>
                          <input
                            type="text"
                            id="listofcountriesthatyourecruitstudentsto"
                            name="listofcountriesthatyourecruitstudentsto"
                            value={formfilledBank?.listOfCountries || ""}
                            onChange={handleBankInputChange}
                            disabled={submitted && !formfilledBank.isRejected}
                            placeholder="
                        Countries in Avis student recruitment."
                            className="border border-gray-300 rounded-md w-[350px] text-[13px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          {formBankErrors.listofcountriesthatyourecruitstudentsto && (
                            <p className="text-red-500 text-sm">
                              {
                                formBankErrors.listofcountriesthatyourecruitstudentsto
                              }
                            </p>
                          )}
                        </div>
                        <div className="mb-4">
                          <label
                            htmlFor="email"
                            className="block text-gray-700 text-sm mb-2"
                          >
                            Number of students recruited by you last<br></br> 12
                            months
                          </label>
                          <input
                            type="number"
                            id="numberofstudentsrecruitedbyyoulast12months"
                            name="numberofstudentsrecruitedbyyoulast12months"
                            value={
                              formfilledBank?.numberOfStudentsInLastYear || ""
                            }
                            onChange={handleBankInputChange}
                            disabled={submitted && !formfilledBank.isRejected}
                            placeholder="Students recruited in the last 12 months."
                            className="border border-gray-300 rounded-md w-[350px] text-[13px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          {formBankErrors.numberofstudentsrecruitedbyyoulast12months && (
                            <p className="text-red-500 text-sm">
                              {
                                formBankErrors.numberofstudentsrecruitedbyyoulast12months
                              }
                            </p>
                          )}
                        </div>
                      </div>
                      <div className=" flex-col md:flex-row flex justify-between items-center gap-x-6 ">
                        <div className="mb-4">
                          <label
                            htmlFor="email"
                            className="block text-gray-700 text-sm mb-2"
                          >
                            List of Education institute thatyou represent
                            <br></br>in Mauritius
                          </label>
                          <input
                            type="text"
                            id="listofEducationinstitutethatyourepresentinMauritius"
                            name="listofEducationinstitutethatyourepresentinMauritius"
                            value={
                              formfilledBank?.listOfInStituteInMauritius || ""
                            }
                            onChange={handleBankInputChange}
                            disabled={submitted && !formfilledBank.isRejected}
                            placeholder="Mauritius institute representation."
                            className="border border-gray-300 rounded-md w-[350px] text-[13px]  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          {formBankErrors.listofEducationinstitutethatyourepresentinMauritius && (
                            <p className="text-red-500 text-sm">
                              {
                                formBankErrors.listofEducationinstitutethatyourepresentinMauritius
                              }
                            </p>
                          )}
                        </div>
                        <div className="mb-4">
                          <label
                            htmlFor="email"
                            className="block text-gray-700 text-sm mb-2"
                          >
                            List of Major Education Institute That You Currently{" "}
                            <br></br>Represent And Their Location
                          </label>
                          <input
                            type="text"
                            id="listofmajoreducationinstitutethatyoucurrentlyrepresentandtheirlocation"
                            name="listofmajoreducationinstitutethatyoucurrentlyrepresentandtheirlocation"
                            value={
                              formfilledBank?.majorEducationInstitute || ""
                            }
                            onChange={handleBankInputChange}
                            disabled={submitted && !formfilledBank.isRejected}
                            placeholder="Representing major institutes, locations."
                            className="border border-gray-300 rounded-md w-[350px] text-[13px]  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          {formBankErrors.listofmajoreducationinstitutethatyoucurrentlyrepresentandtheirlocation && (
                            <p className="text-red-500 text-sm">
                              {
                                formBankErrors.listofmajoreducationinstitutethatyoucurrentlyrepresentandtheirlocation
                              }
                            </p>
                          )}
                        </div>
                      </div>
                      {formfilledBank.isRejected ? (
                        <div className="flex flex-end justify-end pb-10">
                          <button
                            type="submit"
                            className="text-sm sm:text-md border sm:w-full md:w-[250px] text-red-600 border-red-500 font-bold py-2 px-10 rounded focus:outline-none focus:shadow-outline shadow-lg "
                            onClick={handleBankSubmit}
                            disabled={isLoader}
                          >
                            {isLoader ? <Loader /> : "Submit Application"}
                          </button>
                        </div>
                      ) : (
                       null
                      )}

                      <p>
                        {formfilledBank.isRejected ? (
                          <div className="bg-red-100 rounded-md ">
                            <div>
                              <p className="text-lg font-semibold text-red-600 px-8 pt-1 ">
                                Error
                              </p>
                            </div>
                            <div className=" flex items-center pb-2  px-6">
                              <div className="text-red-600">
                                <ErrorIcon />
                              </div>
                              <div>
                                <p className="text-[13px] text-red-500 font-semibold px-2">
                                  Your Applicant Information Rejected By Admin
                                </p>
                                <p className="text-[13px] text-red-500 font-semibold px-2">Admin Message:</p>
                              </div>
                            </div>
                            <div className="h-1 rounded-lg bg-red-400"></div>
                          </div>
                        ) : (
                          null
                        )}
                      </p>
                      <p>
                        {formfilledBank.isApproved ? (
                          <div className="bg-green-100 rounded-md ">
                            <div>
                              <p className="text-lg font-semibold text-green-600 px-8 pt-1 ">
                                Error
                              </p>
                            </div>
                            <div className=" flex items-center pb-2  px-6">
                              <div className="text-green-600">
                                <VerifiedIcon/>
                              </div>
                              <div>
                                <p className="text-[13px] text-green-500 font-semibold px-2">
                                  Your Applicant Information Approved By Admin
                                </p>
                              </div>
                            </div>
                            <div className="h-1 rounded-lg bg-green-400"></div>
                          </div>
                        ) : (
                          null
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      ) : (
        <div>
          <div
            className={`accordion ${
              isOpenBank ? "open" : ""
            }mb-6 rounded-none `}
          >
            <div
              className="accordion-header w-[374px] md:w-[814px] rounded shadow-lg"
              onClick={toggleAccordionBank}
            >
              <div className="flex justify-center items-center gap-x-4">
                <div className="    flex  items-center gap-x-2 bg-red-500 px-1 py-1 rounded ">
                  <p className="text-white font-bold text-sm">STEP 2</p>
                </div>
                <div>
                  <p className="text-md font-semibold ">BUSINESS INFORMATION</p>
                </div>
              </div>
              <div className="flex justify-end items-end">
                <i className="fa-solid fa-angle-up" />
                <span className="icon">
                  {isOpenBank ? <RemoveIcon /> : <AddIcon />}
                </span>
              </div>
            </div>

            {isOpenBank && (
              <form>
                <div className="accordion-content text-black">
                  <div className="panel w-full sm:w-full md:w-[814px]  flex justify-center sm:flex sm:justify-content  md:flex-row  md:justify-center md:item-center shadow-lg ">
                    <div className="py-10 flex flex-col justify-between">
                      <div className=" flex justify-between items-center flex-col md:flex-row gap-x-6 ">
                        <div className="mb-4">
                          <label
                            htmlFor="name"
                            className="block text-black  text-sm mb-2"
                          >
                            Number of Employees
                          </label>
                          <input
                            type="number"
                            id="numberofEmployees"
                            name="numberofEmployees"
                            value={formBankData.numberofEmployees}
                            onChange={handleBankInputChange}
                            placeholder="Number of Employees"
                            disabled={submitted}
                            className="border border-gray-300 rounded-md w-[350px] text-[13px]  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          {formBankErrors.numberofEmployees && (
                            <p className="text-red-500 text-sm">
                              {formBankErrors.numberofEmployees}
                            </p>
                          )}
                        </div>
                        <div className="mb-4">
                          <label
                            htmlFor="email"
                            className="block text-gray-700 text-sm mb-2"
                          >
                            Where is your office located
                          </label>
                          <input
                            type="text"
                            id="whereisyourofficelocated"
                            name="whereisyourofficelocated"
                            value={formBankData.whereisyourofficelocated}
                            onChange={handleBankInputChange}
                            placeholder=" Where is your office located"
                            disabled={submitted}
                            className="border border-gray-300 rounded-md w-[350px] text-[13px]  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          {formBankErrors.whereisyourofficelocated && (
                            <p className="text-red-500 text-sm">
                              {formBankErrors.whereisyourofficelocated}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className=" flex-col md:flex-row flex justify-between items-center gap-x-6 ">
                        <div className="mb-4">
                          <label
                            htmlFor="email"
                            className="block text-gray-700 text-sm mb-2"
                          >
                            List of countries that you recruit students to
                          </label>
                          <input
                            type="text"
                            id="listofcountriesthatyourecruitstudentsto"
                            name="listofcountriesthatyourecruitstudentsto"
                            value={
                              formBankData.listofcountriesthatyourecruitstudentsto
                            }
                            onChange={handleBankInputChange}
                            disabled={submitted}
                            placeholder="
                        Countries in Avis student recruitment."
                            className="border border-gray-300 rounded-md w-[350px] text-[13px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          {formBankErrors.listofcountriesthatyourecruitstudentsto && (
                            <p className="text-red-500 text-sm">
                              {
                                formBankErrors.listofcountriesthatyourecruitstudentsto
                              }
                            </p>
                          )}
                        </div>
                        <div className="mb-4">
                          <label
                            htmlFor="email"
                            className="block text-gray-700 text-sm mb-2"
                          >
                            Number of students recruited by you last 12 months
                          </label>
                          <input
                            type="number"
                            id="numberofstudentsrecruitedbyyoulast12months"
                            name="numberofstudentsrecruitedbyyoulast12months"
                            value={
                              formBankData.numberofstudentsrecruitedbyyoulast12months
                            }
                            onChange={handleBankInputChange}
                            disabled={submitted}
                            placeholder="Students recruited in the last 12 months."
                            className="border border-gray-300 rounded-md w-[350px] text-[13px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          {formBankErrors.numberofstudentsrecruitedbyyoulast12months && (
                            <p className="text-red-500 text-sm">
                              {
                                formBankErrors.numberofstudentsrecruitedbyyoulast12months
                              }
                            </p>
                          )}
                        </div>
                      </div>
                      <div className=" flex-col md:flex-row flex justify-between items-center gap-x-6 ">
                        <div className="mb-4">
                          <label
                            htmlFor="email"
                            className="block text-gray-700 text-sm mb-2"
                          >
                            List of Education institute that you represent in
                            <br></br>
                            Mauritius
                          </label>
                          <input
                            type="text"
                            id="listofEducationinstitutethatyourepresentinMauritius"
                            name="listofEducationinstitutethatyourepresentinMauritius"
                            value={
                              formBankData.listofEducationinstitutethatyourepresentinMauritius
                            }
                            onChange={handleBankInputChange}
                            disabled={submitted}
                            placeholder="Mauritius institute representation."
                            className="border border-gray-300 rounded-md w-[350px] text-[13px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          {formBankErrors.listofEducationinstitutethatyourepresentinMauritius && (
                            <p className="text-red-500 text-sm">
                              {
                                formBankErrors.listofEducationinstitutethatyourepresentinMauritius
                              }
                            </p>
                          )}
                        </div>
                        <div className="mb-4">
                          <label
                            htmlFor="email"
                            className="block text-gray-700 text-sm mb-2"
                          >
                            List of Major Education Institute That You Currently{" "}
                            <br></br>Represent And Their Location
                          </label>
                          <input
                            type="text"
                            id="listofmajoreducationinstitutethatyoucurrentlyrepresentandtheirlocation"
                            name="listofmajoreducationinstitutethatyoucurrentlyrepresentandtheirlocation"
                            value={
                              formBankData.listofmajoreducationinstitutethatyoucurrentlyrepresentandtheirlocation
                            }
                            onChange={handleBankInputChange}
                            disabled={submitted}
                            placeholder="Representing major institutes, locations.."
                            className="border border-gray-300 rounded-md w-[350px] text-[13px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          {formBankErrors.listofmajoreducationinstitutethatyoucurrentlyrepresentandtheirlocation && (
                            <p className="text-red-500 text-sm">
                              {
                                formBankErrors.listofmajoreducationinstitutethatyoucurrentlyrepresentandtheirlocation
                              }
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="mb-4 flex justify-center sm:justify-center md:justify-end md:mr-10">
                        <div>
                          {submitted ? (
                            <button
                              type="submit"
                              className="text-sm sm:text-md border sm:w-full md:w-[250px] text-red-600 border-red-500 font-bold py-2 px-10 rounded focus:outline-none focus:shadow-outline shadow-lg hidden"
                              onClick={handleBankSubmit}
                              disabled={isLoader}
                            >
                              {isLoader ? <Loader /> : "Submit Application"}
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="text-sm sm:text-md border sm:w-full md:w-[250px] text-red-600 border-red-500 font-bold py-2 px-10 rounded focus:outline-none focus:shadow-outline shadow-lg"
                              onClick={handleBankSubmit}
                              disabled={isLoader}
                            >
                              {isLoader ? <Loader /> : "Submit Application"}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default BusinessInformation;
