import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchStudentContractDetails } from "../../API/admin/GenerateStudentDocuments";

const AdvisoryNote = () => {
  const handleDownload = () => {
    window.print();
  };
  const [studentContractLetterData, setStudentContractLetterData] = useState(
    {}
  );

  
  // const studentId = useParams();
  const { studentId } = useParams();
  const handelStudentContractDataFetch = async () => {
    try {
      const response = await fetchStudentContractDetails(studentId);
      console.log(response);
      if (response.status === 200) {
        setStudentContractLetterData(response.data);
        console.log(response.data, "response.data");
        document.title = `${response?.data?.name}-Student-Advisory-Note`;
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handelStudentContractDataFetch();
  }, []);
  return (
    <>
      <div className=" px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse no-print fixed right-0">
        <button
          type=""
          className="mt-3 w-full inline-flex justify-center rounded-md border border-green-700 shadow-sm px-4 py-2 bg-green-600 text-white text-base font-medium hover:text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={handleDownload}
        >
          Download
        </button>
      </div>
      <div className="bg-slate-300 w-full h-full flex flex-col justify-center items-center">
        <div className="w-[210mm] bg-white" id="offerLetter">
          <div className="p-10 pt-16">
            <div>
              <span className="flex justify-end text-[13px] font-semibold">
                Regulation 25(5)(b)
              </span>

              {/* Header */}
              <div className="flex text-[16px] font-bold mt-6 flex-col gap-6 text-center items-center">
                <span>
                  FORM 12 <br />
                  PRIVATE EDUCATION ACT <br />
                  (No. 21 of 2009)
                </span>

                <span>
                  PRIVATE EDUCATION REGULATIONS <br />
                  ADVISORY NOTE TO STUDENTS
                </span>
              </div>

              {/* Paragraph */}
              <div className="flex flex-col m-12 pt-4 gap-5 text-[14px]">
                <span>This note is for prospective student.</span>
                <span>
                  You are strongly encouraged to thoroughly research on the
                  private education institution (PEI) conducting the course
                  before signing up for any course. You should consider, for
                  example, the reputation of the PEI, the teacher-student ratio
                  of its classes, the qualifications of the teaching staff, and
                  the course materials provided by the PEI.
                </span>
                <span>
                  By signing and returning the Student Contract (the
                  "Contract"), you agree to the terms and conditions which will
                  bind you and the PEI, if you accept the PEI's offer of a place
                  in a course of study offered or provided by the PEI.
                </span>
                <span>
                  You should ask the PEI to allow you to read a copy of the
                  Contract (with all blanks filled in and options selected) in
                  both English and the official language of your home country,
                  if necessary. For your own protection, you should review all
                  the PEI's policies, and check carefully that you agree to all
                  the terms of the Contract, including the details relating to
                  each of the following sections, before signing the Contract:
                </span>
                <div>
                  <ol type="a" className="list-[lower-alpha] pl-14">
                    <li>
                      The duration of the course, including holidays and
                      examination schedules, and contact hours by days and week;
                    </li>
                    <li>
                      The total fees payable, including course fees and other
                      related costs;
                    </li>
                    <li>Dates when respective payments are due;</li>
                    <li>
                      The refund policy in the event of voluntary withdrawal (by
                      you) or enforced dismissal from the course or programme
                      (by PEI);
                    </li>
                    <li>
                      The Fee Protection Scheme you are subscribed to and its
                      coverage;
                    </li>
                    <li>The dispute resolution methods available; and</li>
                    <li>
                      Information about the PEI’s policies on academic and
                      disciplinary matters.
                    </li>
                    <li>
                      The degree or diploma or qualification which will be
                      awarded to you upon successful completion of the course
                    </li>
                  </ol>
                </div>
              </div>

              <div className="text-center mt-40">1 of 2</div>

              {/* //Nextpage */}
              <div className="flex flex-col m-12 pt-4 gap-5 text-[14px]">
                <span>
                  {" "}
                  If you have any doubt about the contents of the Contract, or
                  if the terms are different from what the agent or the PEI have
                  informed you previously, or advertised, you should always seek
                  advice and/or clarifications before signing the Contract.
                </span>
                <span>
                  -------------------------------------------------------------------------
                </span>
                <span>
                  This portion below is to be completed by the signatory of the
                  Student Contract, i.e. either the student, or if the student
                  is below the age of 18, his parent or guardian
                </span>
                <span className="flex gap-4 pl-3 ">
                  I ,{" "}
                  <span>
                    {" "}
                    <span className="font-semibold flex  flex-col uppercase">
                    {studentContractLetterData?.name}
                    </span>
                    <hr
                      style={{
                        backgroundColor: "black",
                        height: "1px",
                        border: "none",
                      }}
                      className="mt-3 "
                    />
                    <span className="text-[12px]">
                      {" "}
                      (name of student/<strike>parent/guardian </strike>){" "}
                    </span>{" "}
                  </span>{" "}
                  ,{" "}
                  <span>
                    {" "}
                    <strike>NRIC</strike>/passport number,
                  </span>{" "}
                  <span className="font-semibold flex flex-col">
                    <span className="font-semibold">
                      {" "}
                      {studentContractLetterData.passport},
                    </span>
                    <hr
                      style={{
                        backgroundColor: "black",
                        height: "1px",
                        border: "none",
                      }}
                      className="mt-3 "
                    />
                  </span>
                </span>
                <span>
                  have read and understood this advisory note before signing the
                  Student Contract for
                </span>

                <span className="flex gap-4 pl-3 ">
                  <span>
                    myself /<strike> my ward </strike>**(
                  </span>
                  <span>
                    {" "}
                    <span className="font-semibold flex flex-col ">{studentContractLetterData?.age<18?studentContractLetterData?.guardianName:"NA"}</span>
                    <hr
                      style={{
                        backgroundColor: "black",
                        width: "150px",
                        height: "1px",
                        border: "none",
                      }}
                      className="mt-3 "
                    />
                    <span className="text-[12px]"> (name of ward) </span>{" "}
                  </span>{" "}
                  <span>
                    {" "}
                    (<strike>NRIC</strike>/passport)
                  </span>{" "}
                  <span className=" ">
                    <span className="font-semibold"> {studentContractLetterData?.age<18?studentContractLetterData?.guardianPassport:"NA"}</span>
                    
                    <hr
                      style={{
                        backgroundColor: "black",
                        width: "120px",
                        height: "1px",
                        border: "none",
                      }}
                      className="mt-3 "
                    />
                  </span>
                </span>
                <span className="flex gap-4">
                  with{" "}
                  <span className="flex gap-2 flex-col">
                    {" "}
                    <u>Acetek College Pte Ltd</u>
                    <span> (name of PEI)</span>
                  </span>{" "}
                </span>

                <span className="mt-48  text-start flex flex-col  items-end ">
                  <hr
                    style={{
                      backgroundColor: "black",
                      width: "70px",
                      height: "1px",
                      width: "300px",
                      border: "none",
                    }}
                    className="mt-3 "
                  />
                  <span className="text-[12px] pe-10 mt-2">
                    {" "}
                    (signature of student or parent / guardian)
                  </span>
                </span>
                <span className="flex font-semibold gap-2 justify-end text-[16px]">
                  Date:
                  <hr
                    style={{
                      backgroundColor: "black",
                      height: "1px",
                      width: "260px",
                      border: "none",
                    }}
                    className="mt-5 "
                  />
                </span>
                <span className="mt-8 pr-4">
                  *Please delete whichever is inapplicable.
                </span>
                <div className="text-center mt-40">2 of 2</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdvisoryNote;
