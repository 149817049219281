import React, { useEffect, useState } from "react";
import { addCollegesAPI } from "../../../API/admin/AdminAPI";
import Loader from "./Loader";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const AddColleges = ({ handleCloseModal, data, setData }) => {
  const [formErrors, setFormErrors] = useState({});
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [collegeExist, setCollegeExist] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [formData, setFormData] = useState({
    logo: null,
    fullName: "",
    email: "",
    contactNumber: "",
    description: "",
    currency: "",
    state: "",
    country: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const errors = {};
    // Validate each input here
    if (formData.fullName.trim() === "") {
      errors.fullName = "Full Name is required";
    }
    if (formData.email.trim() === "") {
      errors.email = "Email is required";
    }
    if (formData.contactNumber.trim() === "") {
      errors.contactNumber = "contact Number is required";
    }
    // if (formData.description.trim() === "") {
    //   errors.description = "description is required";
    // }
    if (formData.currency.trim() === "") {
      errors.currency = "currency is required";
    }
    if (formData.state.trim() === "") {
      errors.state = "State is required";
    }
    if (formData.country.trim() === "") {
      errors.country = "Country is required";
    }
    return errors;
  };
  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(formData.email);
    setIsEmailValid(isValidEmail);
  };
  const handleFileChange = (event) => {
    const { name, files } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: files[0],
    }));

    // Get the selected image element
    const selectedImage = document.getElementById("selected-image");

    if (files[0]) {
      const reader = new FileReader();

      reader.onload = function (e) {
        // Set the src attribute of the <img> element to the selected image's data URL
        selectedImage.src = e.target.result;

        // Make the <img> element visible
        selectedImage.classList.remove("hidden");
      };

      // Read the selected file as a data URL
      reader.readAsDataURL(files[0]);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const errors = validateForm();

    if (isEmailValid && Object.keys(errors).length === 0) {
      setIsLoader(true);

      try {
        // Create a FormData object to send the file and other form data
        const formDataToSend = new FormData();
        formDataToSend.append("logo", formData.logo);
        formDataToSend.append("fullName", formData.fullName);
        formDataToSend.append("email", formData.email);
        formDataToSend.append("contactNumber", formData.contactNumber);
        // formDataToSend.append('description', formData.description);
        formDataToSend.append("currency", formData.currency);
        formDataToSend.append("state", formData.state);
        formDataToSend.append("country", formData.country);

        try {
          const response = await addCollegesAPI(formData);
          if (response.status === 201) {
            setIsLoader(false);
            // Reset form after successful submission
            setFormData({
              logo: "",
              fullName: "",
              email: "",
              contactNumber: "",
              description: "",
              currency: "",
              state: "",
              country: "",
            });
            setData([...data, response.data]);
            handleCloseModal();
          } else if (response.status === 409) {
            setIsLoader(false);
            setCollegeExist(true);
          }
        } catch (error) {
          console.log(error);
          setIsLoader(false);
        }
      } catch (error) {
        console.error("Error:", error);
        setIsLoader(false);
      }
    } else {
      setFormErrors(errors);
      setIsLoader(false);
    }
  };
  return (
    <div className="w-full py-[20px]">
      <div className="py-[10px] rounded-2xl shadow-lg shadow-gray-500 w-full bg-white">
        <div className="w-[95%] mx-auto">
          {/* heading */}
          <div className=" text-center md:py-[20px] text-[30px] md:text-left flex py-1">
            <p className="font-bold border-b-2 border-gray-400">Add College</p>
          </div>
          <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-3 justify-between">
            {/* name */}
            <div>
              <label
                className="block mb-2 font-medium text-sm"
                htmlFor="fullName"
              >
                College Name:
              </label>
              <input
                className="block px-4 py-2 w-full border placeholder:text-gray-500  border-gray-300 rounded-lg"
                type="text"
                id="fullName"
                name="fullName"
                placeholder="College Name"
                value={formData.fullName}
                onChange={handleInputChange}
                required
              />
              {formErrors.fullName && (
                <span className="text-xs text-red-600">
                  {formErrors.fullName}
                </span>
              )}
            </div>
            {/* phone */}
            <div>
              <label
                className="block mb-2 font-medium text-sm"
                htmlFor="contactNumber"
              >
                Contact Number:
              </label>
              <input
                className="block px-4 py-2 w-full border placeholder:text-gray-500  border-gray-300 rounded-lg"
                type="tel"
                id="contactNumber"
                name="contactNumber"
                placeholder="Contact Number"
                value={formData.contactNumber}
                onChange={handleInputChange}
                required
              />
              {formErrors.contactNumber && (
                <span className="text-xs text-red-600">
                  {formErrors.contactNumber}
                </span>
              )}
            </div>
            {/* mail id */}
            <div>
              <label
                className="block mb-2 font-medium text-sm"
                htmlFor="emailAddress"
              >
                Email Address:
              </label>
              <input
                className="block px-4 py-2 w-full border placeholder:text-gray-500  border-gray-300 rounded-lg"
                type="email"
                id="email"
                name="email"
                placeholder="Email Address"
                value={formData.email}
                onChange={handleInputChange}
                onBlur={validateEmail}
                required
              />
              {collegeExist && (
                <span className="text-xs text-red-600">
                  Email already exist !
                </span>
              )}
              {!isEmailValid && (
                <span className="text-xs text-red-600">
                  Please enter a valid email address.
                </span>
              )}
              {formErrors.email && (
                <span className="text-xs text-red-600">{formErrors.email}</span>
              )}
            </div>
            <div>
              <label
                className="block mb-2 font-medium text-sm"
                htmlFor="currency"
              >
                Currency:
              </label>
              <select
                className="block w-full px-4 py-2 border placeholder:text-gray-500  border-gray-300 rounded-lg"
                id="currency"
                name="currency"
                value={formData.currency}
                onChange={handleInputChange}
                required
              >
                <option className="text-black" value="">
                  Select a Currency
                </option>
                <option className="text-black">SGD</option>
                <option className="text-black">MUR</option>
                <option className="text-black">INR</option>
                <option className="text-black">SLR</option>
                <option className="text-black">MYR</option>
                <option className="text-black">USD</option>
                <option className="text-black">EUR</option>
                <option className="text-black">AED</option>
                <option className="text-black">QAR</option>
              </select>
              {formErrors.currency && (
                <span className="text-xs text-red-600">
                  {formErrors.currency}
                </span>
              )}
            </div>
          </div>
          {/* third section */}
          {/* <div className="mt-3">
            <label
              className="block mb-1 font-medium text-sm"
              htmlFor="description"
            >
              Description:
            </label>
            <textarea
              id="description"
              name="description"
              rows="3"
              onChange={handleInputChange}
              value={formData.description}
              className="block w-full px-4 py-2 border placeholder:text-gray-500  border-gray-300 rounded-lg"
              placeholder="Enter a description"
            />
            {formErrors.description && (
              <span className="text-xs text-red-600">
                {formErrors.description}
              </span>
            )}
          </div> */}

          <div className="flex items-center justify-center w-full my-4">
            <label
              htmlFor="dropzone-file"
              className="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
            >
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <CloudUploadIcon className="opacity-40" />
                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                  <span className="font-semibold">Click to upload Logo</span> or
                  drag and drop
                </p>
                <p className="text-xs text-gray-500 dark:text-gray-400">
                  SVG, PNG, JPG or GIF (MAX. 800x400px)
                </p>
              </div>
              <input
                onChange={handleFileChange}
                id="dropzone-file"
                type="file"
                name="logo"
                className="hidden"
              />
            </label>

            <img
              id="selected-image"
              src=""
              alt="SelectedImage"
              className="hidden h-24"
            />
          </div>

          {/* fourth section */}
          <p className="block my-3 font-medium text-sm" htmlFor="Address">
            Address:
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-3 my-[10px]">
            <div>
              <input
                className="block w-full px-4 py-2 border placeholder:text-gray-500  border-gray-300 rounded-lg"
                type="text"
                id="state"
                name="state"
                placeholder="State"
                value={formData.state}
                onChange={handleInputChange}
                required
              />
              {formErrors.state && (
                <span className="text-xs text-red-600">{formErrors.state}</span>
              )}
            </div>
            <div>
              <input
                className="block w-full px-4 py-2 border placeholder:text-gray-500  border-gray-300 rounded-lg"
                type="text"
                id="country"
                name="country"
                placeholder="country"
                value={formData.country}
                onChange={handleInputChange}
                required
              />
              {formErrors.country && (
                <span className="text-xs text-red-600">
                  {formErrors.country}
                </span>
              )}
            </div>
          </div>
          <div className="flex justify-end gap-x-[20px]">
            <button
              onClick={handleCloseModal}
              className="py-[8px] md:ease-in duration-300 mt-4 bg-black hover:bg-red-600  text-sm text-white font-bold px-2  rounded md:w-40"
              type="submit"
            >
              Cancel
            </button>
            <button
              onClick={handleSubmit}
              className=" ease-in duration-300 mt-4 bg-red-500 hover:bg-green-600  text-sm text-white font-bold  py-2 px-3  rounded md:w-40"
              type="submit"
              disabled={isLoader}
            >
              {!isLoader ? <span>Save</span> : <Loader />}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddColleges;
