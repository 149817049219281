import React, { useState } from "react";
import StudentTableRow from "./StudentTableRow";
import AddStude from "../CreateForms/AddStude";
import QuickCommissionChange from "../CommissionInputs/QuickCommissionChange";
// import EditStudent from "../EditForms.jsx/EditStudent";

const StudentsTable = ({
  collegeData,
  students,
  setStudents,
  isAddStudent: addStudentModal,
  setIsAddStudent: setAddStudentModal,
}) => {
  // const [addStudentModal, setAddStudentModal] = useState(false);
  const [quicKCommissionModal, setQuicKCommissionModal] = useState(false);
  const [commissionData, setCommissionData] = useState({});

  
  return (
    <>
      {!addStudentModal ? (
        <div className="mt-10 p-3 rounded-md shadow-lg shadow-gray-300 bg-gray-50 max-h-[400px] overflow-y-scroll ">
          <table className="w-full border-collapse">
            <thead>
              <tr>
              <th className="text-gray-500 text-[16px]  py-[10px] border border-1 border-solid text-left p-[8px]">
                  Name
                </th>
                {/* <th className="text-gray-500 text-[16px]  py-[10px] border border-1 border-solid text-left p-[8px]">
                  Course
                </th> */}
                <th className="text-gray-500 text-[16px]  py-[10px] border border-1 border-solid text-left p-[8px]">
                  Country/Location
                </th>
                {/* <th className="text-gray-500 text-[16px]  py-[10px] border border-1 border-solid text-left p-[8px]">
                  Course
                </th> */}
                {/* <th className="text-gray-500 text-[16px]  py-[10px] border border-1 border-solid text-left p-[8px]">
                  Fees
                </th> */}
                {/* <th className="text-gray-500 text-[16px]  py-[10px] border border-1 border-solid text-left p-[8px] ">
                  Outstanding fee
                </th> */}
                <th className="border border-1 border-solid text-center p-[8px]">
                  <span
                    onClick={() => setAddStudentModal(true)}
                    className="border bg-gray-600  px-3 rounded-lg text-xs py-1 text-left text-white hover:bg-gray-200 hover:text-gray-600 font-bold cursor-pointer transition "
                  >
                    Add+
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {students.length > 0 &&
                students.map((student) => (
                  <StudentTableRow
                    collegeData={collegeData}
                    student={student}
                    key={student._id}
                  />
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div>
          {!quicKCommissionModal ? (
            <AddStude
              students={students}
              setStudents={setStudents}
              collegeData={collegeData}
              setAddStudentModal={setAddStudentModal}
              setQuicKCommissionModal={setQuicKCommissionModal}
              setCommissionData={setCommissionData}
            />
          ) : (
            <>
              {Object.keys(commissionData).length >= 0 ? (
                <QuickCommissionChange
                  setAddStudentModal={setAddStudentModal}
                  commissionData={commissionData}
                />
              ) : (
                <p>commission issue</p>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default StudentsTable;
