import React from "react";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Link } from "react-router-dom";
const EsighServices = ({ Status }) => {
  const [isOpenServices, setIsOpenServices] = useState(false);

  const toggleAccordionServices = () => {
    setIsOpenServices(!isOpenServices);
  };
  return (
    <div>
      <div className={`accordion ${isOpenServices ? "open" : ""}mb-6`}>
        <div
          className="accordion-header  w-[374px]  md:w-[814px] rounded-sm shadow-lg"
          onClick={toggleAccordionServices}
        >
          <div className="flex justify-center items-center gap-x-4">
            <div className="    flex  items-center gap-x-2 bg-red-500 px-1 py-1 rounded ">
              <p className="text-white font-bold text-sm">STEP 6</p>
            </div>
            <div>
              <p className="text-md font-semibold uppercase ">
                {" "}
                E sigh Service Agreement Complete
              </p>
            </div>
          </div>
          <div className="flex justify-end items-end">
            <i className="fa-solid fa-angle-up" />
            <span className="icon">
              {isOpenServices ? <RemoveIcon /> : <AddIcon />}
            </span>
          </div>
        </div>
        {isOpenServices && (
          <div className="accordion-content text-black">
            <div className="panel w-full sm:w-full md:w-[814px]  flex justify-center items-center sm:flex sm:items-center sm:justify-center  md:flex-row  md:justify-center md:item-center shadow-lg">
              <div className="py-10 px-4">
                <div className="flex justify-between ">
                  <div className=" flex-col md:flex-row flex gap-x-6 justify-between pt-4 items-center ">
                    <div className="mb-4 ">
                      <div className=" max-w-40">
                        <p className="text-gray-600 text-sm w-[340px] md:w-full">
                          You have already signed your agreement.Click on the
                          'view Agreement' button to see a digitally signed copy
                          of your agreement
                        </p>
                      </div>
                    </div>
                    <div className="flex justify-center items-center md:justify-end md:items-end pr-10">
                      <div className="w-[100px] h-auto ">
                        <img
                          className="w-[100%] h-[100%] object-cover"
                          src="/assets/img/handshake.svg"
                          alt="nandshake"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-4 flex  flex-col md:flex-row  items-center gap-6 justify-center">
                  <div>
                    <button
                      type="submit"
                      className=" text-sm sm:text-md border   sm:w-full md:w-[250px] text-red-600 border-red-500 whitespace-nowrap  font-bold py-2 px-10 rounded focus:outline-none focus:shadow-outline shadow-lg"
                    >
                      View Sighed Agreement
                    </button>
                  </div>
                  <div>
                    <Link to={"/agent/login"}>
                      <button
                        type="submit"
                        className=" text-sm sm:text-md border   sm:w-full md:w-[250px] text-red-600 border-red-500 whitespace-nowrap  font-bold py-2 px-10 rounded focus:outline-none focus:shadow-outline shadow-lg"
                      >
                        Go to Login
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EsighServices;
