// EditCourseModal.js
import React from "react";
import { Modal, Box, Button, TextField, MenuItem } from "@mui/material";
import { editCourseAPI } from "../../../API/admin/AdminAPI";

const EditCourseModal = ({
  open,
  onClose,
  editCourseData,
  setEditCourseData,
  onCourseUpdated,
}) => {

  // Handle form field changes
  const handleChange = (e) => {
    setEditCourseData({ ...editCourseData, [e.target.name]: e.target.value });
  };

  // Handle Edit Course Submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await editCourseAPI(editCourseData, editCourseData._id);
      if (response.status === 200) {
        onCourseUpdated(response.data.course); // Notify parent about the update
        alert("Course updated successfully");
        onClose(); // Close modal on successful update
      }
    } catch (error) {
      console.log("Error updating course:", error);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          borderRadius: "20px",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <h2>Edit Course</h2>
        <TextField
          label="Course Name"
          name="course"
          value={editCourseData.course}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Duration"
          name="duration"
          value={editCourseData.duration}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />

        <TextField
          label="Application Fees"
          name="applicationFees"
          type="number"
          value={editCourseData.applicationFees}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />

        {/* Course Type field */}
        <TextField
          select
          label="Course Type"
          name="courseType"
          value={editCourseData.courseType}
          onChange={handleChange}
          fullWidth
          margin="normal"
        >
          <MenuItem value="">Select Course Type</MenuItem>
          <MenuItem value="diploma">Diploma</MenuItem>
          <MenuItem value="higher_diploma">Higher Diploma</MenuItem>
          <MenuItem value="post_graduation">Post Graduation</MenuItem>
        </TextField>

        {/* Requirement Description field */}
        <TextField
          label="Requirement Description"
          name="requirementDescription"
          value={editCourseData.requirementDescription}
          onChange={handleChange}
          fullWidth
          margin="normal"
          multiline
          rows={4}
        />

        <Box sx={{ mt: 2 }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ borderRadius: "20px" }}
            fullWidth
          >
            Update Course
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditCourseModal;
