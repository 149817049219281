import React, { useEffect, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Loader from "../CreateForms/Loader";
import { uploadAgentCertificateAPI } from "../../../API/admin/AdminAPI";
import jwt_decode from "jwt-decode";

const UploadCertificateFIles = ({
  setIsUploadCertificate,
  handleCertificateCloseModal,
}) => {
  const [agentCertificate, setAgentCertificate] = useState(null);
  const [fieldError, setFieldError] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  const agentToken = localStorage.getItem("agent-auth-token");
  const [agentId, setAgentId] = useState("");
  useEffect(() => {
    // Check if the token exists before attempting to decode
    if (agentToken) {
      // Decode the JWT
      const decodedToken = jwt_decode(agentToken);
      // For example, update state or make an API request using the decoded information
      setAgentId(decodedToken.agentId);
    }
  }, [agentToken]); // Run the effect when agentToken changes

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    // Do something with the selected file
    // For example, you can set it in the component state
    setAgentCertificate(file);
  };
  const handleFileUploadButton = async () => {
    // Create a FormData object to send the file and other form data
    const formDataToSend = new FormData();
    formDataToSend.append("agentCertificate", agentCertificate);
    formDataToSend.append("agentId", agentId);
    if (!agentCertificate) {
      setFieldError(true);
      return;
    }
    setFieldError(false);
    console.log(formDataToSend);
    const response = await uploadAgentCertificateAPI(formDataToSend, agentId);
    console.log(response);
    if (response.status === 200) {
      setIsLoader(false);
    } else {
      setIsLoader(false);
    }
  };
  return (
    <div className="flex justify-center items-center md:flex py-[20px] bg-white">
      <div className="w-full py-[10px] rounded-2xl shadow-lg shadow-gray-500">
        <div className="w-[95%] mx-auto">
          <div className=" text-center md:py-[20px] text-[20] md:text-left font-bold">
            <p className="underline underline-offset-8">Add Agents</p>
          </div>
          <div className="">
            <div className="flex items-center justify-center w-full my-4">
              <label
                htmlFor="dropzone-file"
                className="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <CloudUploadIcon className="opacity-40" />
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span className="font-semibold">Click to upload Logo</span>{" "}
                    or drag and drop
                  </p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    SVG, PNG, JPG or GIF (MAX. 800x400px)
                  </p>
                </div>
                <input
                  onChange={handleFileChange}
                  id="dropzone-file"
                  type="file"
                  name="agentCertificate"
                  className="hidden"
                />
              </label>

              <img
                id="selected-image"
                src=""
                alt="SelectedImage"
                className="hidden h-24"
              />
            </div>
            {fieldError && (
              <span className="text-xs text-red-600">File not uploaded</span>
            )}
            <div className="flex justify-end gap-x-[20px]">
              <button
                onClick={handleCertificateCloseModal}
                className="py-[8px] md:ease-in duration-300 mt-4 bg-black hover:bg-red-600  text-sm text-white font-bold px-2  rounded md:w-40"
                type="submit"
              >
                Cancel
              </button>
              <button
                onClick={handleFileUploadButton}
                className=" ease-in duration-300 mt-4 bg-red-500 hover:bg-green-600  text-sm text-white font-bold  py-2 px-3  rounded md:w-40"
                type="submit"
                disabled={isLoader}
              >
                {!isLoader ? <span>Save</span> : <Loader />}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadCertificateFIles;
