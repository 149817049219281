import React, { useEffect, useState } from "react";
import Layout from "../../../components/AdminComponents/Layout/Layout";
import AgentPro from "../../../components/AdminComponents/Profiles/AgentPro";
import { useLocation } from "react-router-dom";
import { fetchSingleAgentAPI } from "../../../API/admin/AdminAPI";
import EditAgents from "../../../components/AdminComponents/EditForms.jsx/EditAgents";
import UploadCertificateFIles from "../../../components/AdminComponents/UploadFiles/UploadCertificateFIles";
import ComponentLoading from "../../CommonPreloader/ComponentLoading";

const AgentProfile = () => {
  const [agentData, setAgentData] = useState({});
  const [isEditAgent, setIsEditAgent] = useState(false);
  const [isUploadCertificate, setIsUploadCertificate] = useState(false);

  const [componentLoader, setComponentLoader] = useState(true);
  useEffect(() => {
    if (componentLoader) {
      // Set a delay of 3 seconds
      const timer = setTimeout(() => {
        setComponentLoader(false);
      }, 500);

      // Cleanup function to clear the timer if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [componentLoader]);

  const { state } = useLocation();
  const handleCloseModal = () => {
    setIsEditAgent(false);
  };
  const handleCertificateCloseModal = () => {
    setIsUploadCertificate(false);
  };
  useEffect(() => {
    const fetchSingleAgents = async () => {
      try {
        const response = await fetchSingleAgentAPI(state?.agentId);
        if (response?.status === 200) {
          setAgentData(response?.data);
        } else {
          setAgentData([]);
        }
      } catch (error) {
        setAgentData([]);
      }
    };
    fetchSingleAgents(state.agentId);
  }, [state]);

  return (
    <>
      <Layout>
        {!componentLoader ? (
          <div className="relative">
            {Object.keys(agentData).length > 0 ? (
              <AgentPro
                agentData={agentData}
                setIsEditAgent={setIsEditAgent}
                handleCloseModal={handleCloseModal}
                setIsUploadCertificate={setIsUploadCertificate}
                handleCertificateCloseModal={handleCertificateCloseModal}
              />
            ) : (
              <ComponentLoading />
            )}
            {isEditAgent && (
              <div className="absolute top-0 w-full">
                <EditAgents
                  agentData={agentData}
                  setAgentData={setAgentData}
                  setIsEditAgent={setIsEditAgent}
                  handleCloseModal={handleCloseModal}
                />
              </div>
            )}
            {isUploadCertificate && (
              <div className="absolute top-0 w-full">
                <UploadCertificateFIles
                  agentData={agentData}
                  setIsUploadCertificate={setIsUploadCertificate}
                  handleCertificateCloseModal={handleCertificateCloseModal}
                />
              </div>
            )}
          </div>
        ) : (
          <ComponentLoading />
        )}
      </Layout>
    </>
  );
};

export default AgentProfile;
