import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import FlagIcon from "@mui/icons-material/Flag";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import HomeIcon from "@mui/icons-material/Home";
import { useLocation, useNavigate } from "react-router-dom";
import {
  deleteAdminAPI,
  fetchAdminProfileAPI,
} from "../../../API/admin/AdminAPI";
import EditAdmin from "../EditForms.jsx/EditAdmin";
import DeleteIcon from "@mui/icons-material/Delete";
import ComponentLoading from "../../../pages/CommonPreloader/ComponentLoading";

const AdminProfiles = () => {
  const [adminData, setAdminData] = useState({});
  const [isEditAdmin, setIsEditAdmin] = useState(false);

  const [componentLoader, setComponentLoader] = useState(true);
  useEffect(() => {
    if (componentLoader) {
      // Set a delay of 3 seconds
      const timer = setTimeout(() => {
        setComponentLoader(false);
      }, 500);

      // Cleanup function to clear the timer if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [componentLoader]);

  const { state } = useLocation();
  const handleCloseModal = () => {
    setIsEditAdmin(false);
  };

  const navigate = useNavigate();

  useEffect(() => {
    const fetchAdminProfile = async () => {
      const response = await fetchAdminProfileAPI(state.adminId);
      if (response.status === 200) {
        setAdminData(response?.data);
      } else {
        setAdminData({});
      }
    };
    try {
      fetchAdminProfile();
    } catch (error) {
      setAdminData({});
    }
  }, [state]);

  const handleDeleteAdmin = async () => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this admin?"
    );

    if (confirmed) {
      const response = await deleteAdminAPI(adminData?._id);
      if (response.status) {
        alert("deleted");
        navigate("/admin/admins");
      } else {
        alert("Something went wrong");
      }
    }
  };

  return (
    <Layout>
      {!componentLoader ? (
        <>
          {Object.keys(adminData).length > 0 ? (
            <div className="px-10 md:px-0 relative">
              {/* <div>
          <p class=" text-[24px] md:text-[27px] font-bold pt-[50px]">{adminData?.name}</p>
          <p class="text-red-600">{adminData.type}</p>
        </div> */}
              <div
                className={`flex justify-between gap-x-[80px] sm:gap-x-[70px] md:gap-x-[800px] ${
                  isEditAdmin && `blur-sm`
                }`}
              >
                <div>
                  <h4 className="font-bold text-[24px] md:text-[30px]">
                    {adminData?.name}
                  </h4>
                </div>
                <div className="md:flex gap-2">
                  <div
                    onClick={() => setIsEditAdmin(true)}
                    className=" flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 hover:bg-black transition group"
                  >
                    <BorderColorRoundedIcon
                      sx={{ fontSize: 25 }}
                      className="text-black-600 group-hover:text-white transition"
                    />
                  </div>
                  <div
                    onClick={handleDeleteAdmin}
                    className=" flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 hover:bg-red-600 transition group"
                  >
                    {/* <LogoutOutlinedIcon
                sx={{ fontSize: 25 }}
                className="text-black-600 group-hover:text-white transition"
              /> */}
                    <DeleteIcon
                      sx={{ fontSize: 25 }}
                      className="text-black-600 group-hover:text-white transition"
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="flex gap-x-[10px] pb-[15px] items-center">
                  <div className="text-[20px]">
                    <HomeIcon />
                  </div>
                  <div>
                    <p className="text-gray-800">
                      {adminData?.address?.city},{adminData?.address?.state}
                    </p>
                  </div>
                </div>
                <div className="flex gap-x-[10px] pb-[15px] items-center">
                  <div className="text-[20px]">
                    <FlagIcon />
                  </div>
                  <div>
                    <p className="text-gray-800">
                      {adminData?.address?.country}
                    </p>
                  </div>
                </div>
                <div className="flex gap-x-[15px] ">
                  <div className="flex items-center ">
                    <div className="text-[20px]">
                      <AttachEmailIcon />
                    </div>
                    <div>
                      <p className="text-gray-800 px-2">{adminData?.email}</p>
                    </div>
                  </div>
                </div>
              </div>
              {isEditAdmin && (
                <div className="absolute top-0 w-full">
                  <EditAdmin
                    adminData={adminData}
                    setAdminData={setAdminData}
                    handleCloseModal={handleCloseModal}
                  />
                </div>
              )}
            </div>
          ) : (
            <ComponentLoading />
          )}
        </>
      ) : (
        <ComponentLoading />
      )}
    </Layout>
  );
};

export default AdminProfiles;
