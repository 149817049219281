import React, { useEffect, useState } from "react";
import Layout from "../../../components/AdminComponents/Layout/Layout";
import DashboardContent from "../../../components/AdminComponents/Dashboard/DashboardContent";
import ComponentLoading from "../../CommonPreloader/ComponentLoading";

const Dashboard = () => {
  const [componentLoader, setComponentLoader] = useState(true);
  useEffect(() => {
    if (componentLoader) {
      // Set a delay of 3 seconds
      const timer = setTimeout(() => {
        setComponentLoader(false);
      }, 500);

      // Cleanup function to clear the timer if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [componentLoader]);

  return (
    <Layout>
      {!componentLoader ? (
        <div className="px-8">
          <DashboardContent />
        </div>
      ) : (
        <ComponentLoading />
      )}
    </Layout>
  );
};

export default Dashboard;
