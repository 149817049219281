import React, { useEffect, useState } from "react";
import Layout from "../../../components/AdminComponents/Layout/Layout";
import { useLocation } from "react-router-dom";
import CollegeDetails from "../../../components/AdminComponents/CollageProfile/CollegeDetails";
import StudentsTable from "../../../components/AdminComponents/CollageProfile/StudentsTable";
import CoursesTable from "../../../components/AdminComponents/CollageProfile/CoursesTable";
import { fetchSingleCollegeAPI } from "../../../API/admin/AdminAPI";
import CommonPreloader from "../../CommonPreloader/CommonPreloader";
import ComponentLoading from "../../CommonPreloader/ComponentLoading";

const CollegeProfile = () => {
  const [isStudents, setIsStudents] = useState(true);
  const [collegesData, setCollegeData] = useState({});
  const [students, setStudents] = useState([]);
  const [isAddStudent, setIsAddStudent] = useState(false);
  const [isAddCourse, setIsAddCourse] = useState(false);
  const location = useLocation();
  const collegeId = location.state?.collegeId;

  

  useEffect(() => {
    const fetchSingleCollege = async () => {
      try {
        const response = await fetchSingleCollegeAPI(collegeId);
        if (response?.status === 200) {
          setCollegeData(response.data);
  
          // Sort students in LIFO order, using `createdAt` if it exists, otherwise by `_id`
          const sortedStudents = response.data.students.sort((a, b) => 
            (b.createdAt || b._id) > (a.createdAt || a._id) ? 1 : -1
          );
  
          setStudents(sortedStudents);
        } else {
          setCollegeData({});
        }
      } catch (error) {
        console.log(error);
      }
    };
    
    fetchSingleCollege();
  }, [collegeId]);
  

  const isActive =
    "py-2 px-3 shadow-inner shadow-gray-500 text-2xl font-bold text-red-600 cursor-pointer";
  const inActive =
    "py-2 px-3 shadow-sm shadow-gray-500 text-2xl font-bold cursor-pointer";
  return (
    <Layout>
      {Object.keys(collegesData).length !== 0 ? (
        <div>
          <CollegeDetails
            collegesData={collegesData}
            isAddStudent={setIsAddStudent}
            isAddCourse={setIsAddCourse}
          />
          <div className="flex bg-gray mt-5">
            <div
              className={isStudents ? isActive : inActive}
              onClick={() => setIsStudents(true)}
            >
              <h3>Students</h3>
            </div>
            <div
              className={!isStudents ? isActive : inActive}
              onClick={() => setIsStudents(false)}
            >
              <h3 className="">Courses</h3>
            </div>
          </div>
          {isStudents ? (
            <div>
              <StudentsTable
                collegeData={{ _id: collegesData._id, name: collegesData.name }}
                students={students}
                setStudents={setStudents}
                isAddStudent={isAddStudent}
                setIsAddStudent={setIsAddStudent}
              />
            </div>
          ) : (
            <div>
              <CoursesTable
                collegeData={{ _id: collegesData._id, name: collegesData.name }}
                setIsAddCourse={setIsAddCourse}
                isAddCourse={isAddCourse}
              />
            </div>
          )}
        </div>
      ) : (
        <ComponentLoading />
      )}
    </Layout>
  );
};

export default CollegeProfile;
