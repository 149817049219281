import React, { useEffect, useState } from "react";
import Layout from "../../../components/AdminComponents/Layout/Layout";
import AgentsTable from "../../../components/AdminComponents/AgentsTable/AgentsTable";
import AgentsTableRow from "../../../components/AdminComponents/AgentsTable/AgentsTableRow";
import { fetchAllAgentsAPI } from "../../../API/admin/AdminAPI";
import AddAgents from "../../../components/AdminComponents/CreateForms/AddAgents";
import AgentCommission from "./AgentCommission";
import ComponentLoading from "../../CommonPreloader/ComponentLoading";

const Agents = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [agentsData, setAgentsData] = useState([]);
  const [commissionTab, setCommissionTab] = useState(false);

  const [componentLoader, setComponentLoader] = useState(true);
  useEffect(() => {
    if (componentLoader) {
      // Set a delay of 3 seconds
      const timer = setTimeout(() => {
        setComponentLoader(false);
      }, 500);

      // Cleanup function to clear the timer if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [componentLoader]);

  const fetchAgents = async () => {
    const response = await fetchAllAgentsAPI();
    if (response.status === 200) {
      setAgentsData(response.data);
    } else {
      setAgentsData([]);
    }
  };
  useEffect(() => {
    fetchAgents();
  }, []);
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  return (
    <Layout>
      {!componentLoader ? (
        <div className="relative">
          <div
            className={
              isModalOpen
                ? "blur-sm relative pt-[30px] p-3 rounded-2xl  shadow-lg shadow-gray-500 bg-gray-100 mt-[30px] max-h-[90vh] overflow-y-scroll cursor-not-allowed"
                : "pt-[30px] p-3 rounded-2xl  shadow-lg shadow-gray-300 bg-gray-50 mt-[30px] max-h-[90vh] overflow-y-scroll"
            }
          >
            <AgentsTable setIsModalOpen={setIsModalOpen}>
              {agentsData.length > 0 &&
                agentsData.map((agents, i) => (
                  <AgentsTableRow
                    setCommissionTab={setCommissionTab}
                    agents={agents}
                    i={i}
                    key={agents?._id}
                  />
                ))}
            </AgentsTable>
          </div>
          {commissionTab && (
            <div>
              <AgentCommission />
            </div>
          )}
          {isModalOpen && (
            <div className="absolute top-0 z-10 w-full">
              <AddAgents
                handleCloseModal={handleCloseModal}
                setAgentsData={setAgentsData}
                agentsData={agentsData}
              />
            </div>
          )}
        </div>
      ) : (
        <ComponentLoading />
      )}
    </Layout>
  );
};

export default Agents;
