import { useState } from "react";

const EditContractField = ({
  fieldKey,
  value,
  handleChange,
  isDateField = false,
}) => {
  const [isEditing, setIsEditing] = useState(false); // Toggle edit mode

  // Toggle between editing and saving mode
  const handleToggleEdit = () => {
    setIsEditing(!isEditing); // Toggle edit state
  };
  

  return (
    <div className="flex justify-between  ">
      {/* Left side: Editable value */}
      <span className="">
        {isEditing ? (
          isDateField ? ( // Check if the field is a date
            <input
              className="border border-gray-300 p-1 text-[14px]"
              type="date" // Input type for date
              value={value}
              onChange={(e) => handleChange(fieldKey, e.target.value)} // Update the respective field
              onBlur={handleToggleEdit} // Save the value on losing focus
              autoFocus // Automatically focus input when editing starts
            />
          ) : (
            <input
              className="border border-gray-300 p-1 text-[14px]"
              type="text"
              value={value}
              onChange={(e) => handleChange(fieldKey, e.target.value)} // Update the respective field
              onBlur={handleToggleEdit} // Save the value on losing focus
              autoFocus // Automatically focus input when editing starts
            />
          )
        ) : !isDateField ? ( // Check if the field is a date
          <span
            className="cursor-pointer"
            onClick={handleToggleEdit} // Enable edit mode on click
          >
            {value}
          </span>
        ) : (
          <span
            className="cursor-pointer"
            onClick={handleToggleEdit} // Enable edit mode on click
          >
            {new Date(
             value
            ).toLocaleDateString("en-GB")}{" "}
          </span>
        )}
      </span>
    </div>
  );
};
export default EditContractField;
