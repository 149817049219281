import React from "react";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

const ApplicationVerification = ({ Status }) => {
  console.log(Status);
  return (
    <div>
      <div className=" flex flex-col gap-y-10  w-[370px]   md:flex md:flex-row md:justify-between gap-x-4 md:gap-x-4  sm:w-full md:w-[814px] mt-10 bg-white px-6 py-10 rounded-lg relative z-50 shadow-lg">
        {/* <div class="line  border-b border-dashed  w-full absolute -z-1 top-[30px] border-red-600 "></div> */}
        <div className="flex flex-col justify-center items-center">
          <div className="flex flex-col  gap-y-2  justify-center items-center">
            {Status?.isApplicantInformation ? (
              <div className="border-2 bg-green-500 w-[30px] h-[30px] rounded-full shadow-gray-700 flex justify-center items-center">
                <div className="text-white font-bold text-lg flex justify-center items-center">
                  <DoneIcon sx={{ fontSize: 25, fontWeight: "bold" }} />
                </div>
              </div>
            ) : (
              <div className="border-2 bg-red-500 w-[30px] h-[30px] rounded-full shadow-gray-700 flex justify-center items-center">
                <div className="text-white font-bold text-lg flex justify-center items-center">
                  <CloseIcon />
                </div>
              </div>
            )}
          </div>
          <div>
            <p className="text-[12px] whitespace-nowrap font-semibold">
              APPLICANT INFORMATION
            </p>
          </div>
        </div>

        <div className=" flex flex-col justify-center items-center">
          <div className="flex flex-col  gap-y-2  justify-center items-center">
            {Status?.isBusinessInformation ? (
              <div className="border-2  bg-green-500 w-[30px] h-[30px] rounded-full shadow-gray-700 flex justify-center items-center">
                <div className="text-white font-bold text-lg flex justify-center items-center">
                  <DoneIcon />
                </div>
              </div>
            ) : (
              <div className="border-2 bg-red-500 w-[30px] h-[30px] rounded-full shadow-gray-700 flex justify-center items-center">
                <div className="text-white font-bold text-lg flex justify-center items-center">
                  <CloseIcon />
                </div>
              </div>
            )}
          </div>
          <div>
            <p className="text-[12px] whitespace-nowrap font-semibold">
              BUSINESS INFORMATION
            </p>
          </div>
        </div>

        <div className="flex flex-col justify-center items-center">
          <div className="flex flex-col  gap-y-2  justify-center items-center">
            {Status?.isStudentRecruitment ? (
              <div className="border-2  bg-green-500 w-[30px] h-[30px] rounded-full shadow-gray-700 flex justify-center items-center">
                <div className="text-white font-bold text-lg flex justify-center items-center">
                  <DoneIcon />
                </div>
              </div>
            ) : (
              <div className="border-2 bg-red-500 w-[30px] h-[30px] rounded-full shadow-gray-700 flex justify-center items-center">
                <div className="text-white font-bold text-lg flex justify-center items-center">
                  <CloseIcon />
                </div>
              </div>
            )}
          </div>
          <div>
            <p className="text-[12px] whitespace-nowrap font-semibold">
              {" "}
              STUDENT RECRUITMENT
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center">
          <div className="flex flex-col  gap-y-2 justify-center items-center">
            {Status?.isBusinessReference ? (
              <div className="border-2  bg-green-500 w-[30px] h-[30px] rounded-full shadow-gray-700 flex justify-center items-center">
                <div className="text-white font-bold text-lg flex justify-center items-center">
                  <DoneIcon />
                </div>
              </div>
            ) : (
              <div className="border-2 bg-red-500 w-[30px] h-[30px] rounded-full shadow-gray-700 flex justify-center items-center">
                <div className="text-white font-bold text-lg flex justify-center items-center">
                  <CloseIcon />
                </div>
              </div>
            )}
          </div>
          <div>
            <p className="text-[12px] whitespace-nowrap font-semibold">
              {" "}
              BUSINESS REFERENCE
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center">
          <div className="flex flex-col  gap-y-2 justify-center items-center">
            {Status?.isSupportingDocumentsTwo ? (
              <div className="border-2  bg-green-500 w-[30px] h-[30px] rounded-full shadow-gray-700 flex justify-center items-center">
                <div className="text-white font-bold text-lg flex justify-center items-center">
                  <DoneIcon />
                </div>
              </div>
            ) : (
              <div className="border-2 bg-red-500 w-[30px] h-[30px] rounded-full shadow-gray-700 flex justify-center items-center">
                <div className="text-white font-bold text-lg flex justify-center items-center">
                  <CloseIcon />
                </div>
              </div>
            )}
          </div>
          <div>
            <p className="text-[12px] whitespace-nowrap font-semibold">
              {" "}
              SUPPORTING DOCUMENTS
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationVerification;
