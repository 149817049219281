import React from 'react';
import { Link } from 'react-router-dom';

const Page404 = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <h1 className="text-4xl font-bold mb-4">Oops! Page Not Found</h1>
      <p className="text-gray-600 mb-8">Sorry, the page you are looking for does not exist.</p>
      <p className='text-[100px] font-bold'>404</p>
      <Link className="text-red-500 hover:underline" to="/">Go back to Dashboard</Link>
      {/* // sdfhdshfadskl */}
    </div>
  );
};
export default Page404;
