import React from "react";
import { Link } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const SuccessFullyEdited = ({ isOpen, student, handleRefresh }) => {
  return (
    <div
      className={`fixed z-30 inset-0 overflow-y-auto bg-gray-100 bg-opacity-75 transition-opacity ${
        isOpen ? "flex" : "hidden"
      } justify-center items-center`}
    >
      <div className="flex items-center justify-center min-h-screen px-4">
        <div className="bg-white rounded-lg max-w-md w-full p-8 shadow-md shadow-gray-300">
          <div className="text-center">
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
              <CheckCircleIcon className="h-6 w-6 text-green-600" />
            </div>
            <h3 className="mt-4 text-lg leading-6 font-medium text-gray-900 ">
              Update Successful!
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                Go to the profile and manage Commissions.
              </p>
            </div>
            <div className="mt-6 flex gap-3 justify-center">
              {/* <Link
                to={`/admin/student/${student?.name}`}
                state={{ studentId: student?._id }}
                key={student?._id}
              > */}
              <button
                onClick={handleRefresh}
                type="button"
                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                //   onClick={onClose}
              >
                Go to Profile
              </button>
              {/* </Link> */}
              {/* <button
                type="button"
                onClick={handleInvoiceButton}
                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                // onClick={onClose}
              >
                invoice
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessFullyEdited;
