import React, { useEffect, useState } from "react";
import Layout from "../../../components/AdminComponents/Layout/Layout";
import AddAdmin from "../../../components/AdminComponents/CreateForms/AddAdmin";
import AdminTable from "../../../components/AdminComponents/AdminTable/AdminTable";
import AdminTableRows from "../../../components/AdminComponents/AdminTable/AdminTableRows";
import { fetchAdminAPI } from "../../../API/admin/AdminAPI";
import ComponentLoading from "../../CommonPreloader/ComponentLoading";

const Admins = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [adminData, setAdminData] = useState([]);
  

  useEffect(() => {
    try {
      const fetchAdminData = async () => {
        const response = await fetchAdminAPI();
        if (response.status === 200) {
          setAdminData(response?.data);
        } else {
          setAdminData([]);
        }
      };
      fetchAdminData();
    } catch (error) {
      setAdminData([]);
    }
  }, []);

  const handleAddUserClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const [componentLoader, setComponentLoader] = useState(true);
  useEffect(() => {
    if (componentLoader) {
      // Set a delay of 3 seconds
      const timer = setTimeout(() => {
        setComponentLoader(false);
      }, 500);

      // Cleanup function to clear the timer if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [componentLoader]);

  return (
    <Layout>
       {!componentLoader ? (
      <div className="relative">
        <div
          className={
            isModalOpen
              ? "blur-sm relative pt-[30px] p-3 rounded-2xl  shadow-lg shadow-gray-500 bg-gray-50 mt-[30px] max-h-[90vh] overflow-y-scroll cursor-not-allowed"
              : "pt-[30px] p-3 rounded-2xl  shadow-lg shadow-gray-500 bg-gray-50 mt-[30px] max-h-[90vh] overflow-y-scroll"
          }
        >
          <AdminTable handleAddUserClick={handleAddUserClick}>
            {adminData.length !== 0
              ? adminData?.map((admin) => (
                  <AdminTableRows admin={admin} />
                ))
              : null}
          </AdminTable>
        </div>
        {isModalOpen && (
          <div className="absolute top-0 z-10 w-full">
            <AddAdmin
              handleCloseModal={handleCloseModal}
              setAdminData={setAdminData}
              adminData={adminData}
            />
          </div>
        )}
      </div>
      ) : (
        <ComponentLoading />
      )}
    </Layout>
  );
};

export default Admins;
