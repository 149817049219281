import React from "react";
import { Link, useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
// import CameraAltIcon from "@mui/icons-material/CameraAlt";

const SideBar = ({ setNavButton, navButton }) => {
  // const [adminData, setAdminData] = useState({});
  const location = useLocation();
  const pathname = location.pathname;
  const parts = pathname.split("/");
  const active = "text-[20px] font-bold";
  const inActive = "text-[18px] hover:font-bold transition";
  const adminData = useSelector((state) => state.adminData.adminData);
  // useEffect(() => {
  //   setAdminData(data);
  // }, [data]);

  return (
    <div className="">
      <div className="h-[100vh] relative">
        <div
          onClick={() => setNavButton(!navButton)}
          className="md:hidden absolute right-0 px-5 py-6 "
        >
          <CloseIcon sx={{ fontSize: 35 }} />
        </div>
        <div className="flex flex-col justify-center items-center">
          {/* dashboardNavbar */}
          <div className="w-full h-[70px] overflow-hidden mb-[50px]">
            {/* logo */}
            <img
              className="w-[100%] h-[100%] object-contain"
              src="/assets/img/logo-01.png"
              alt="logo"
            />
          </div>
          {/* profilePhoto */}
          <div className="relative">
            <Link to="/admin/profile">
              <div className="w-[100px] h-[100px] overflow-hidden rounded-[50%] border-2 border-red-600">
                {adminData?.type === "super-admin" ? (
                  <img
                    className="w-[100%] h-[100%] object-contain"
                    src={adminData?.logo_name?`${process.env.REACT_APP_BACKEND_URL}\\uploads\\${adminData?.logo_name}`:"https://beta.acetek.edu.sg/wp-content/themes/acetek/assets/images/favicon.png"}
                    alt="profile"
                  />
                ) : (
                  <img
                    className="w-[100%] h-[100%] object-contain"
                    // src="/assets/img/user.png"
                    src={adminData?.logo_name?`${process.env.REACT_APP_BACKEND_URL}\\uploads\\${adminData?.logo_name}`:"https://beta.acetek.edu.sg/wp-content/themes/acetek/assets/images/favicon.png"}
                    alt="profile"
                  />
                )}
              </div>
            </Link>
            {/* <div className="absolute right-0 bottom-0 bg-white rounded-full p-1">
              <CameraAltIcon  />
            </div> */}
          </div>
          {/* name */}
          <div className="pb-[60px]">
            <Link to="/admin/profile">
              <p className="text-[20px] md:text-[17px] lg:text-[18px] font-bold hover:underline text-center">
                {adminData?.name}
              </p>
            </Link>
            <p className="text-red-600 cursor-default text-center">
              {adminData?.type}
            </p>
          </div>
          {/* dashboard */}
          <div className="pb-[10px]">
            <Link to={"/admin/dashboard"}>
              <p
                className={
                  parts[parts.length - 1] === "dashboard" ? active : inActive
                }
              >
                Dashboard
              </p>
            </Link>
          </div>
          {/* college */}
          <div className="pb-[10px]">
            <Link to={"/admin/colleges"}>
              <p
                className={
                  parts[parts.length - 1] === "colleges" ? active : inActive
                }
              >
                Colleges
              </p>
            </Link>
          </div>
          {/* payments */}
          <div className="pb-[10px]">
            <Link to={"/admin/payments"}>
              <p
                className={
                  parts[parts.length - 1] === "payments" ? active : inActive
                }
              >
                Payments
              </p>
            </Link>
          </div>
          {/* admins */}
          <div className="pb-[10px]">
            <Link to={"/admin/reports"}>
              <p
                className={
                  parts[parts.length - 1] === "reports" ? active : inActive
                }
              >
                Reports
              </p>
            </Link>
          </div>
          {/* horizontalline */}
          <div className="border border-black opacity-50 w-[200px] mt-32 mb-2" />
          {/* copy right */}
          <div className="max-w-[220px] flex-col items-center justify-center text-[13px] px-3 text-center">
            <p className="flex flex-col">
              <span>@2023</span>
              <Link
                to={"https://superiodigital.com/"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="font-semibold hover:underline cursor-pointer hover:text-orange-600">
                  Superio Digital Solution
                </span>
              </Link>
              <span> All rights reserved.</span>
            </p>
            {/* Developed by {""}
            <span className="font-semibold">Superio Digital Solution</span>{" "} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
