import React, { useEffect, useState } from "react";
import MiniLineGraph from "./MiniLineGraph";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import ArrowCircleUpSharpIcon from "@mui/icons-material/ArrowCircleUpSharp";
import ArrowCircleDownSharpIcon from "@mui/icons-material/ArrowCircleDownSharp";

const ReportTopRow = ({ commissionData }) => {
  const [shouldRenderGraph, setShouldRenderGraph] = useState(false);

  useEffect(() => {
    setShouldRenderGraph(true);
  }, [commissionData]); // The effect will re-run whenever `commissionData` changes

  return (
    <>
      <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-4 py-[30px]">
        <div className="flex flex-col items-start hover:border shadow-gray-300 bg-white hover:border-1 hover:ring-red-500 hover:ring-[3px] hover:rounded-lg hover:shadow  px-[20px] py-[10px] shadow-md rounded-sm shadow-gary-600 justify-start  md:flex md:items-start">
          <div className=" items-start justify-start  text-[14px] font-semibold  flex ">
            <p>Total Commission</p>
          </div>
          <div className="flex items-start justify-between w-full">
            <p className="text-[40px] font-bold text-blue-900 whitespace-nowrap">
              {/* <span>
                <CurrencyRupeeIcon />
              </span> */}
              {commissionData?.totalCommission}
            </p>
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-50">
              <MonetizationOnIcon
                sx={{ fontSize: 35 }}
                className="h-8 w-8 text-blue-600 hover:animate-bounce"
              />
            </div>
          </div>
          {/* <p className="">
          Total commission you have
          </p> */}
        </div>
        {/* commission earned */}
        <div className="flex flex-col items-start hover:border shadow-gray-300 bg-white hover:border-1 hover:ring-red-500 hover:ring-[3px] hover:rounded-lg hover:shadow  px-[20px] py-[10px] shadow-md rounded-sm shadow-gary-600 justify-start  md:flex md:items-start">
          <div className="items-start justify-start  text-[14px] font-semibold  flex  ">
            <p>Total Earned</p>
          </div>
          <div className="flex items-start justify-between w-full">
            <p className="text-[40px] font-bold text-green-700 whitespace-nowrap">
              {/* <span>
                <CurrencyRupeeIcon />
              </span> */}
              {Math.round(commissionData?.earnedCommission)}
            </p>
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-50 mt-1">
              <ArrowCircleUpSharpIcon
                sx={{ fontSize: 35 }}
                className="h-8 w-8 text-green-600 hover:animate-bounce"
              />
            </div>
          </div>
          {/* <p className="">
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
          </p> */}
        </div>
        {/* commission pending */}
        <div className="flex flex-col items-start hover:border shadow-gray-300 bg-white hover:border-1 hover:ring-red-500 hover:ring-[3px] hover:rounded-lg hover:shadow  px-[20px] py-[10px] shadow-md rounded-sm shadow-gary-600 justify-start  md:flex md:items-start">
          <div className=" items-start justify-start  text-[14px] font-semibold  flex">
            <p>Total Pending</p>
          </div>
          <div className="flex items-start justify-between w-full ">
            <p className="text-[40px] font-bold text-red-600 whitespace-nowrap">
              {/* <span>
                <CurrencyRupeeIcon />
              </span> */}
              {Math.round(commissionData?.pendingCommission)}
            </p>
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-50 mt-1">
              <ArrowCircleDownSharpIcon
                sx={{ fontSize: 35 }}
                className="h-8 w-8 text-red-600 hover:animate-bounce"
              />
            </div>
          </div>
          {/* <p className="">
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
          </p> */}
        </div>
        <>
          {shouldRenderGraph && (
            <MiniLineGraph data={commissionData?.totalCommissionByDate} />
          )}
        </>
      </div>
    </>
  );
};

export default ReportTopRow;
