import React, { useState } from "react";
import { Link } from "react-router-dom";
import { assignAgentToRepOrCollegeAPI } from "../../../API/admin/AssignAgentToRepAPI";

const CollegeTableRowAssign = ({ collegeData, i, agentId }) => {
  const handleAssignToCollege = async () => {
    try {
      // Display a confirmation dialog
      const userConfirmed = window.confirm(
        "Are you sure you want to assign to this representative?"
      );

      // Proceed only if the user confirmed
      if (userConfirmed) {
        const reference = "college";
        const formData = {
          collegeId: collegeData._id,
          agentId,
        };
        const response = await assignAgentToRepOrCollegeAPI(
          formData,
          reference
        );
        if (response.status === 200) {
          window.location.reload();
        } else {
          alert("soothing failed");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <tr key={i} className="text-gray-700">
      <td className="py-3 px-4">
        <Link
          to={`${collegeData.name}`}
          state={{ collegeId: collegeData._id }}
          className="hover:underline"
        >
          {collegeData.name}
        </Link>
      </td>
      <td className="py-3 px-4">
        <button
          className="bg-blue-300 text-white px-4 py-1 rounded hover:bg-blue-600  text-sm font-semibold"
          onClick={() => handleAssignToCollege(collegeData._id)}
        >
          Assign
        </button>
      </td>
    </tr>
  );
};

export default CollegeTableRowAssign;
