import React, { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { fetchInvoiceAPI } from "../../../API/admin/PaymentsAPI";
import { Link, useLocation } from "react-router-dom";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import CloseIcon from "@mui/icons-material/Close";
// import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";

const PaymentInvoice = ({ paymentReceipt, setIsInvoiceModal }) => {
  const [invoices, setPaymentInvoices] = useState([]);
  const [singleInvoices, setPaymentSingleInvoices] = useState({});
  function handleDownloadButtonClick() {
    // Code to download the invoice as PDF
    const invoiceElement = document.getElementById("invoice");

    html2canvas(invoiceElement).then((canvas) => {
      const invoiceImage = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "px", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      pdf.addImage(invoiceImage, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save(`${singleInvoices?.name}-invoice.pdf`);
    });
  }
  useEffect(() => {
    const handelFetchInvoice = async () => {
      try {
        const response = await fetchInvoiceAPI(paymentReceipt);
        if (response.status === 200) {
          setPaymentInvoices(response?.data);
          setPaymentSingleInvoices(response?.data[0]);
        } else {
        }
      } catch (error) {}
    };
    handelFetchInvoice();
  }, [paymentReceipt]);
  const invoiceRef = useRef(null);
  const changeInvoice = (index) => {
    setPaymentSingleInvoices(invoices[index]);
  };

  const location = useLocation();
  return (
    <>
      <div className="flex justify-center">
        <div className="w-full h-auto shadow-lg shadow-gray-500 rounded-lg ">
          <div className="w-[90%] mx-auto">
            <div>
              {invoices.length > 1 && (
                <h4 className={`text-gray-500 font-medium mt-3 px-5`}>
                  Select invoice
                </h4>
              )}
              <div className="flex gap-3 px-5">
                {invoices.length > 1 &&
                  invoices.map((data, index) => (
                    <button
                      onClick={() => changeInvoice(index)}
                      className=" ease-in duration-300 font-bold  bg-blue-500 hover:bg-blue-600  text-sm text-white  py-2 px-4  rounded"
                    >
                      {index + 1}
                    </button>
                  ))}
              </div>
              {Object.keys(singleInvoices).length > 0 ? (
                <div className="p-5 border my-5" ref={invoiceRef} id="invoice">
                  <div className="flex justify-between">
                    <div className="">
                      <div>
                        <p className="text-[25px] text-red-500 font-semibold ">
                          Invoice
                        </p>
                      </div>
                      <div>
                        <p className="text-md text-black font-semibold">
                          {singleInvoices?.name}
                        </p>
                        <p className="text-md text-black font-semibold">
                          {singleInvoices?.collegeName}
                        </p>
                      </div>
                    </div>
                    <div className="">
                      <div className="flex flex-col justify-end">
                        <img
                          className="h-10 object-contain"
                          src="https://beta.acetek.edu.sg/wp-content/themes/acetek/assets/images/favicon.png"
                          alt="logo"
                        />
                        <p className="text-md text-black font-semibold ">
                          Acetek
                        </p>
                        {/* <p className="text-md text-black font-semibold text-center">
                          singapore
                        </p> */}
                        {/* trst comment */}
                      </div>
                    </div>
                  </div>
                  <div className="border-2 border-gray-300 m-3" />
                  <div className="flex flex-col  gap-y-[20px] ">
                    <div className="flex justify-between">
                      <div className="text-gray-500 font-medium text-sm">
                        <p>Date</p>
                      </div>
                      <div className="font-medium capitalize text-sm">
                        <p>{singleInvoices?.date}</p>
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <div className="text-gray-500 font-medium text-sm">
                        <p>intake</p>
                      </div>
                      <div className="font-medium capitalize text-sm">
                        <p>{singleInvoices?.intake}</p>
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <div className="text-gray-500 font-medium text-sm">
                        <p>Payment Method</p>
                      </div>
                      <div className="font-medium capitalize text-sm">
                        <p>{singleInvoices?.paymentMethod}</p>
                      </div>
                    </div>

                    {/* <div className="flex justify-between">
                      <div className="text-gray-500 font-medium">
                        <p>Student</p>
                      </div>
                      <div className="font-medium capitalize">
                        <p>{singleInvoices?.name}</p>
                      </div>
                    </div> */}
                    {/* <div className="flex justify-between">
                      <div className="text-gray-500 font-medium text-sm">
                        <p>Course</p>
                      </div>
                      <div className=" font-medium mt-1 text-sm">
                        <p>baking</p>
                      </div>
                    </div> */}

                    <div className="flex justify-between">
                      <div className="text-black font-bold text-sm">
                        <p>Amount paid</p>
                      </div>
                      <div className="flex gap-1">
                        <span className="text-xs mt-[3px] font-semibold ">{singleInvoices?.currency}.</span>
                        <p className="font-bold text-sm ">{singleInvoices?.paidAmount}</p>
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <div className="text-black font-bold text-sm">
                        <p>Transaction id</p>
                      </div>
                      <div className="font-bold text-sm">
                        <p>{singleInvoices?.transactionId}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <p>loading....</p>
              )}
              <div className="flex flex-row justify-center gap-x-[20px] py-[10px] mb-5">
                <div>
                  {/* <button
                    onClick={handleDownloadButtonClick}
                    className=" ease-in duration-300  font-bold bg-green-500 hover:bg-green-600  text-sm text-white  py-2 px-8  rounded"
                    type="submit"
                  >
                    PRINT
                  </button> */}
                  <button
                    onClick={handleDownloadButtonClick}
                    className="relative inline-flex items-center justify-center px-8 py-2 overflow-hidden font-medium text-blue-600 transition duration-300 ease-out border-2 border-blue-500 rounded shadow-md group text-xs"
                  >
                    <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-blue-500 group-hover:translate-x-0 ease">
                      <DownloadForOfflineIcon />
                    </span>
                    <span className="absolute flex items-center justify-center w-full h-full text-blue-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                      Download
                    </span>
                    <span className="relative invisible">Download</span>
                  </button>
                </div>
                {location.pathname === "/admin/payments" ? (
                  <div>
                    <button
                      onClick={() => setIsInvoiceModal(false)}
                      className="relative inline-flex items-center justify-center px-8 py-2 overflow-hidden font-medium text-red-600 transition duration-300 ease-out border-2 border-red-500 rounded shadow-md group text-xs"
                    >
                      <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-red-500 group-hover:translate-x-0 ease">
                        <CloseIcon />
                      </span>
                      <span className="absolute flex items-center justify-center w-full h-full text-red-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                        Close
                      </span>
                      <span className="relative invisible">Close</span>
                    </button>
                  </div>
                ) : (
                  <div>
                    <Link
                      onClick={() => setIsInvoiceModal(false)}
                      className="relative inline-flex items-center justify-center px-8 py-2 overflow-hidden font-medium text-red-600 transition duration-300 ease-out border-2 border-red-500 rounded shadow-md group text-xs"
                    >
                      <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-red-500 group-hover:translate-x-0 ease">
                        <CloseIcon />
                      </span>
                      <span className="absolute flex items-center justify-center w-full h-full text-red-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                        Close
                      </span>
                      <span className="relative invisible">Close</span>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentInvoice;
