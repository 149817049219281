import { API } from "../axios";
import axios from "axios";

export const fetchAdminProfileAPI = async (id) => {
  try {
    const { data, status } = await API.get(`/admin/admin-profile/${id}`);
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

export const fetchCollegesAPI = async () => {
  try {
    const { data, status } = await API.get("/admin/all-colleges");
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

export const fetchCoursesAPI = async (collegeId) => {
  try {
    const { data, status } = await API.get(`/admin/all-courses/${collegeId}`);
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

export const fetchStudentsAPI = async (collegeId) => {
  try {
    const { data, status } = await API.get(`/admin/all-students/${collegeId}`);
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

export const addAdminAPI = async (formData) => {
  try {
    const { data, status } = await API.post("/admin/add-Admin", formData);
    return { data, status };
  } catch ({ response }) {
    return response;
  }
};

export const fetchAdminAPI = async () => {
  try {
    const { data, status } = await API.get(`/admin/all-admins/`);
    return { data, status };
  } catch (error) {
    return error.status;
  }
};

export const fetchAdminShortData = async (id) => {
  try {
    const { data, status } = await API.get(`/admin/admin-short/${id}`);
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

export const fetchCountriesAPI = async () => {
  try {
    const response = await axios.get(process.env.REACT_APP_COUNTRIES_API_URL);
    const countryNames = response.data.map((country) => country.name);
    return countryNames;
  } catch (error) {
    return error?.response;
  }
};

export const fetchCurrencyAPI = async () => {
  try {
    const response = await axios.get(process.env.REACT_APP_COUNTRIES_API_URL);
    const data = response.data;
    return data;
  } catch (error) {
    // Handle any errors
    return error?.response;
  }
};

export const fetchCourseNameAPI = async () => {
  try {
    const { data, status } = await API.get("/admin/all-course-name");
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};
// fetch all courses api for input options based on college
export const fetchCourseOptionAPI = async (collegeId) => {
  try {
    const { data, status } = await API.get(
      `/admin/all-course-name/${collegeId}`
    );
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

export const addCollegesAPI = async (formData) => {
  try {
    const { data, status } = await API.post("/admin/add-colleges", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

export const fetchAllRepresentativesAPI = async () => {
  try {
    const { data, status } = await API.get("/admin/representatives-list");
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

export const fetchAllAgentsAPI = async () => {
  try {
    const { data, status } = await API.get("/admin/agents-list");
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

export const fetchRepNamesAPI = async (collegeId) => {
  try {
    const { data, status } = await API.get(
      `/admin/representative-name-options/`
    );
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

export const AddRepresentativeAPI = async (formData) => {
  try {
    const { data, status } = await API.post(
      "/admin/add-representatives",
      formData
    );
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

export const AddAgentsAPI = async (formData) => {
  try {
    const { data, status } = await API.post("/admin/add-agents", formData);
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

export const fetchSingleCollegeAPI = async (id) => {
  try {
    const { data, status } = await API.get(`/admin/college/${id}`);
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

export const fetchAgentNamesAPI = async (repId, collegeId) => {
  try {
    const { data, status } = await API.get(
      `/admin/all-agents-name/${repId}/${collegeId}`
    );
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

export const AddStudentAPI = async (formData) => {
  try {
    const { data, status } = await API.post(`/admin/add-student`, formData);
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

export const fetchRepDataAPI = async (id) => {
  try {
    const { data, status } = await API.get(`/admin/representative/${id}`);
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

export const fetchSingleAgentAPI = async (id) => {
  try {
    const { data, status } = await API.get(`/admin/agent/${id}`);
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

export const addCoursesAPI = async (formData) => {
  try {
    const { data, status } = await API.post("/admin/add-course", formData);
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

export const fetchSingleStudentAPI = async (studentId) => {
  try {
    const { data, status } = await API.get(`/admin/student/${studentId}`);
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

export const fetchSingleCourseAPI = async (courseId) => {
  try{
    const { data, status } = await API.get(`/admin/course/${courseId}`);
    return { data, status }

  }catch (error) {
    return error?.response;

  }
}


export const editStudentAPI = async (formData, studentId) => {
  try {
    const { data, status } = await API.put(
      `/admin/edit-student/${studentId}`,
      formData
    );
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};


export const editCourseAPI = async (formData, courseId) => {
  console.log(formData);
  
  try {
    const { data, status } = await API.put(
      `/admin/edit-courses/${courseId}`,
      formData
    );
    return { data, status };
  } catch (error) {
    return error?.response;
  }
}


export const editCollegeAPI = async (formData, collegeId) => {

  try {
    const { data, status } = await API.put(
      `/admin/edit-college/${collegeId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};




export const editProfileAPI = async (formData) => {
  try {
    const { data, status } = await API.patch(
      "/admin/edit-admin-profile",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};


export const editRepData = async (formData, repId) => {
  try {
    const { data, status } = await API.put(
      "/admin/edit-representatives/" + repId,
      formData
    );
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

export const editAgentDataAPI = async (formData, agentId) => {
  try {
    const { data, status } = await API.put(
      "/admin/edit-agent/" + agentId,
      formData
    );
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

export const deleteCollegeAPI = async (collegeId) => {
  try {
    const { data, status } = await API.patch(
      `/admin/delete-colleges/${collegeId}`
    );
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

export const deleteAdminAPI = async (id) => {
  try {
    const { data, status } = await API.patch(`/admin/delete-admin/${id}`);
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

export const deleteAgentAPI = async (id) => {
  try {
    const { data, status } = await API.patch(`/admin/delete-agent/${id}`);
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

export const deleteRepAPI = async (id) => {
  try {
    const { data, status } = await API.patch(`/admin/delete-rep/${id}`);
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

export const deleteCoursesAPI = async (id) => {
  try {
    const { data, status } = await API.patch(`/admin/delete-courses/${id}`);
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

export const editRepCommissionAPI = async (body) => {
  try {
    const { data, status } = await API.put(`/admin/edit-rep-commission`, body);
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

export const editAgentCommissionAPI = async (body) => {
  try {
    const { data, status } = await API.put(
      `/admin/edit-agent-Commission`,
      body
    );
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

export const editAdminCommissionAPI = async (body) => {
  try {
    const { data, status } = await API.put(
      `/admin/edit-admin-Commission`,
      body
    );
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

export const uploadAgentCertificateAPI = async (formData, agentId) => {
  try {
    const { data, status } = await API.post(
      `/admin/certificate-file-upload/${agentId}`,
      formData
    );
    return { data, status };
  } catch (error) {
    return error?.response;
  }
}