import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import { addBasicAgentAPI } from "../../../API/Agent/AgentAPI";
import RemoveRedEyeSharpIcon from "@mui/icons-material/RemoveRedEyeSharp";
import VisibilityOffSharpIcon from "@mui/icons-material/VisibilityOffSharp";
import Loader from "../../AgentComponents/Loader/Loader";

const Registration = () => {
  const [isLoader, setIsLoader] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    phoneNumber: "",
  });
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);
    
    if (Object.keys(validationErrors).length === 0) {
      setIsLoader(true);
      try {
        const response = await addBasicAgentAPI(formData);
        console.log(response);
        setIsLoader(false);
  
        if (response.status === 201) {
          setFormData({
            name: "",
            email: "",
            password: "",
            phoneNumber: "",
          });
          localStorage.setItem("agentToken", response.data.basicToken);
          navigate("/agent/agentmainapplication");
        } else if (response.status === 409) {
          setErrors({ email: " This Email already exists" });
        } else {
          console.error("API request failed with status:", response.status);
          console.error("Response data:", response.data);
        }
      } catch (error) {
        console.error("An error occurred while making the API request", error);
        setIsLoader(false); // Make sure to reset loader state in case of an error
      }
    } else {
      setIsLoader(false); // Reset loader state if there are validation errors
      setErrors(validationErrors);
    }
  };
  
  
  
  const validateForm = (data) => {
    const errors = {};

    if (data.name.trim() === "") {
      errors.name = "Name is required";
    }

    if (!isValidEmail(data.email)) {
      errors.email = "Enter Valid Email";
    }

    if (data.password.length < 6) {
      errors.password = "Password must be at least 6 characters long";
    }

    if (data.phoneNumber=="") {
      errors.phoneNumber = " phone number required";
    }
    //
    return errors;
  };

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  // const isValidPhoneNumber = (phoneNumber) => {
  //   return /^\d{10}$/.test(phoneNumber);
  // };

  return (
    <div>
      <div className="relative">
        <div className="w-[95%] my-0 mx-auto">
          <div className=" flex flex-col sm:flex sm:flex-col md:flex  md:justify-between md:pt-20 lg:flex  lg:flex-row lg:justify-around">
            <div className=" flex flex-col justify-center item-center gap-y-6 pt-20 z-20 basis-2/1 pb-6 ">
              <div className="flex justify-center items-center absolute top-2  left-0 md:left-[110px] md:top-[50px] lg:left-0 lg:top-44 xl:left-[110px]  xl:top-[200px]">
                <div className="w-[60px] h-auto">
                  <img
                    className="w-[100%] h-[100%] object-cover"
                    src="/assets/img/logo-01.png"
                    alt=""
                  />
                </div>
                <div>
                  <p className="  sm:text-lg md:text-2xl lg:text-3xl  text-2xl text-gray-700 font-semibold drop-shadow-md ">
                    Agent Management System
                  </p>
                </div>
              </div>
              <div>
                <p className="text-lg sm:text-lg text-gray-700 font-semibold flex">
                  Why become a AMS Partner
                </p>
              </div>
              <div className="flex gap-x-4">
                <div className="border flex justify-center items-center  bg-red-500 w-1 h-1 px-3 py-3 rounded-full shadow-gray-700">
                  <div className="flex justify-center item-center text-white font-bold text-lg ">
                    <DoneIcon sx={{ fontSize: 18 }} />
                  </div>
                </div>
                <div>
                  <p className=" text-sm sm:text-md text-gray-800 text-md">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Delectus incidunt eum suscipit
                  </p>
                </div>
              </div>
              <div className="flex gap-x-4">
                <div className="border flex justify-center items-center  bg-red-500 w-1 h-1 px-3 py-3  rounded-full shadow-gray-700">
                  <div className="flex justify-center item-center text-white font-bold text-lg ">
                    <DoneIcon sx={{ fontSize: 16 }} />
                  </div>
                </div>
                <div>
                  <p className=" text-sm sm:text-md  text-gray-800 text-md">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Delectus incidunt eum suscipit
                  </p>
                </div>
              </div>
              <div className="flex gap-x-4">
                <div className="border flex justify-center items-center  bg-red-500 w-1 h-1 px-3 py-3 rounded-full shadow-gray-700">
                  <div className="flex justify-center item-center text-white font-bold text-lg ">
                    <DoneIcon sx={{ fontSize: 16 }} />
                  </div>
                </div>
                <div>
                  <p className=" text-sm sm:text-md  text-gray-800 text-md">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Delectus incidunt eum suscipit
                  </p>
                </div>
              </div>
              <div className="flex gap-x-4">
                <div className=" border flex justify-center items-center bg-red-500 w-1 h-1 px-3 py-3 rounded-full shadow-gray-700">
                  <div className="flex justify-center item-center text-white font-bold text-lg  ">
                    <DoneIcon sx={{ fontSize: 16 }} />
                  </div>
                </div>
                <div>
                  <p className=" text-sm sm:text-md  text-gray-800 text-md">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Delectus incidunt eum suscipit
                  </p>
                </div>
              </div>
            </div>
            <div className=" vector-bg md:hidden lg:block">
              <img src="/assets/img/vector.svg" alt="" />
            </div>
            <div className="shadow-lg px-10 py-10 bg-white rounded-lg z-10 flex justify-center item-center flex-col basis-1/4 ">
              <div className=" text-md sm:text-lg md:text-xl pb-6 font-semibold text-gray-600 flex justify-center item-center md:flex-col">
                <p>Create Your AMS Agent Account</p>
              </div>
              <form
                className="w-full  max-w-md mx-auto sm:max-w-lg md:max-w-xl lg:max-w-2xl "
                onSubmit={handleSubmit}
              >
                <div className="mb-4">
                  <label
                    htmlFor="name"
                    className=" text-sm flex  mb-2 text-gray-600 font-semibold"
                  >
                    {" "}
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Enter Your Name"
                    value={formData.name}
                    onChange={handleInputChange}
                    className="border border-gray-600 opacity-50 rounded w-full sm:w-full text-sm  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                  {errors.name && (
                    <span className="text-red-500 text-sm flex justify-start items-start">
                      {errors.name}
                    </span>
                  )}
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="name"
                    className="  text-sm  flex mb-2 text-gray-600 font-semibold"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Enter Your Email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className="border border-gray-600 opacity-50 rounded w-full sm:w-full text-sm  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                  {errors.email && (
                    <span className="text-red-500 text-sm flex justify-start items-start">
                      {errors.email}
                    </span>
                  )}
                </div>
                <div className="mb-4 relative">
                  <label
                    htmlFor="name"
                    className=" text-sm flex mb-2 text-gray-600 font-semibold"
                  >
                    Password
                  </label>
                  <input
                    type={isVisible ? "text" : "password"}
                    id="password"
                    name="password"
                    placeholder="Enter Your Password"
                    value={formData.password}
                    onChange={handleInputChange}
                    className="border border-gray-600 opacity-50 rounded w-full sm:w-full text-sm  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "
                  />
                  <button
                    type="button"
                    className="absolute  top-8 right-6 bg-white text-gray-500"
                    onClick={() => setIsVisible(!isVisible)}
                  >
                    {!isVisible ? (
                      <RemoveRedEyeSharpIcon />
                    ) : (
                      <VisibilityOffSharpIcon />
                    )}
                  </button>
                  {errors.password && (
                    <span className="text-red-500 text-sm flex justify-start items-start">
                      {errors.password}
                    </span>
                  )}
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="name"
                    className=" text-sm flex  mb-2 text-gray-600 font-semibold"
                  >
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    id="phoneNumber"
                    name="phoneNumber"
                    placeholder="Enter Your PhoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleInputChange}
                    className="border border-gray-600 opacity-50 rounded w-full sm:w-full text-sm  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                  {errors.phoneNumber && (
                    <span className="text-red-500 text-sm flex justify-start items-start">
                      {errors.phoneNumber}
                    </span>
                  )}
                </div>

                <div className="mb-4 mt-8 flex justify-center items-center">
                  <button
                    type="submit"
                    className=" text-sm sm:text-md  w-full sm:w-full  rounded-full bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-10 focus:outline-none focus:shadow-outline shadow-lg"
                    disabled={isLoader}
                  >
                  {isLoader ? <Loader /> : "Submit Application"}
                  </button>
                </div>

                <div className="w-full md:w-[360px] flex justify-center items-center">
                  <p className="text-sm sm:text-md  text-gray-500">
                    <div>
                      By signing up, you agree to our
                      <a href="!#" className="text-red-500">
                        Terms &amp; Conditions
                        <span className="text-gray-500">and </span>
                      </a>
                      <a href="!#" className=" text-red-500">
                        Privacy Policy
                      </a>
                    </div>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration;
