import { API } from "../axios";

export const addBasicAgentAPI = async (formData) => {
  try {
    const { data, status } = await API.post(
      "agent/agent-application/basic-registration",
      formData
    );
    return { data, status };
  } catch ({ response }) {
    return response;
  }
};

export const checkAPI = async (agentTokens) => {
  try {
    const { data, status } = await API.post(
      "/agent/registration/registration-steps-checks",
      { basicToken: agentTokens }
    );
    return { data, status };
  } catch ({ response }) {
    return response;
  }
};
// export const panAPI = async (formPanData) => {
//   try {
//     const { data, status } = await API.post(
//       "/agent/registration/pan-details",
//       formPanData
//     );
//     return { data, status };
//   } catch ({ response }) {
//     return response;
//   }
// };
export const fileAPI = async (formData) => {
  try {
    const { data, status } = await API.post(
      "/agent/registration/documents-verification",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return { data, status };
  } catch ({ response }) {
    return response;
  }
};

export const applicantApi = async (formData) => {
  try {
    const { data, status } = await API.post(
      "/agent/agent-application/applicant-information",
      formData
    );
    return { data, status };
  } catch ({ response }) {
    return response;
  }
};
export const businessInfoaApi = async (formBankData) => {
  try {
    const { data, status } = await API.post(
      "/agent/agent-application/business-information",
      formBankData
    );
    return { data, status };
  } catch ({ response }) {
    return response;
  }
};
export const referenceApi = async (formReference) => {
  try {
    const { data, status } = await API.post(
      "/agent/agent-application/business-reference",
    
      formReference
    );
    return { data, status };
  } catch ({ response }) {
    return response;
  }
};
export const businessreferenceApi = async (formBusinessReference) => {
  try {
    const { data, status } = await API.post(
      "/agent/agent-application/business-reference",
    
      formBusinessReference
    );
    return { data, status };
  } catch ({ response }) {
    return response;
  }
};

export const recruitmentApi = async (formPanData) => {
  try {
    const { data, status } = await API.post(
      "/agent/agent-application/student-recruitment",
    
      formPanData
    );
    return { data, status };
  } catch ({ response }) {
    return response;
  }
};
export const  documentsCompanyApi = async (formData) => {
  try {
    const { data, status } = await API.post(
      "/agent/agent-application/supporting-documents-as-company",
    
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return { data, status };
  } catch ({ response }) {
    return response;
  }
};
export const documentsIndividualApi = async (formDataIndividual) => {
  try {
    const { data, status } = await API.post(
      "/agent/agent-application/supporting-documents-as-individual",
      formDataIndividual,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return { data, status };
  } catch ({ response }) {
    return response;
  }
};
export const ApplicationCheckAPI = async (agentTokens) => {
  try {
    const { data, status } = await API.post(
      "/agent/agent-application/application-steps-checks",
      { basicToken: agentTokens }
    );
    return { data, status };
  } catch ({ response }) {
    return response;
  }
};

export const fetchCollegesAPI = async (agentToken) => {
  try {
    const { data, status } = await API.get(`/agent/all-colleges/${agentToken}`);
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};
export const fetchCourseAPI = async (collegeId) => {
  try {
    const { data, status } = await API.get(`/agent/all-courses/${collegeId}`);
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};
export const fetchStudentsAPI = async (collegeId) => {
  try {
    const { data, status } = await API.get(`/agent/all-students/${collegeId}`);
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};
export const fetchCourseNameAPI = async () => {
  try {
    const { data, status } = await API.get("/agent/all-course-name");
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

export const fetchSingleStudentAPI = async (studentId) => {
  try {
    const { data, status } = await API.get(`/agent/student/${studentId}`);
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

export const fetchAgentProfileAPI = async (agentId) => {
 
  try {
    const { data, status } = await API.get(`/agent/agent-profile/${agentId}`);
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

export const fetchAgentProfileForSideBarAPI = async (agentId) => {
 
  try {
    const { data, status } = await API.get(`/agent/agent-profile-for-side-bar/${agentId}`);
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};


export const fetchASingleCollageAPI = async (collegeId) => {
  try {
    const { data, status } = await API.get(`/agent/college/${collegeId}`);
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

