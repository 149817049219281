import React, { useEffect, useState } from "react";
import Layout from "../../../components/AdminComponents/Layout/Layout";
import ComponentLoading from "../../CommonPreloader/ComponentLoading";
import { useLocation } from "react-router-dom";
import { fetchSingleCourseAPI } from "../../../API/admin/AdminAPI";
import CourseProfile from "../../../components/AdminComponents/Profiles/CourseProfile";

const CoursePro = () => {
  const { state } = useLocation();
  const [courseData, setCourseData] = useState({});
  const [componentLoader, setComponentLoader] = useState(true);
  const [refreshComponent, setRefreshComponent] = useState(false);
  console.log(courseData,"courseData");
  
  useEffect(() => {
    if (componentLoader) {
      // Set a delay of 3 seconds
      const timer = setTimeout(() => {
        setComponentLoader(false);
      }, 500);

      // Cleanup function to clear the timer if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [componentLoader]);
  const handleRefresh = () => {
    setRefreshComponent(true);
  };

  useEffect(() => {
    if (refreshComponent) {
      window.location.reload();
    }
  }, [refreshComponent]);

  const fetchSingleCourse = async () => {
    const response = await fetchSingleCourseAPI(state?.courseId);
    if (response.status === 200) {
        setCourseData(response?.data);
    } else {
        setCourseData({});
    }
  };

  useEffect(() => {
    try {
      fetchSingleCourse();
    } catch (error) {
      console.log(error);
    }
  }, [state]);

  return (
    <Layout>
      {!componentLoader ? (
        <>
          {Object.keys(courseData).length !== 0 ? (
            <CourseProfile
              courseData={courseData}
              handleRefresh={handleRefresh}
              fetchSingleCourse={fetchSingleCourse}
            />
          ) : (
            <ComponentLoading />
          )}
        </>
      ) : (
        <ComponentLoading />
      )}
    </Layout>
  );
};

export default CoursePro;
