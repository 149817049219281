import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from '@mui/icons-material/Edit';

const AdminTableRows = ({ admin }) => {
  const adminRed = useSelector((state) => state.adminData.adminData);
  return (
    <>
      <tr key={admin?._id}>
        <td className="border border-1 border-solid text-center text-sm p-[8px] hover:underline">
          <Link
            to={
              adminRed._id === admin._id
                ? "/admin/profile"
                : `/admin/admin-profile/${admin?.name}`
            }
            state={{ adminId: admin._id }}
          >
            {admin?.name}
          </Link>
        </td>
        <td className="border border-1 border-solid text-center text-sm p-[8px]">
          {admin?.email}
        </td>
        <td className="border border-1 border-solid text-center text-sm p-[8px]">
          {admin?.type}
        </td>
        <td className="border border-1 border-solid text-center text-sm p-[8px]">
          {admin?.address?.country}
        </td>
        <td className="text-[30px] text-gray-500 text-center border border-1 border-solid  p-[8px]">
          {/* <button class="bg-blue-300 hover:bg-blue-400 text-black font-bold py-2 px-2 rounded inline-flex items-center">
            <EditIcon />
          </button>
          <button class="ml-2 bg-red-300 hover:bg-red-400 text-white font-bold py-2 px-2 rounded inline-flex items-center">
            <DeleteIcon />
          </button> */}
          ...
        </td>
      </tr>
    </>
  );
};

export default AdminTableRows;
