import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
// import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";
import { quickEditAgentRepAndAdminCommission } from "../../../API/admin/PaymentsAPI";

const QuickCommissionChange = ({ commissionData, setAddStudentModal }) => {
  const [formData, setFormData] = useState({
    adminCommission: commissionData?.admin?.commission,
    repCommission: commissionData?.representative?.commission,
    agentCommission: commissionData?.agent?.commission,
    amountGoesToCollege:commissionData.collegeAmount
  });
  const [commissionAmount, setCommissionAmount] = useState(
    // Number(formData.adminCommission) +
      Number(formData.repCommission) +
      Number(formData.agentCommission) 
  );
  const [collegeAmount, setCollegeAmount] = useState(0);
  const [notification, setNotification] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const handleAdminInputChange = (e) => {
    let value = parseInt(e.target.value);
    if (isNaN(value)) {
      value = 0;
    }
    setFormData((prevData) => ({
      ...prevData,
      // adminCommission: value,
      repCommission: Math.min(prevData.repCommission, value),
      agentCommission: Math.min(prevData.agentCommission, value),
    }));
  };

  useEffect(() => {
    setCollegeAmount(
      commissionData.collegeAmount -
        (
          // Number(formData.adminCommission) +
          Number(formData.repCommission) +
          Number(formData.agentCommission))
    );
  }, [formData]);

  const handleRepInputChange = (e) => {
    let value = parseInt(e.target.value);
    if (isNaN(value)) {
      value = 0;
    }
    // const adminCommission = parseInt(formData.adminCommission);
    setFormData((prevData) => ({
      ...prevData,
      repCommission: Math.min(
        value,
        // adminCommission - prevData.agentCommission
      ),
    }));
  };

  const handleAgentInputChange = (e) => {
    let value = parseInt(e.target.value);
    if (isNaN(value)) {
      value = 0;
    }
    // const adminCommission = parseInt(formData.adminCommission);
    setFormData((prevData) => ({
      ...prevData,
      agentCommission: Math.min(
        value,
        // adminCommission - prevData.repCommission
      ),
    }));
  };

  const validateForm = () => {
    const errors = {};
    // Validate each input here
    if (String(formData.agentCommission).trim() === "") {
      errors.agentCommission = "Field is required";
    }
    if (String(formData.repCommission).trim() === "") {
      errors.repCommission = "Field is required";
    }
    // if (String(formData.adminCommission).trim() === "") {
    //   errors.adminCommission = "Field is required";
    // }
    return errors;
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const errors = validateForm();
    if (formData) {
    }
    if (Object.keys(errors).length === 0) {
      try {
        const createFormData = {
          ...formData,
          paymentId: commissionData._id,
          agentId: commissionData?.agent?._id,
          repId: commissionData?.representative._id,
          admin: commissionData?.admin?._id,
        };
        const response = await quickEditAgentRepAndAdminCommission(
          createFormData
        );
        if (response?.status === 200) {
          // Reset form after successful submission
          const { repCommission, adminCommission, agentCommission } =
            response.data;
          setFormData(() => ({
            repCommission,
            adminCommission,
            agentCommission,
          }));
          setCommissionAmount(
            // response?.data?.adminCommission +
              response?.data?.repCommission +
              response?.data?.agentCommission
          );
          setCollegeAmount(
            collegeAmount -
              (response?.data?.adminCommission +
                response?.data?.repCommission +
                response?.data?.agentCommission)
          );
          setNotification(true);
          setTimeout(() => {
            setNotification(false);
          }, 3000);
        } else {
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setFormErrors(errors);
    }
  };

  return (
    <>
      <div className="py-4 border-2 border-black my-5 rounded-2xl w-full bg-white shadow-md shadow-gray-400">
        <div className="px-[20px]">
          <div className="flex justify-between">
            <div>
              <h2 className="text-red-600 font-bold text-2xl my-1">
                Commissions:-
              </h2>
              <div className="flex gap-2">
                <h4 className="font-bold text-sm ">
                  Amount Goes to College :{" "}
                </h4> 
                <span className="text-md font-bold">{formData.amountGoesToCollege}</span>
              </div>
              <div className="flex gap-2">
                <h4 className="font-bold text-sm ">Total Commission :</h4>
                <span className="text-md font-bold">{commissionAmount}</span>
              </div>
            </div>
            <div>
              <div className="px-3">
                <button
                  onClick={() => setAddStudentModal(false)}
                  className="md:ease-in duration-300 mt-4 bg-red-300 hover:bg-red-600 text-black text-sm hover:text-white font-semibold py-2 px-5  rounded"
                  type="submit"
                >
                  <CloseIcon />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full overflow-x-scroll">
          <div className="py-4"></div>
          <table className="w-[100%]">
            <thead>
              <tr className="text-gray-500">
                <th className="text-xs w-40">Course Name:</th>
                <th className="text-xs w-40">Student Name:</th>
                <th className="text-xs w-40">Total Fees:</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="font-semibold text-center">
                  {commissionData?.courseName}
                </td>
                <td className="font-semibold text-center p-md">
                  {commissionData?.studentName}
                </td>
                <td className="font-semibold text-center p-md">
                  {commissionData?.totalFees}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex justify-center">
          <div className="border w-[90%] my-2"/>
        </div>
        <div>
          <table className="w-[100%] my-4">
            <thead>
              <tr className="text-gray-500">
                {/* <th className="text-xs">Admin commission:</th> */}
                <th className="text-xs">Rep Commission:</th>
                <th className="text-xs">Agent Commission:</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {/* <td>
                  <div className=" flex justify-center items-center">
                    <div>
                      <input
                        className="block w-[140px] px-4 py-1 border placeholder:text-gray-500 border-gray-300 rounded-lg"
                        type="number"
                        id="adminCommission"
                        name="adminCommission"
                        value={formData.adminCommission}
                        onChange={handleAdminInputChange}
                        required
                        readOnly
                      />
                      {formErrors.adminCommission && (
                        <span className="text-xs text-red-600">
                          {formErrors.adminCommission}
                        </span>
                      )}
                    </div>
                  </div>
                </td> */}
                <td>
                  <div className="flex justify-center items-center">
                    <div>
                      <input
                        className="block px-4 py-1 border placeholder:text-gray-500  border-gray-300 rounded-lg"
                        type="number"
                        id="repCommission"
                        name="repCommission"
                        value={formData.repCommission}
                        onChange={handleRepInputChange}
                        required
                        readOnly
                      />
                      {formErrors.repCommission && (
                        <span className="text-xs text-red-600 text-center">
                          {formErrors.repCommission}
                        </span>
                      )}
                    </div>
                  </div>
                </td>
                <td>
                  <div className=" flex justify-center items-center">
                    <div>
                      <input
                        className="block  px-4 py-1 border placeholder:text-gray-500  border-gray-300 rounded-lg"
                        type="number"
                        id="agentCommission"
                        name="agentCommission"
                        value={formData.agentCommission}
                        onChange={handleAgentInputChange}
                        readOnly
                        required
                      />
                      {formErrors.agentCommission && (
                        <span className="text-xs text-center text-red-600">
                          {formErrors.agentCommission}
                        </span>
                      )}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* {notification && (
          <div className="text-center font-bold text-md text-green-600 flex gap-2 justify-center">
            <FileDownloadDoneIcon />
            <p>Successfully Edited</p>
          </div>
        )}
        <div className="flex justify-center w-full">
          <th className=" text-[18px] py-[10px] text-center p-md">
            <button
              onClick={handleSubmit}
              className=" ease-in duration-300 bg-green-500 hover:bg-green-700 w-52  text-sm text-white font-bold  py-2 px-6  rounded"
              type="submit"
            >
              save
            </button>
          </th>
        </div> */}
      </div>
    </>
  );
};

export default QuickCommissionChange;
