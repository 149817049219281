import React from "react";

const ReportTr = ({ tableData }) => {
  return (
    <>
      <tr className="font-semibold border border-1 border-solid text-gray-700 border-gray-400 p-[8px]">
        {/* <td className="border border-1 border-solid text-left text-sm p-[8px] whitespace-nowrap ">
          12345678
        </td> */}
        <td className=" border border-1 border-solid text-left text-sm p-[8px] whitespace-nowrap ">
          <p className="capitalize">{tableData?.name}</p>
          <p>{tableData?.email}</p>
        </td>
        <td className=" border border-1 border-solid text-left text-sm p-[8px] whitespace-nowrap capitalize">
          {tableData?.totalCommission}
        </td>
        <td className=" border border-1 border-solid text-left text-sm p-[8px] whitespace-nowrap capitalize">
          {tableData?.totalEarnedCommission}
        </td>
        <td className=" border border-1 border-solid text-left text-sm p-[8px] whitespace-nowrap capitalize">
          {tableData?.totalPendingCommission}
        </td>
      </tr>
    </>
  );
};

export default ReportTr;
