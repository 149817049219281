import React from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AgentLayout from "../../../components/AgentComponents/AgentLayout/AgentLayout";
import AgentRecruitmentDocuments from "../AgentRecruitmentDocuments/AgentRecruitmentDocuments";
import { fetchAgentProfileAPI } from "../../../API/Agent/AgentAPI";
import jwt_decode from "jwt-decode";

const AgentSinglepro = () => {
  const [agentData, setAgentData] = useState({});
  const agentToken = localStorage.getItem("agent-auth-token");
  const [agentId, setAgentId] = useState("");
  useEffect(() => {
    // Check if the token exists before attempting to decode
    if (agentToken) {
      // Decode the JWT
      const decodedToken = jwt_decode(agentToken);

      // Access the decoded information
      console.log(decodedToken);

      // Do something with the decoded information if needed
      // For example, update state or make an API request using the decoded information
      setAgentId(decodedToken.agentId);
    }
  }, [agentToken]); // Run the effect when agentToken changes

  const { state } = useLocation();
  console.log(state);

  useEffect(() => {
    const fetchSingleAgents = async () => {
      try {
        // Add a null check for state before accessing state.agentId
        if (agentId.length !== 0) {
          const response = await fetchAgentProfileAPI(agentId);
          if (response?.status === 200) {
            setAgentData(response?.data);
          } else {
            setAgentData([]);
          }
        } else {
          setAgentData([]); // Handle the case when state or state.agentId is null or undefined
        }
      } catch (error) {
        setAgentData([]);
      }
    };

    fetchSingleAgents(); // Remove the unnecessary argument from fetchSingleAgents
  }, [agentId]);
  return (
    <div>
      <AgentLayout>
        <div className="relative">
          {Object.keys(agentData).length > 0 ? (
            <AgentRecruitmentDocuments agentData={agentData} />
          ) : (
            <p>no agent</p>
          )}
        </div>
      </AgentLayout>
    </div>
  );
};

export default AgentSinglepro;
