import React from "react";
// import { fetchCoursesAPI } from "../../../API/admin/AdminAPI";
import AgentCourseTableRow from "./AgentCourseTableRow";
import { useState, useEffect } from "react";
import { fetchCourseAPI } from "../../../API/Agent/AgentAPI";

const AgentCourseTable = ({ collegeData }) => {
  const [courseData, setCourseData] = useState([]);
  console.log("10000");
  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        console.log("20000");
        const response = await fetchCourseAPI(collegeData._id);
        if (response?.status === 200) {
          console.log("30000");

          setCourseData(response?.data);
        } else {
          setCourseData([]);
        }
      } catch (error) {
        setCourseData([]);
      }
    };
    fetchCourseData();
  }, [collegeData]);
  return (
    <div>
      <div className="mt-10 p-3 rounded-md shadow-lg shadow-gray-300 bg-gray-50 max-h-[400px] overflow-y-scroll">
        <table className="w-full border-collapse">
          <thead>
            <tr>
              <th className="text-gray-500 text-[16px]  py-[10px] border border-1 border-solid text-left p-[8px]">
                Course
              </th>
              <th className="text-gray-500 text-[16px]  py-[10px] border border-1 border-solid text-left p-[8px]">
                Duration
              </th>
              <th className="text-gray-500 text-[16px]  py-[10px] border border-1 border-solid text-left p-[8px]">
                Course Fees
              </th>

              <th className="text-gray-500 text-[16px]  py-[10px] border border-1 border-solid text-left p-[8px] ">
                Application Fees
              </th>
            </tr>
          </thead>
          <tbody>
            {courseData.length > 0
              ? courseData.map((course) => (
                  <AgentCourseTableRow course={course} key={course._id} />
                ))
              : null}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AgentCourseTable;
