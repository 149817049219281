import React, { useEffect, useState } from "react";
import Layout from "../../../components/AdminComponents/Layout/Layout";
import ReportComponent from "../../../components/AdminComponents/ReportComponent/ReportComponent";
import ComponentLoading from "../../CommonPreloader/ComponentLoading";

const Reports = () => {
  const [componentLoader, setComponentLoader] = useState(true);
  useEffect(() => {
    if (componentLoader) {
      // Set a delay of 3 seconds
      const timer = setTimeout(() => {
        setComponentLoader(false);
      }, 500);

      // Cleanup function to clear the timer if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [componentLoader]);
  return (
    <>
      <Layout>
        {!componentLoader ? <ReportComponent /> : <ComponentLoading />}
      </Layout>
    </>
  );
};

export default Reports;
