import { API } from "../axios";


export const assignAgentToRepOrCollegeAPI = async (formData, reference) => {
    try {
        const { data, status } = await API.post(`/admin/assign-agent-to-rep/${reference}`, formData);
        return { data, status };
    } catch (error) {
        return error?.response;
    }
};

export const fetchAllRepDataForAssignAPI = async () => {
    try {
        const { data, status } = await API.get(`/admin/fetch-rep-data-assign`);
        return { data, status };
    } catch (error) {
        return error?.response;
    }
};

export const fetchAllCollegesDataForAssignAPI = async () => {
    try {
        const { data, status } = await API.get(`/admin/fetch-college-data-assign`);
        return { data, status };
    } catch (error) {
        return error?.response;
    }
};