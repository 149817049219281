import React from "react";

const LogTabTh = ({ children }) => {
  return (
    <>
      <table className="w-full border-collapse">
        <thead>
          <tr className="">
            <th className="text-gray-500 text-[16px]  py-[10px] border border-1 border-solid text-center p-[8px]">
              Date
            </th>
            <th className="text-gray-500 text-[16px] text-md py-[10px] border border-1 border-solid text-center p-[8px]">
              Student
            </th>
            <th className="text-gray-500 text-[16px] text-md py-[10px] border border-1 border-solid text-center p-[8px]">
              College Name
            </th>
            <th className="text-gray-500 text-[16px] text-md py-[10px] border border-1 border-solid text-center p-[8px]">
              Total Fee
            </th>
            <th className="text-gray-500 text-[16px] text-md py-[10px] border border-1 border-solid text-center p-[8px]">
              To college
            </th>
            <th className="text-gray-500 text-[16px] text-md py-[10px] border border-1 border-solid text-center p-[8px]">
              Rep Commission
            </th>
            <th className="text-gray-500 text-[16px] text-md py-[10px] border border-1 border-solid text-center p-[8px]">
              Agent Commission
            </th>
            {/* <th className="text-gray-500 text-[16px] text-md py-[10px] border border-1 border-solid text-center p-[8px] ">
              Admin Commission
            </th> */}
            <th className="text-gray-500 text-[16px] text-md py-[10px] border border-1 border-solid text-center p-[8px] ">
              Invoice
            </th>
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </>
  );
};

export default LogTabTh;
