import React, { useEffect, useState } from "react";
import {
  editStudentAPI,
  // fetchCountriesAPI,
  fetchCourseOptionAPI,
} from "../../../API/admin/AdminAPI";
import SuccessFullyEdited from "./SuccessFullyEdited";
import Loader from "../CreateForms/Loader";
import { Discount } from "@mui/icons-material";

const EditStudent = ({
  collegeData,
  student,
  closeModal,
  setEditStudentModal,
  handleRefresh,
}) => {
  // const [countries, setCountries] = useState([]);
  const [courseOptions, setCourseOptions] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [studentExists, setStudentExists] = useState(false);
  const [tenureInputs, setTenureInputs] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [isEditSuccess, setIsEditSuccess] = useState(false);
  const [formData, setFormData] = useState({
    fullName: student?.name,
    email: student?.email,
    age: student?.age,
    // phone: student?.phone,
    passport: student?.passport,
    representative: student?.representative?._id,
    college: student?.college,
    course: student?.course?._id,
    discount:student?.discount,
    agents: student?.agent?._id,
    installment: student?.installment,
    intake: student?.intake,
    tenure: student?.tenure,
    tenureDate: [...student?.tenureDate],
    city: student?.address?.city,
    state: student?.address?.state,
    country: student?.address?.country,
    guardianName: student?.guardianName,
    guardianPassport: student?.guardianPassport,
    finalExamResultDate: student?.finalExamResultDate,
    confermentDate: student?.confermentDate,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "age" && value > 18) {
      setFormData((prevState) => ({
        ...prevState,
        age: value,
        guardianName: '',   // Reset guardian name
        guardianPassport: '', // Reset guardian passport
      }));
    }else{

      if (name === "tenureDate") {
        setFormData((prevState) => ({
          ...prevState,
          [name]: [...prevState[name], value],
        }));
      } else {
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    }
  };

  const handleTenureDateChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevState) => {
      const updatedTenureDate = [...prevState.tenureDate];
      const existingIndex = updatedTenureDate.findIndex((item) => item[name]);

      if (existingIndex !== -1) {
        updatedTenureDate[existingIndex][name] = value;
      } else {
        updatedTenureDate.push({ [name]: value });
      }

      return {
        ...prevState,
        tenureDate: updatedTenureDate,
      };
    });
  };

  const handleInstallmentInputChange = (event) => {
    const { name, value } = event.target;
    if (value === "one-time-settlement") {
      formData.tenure = "1-time";
    }
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // const validateEmail = () => {
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   const isValidEmail = emailRegex.test(formData.email);
  //   setIsEmailValid(isValidEmail);
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const errors = validateForm();
    console.log(errors, "errors");

    if (Object.keys(errors).length === 0) {
      try {
        const response = await editStudentAPI(formData, student._id);
        
        setIsLoader(true);
        if (response.status === 200) {
          setIsLoader(false);
          // Reset form after successful submission
          setIsEditSuccess(true);
        } else if (response.status === 409) {
          setIsLoader(false);
          setStudentExists(true);
        }
      } catch (error) {
        setIsLoader(false);
        console.log(error);
      }
    } else {
      setFormErrors(errors);
    }
  };
  const validateForm = () => {
    const errors = {};
    // Validate each input here
    if (formData.fullName?.trim() === "") {
      errors.fullName = "Full Name is required";
    }
    // if (formData.email?.trim() === "") {
    //   errors.email = "Email is required";
    // }
    // if (formData.phone.trim() === "") {
    //   errors.phone = "Phone is required";
    // }
    if (!formData.age) {
      errors.age = "Age is required";
    }
  
    if (formData.passport?.trim() === "") {
      errors.passport = "Passport is required";
    }
    if (formData.representative?.trim() === "") {
      errors.representative = "Representative is required";
    }
    if (formData.agents?.trim() === "") {
      errors.agents = "Agents is required";
    }
    if (formData.intake?.trim() === "") {
      errors.intake = "Intake is required";
    }
    if (formData.installment?.trim() === "") {
      errors.installment = "Mode is required";
    }
    // if (
    //   formData.installment === "installment" &&
    //   formData.tenure.trim() === "1-time"
    // ) {
    //   errors.tenure = "Tenure is required";
    // }
    // if (formData.tenureDate.length !== Number(formData.tenure.split("-")[0])) {
    //   errors.tenureDate = "Set all date is required";
    // }
    // if (formData.addressLine1.trim() === "") {
    //   errors.addressLine1 = "Address is required";
    // }
    // if (formData.addressLine2.trim() === "") {
    //   errors.addressLine2 = "Address Line2 is required";
    // }
    if (formData.city?.trim() === "") {
      errors.city = "City is required";
    }
    if (formData.age <   18) {
      if (formData.guardianName?.trim() === "") {
        errors.guardianName = "Guardian Name is required";
      }
      if (formData.guardianPassport?.trim() === "") {
        errors.guardianPassport = "Guardian Passport is required";
      }
    } 
    if (formData.finalExamResultDate?.trim() === "") {
      errors.finalExamResultDate = "finalExamResultDate is required";
    }
    if (formData.confermentDate?.trim() === "") {
      errors.confermentDate = "confermentDate is required";
    }
    if (formData.state?.trim() === "") {
      errors.state = "State is required";
    }
    if (formData.country?.trim() === "") {
      errors.country = "Country is required";
    }
    // if (formData.zip.trim() === "") {
    //   errors.zip = "Zip is required";
    // }
    return errors;
  };

  // const fetchCountries = async () => {
  //   const country = await fetchCountriesAPI();
  //   setCountries(country);
  // };
  // const fetchAgentNameOptions = async (repId) => {
  //   try {
  //     const response = await fetchAgentNamesAPI(repId);
  //     if (response?.status === 200) {
  //       setAgentOptions(response?.data);
  //     } else {
  //       setAgentOptions([]);
  //     }
  //   } catch (error) {
  //     setAgentOptions([]);
  //   }
  // };
  // const fetchRepData = async () => {
  //   try {
  //     const response = await fetchRepNamesAPI(formData.representative);
  //     if (response?.status === 200) {
  //       setRepData(response?.data);
  //     } else {
  //       setRepData([]);
  //     }
  //   } catch (error) {
  //     setRepData([]);
  //   }
  // };
  const fetchCourseNameOptions = async () => {
    try {
      const courseNames = await fetchCourseOptionAPI(collegeData?._id);
      if (courseNames.status === 200) {
        setCourseOptions(courseNames.data);
      } else {
        setCourseOptions([]);
      }
    } catch (error) {
      setCourseOptions([]);
    }
  };
  // useEffect(() => {
  //   if (formData.representative !== "") {
  //     fetchAgentNameOptions(formData.representative);
  //   }
  // }, [formData.representative]);

  useEffect(() => {
    fetchCourseNameOptions();
    // fetchCountries();
    // fetchRepData();
  }, []);

  useEffect(() => {
    // Update the total amount and tenure when payment prop changes
    if (formData.tenure !== "") {
      // Generate an array of checkbox values based on the tenure
      const values = Array.from(
        { length: formData.tenure?.split("-")[0] },
        () => false
      );
      setTenureInputs(values);
    }
  }, [formData.tenure]);

  return (
    <>
      <div className="flex items-center py-[20px]">
        <div
        >
          <div className="w-[95%] mx-auto">
            {/* heading */}
            <div className=" text-center md:py-[20px] text-[30px] md:text-left flex py-1">
              <p className="font-bold border-b-2 border-gray-400">
                Edit Student
              </p>
            </div>
            <div className="grid gap-3 grid-cols-1 md:grid-cols-3 my-3">
              {/* name */}
              <div>
                <label
                  className="block mb-2 font-medium text-sm"
                  htmlFor="fullName"
                >
                  Full Name:
                </label>
                <input
                  className="block px-4 py-2 w-full border placeholder:text-gray-500  border-gray-300 rounded-lg"
                  type="text"
                  id="fullName"
                  name="fullName"
                  placeholder="Full Name"
                  value={formData.fullName}
                  onChange={handleInputChange}
                  required
                />
                {formErrors.fullName && (
                  <span className="text-xs text-red-600">
                    {formErrors.fullName}
                  </span>
                )}
              </div>
              {/* phone */}
              {/* <div>
                <label
                  className="block mb-2 font-medium text-sm"
                  htmlFor="contactNumber"
                >
                  Contact Number:
                </label>
                <input
                  className="block px-4 py-2 w-full border placeholder:text-gray-500  border-gray-300 rounded-lg"
                  type="tel"
                  id="contactNumber"
                  name="phone"
                  placeholder="Contact Number"
                  value={formData.phone}
                  onChange={handleInputChange}
                  required
                />
                {formErrors.phone && (
                  <span className="text-xs text-red-600">
                    {formErrors.phone}
                  </span>
                )}
              </div> */}
              {/* mail id */}
              {/* <div>
                <label
                  className="block mb-2 font-medium text-sm"
                  htmlFor="emailAddress"
                >
                  Email Address:
                </label>
                <input
                  className="block px-4 py-2 w-full border placeholder:text-gray-500  border-gray-300 rounded-lg"
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email Address"
                  value={formData.email}
                  onChange={handleInputChange}
                  onBlur={validateEmail}
                  required
                />
                {studentExists && (
                  <span className="text-xs text-red-600">
                    Email already exist !
                  </span>
                )}
                {formErrors.email && (
                  <span className="text-xs text-red-600">
                    {formErrors.email}
                  </span>
                )}
              </div> */}
              <div className="">
                <label
                  className="block mb-2 font-medium text-sm"
                  htmlFor="passport"
                >
                  Passport:
                </label>
                <input
                  className="block w-full px-4 py-2 border placeholder:text-gray-500  border-gray-300 rounded-lg"
                  type="text"
                  id="passport"
                  name="passport"
                  placeholder="Passport"
                  value={formData?.passport}
                  onChange={handleInputChange}
                  required
                />
                {formErrors.passport && (
                  <span className="text-xs text-red-600">
                    {formErrors.passport}
                  </span>
                )}
              </div>
              <div className="">
              <label
                className="block mb-2 font-medium text-sm"
                htmlFor="discount"
              >
                Discount:
              </label>
              <input
                className="block w-full px-4 py-2 border placeholder:text-gray-500  border-gray-300 rounded-lg"
                type="number"
                id="discount"
                name="discount"
                placeholder="discount"
                value={formData.discount}
                onChange={handleInputChange}
                required
              />
            
            </div>

            </div>
            <div className="grid gap-3 grid-cols-1 md:grid-cols-3 my-3">
          <div className="">
              <label
                className="block mb-2 font-medium text-sm"
                htmlFor="salutation"
              >
                Age:
              </label>
              <input
                type="number"
                className="block w-full px-4 py-2 border placeholder:text-gray-500  border-gray-300 rounded-lg"
                id="age"
                name="age"
                value={formData.age}
                onChange={handleInputChange}
                required
              />
              {formErrors.age && (
                <span className="text-xs text-red-600">{formErrors.age}</span>
              )}
            </div>
            {formData.age && formData.age < 18 && (
  <>
    <div className="">
      <label className="block mb-2 font-medium text-sm" htmlFor="guardianName">
        Guardian Name:
      </label>
      <input
        className="block w-full px-4 py-2 border placeholder:text-gray-500 border-gray-300 rounded-lg"
        type="text"
        id="guardianName"
        name="guardianName"
        placeholder="Guardian Name"
        value={formData.guardianName}
        onChange={handleInputChange}
        required
      />
      {formErrors.guardianName && (
        <span className="text-xs text-red-600">{formErrors.guardianName}</span>
      )}
    </div>

    <div className="">
      <label className="block mb-2 font-medium text-sm" htmlFor="guardianPassport">
        Guardian Passport:
      </label>
      <input
        className="block w-full px-4 py-2 border placeholder:text-gray-500 border-gray-300 rounded-lg"
        type="text"
        id="guardianPassport"
        name="guardianPassport"
        placeholder="Guardian Passport"
        value={formData.guardianPassport}
        onChange={handleInputChange}
        required
      />
      {formErrors.guardianPassport && (
        <span className="text-xs text-red-600">{formErrors.guardianPassport}</span>
      )}
    </div>
  </>
)}

          </div>
          <div className="grid gap-3 grid-cols-1 md:grid-cols-3 my-3">

     
              <div>
                {/* college */}
                <label
                  className="block mb-2 font-medium text-sm"
                  htmlFor="courses"
                >
                  College
                </label>
                <select
                  className="block w-full px-4 py-2 border border-gray-300 rounded-lg"
                  id="colleges"
                  name="colleges"
                  value={formData.qualification}
                  onChange={handleInputChange}
                  required
                >
                  <option
                    className="text-black"
                    value={collegeData?._id}
                    selected
                  >
                    {collegeData?.name}
                  </option>
                </select>
              </div>
              {/* course */}
              <div>
                <label
                  className="block mb-2 font-medium text-sm"
                  htmlFor="Course"
                >
                  Course:
                </label>
                <select
                  className="block w-full px-4 py-2 border text-gray-500 border-gray-300 rounded-lg"
                  id="course"
                  name="course"
                  value={formData.course}
                  onChange={handleInputChange}
                  required
                >
                  <option className="text-black" selected>
                    Select a Course
                  </option>
                  {courseOptions.length !== 0 &&
                    courseOptions.map((courses) => (
                      <option
                        className="text-black"
                        key={courses?._id}
                        value={courses?._id}
                      >
                        {courses?.course}
                      </option>
                    ))}
                </select>
                {formErrors.course && (
                  <span className="text-xs text-red-600">
                    {formErrors.course}
                  </span>
                )}
              </div>

              <div>
                <label
                  className="block mb-2 font-medium text-sm"
                  htmlFor="Intake"
                >
                  Intake:
                </label>
                <select
                  className="block w-full px-4 py-2 border placeholder:text-gray-500  border-gray-300 rounded-lg"
                  id="intake"
                  name="intake"
                  value={formData.intake}
                  onChange={handleInputChange}
                  required
                >
                  <option selected>select a month</option>
                  <option className="text-black" value="Jan">
                    Jan
                  </option>
                  <option className="text-black" value="Feb">
                    Feb
                  </option>
                  <option className="text-black" value="Mar">
                    Mar
                  </option>
                  <option className="text-black" value="Apr">
                    Apr
                  </option>
                  <option className="text-black" value="May">
                    May
                  </option>
                  <option className="text-black" value="Jun">
                    Jun
                  </option>
                  <option className="text-black" value="Jul">
                    Jul
                  </option>
                  <option className="text-black" value="Aug">
                    Aug
                  </option>
                  <option className="text-black" value="Sep">
                    Sep
                  </option>
                  <option className="text-black" value="Oct">
                    Oct
                  </option>
                  <option className="text-black" value="Nov">
                    Nov
                  </option>
                  <option className="text-black" value="Dec">
                    Dec
                  </option>
                </select>
                {formErrors.intake && (
                  <span className="text-xs text-red-600">
                    {formErrors.intake}
                  </span>
                )}
              </div>
            </div>
            {/* second section */}
            <div className="grid gap-3 grid-cols-1 md:grid-cols-3 my-3">
 
              <div>
                {/* college */}
                <label
                  className="block mb-2 font-medium text-sm"
                  htmlFor="Installment"
                >
                  Payment:
                </label>
                <select
                  className="block px-4 py-2 w-full border placeholder:text-gray-500  border-gray-300 rounded-lg"
                  id="installment"
                  name="installment"
                  value={formData.installment}
                  onChange={handleInstallmentInputChange}
                  required
                >
                  <option selected>Select payment mode</option>
                  <option className="text-black" value="one-time-settlement">
                    One time settlement
                  </option>
                  <option className="text-black" value="installment">
                    Installment
                  </option>
                  {/* Add more options as needed */}
                </select>
                {formErrors.installment && (
                  <span className="text-xs text-red-600">
                    {formErrors.installment}
                  </span>
                )}
              </div>

              <div>
                <label
                  className="block mb-2 font-medium text-sm"
                  htmlFor="finalExamResultDate"
                >
                  finalExamResultDate:
                </label>
                <input
                  className="block px-4 py-2 w-full border placeholder:text-gray-500  border-gray-300 rounded-lg"
                  type="date"
                  id="finalExamResultDate"
                  name="finalExamResultDate"
                  placeholder="finalExamResultDate"
                  value={formData.finalExamResultDate}
                  onChange={handleInputChange}
                  required
                />
                {formErrors.finalExamResultDate && (
                  <span className="text-xs text-red-600">
                    {formErrors.finalExamResultDate}
                  </span>
                )}
              </div>
              <div>
                <label
                  className="block mb-2 font-medium text-sm"
                  htmlFor="confermentDate"
                >
                  confermentDate:
                </label>
                <input
                  className="block px-4 py-2 w-full border placeholder:text-gray-500  border-gray-300 rounded-lg"
                  type="date"
                  id="confermentDate"
                  name="confermentDate"
                  placeholder="confermentDate"
                  value={formData.confermentDate}
                  onChange={handleInputChange}
                  required
                />
                {formErrors.confermentDate && (
                  <span className="text-xs text-red-600">
                    {formErrors.confermentDate}
                  </span>
                )}
              </div>
              {/* {formData.installment === "installment" && (
                <div>
                  <label
                    className="block mb-2 font-medium text-sm"
                    htmlFor="tenure"
                  >
                    Payment Tenure:
                  </label>
                  <select
                    className="block px-4 py-2 w-full border placeholder:text-gray-500  border-gray-300 rounded-lg"
                    id="tenure"
                    name="tenure"
                    value={formData.tenure}
                    onChange={handleInputChange}
                    required
                  >
                    <option selected>Select times</option>
                    <option className="text-black" value="2-Time">
                      2 Times
                    </option>
                    <option className="text-black" value="3-Time">
                      3 Times
                    </option>
                    <option className="text-black" value="4-Times">
                      4 Times
                    </option>
                    <option className="text-black" value="5-Times">
                      5 Times
                    </option>
                    <option className="text-black" value="6-Times">
                      6 Times
                    </option>
                    <option className="text-black" value="Extra">
                      8 Times
                    </option>
                    <option className="text-black" value="Extra">
                      9 Times
                    </option>
                    <option className="text-black" value="Extra">
                      10 Times
                    </option>
                    <option className="text-black" value="Extra">
                      11 Times
                    </option>
                    <option className="text-black" value="Extra">
                      12 Times
                    </option>
                  </select>

                  {formErrors.tenure && (
                    <span className="text-xs text-red-600">
                      {formErrors.tenure}
                    </span>
                  )}
                </div>
              )} */}
            </div>
            {/* <div>
              <div className="grid md:grid-cols-4 lg:grid-cols-6 grid-cols-2 gap-3 bg-gray-100 p-2 rounded-lg my-2">
                {tenureInputs.length !== 0
                  ? tenureInputs.map((input, index) => (
                      <>
                        <div>
                          <p className="p-1 text-xs">
                            {tenureInputs.length === 1
                              ? "Expiration date:"
                              : `Emi-${index + 1} Expiration Date`}
                          </p>
                          <input
                            className="block px-2 py-1 border border-gray-300 rounded-lg text-sm"
                            id="tenureDate"
                            name={`tenureDate-${index + 1}`}
                            value={
                              formData.tenureDate.length !== 0
                                ? Object.values(formData.tenureDate[index])[0]
                                : ""
                            }
                            onChange={handleTenureDateChange}
                            required
                            type="date"
                          />
                        </div>
                      </>
                    ))
                  : null}
              </div>
            </div> */}

            {formErrors.tenureDate && (
              <div className="py-2 w-full flex justify-center">
                <span className="text-xs text-red-600 w-full text-center">
                  {formErrors.tenureDate}
                </span>
              </div>
            )}
            <p className="block my-3 font-medium text-sm" htmlFor="Address">
              Address:
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 my-[10px]">
              {/* <div>
                <input
                  className="block w-full px-4 py-2 border placeholder:text-gray-500  border-gray-300 rounded-lg"
                  type="text"
                  id="addressLine1"
                  name="addressLine1"
                  placeholder="Address Line 1"
                  value={formData.addressLine1}
                  onChange={handleInputChange}
                  required
                />
                {formErrors.addressLine1 && (
                  <span className="text-xs text-red-600">
                    {formErrors.addressLine1}
                  </span>
                )}
              </div>
              <div className="">
                <input
                  className="block w-full px-4 py-2 border placeholder:text-gray-500  border-gray-300 rounded-lg"
                  type="text"
                  id="addressLine2"
                  name="addressLine2"
                  placeholder="Address Line 2"
                  value={formData.addressLine2}
                  onChange={handleInputChange}
                  required
                />
                {formErrors.addressLine2 && (
                  <span className="text-xs text-red-600">
                    {formErrors.addressLine2}
                  </span>
                )}
              </div> */}
              <div className="">
                <input
                  className="block w-full px-4 py-2 border placeholder:text-gray-500  border-gray-300 rounded-lg"
                  type="text"
                  id="city"
                  name="city"
                  placeholder="City"
                  value={formData.city}
                  onChange={handleInputChange}
                  required
                />
                {formErrors.city && (
                  <span className="text-xs text-red-600">
                    {formErrors.city}
                  </span>
                )}
              </div>
              <div>
                <input
                  className="block w-full px-4 py-2 border placeholder:text-gray-500  border-gray-300 rounded-lg"
                  type="text"
                  id="state"
                  name="state"
                  placeholder="State"
                  value={formData.state}
                  onChange={handleInputChange}
                  required
                />
                {formErrors.state && (
                  <span className="text-xs text-red-600">
                    {formErrors.state}
                  </span>
                )}
              </div>
              <div>
                {/* college */}
                {/* <select
                  className="block w-full px-4 py-2 border placeholder:text-gray-500  border-gray-300 rounded-lg"
                  id="country"
                  name="country"
                  value={formData.country}
                  onChange={handleInputChange}
                  required
                >
                  <option value="" className="text-gray-500" selected>
                    Select a country
                  </option>
                  {countries.map((country) => (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  ))}
                  <option value="" className="text-gray-500" selected>
                    None
                  </option>
                </select> */}
                <input
                  className="block w-full px-4 py-2 border placeholder:text-gray-500  border-gray-300 rounded-lg"
                  type="text"
                  id="country"
                  name="country"
                  placeholder="country"
                  value={formData.country}
                  onChange={handleInputChange}
                  required
                />
                {formErrors.country && (
                  <span className="text-xs text-red-600">
                    {formErrors.country}
                  </span>
                )}
              </div>
              {/* <div>
                <input
                  className="block w-full px-4 py-2 border placeholder:text-gray-500  border-gray-300 rounded-lg"
                  type="text"
                  id="zip"
                  name="zip"
                  placeholder="Zip"
                  value={formData.zip}
                  onChange={handleInputChange}
                  required
                />
                {formErrors.zip && (
                  <span className="text-xs text-red-600">{formErrors.zip}</span>
                )}
              </div> */}
            </div>
            <div className="flex justify-end gap-x-[20px]">
              <button
                onClick={() => setEditStudentModal(false)}
                className=" md:ease-in duration-300 mt-4 bg-black hover:bg-red-600  text-sm text-white font-bold py-1 px-2  rounded"
                type="submit"
              >
                cancel
              </button>
              <button
                onClick={handleSubmit}
                className=" ease-in duration-300 mt-4 bg-red-500 hover:bg-green-600  text-sm text-white font-bold  py-2 px-3  rounded"
                type="submit"
                disabled={isLoader}
              >
                {!isLoader ? <span>Save</span> : <Loader />}
              </button>
            </div>
          </div>
        </div>
      </div>
      {isEditSuccess && (
        <SuccessFullyEdited
          isOpen={isEditSuccess}
          student={student}
          handleRefresh={handleRefresh}
        />
      )}
    </>
  );
};

export default EditStudent;
