import React from "react";
import { useState,useEffect } from "react";
import AgentLayout from "../../../components/AgentComponents/AgentLayout/AgentLayout";
import CollegeCard from "../../../components/AdminComponents/CollegeCard/CollegeCard";
import { fetchCollegesAPI } from "../../../API/Agent/AgentAPI";

const CollagesAgent = () => {
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  //   const handleCloseModal = () => {
  //     setIsModalOpen(false);
  //   };
  const agentToken = localStorage.getItem("agent-auth-token");
    useEffect(() => {
      const fetchColleges = async () => {
        try {
          const response = await fetchCollegesAPI(agentToken);
          console.log(response)
          if (response.status === 200) {
            setData(response?.data); 
          }
        } catch (error) {
          console.log(error);
        }
      };
      fetchColleges();
    }, []);
  return (
    <AgentLayout>
      <div className="relative">
        {/* <div className="flex justify-center mb-2">
          <CollegeSearch setData={setData} />
        </div> */}
        <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
          {
            data?.length !== 0 &&
              data?.map((college) => (
                <CollegeCard key={college?._id} college={college} />
              ))
            //  : (
            //   <div className="border border-1 bg-gray-200 animate-pulse p-[30px] m-[10px] min-h-[130px] flex justify-center items-center flex-col shadow-md hover:shadow-sm shadow-gray-500 rounded-md hover:bg-gray-200  transition"></div>
            // )
          }
          {/* <div
            onClick={() => setIsModalOpen(true)}
            class="relative cursor-pointer border border-1 inline-flex items-center justify-center p-[30px] m-[10px]  w-[170px] min-h-[130px] overflow-hidden font-medium text-white transition duration-300 ease-out border-gray-300 shadow-md group rounded-md "
          >
            <span class=" font-bold absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-black group-hover:translate-x-0 ease">
              Add college{" "}
              <span className="mt-[3px] animate-pulse">
                <ArrowRightAltIcon sx={{ fontSize: 30 }} />
              </span>
            </span>
            <span class="absolute flex items-center justify-center w-full h-full text-black transition-all duration-300 transform group-hover:translate-x-full ease">
              <AddIcon sx={{ fontSize: 35 }} />
            </span>
          </div> */}
          {/* <div
            onClick={() => setIsModalOpen(true)}
            className="cursor-pointer border border-1 border-gray-100 p-[30px] m-[10px]  w-[170px] min-h-[130px]  flex justify-center items-center flex-col shadow-md hover:shadow-sm shadow-gray-300 rounded-md hover:ring ring-gray-300  transition group"
          >
            <p className="font-bold text-[40px] group-hover:hidden">
              <AddIcon sx={{ fontSize: 65 }} />
            </p>
            <p className="hidden group-hover:block text-center">Add college</p>
          </div> */}
        </div>
        {/* {isModalOpen && (
          <div className="absolute top-0 z-10 w-full">
            <AddColleges
              setData={setData}
              data={data}
              handleCloseModal={handleCloseModal}
            />
          </div>
        )} */}
      </div>
    </AgentLayout>
  );
};

export default CollagesAgent;
