import React, { useEffect, useState } from "react";
import RepTableForAssign from "./RepTableForAssign";
import { RepTableRowAssign } from "./RepTableRowAssign";
import CollegeTableAssign from "./CollegeTableAssign";
import CollegeTableRowAssign from "./CollegeTableRowAssign";
import {
  fetchAllCollegesDataForAssignAPI,
  fetchAllRepDataForAssignAPI,
} from "../../../API/admin/AssignAgentToRepAPI";
import WarningIcon from "@mui/icons-material/Warning";
const AssignAgentToRepCollege = ({ agentData }) => {
  const [isRepresentative, setIsRepresentative] = useState(true);
  const [repData, setRepData] = useState([]);
  const [collegeData, setCollegeData] = useState([]);

  const handleFetchRepData = async () => {
    try {
      const response = await fetchAllRepDataForAssignAPI();
      if (response.status === 200) {
        console.log(response, "repData");
        setRepData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFetchCollegeData = async () => {
    try {
      const response = await fetchAllCollegesDataForAssignAPI();
      if (response.status === 200) {
        console.log(response, "agentData");
        setCollegeData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isRepresentative === true) {
      handleFetchRepData();
    } else {
      handleFetchCollegeData();
    }
  }, [isRepresentative]);

  const isActive =
    "py-1 px-2 shadow-inner shadow-gray-500 text-lg font-bold text-red-600 cursor-pointer w-40";
  const inActive =
    "py-1 px-2 shadow-sm shadow-gray-500 text-lg font-bold cursor-pointer w-40";
  return (
    <div className="my-4">
      <div>
        <div className="flex gap-2">
          <span className="mt-0.5 text-red-500">
            <WarningIcon sx={{ fontSize: 20 }} />
          </span>
          <h3 className="pt-2 text-sm font-bold text-red-500">
            Assign agent to representative or college
          </h3>
        </div>
        <div className="rounded shadow-sm shadow-gray-200 w-1/2">
          <div className="flex bg-gray mt-5">
            <div
              className={isRepresentative ? isActive : inActive}
              onClick={() => setIsRepresentative(true)}
            >
              <h3>Reps</h3>
            </div>
            <div
              className={!isRepresentative ? isActive : inActive}
              onClick={() => setIsRepresentative(false)}
            >
              <h3 className="">Colleges</h3>
            </div>
          </div>
          {isRepresentative ? (
            <div>
              <RepTableForAssign>
                {repData.length > 0 ? (
                  repData.map((rep, i) => (
                    <RepTableRowAssign
                      rep={rep}
                      i={i}
                      agentId={agentData._id}
                    />
                  ))
                ) : (
                  <>
                    <tr>No representatives found</tr>
                  </>
                )}
              </RepTableForAssign>
            </div>
          ) : (
            <div>
              <CollegeTableAssign>
                {collegeData.length > 0 ? (
                  collegeData.map((col, i) => (
                    <CollegeTableRowAssign
                      collegeData={col}
                      i={i}
                      agentId={agentData._id}
                    />
                  ))
                ) : (
                  <>
                    <tr>No representatives found</tr>
                  </>
                )}
              </CollegeTableAssign>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AssignAgentToRepCollege;
