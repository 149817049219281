import React from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AgentStudentProfile from "../../../components/AgentComponents/Profile/AgentStudentProfile";
import AgentLayout from "../../../components/AgentComponents/AgentLayout/AgentLayout";
import { fetchSingleStudentAPI } from "../../../API/Agent/AgentAPI";

const AgentStudentpro = () => {
  const { state } = useLocation();
  const [studentData, setStudentDta] = useState({});
  const [commissionData, setCommissionData] = useState({});
  const [refreshComponent, setRefreshComponent] = useState(false);
  const handleRefresh = () => {
    setRefreshComponent(true);
  };
  useEffect(() => {
    if (refreshComponent) {
      window.location.reload();
    }
  }, [refreshComponent]);
  console.log("3");
  useEffect(() => {
    const fetchSingleStudent = async () => {
      const response = await fetchSingleStudentAPI(state?.studentId);
      if (response.status === 200) {
        setStudentDta(response?.data?.student);
        setCommissionData(response?.data?.commission);
      } else {
        setStudentDta({});
      }
    };
    try {
      fetchSingleStudent();
    } catch (error) {
      console.log(error);
    }
  }, [state]);
  return (
    <AgentLayout>
      <AgentStudentProfile
        studentData={studentData}
        commission={commissionData}
        handleRefresh={handleRefresh}
      />
    </AgentLayout>
  );
};

export default AgentStudentpro;
