import React from "react";
import GroupIcon from "@mui/icons-material/Group";
import Groups2Icon from "@mui/icons-material/Groups2";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';  // Keep this line
// import MenuIcon from "@mui/icons-material/Menu";               // You can choose to keep or remove this line
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";


const SideList = () => {
  const adminData = useSelector((state) => state.adminData.adminData);
  return (
    <div className="fixed z-50 bg-gray-50 shadow-sm shadow-gray-300 rounded-sm right-5 top-20 w-60 pl-3 pr-3 py-2">
      <div className="flex gap-2 justify-between pl-4 pr-4">
        <span className="font-semibold text-sm mt-1 text-red-600">Menu</span>
        {/* <MenuIcon /> */}
      </div>
      <div className="border my-2 border-black"></div>
      <div className="grid grid-cols-1 gap-2">
        {adminData?.type === "super-admin" && (
          <div className="">
            <Link
              to={"/admin/admins"}
              className="gap-4 relative inline-flex items-center justify-start py-2 pl-4 pr-12 overflow-hidden font-semibold text-black transition-all duration-150 ease-in-out  hover:pl-10 hover:pr-6 bg-gray-50 group w-full"
            >
              <span class="absolute bottom-0 left-0 w-full h-[1px] transition-all duration-150 ease-in-out bg-black group-hover:h-full"></span>
              <span class="absolute right-0 pr-4 duration-200 ease-out group-hover:translate-x-12">
                <div className="">
                  <AdminPanelSettingsIcon />
                </div>
              </span>
              <span class="absolute left-0 pl-2.5 -translate-x-12 group-hover:translate-x-0 ease-out duration-200">
                <div className="text-white">
                  <KeyboardArrowRightIcon />
                </div>
              </span>
              <span class="relative w-full text-left transition-colors duration-200 ease-in-out group-hover:text-white text-sm">
                Admins
              </span>
            </Link>
          </div>
        )}
        <Link
          to={"/admin/representatives"}
          className="gap-4 relative inline-flex items-center justify-start py-2 pl-4 pr-12 overflow-hidden font-semibold text-black transition-all duration-150 ease-in-out  hover:pl-10 hover:pr-6 bg-gray-50 group w-full"
        >
          <span class="absolute bottom-0 left-0 w-full h-[1px] transition-all duration-150 ease-in-out bg-black group-hover:h-full"></span>
          <span class="absolute right-0 pr-4 duration-200 ease-out group-hover:translate-x-12">
            <div className="">
              <GroupIcon />
            </div>
          </span>
          <span class="absolute left-0 pl-2.5 -translate-x-12 group-hover:translate-x-0 ease-out duration-200">
            <div className="text-white">
              <KeyboardArrowRightIcon />
            </div>
          </span>
          <span class="relative w-full text-left transition-colors duration-200 ease-in-out group-hover:text-white text-sm">
            Representative
          </span>
        </Link>
        <Link
          to={"/admin/agents"}
          className="gap-4 relative inline-flex items-center justify-start py-2 pl-4 pr-12 overflow-hidden font-semibold text-black transition-all duration-150 ease-in-out  hover:pl-10 hover:pr-6 bg-gray-50 group w-full"
        >
          <span class="absolute bottom-0 left-0 w-full h-[1px] transition-all duration-150 ease-in-out bg-black group-hover:h-full"></span>
          <span class="absolute right-0 pr-4 duration-200 ease-out group-hover:translate-x-12">
            <div className="">
              <Groups2Icon />
            </div>
          </span>
          <span class="absolute left-0 pl-2.5 -translate-x-12 group-hover:translate-x-0 ease-out duration-200">
            <div className="text-white">
              <KeyboardArrowRightIcon />
            </div>
          </span>
          <span class="relative w-full text-left transition-colors duration-200 ease-in-out group-hover:text-white text-sm">
            Agent
          </span>
        </Link>
        <Link
          to={"/admin/agent-applications"}
          className="gap-4 relative inline-flex items-center justify-start py-2 pl-4 pr-12 overflow-hidden font-semibold text-black transition-all duration-150 ease-in-out  hover:pl-10 hover:pr-6 bg-gray-50 group w-full"
        >
          <span class="absolute bottom-0 left-0 w-full h-[1px] transition-all duration-150 ease-in-out bg-black group-hover:h-full"></span>
          <span class="absolute right-0 pr-4 duration-200 ease-out group-hover:translate-x-12">
            <div className="">
              <ReceiptLongIcon />
            </div>
          </span>
          <span class="absolute left-0 pl-2.5 -translate-x-12 group-hover:translate-x-0 ease-out duration-200">
            <div className="text-white">
              <KeyboardArrowRightIcon />
            </div>
          </span>
          <span class="relative w-full text-left transition-colors duration-200 ease-in-out group-hover:text-white text-sm">
            Agent Applications
          </span>
        </Link>
      </div>
    </div>
  );
};

export default SideList;
