import React, { useState } from "react";
import {
  TableRow,
  TableCell,
  TextField,
  IconButton,
  Button,
  Snackbar,
  Alert,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import { editAgentCommissionAPI } from "../../../API/admin/AdminAPI";

const AgentCommissionTableRow = ({ item, data, setData }) => {
  const [isEditField, setIsEditField] = useState(false);
  const [commissionInput, setCommissionInput] = useState(item.commission);
  const [open, setOpen] = useState(false);

  const handleEditClick = () => {
    setIsEditField(true);
  };

  const handleSaveClick = async () => {
    const createBody = {
      courseId: item.courseId,
      course: item.course,
      changedCommission: commissionInput,
      agentId: data._id,
    };
    const response = await editAgentCommissionAPI(createBody);
    if (response.status === 200) {
      setIsEditField(false);
      handleSnackbarOpen();
    } else {
      console.error("Error saving commission");
    }
  };

  const handleInputChange = (e) => {
    setCommissionInput(e.target.value);
  };

  const handleSnackbarOpen = () => {
    setOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return;
    setOpen(false);
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Commission successfully saved!
        </Alert>
      </Snackbar>

      <TableRow hover>
        <TableCell>{item.course}</TableCell>
        <TableCell>{item.courseFee}</TableCell>
        <TableCell>
          {isEditField ? (
            <TextField
              value={commissionInput}
              onChange={handleInputChange}
              variant="outlined"
              size="small"
              type="number"
              sx={{ width: 100 }}
            />
          ) : (
            commissionInput
          )}
        </TableCell>
        <TableCell align="center">
          {isEditField ? (
            <Tooltip title="Save">
              <IconButton color="primary" onClick={handleSaveClick}>
                <SaveIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Edit">
              <IconButton color="default" onClick={handleEditClick}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

export default AgentCommissionTableRow;
