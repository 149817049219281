import React from "react";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { applicantApi } from "../../../API/Agent/AgentAPI";
import Loader from "../../AgentComponents/Loader/Loader";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import ErrorIcon from "@mui/icons-material/Error";
import VerifiedIcon from "@mui/icons-material/Verified";

const ApplicantInformation = ({
  applicationSteps,
  setApplicationSteps,
  formfilledData,
  setformfilledData,
 
}) => {
  const agentTokens = localStorage.getItem("agentToken");
  const [isOpen, setIsOpen] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  const [formData, setFormData] = useState({
    agentTokens: agentTokens,
    nameofApplicantRepresentative: "",
    designation: "",
    typeofapplicantIndividualCompany: "",
    nameofBusiness: "",
    countryofBusinessIncorporation: "",
    yearofOperation: "",
    contactnumber: "",
    emailaddress: "",
    address: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (!submitted) {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = {};

    if (!formData.nameofApplicantRepresentative) {
      errors.nameofApplicantRepresentative = "This Field  is required";
    }
    if (!formData.designation) {
      errors.designation = "This Field  is required";
    }
    if (!formData.typeofapplicantIndividualCompany) {
      errors.typeofapplicantIndividualCompany = "This Field  is required";
    }
    if (!formData.nameofBusiness) {
      errors.nameofBusiness = "This Field  is required";
    }
    if (!formData.countryofBusinessIncorporation) {
      errors.countryofBusinessIncorporation = "This Field  is required";
    }
    if (!formData.yearofOperation) {
      errors.yearofOperation = "This Field  is required";
    }
    if (!formData.contactnumber) {
      errors.contactnumber = "This Field  is required";
    }
    if (!formData.emailaddress) {
      errors.emailaddress = "This Field  is required";
    }
    if (!formData.address) {
      errors.address = "This Field  is required";
    }
    if (Object.keys(errors).length === 0) {
      try {
        setIsLoader(true);

        // console.log(formData, "business form data");
        const response = await applicantApi(formData);
        console.log(response);
        if (response.status === 201) {
          setIsLoader(false);

          setSubmitted(true);

          // eslint-disable-next-line no-lone-blocks

          setIsLoader(false);
          setApplicationSteps((prevData) => ({
            ...prevData,
            isApplicantInformation: true,
          }));

          setformfilledData({
            agentTokens: agentTokens,
            name: response.data.data.name,
            designation: response.data.data.designation,
            typeOfApplicant: response.data.data.typeOfApplicant,
            nameOfBusiness: response.data.data.nameOfBusiness,
            address: response.data.data.address,
            email: response.data.data.email,
            contactNumber: response.data.data.contactNumber,
            yearsOfOperation: response.data.data.yearsOfOperation,
            countryOfBusiness: response.data.data.countryOfBusiness,
          });
        } else {
          alert("Failed to submit form data.");
        }
      } catch (error) {
        console.error("API request failed:", error);
      } finally {
        setIsLoader(false);
      }
    } else {
      setFormErrors(errors);
      setIsLoader(false);
    }
    console.log(formfilledData);
  };
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div>
      <div>
        {applicationSteps.isApplicantInformation ? (
          <div>
            <div className={`accordion ${isOpen ? "open" : "open"} mb-6`}>
              <div
                className="accordion-header w-[374px] md:w-[814px] rounded-sm shadow-lg "
                onClick={toggleAccordion}
              >
                <div className="flex justify-center items-center gap-x-4">
                  {formfilledData.isRejected ? (
                    <div className="flex  items-center gap-x-2 bg-red-500 px-1 py-1 rounded">
                      <p className="text-white font-bold text-sm">STEP 1</p>
                    </div>
                  ) : (
                    <div className="flex  items-center gap-x-2 bg-green-500 px-1 py-1 rounded">
                      <p className="text-white font-bold text-sm">STEP 1</p>
                    </div>
                  )}
                  <div>
                    <p className="text-md font-semibold ">
                      APPLICANT INFORMATION
                    </p>
                  </div>
                </div>
                <div className="flex justify-end items-end">
                  <i className="fa-solid fa-angle-up" />
                  <span className="icon">
                    {isOpen ? <RemoveIcon /> : <AddIcon />}
                  </span>
                </div>
              </div>

              {isOpen && (
                <form>
                  <div className="accordion-content text-black">
                    <div className="panel w-full sm:w-full md:w-[814px]  flex justify-center sm:flex sm:justify-content  md:flex-row  md:justify-center md:item-center shadow-lg">
                      <div className="py-10">
                        <div className=" flex-col gap-x-4 md:flex-row flex justify-between items-center">
                          <div className="mb-6">
                            <label
                              htmlFor="name"
                              className="block text-black  text-sm mb-2"
                            >
                              Name of Applicant/Representative
                            </label>
                            <input
                              type="text"
                              id="nameofApplicantRepresentative"
                              name="nameofApplicantRepresentative"
                              value={formfilledData?.name || ""}
                              onChange={handleInputChange}
                              placeholder="Name of Applicant/Representative"
                              disabled={submitted && formfilledData.isRejected}
                              className="border border-gray-300 rounded-md text-[13px] w-[330px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "
                            />
                            {formErrors.nameofApplicantRepresentative && (
                              <p className="text-red-500 text-sm">
                                {formErrors.nameofApplicantRepresentative}
                              </p>
                            )}
                          </div>
                          <div className="mb-6">
                            <label
                              htmlFor="email"
                              className="block text-gray-700 text-sm mb-2"
                            >
                              Designation
                            </label>
                            <input
                              type="text"
                              id="designation"
                              name="designation"
                              value={formfilledData?.designation || ""}
                              onChange={handleInputChange}
                              placeholder="Designation"
                              disabled={submitted && !formfilledData.isRejected}
                              className="border border-gray-300 rounded-md text-[13px] w-[330px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                            {formErrors.designation && (
                              <p className="text-red-500 text-sm">
                                {formErrors.designation}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className=" flex-col md:flex-row flex  justify-between  ">
                          <div className="mb-4">
                            <label
                              htmlFor="email"
                              className="block text-gray-700 text-sm mb-2"
                            >
                              Type of applicant (Individual/Company)
                            </label>
                            <input
                              type="text"
                              id="typeofapplicantIndividualCompany"
                              name="typeofapplicantIndividualCompany"
                              value={formfilledData?.typeOfApplicant || ""}
                              onChange={handleInputChange}
                              placeholder="Type of applicant (Individual/Company)"
                              disabled={submitted && !formfilledData.isRejected}
                              className="border border-gray-300 rounded-md text-[13px]  w-[330px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                            {formErrors.typeofapplicantIndividualCompany && (
                              <p className="text-red-500 text-sm">
                                {formErrors.typeofapplicantIndividualCompany}
                              </p>
                            )}
                          </div>
                          <div className="mb-4">
                            <label
                              htmlFor="email"
                              className="block text-gray-700 text-sm mb-2"
                            >
                              Name of Business
                            </label>
                            <input
                              type="text"
                              id="nameofBusiness"
                              name="nameofBusiness"
                              value={formfilledData?.nameOfBusiness || ""}
                              onChange={handleInputChange}
                              placeholder="Name of Business"
                              disabled={submitted && !formfilledData.isRejected}
                              className="border border-gray-300 rounded-md text-[13px]  w-[330px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                            {formErrors.nameofBusiness && (
                              <p className="text-red-500 text-sm">
                                {formErrors.nameofBusiness}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className=" flex-col md:flex-row   flex justify-between  ">
                          <div className="mb-4">
                            <label
                              htmlFor="email"
                              className="block text-gray-700 text-sm mb-2"
                            >
                              Country of Business Incorporation
                            </label>
                            <input
                              type="text"
                              id="countryofBusinessIncorporation"
                              name="countryofBusinessIncorporation"
                              value={formfilledData?.countryOfBusiness || ""}
                              onChange={handleInputChange}
                              placeholder="Country of Business Incorporation"
                              disabled={submitted && !formfilledData.isRejected}
                              className="border border-gray-300 rounded-md text-[13px]  w-[330px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                            {formErrors.countryofBusinessIncorporation && (
                              <p className="text-red-500 text-sm">
                                {formErrors.countryofBusinessIncorporation}
                              </p>
                            )}
                          </div>
                          <div className="mb-4">
                            <label
                              htmlFor="email"
                              className="block text-gray-700 text-sm mb-2"
                            >
                              Year(s) of Operation
                            </label>
                            <input
                              type="text"
                              id="yearofOperation"
                              name="yearofOperation"
                              value={formfilledData?.yearsOfOperation || ""}
                              onChange={handleInputChange}
                              placeholder="year(s) of Operation"
                              disabled={submitted && !formfilledData.isRejected}
                              className="border border-gray-300 rounded-md text-[13px]  w-[330px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                            {formErrors.yearofOperation && (
                              <p className="text-red-500 text-sm">
                                {formErrors.yearofOperation}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className=" flex-col md:flex-row   flex justify-between  ">
                          <div className="mb-4">
                            <label
                              htmlFor="email"
                              className="block text-gray-700 text-sm mb-2"
                            >
                              Contact number
                            </label>
                            <input
                              type="tel"
                              id="contactnumber"
                              name="contactnumber"
                              value={formfilledData?.contactNumber || ""}
                              onChange={handleInputChange}
                              placeholder="Contact number"
                              disabled={submitted && !formfilledData.isRejected}
                              className="border border-gray-300 rounded-md text-[13px]   w-[330px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                            {formErrors.contactnumber && (
                              <p className="text-red-500 text-sm">
                                {formErrors.contactnumber}
                              </p>
                            )}
                          </div>

                          <div className="mb-4">
                            <label
                              htmlFor="email"
                              className="block text-gray-700 text-sm mb-2"
                            >
                              Email address
                            </label>
                            <input
                              type="email"
                              id="emailaddress"
                              name="emailaddress"
                              value={formfilledData?.email || ""}
                              onChange={handleInputChange}
                              placeholder="Email address"
                              disabled={submitted && !formfilledData.isRejected}
                              className="border border-gray-300 rounded-md text-[13px]   w-[330px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                            {formErrors.emailaddress && (
                              <p className="text-red-500 text-sm">
                                {formErrors.emailaddress}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="flex-col md:flex-row flex justify-between">
                          <div className="mb-4">
                            <label
                              htmlFor="email"
                              className="block text-gray-700 text-sm mb-2"
                            >
                              Address
                            </label>
                            <input
                              type="text"
                              id="address"
                              name="address"
                              value={formfilledData?.address || ""}
                              onChange={handleInputChange}
                              placeholder="Address"
                              disabled={submitted && !formfilledData.isRejected}
                              className="border border-gray-300 rounded-md text-[13px]   w-[330px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                            {formErrors.address && (
                              <p className="text-red-500 text-sm">
                                {formErrors.address}
                              </p>
                            )}
                          </div>
                        </div>
                        {formfilledData.isRejected ? (
                          <div className="flex flex-end justify-end pb-8">
                            <button
                              type="submit"
                              className="text-sm sm:text-md border sm:w-full md:w-[250px] text-red-600 border-red-500 font-bold py-2 px-10 rounded focus:outline-none focus:shadow-outline shadow-lg "
                              onClick={handleSubmit}
                              disabled={isLoader}
                            >
                              {isLoader ? <Loader /> : "Submit Application"}
                            </button>
                          </div>
                        ) : null}

                        <p>
                          {formfilledData.isRejected ? (
                            <div className="bg-red-100 rounded-md">
                              <div>
                                <p className="text-lg font-semibold text-red-600 px-8 pt-1">
                                  Error
                                </p>
                              </div>
                              <div className=" flex items-center pb-2  px-6">
                                <div className="text-red-600">
                                  <ErrorIcon />
                                </div>
                                <div>
                                  <p className="text-[13px] text-red-500 font-semibold px-2">
                                    Your Applicant Information Rejected By Admin
                                  </p>
                                  <p className="text-[13px] text-red-500 font-semibold px-2">
                                    Admin Message:
                                  </p>
                                </div>
                              </div>
                              <div className="h-1 rounded-lg bg-red-400"></div>
                            </div>
                          ) : null}
                        </p>

                        <p>
                          {formfilledData.isApproved ? (
                            <div className="bg-green-100 rounded-md">
                              <div>
                                <p className="text-lg font-semibold text-green-600 px-8 pt-1">
                                  Success
                                </p>
                              </div>
                              <div className=" flex items-center pb-2  px-6">
                                <div className="text-green-600">
                                  <VerifiedIcon />
                                </div>
                                <div>
                                  <p className="text-[13px] text-green-500 font-semibold px-2">
                                    Your Applicant Information Approved By Admin
                                  </p>
                                </div>
                              </div>
                              <div className="h-1 rounded-lg bg-green-400"></div>
                            </div>
                          ) : null}
                        </p>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        ) : (
          <div>
            <div className={`accordion ${isOpen ? "open" : "open"} mb-6`}>
              <div
                className="accordion-header w-[374px] md:w-[814px] rounded-sm shadow-lg "
                onClick={toggleAccordion}
              >
                <div className="flex justify-center items-center gap-x-4">
                  <div className="flex  items-center gap-x-2 bg-red-500 px-1 py-1 rounded ">
                    <p className="text-white font-bold text-sm">STEP 1</p>
                  </div>
                  <div>
                    <p className="text-md font-semibold ">
                      APPLICANT INFORMATION
                    </p>
                  </div>
                </div>
                <div className="flex justify-end items-end">
                  <i className="fa-solid fa-angle-up" />
                  <span className="icon">
                    {isOpen ? <RemoveIcon /> : <AddIcon />}
                  </span>
                </div>
              </div>

              {isOpen && (
                <form>
                  <div className="accordion-content text-black">
                    <div className="panel w-full sm:w-full md:w-[814px]  flex justify-center sm:flex sm:justify-content  md:flex-row  md:justify-center md:item-center shadow-lg">
                      <div className="py-10">
                        <div className=" flex-col gap-x-4 md:flex-row flex justify-between items-center">
                          <div className="mb-6">
                            <label
                              htmlFor="name"
                              className="block text-black  text-sm mb-2"
                            >
                              Name of Applicant/Representative
                            </label>
                            <input
                              type="text"
                              id="nameofApplicantRepresentative"
                              name="nameofApplicantRepresentative"
                              value={formData.nameofApplicantRepresentative}
                              onChange={handleInputChange}
                              placeholder="Name of Applicant/Representative"
                              className="border border-gray-300 rounded-md text-[13px] w-[330px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                            {formErrors.nameofApplicantRepresentative && (
                              <p className="text-red-500 text-sm">
                                {formErrors.nameofApplicantRepresentative}
                              </p>
                            )}
                          </div>
                          <div className="mb-6">
                            <label
                              htmlFor="email"
                              className="block text-gray-700 text-sm mb-2"
                            >
                              Designation
                            </label>
                            <input
                              type="text"
                              id="designation"
                              name="designation"
                              value={formData.designation}
                              onChange={handleInputChange}
                              placeholder="Designation"
                              className="border border-gray-300 rounded-md  text-[13px] w-[330px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                            {formErrors.designation && (
                              <p className="text-red-500 text-sm">
                                {formErrors.designation}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className=" flex-col md:flex-row flex  justify-between  ">
                          <div className="mb-6">
                            <label
                              htmlFor="email"
                              className="block text-gray-700 text-sm mb-2"
                            >
                              Type of applicant (Individual/Company)
                            </label>
                            <input
                              type="text"
                              id="typeofapplicantIndividualCompany"
                              name="typeofapplicantIndividualCompany"
                              value={formData.typeofapplicantIndividualCompany}
                              onChange={handleInputChange}
                              placeholder="Type of applicant (Individual/Company)"
                              className="border border-gray-300 rounded-md  text-[13px] w-[330px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                            {formErrors.typeofapplicantIndividualCompany && (
                              <p className="text-red-500 text-sm">
                                {formErrors.typeofapplicantIndividualCompany}
                              </p>
                            )}
                          </div>
                          <div className="mb-6">
                            <label
                              htmlFor="email"
                              className="block text-gray-700 text-sm mb-2"
                            >
                              Name of Business
                            </label>
                            <input
                              type="text"
                              id="nameofBusiness"
                              name="nameofBusiness"
                              value={formData.nameofBusiness}
                              onChange={handleInputChange}
                              placeholder="Name of Business"
                              className="border border-gray-300 rounded-md  text-[13px] w-[330px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                            {formErrors.nameofBusiness && (
                              <p className="text-red-500 text-sm">
                                {formErrors.nameofBusiness}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className=" flex-col md:flex-row   flex justify-between  ">
                          <div className="mb-6">
                            <label
                              htmlFor="email"
                              className="block text-gray-700 text-sm mb-2"
                            >
                              Country of Business Incorporation
                            </label>
                            <input
                              type="text"
                              id="countryofBusinessIncorporation"
                              name="countryofBusinessIncorporation"
                              value={formData.countryofBusinessIncorporation}
                              onChange={handleInputChange}
                              placeholder="Country of Business Incorporation"
                              className="border border-gray-300 rounded-md  text-[13px] w-[330px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                            {formErrors.countryofBusinessIncorporation && (
                              <p className="text-red-500 text-sm">
                                {formErrors.countryofBusinessIncorporation}
                              </p>
                            )}
                          </div>
                          <div className="mb-6">
                            <label
                              htmlFor="email"
                              className="block text-gray-700 text-sm mb-2"
                            >
                              Year(s) of Operation
                            </label>
                            <input
                              type="text"
                              id="yearofOperation"
                              name="yearofOperation"
                              value={formData.yearofOperation}
                              onChange={handleInputChange}
                              placeholder="year(s) of Operation"
                              className="border border-gray-300 rounded-md  text-[13px]  w-[330px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                            {formErrors.yearofOperation && (
                              <p className="text-red-500 text-sm">
                                {formErrors.yearofOperation}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className=" flex-col md:flex-row   flex justify-between  ">
                          <div className="mb-4">
                            <label
                              htmlFor="email"
                              className="block text-gray-700 text-sm mb-2"
                            >
                              Contact number
                            </label>
                            <input
                              type="tel"
                              id="contactnumber"
                              name="contactnumber"
                              value={formData.contactnumber}
                              onChange={handleInputChange}
                              placeholder="Contact number"
                              className="border border-gray-300 rounded-md  text-[13px]  w-[330px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                            {formErrors.contactnumber && (
                              <p className="text-red-500 text-sm">
                                {formErrors.contactnumber}
                              </p>
                            )}
                          </div>

                          <div className="mb-4">
                            <label
                              htmlFor="email"
                              className="block text-gray-700 text-sm mb-2"
                            >
                              Email address
                            </label>
                            <input
                              type="email"
                              id="emailaddress"
                              name="emailaddress"
                              value={formData.emailaddress}
                              onChange={handleInputChange}
                              placeholder="Email address"
                              className="border border-gray-300 rounded-md  text-[13px]  w-[330px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                            {formErrors.emailaddress && (
                              <p className="text-red-500 text-sm">
                                {formErrors.emailaddress}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className=" flex-col md:flex-row   flex justify-between  ">
                          <div className="mb-4">
                            <label
                              htmlFor="email"
                              className="block text-gray-700 text-sm mb-2"
                            >
                              Address
                            </label>
                            <input
                              type="text"
                              id="address"
                              name="address"
                              value={formData.address}
                              onChange={handleInputChange}
                              placeholder="Address"
                              className="border border-gray-300 rounded-md  text-[13px] w-[330px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                            {formErrors.address && (
                              <p className="text-red-500 text-sm">
                                {formErrors.address}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="mb-4 flex justify-center sm:justify-center md:justify-end md:mr-10">
                          <div>
                            {submitted ? (
                              // Display a message or any other component after submission
                              <button
                                type="submit"
                                className="text-sm sm:text-md border sm:w-full md:w-[250px] text-red-600 border-red-500 font-bold py-2 px-10 rounded focus:outline-none focus:shadow-outline shadow-lg hidden"
                                onClick={handleSubmit}
                                disabled={isLoader}
                              >
                                {isLoader ? <Loader /> : "Submit Application"}
                              </button>
                            ) : (
                              // Render the submit button only if the form hasn't been submitted
                              <button
                                type="submit"
                                className="text-sm sm:text-md border sm:w-full md:w-[250px] text-red-600 border-red-500 font-bold py-2 px-10 rounded focus:outline-none focus:shadow-outline shadow-lg"
                                onClick={handleSubmit}
                                disabled={isLoader}
                              >
                                {isLoader ? <Loader /> : "Submit Application"}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ApplicantInformation;
