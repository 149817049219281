import React from "react";

const AdminTable = ({ handleAddUserClick, children }) => {
  return (
    <table className="w-full ">
      <thead>
        <tr>
          <th className="text-gray-500 text-[16px] py-[10px] border border-1 border-solid text-center p-[8px]">
            Full Name
          </th>
          <th className="text-gray-500 text-[16px] py-[10px] border border-1 border-solid text-center p-[8px]">
            Email Id
          </th>
          <th className="text-gray-500 text-[16px] py-[10px] border border-1 border-solid text-center p-[8px]">
            Type
          </th>
          <th className="text-gray-500 text-[16px] py-[10px] border border-1 border-solid text-center p-[8px]">
            Country
          </th>
          <th className="border border-1 border-solid text-center p-[8px]">
            <span
              onClick={handleAddUserClick}
              className="border bg-gray-600  px-3 rounded-lg text-xs py-1 text-left text-white hover:bg-gray-200 hover:text-gray-600 font-bold cursor-pointer transition "
            >
              Add+
            </span>
          </th>
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
};

export default AdminTable;
