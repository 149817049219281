import { createSlice } from '@reduxjs/toolkit';
const agentAuth=createSlice({
    name:'agent',
    initialState:{
        agentToken:'',
    },
    reducers:{
        addAgentToken(state,actions){
            const agent=actions.payloadload;
            state.agentToken=agent;
        },
        agentLogout(state){
            state.agentToken='';
        },
    },
})
export const {addAgentToken,agentLogout}=agentAuth.actions;
export default agentAuth;