import React from "react";

const AgentCommission = ({ children }) => {
  return (
    <>
      <div className="border-2 border-black rounded-2xl w-full overflow-x-scroll my-4">
        <div>
          <table className="w-[100%]">
            <thead>
              <tr className="border-b border-b-black">
                <th className="text-gray-500 text-[16px] py-[30px] text-center">
                  Course Name
                </th>
                <th className="text-gray-500 text-[16px] py-[30px] text-center">
                  Student Name
                </th>
                <th className="text-gray-500 text-[16px] py-[30px] text-center">
                  Total Fee
                </th>
                <th className="text-gray-500 text-[16px] py-[30px] text-center">
                  Balance
                </th>
                <th className="text-gray-500 text-[16px] py-[30px] text-center ">
                  Commission
                </th>
                <th className="text-gray-500 text-[16px] py-[30px] text-center">
                  Save
                </th>
                
              </tr>
            </thead>
            <tbody>{children}</tbody>
          </table>
        </div>
        {/* <div className="flex justify-end py-4 px-14">
          <button
            className=" md:ease-in duration-300 mt-4 bg-black hover:bg-red-600  text-sm text-white font-bold py-2 px-5  rounded"
            type="submit"
          >
            cancel
          </button>
        </div> */}
      </div>
    </>
  );
};

export default AgentCommission;
