import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import AgentLayout from "../../../components/AgentComponents/AgentLayout/AgentLayout";
import AgentCollageDetails from "../../../components/AgentComponents/AgentCollegeProfile/AgentCollageDetails";
import AgentStudentTable from "../../../components/AgentComponents/AgentCollegeProfile/AgentStudentTable";
import AgentCourseTable from "../../../components/AgentComponents/AgentCollegeProfile/AgentCourseTable";
import { fetchASingleCollageAPI } from "../../../API/Agent/AgentAPI";

const AgentCollegeProfile = () => {
  const [isStudents, setIsStudents] = useState(true);
  const [collegesData, setCollegeData] = useState({});
  const [students, setStudents] = useState([]);
  const [isAddStudent, setIsAddStudent] = useState(false);
  const [isAddCourse, setIsAddCourse] = useState(false);
  const location = useLocation();
  const collegeId = location.state?.collegeId;

  useEffect(() => {
    const fetchSingleCollege = async () => {
      try {
        const response = await fetchASingleCollageAPI(collegeId);
        console.log(response)
        if (response?.status === 200) {
          setCollegeData(response?.data);
          setStudents(response?.data?.students);
        } else {
          setCollegeData({});
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchSingleCollege();
  }, [collegeId]);

  const isActive =
    "py-2 px-3 shadow-inner shadow-gray-500 text-2xl font-bold text-red-600 cursor-pointer";
  const inActive =
    "py-2 px-3 shadow-sm shadow-gray-500 text-2xl font-bold cursor-pointer";
  return (
    <AgentLayout>
      {Object.keys(collegesData).length !== 0 ? (
        <div>
         
          <AgentCollageDetails 
           collegesData={collegesData}
           isAddStudent={setIsAddStudent}
           isAddCourse={setIsAddCourse}
          
          />
          <div className="flex bg-gray mt-5">
            <div
              className={isStudents ? isActive : inActive}
              onClick={() => setIsStudents(true)}
            >
              <h3>Students</h3>
            </div>
            <div
              className={!isStudents ? isActive : inActive}
              onClick={() => setIsStudents(false)}
            >
              <h3 className="">Courses</h3>
            </div>
          </div>
          {isStudents ? (
            <div>
              <AgentStudentTable
                collegeData={{ _id: collegesData._id, name: collegesData.name }}
                students={students}
                setStudents={setStudents}
                isAddStudent={isAddStudent}
                setIsAddStudent={setIsAddStudent}
              /> 
            </div>
          ) : (
            <div>
              <AgentCourseTable
                collegeData={{ _id: collegesData._id, name: collegesData.name }}
                setIsAddCourse={setIsAddCourse}
                isAddCourse={isAddCourse}
              />
            </div>
          )}
        </div>
      ) : (
        <p>no college</p>
      )}
    </AgentLayout>
  );
};

export default AgentCollegeProfile;
