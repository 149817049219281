import React from "react";
import { Route, Routes } from "react-router-dom";
import Registration from "../../components/AgentComponents/Registration/Registration";
import AgentMainApplication from "../../components/AgentComponents/AgentMainApplication/AgentMainApplication";
import Dashboard from "../../pages/AgentPages/Dashboard";
import Login from "../../components/AgentComponents/Login/Login";
import CollagesAgent from "../../pages/AgentPages/CollagesAgent.jsx/CollagesAgent";
import AgentCollegeProfile from "../../pages/AgentPages/AgentCollegeProfile/AgentCollegeProfile";
import AgentReports from "../../pages/AgentPages/AgentReports/AgentReports";
import AgentStudentpro from "../../pages/AgentPages/Profile/AgentStudentpro";

import AgentProtectedRoute from "../../components/AgentComponents/AgentProtectedRoute/AgentProtectedRoute";
import AgentHome from "../../components/AgentComponents/AgentHome/AgentHome";
import InnerProtectedRoute from "../../components/AgentComponents/InnerProtectedRoute/InnerProtectedRoute";
import AgentSinglepro from "../../pages/AgentPages/Profile/AgentSinglepro";
import Page404 from "../../components/AdminComponents/404/404-page";

const Agent = () => {
  return (
    <div>
      <Routes>
        <Route
          path="/registration"
          element={
            <>
              <AgentProtectedRoute />
              <Registration />
              <AgentProtectedRoute />
            </>
          }
          exact
        />
        <Route
          path="/agentmainapplication"
          element={
            <AgentProtectedRoute>
              <AgentMainApplication />
            </AgentProtectedRoute>
          }
          exact
        />
        <Route path="/" element={<AgentHome />} exact />
        <Route path="/login" element={<Login />} exact />
        <Route
          path="/dashboard"
          element={
            <InnerProtectedRoute>
              <Dashboard />
            </InnerProtectedRoute>
          }
          exact
        />
        <Route path="/colleges" element={<CollagesAgent />} exact />
        <Route
          path="/colleges/:college_name"
          element={<AgentCollegeProfile />}
          exact
        />
        {/* <Route path="/reports" element={<AgentReports />} exact /> */}
        <Route
          path="/student/:student_name"
          element={<AgentStudentpro />}
          exact
        />
        <Route path="/profile" element={<AgentSinglepro />} exact />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </div>
  );
};

export default Agent;
