import React from "react";
import { Card, CardContent, IconButton, Typography, Divider, Grid, TextField, Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Alert, Snackbar } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { deleteRepAPI } from "../../../API/admin/AdminAPI";
import { useNavigate } from "react-router-dom";
import RepCommissionTable from "../CommissionPre/RepCommissionTable";

const RepProfileCompo = ({ repData, setIsEditRep }) => {
  const navigate = useNavigate();

  const handleDeleteRep = async () => {
    const confirmed = window.confirm("Are you sure you want to delete this Representative?");
    if (confirmed) {
      const response = await deleteRepAPI(repData?._id);
      if (response.status) {
        alert("Representative deleted successfully");
        navigate("/admin/representatives");
      } else {
        alert("Something went wrong. Try again.");
      }
    }
  };

  const commission = repData?.commission || ""; // Safe access to commission

  return (
    <Card sx={{ maxWidth: "100%", margin: 2, boxShadow: 3 }}>
      <CardContent>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h5" component="h2" fontWeight="bold">
              Representative Profile
            </Typography>
          </Grid>
          <Grid item>
            <IconButton sx={{ marginRight: 2 }} onClick={() => setIsEditRep(true)} color="primary">
              <BorderColorRoundedIcon sx={{ fontSize: 28 }} />
            </IconButton>
            <IconButton onClick={handleDeleteRep} color="error">
              <DeleteRoundedIcon sx={{ fontSize: 28 }} />
            </IconButton>
          </Grid>
        </Grid>

        <Divider sx={{ my: 2 }} />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography variant="body1" color="textSecondary" display="flex" alignItems="center">
              <HomeIcon sx={{ marginRight: 1 }} />
              {repData?.address?.state}, {repData?.address?.country}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="body1" color="textSecondary" display="flex" alignItems="center">
              <EmailIcon sx={{ marginRight: 1 }} />
              {repData?.email}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="body1" color="textSecondary" display="flex" alignItems="center">
              <LocalPhoneIcon sx={{ marginRight: 1 }} />
              {repData?.phone}
            </Typography>
          </Grid>
        </Grid>

        <Divider sx={{ my: 2 }} />

        {/* Commission Section */}
       
       

        <RepCommissionTable personData={repData} />
      </CardContent>
    </Card>
  );
};

export default RepProfileCompo;
