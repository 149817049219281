import React from "react";
import Layout from "../../../components/AdminComponents/Layout/Layout";
import CourseDetailsCompo from "../../../components/AdminComponents/CourseComponent/CourseDetailsCompo";

const CourseDetails = () => {
  return (
    <Layout>
      <CourseDetailsCompo />
    </Layout>
  );
};

export default CourseDetails;
