import React from "react";
import { Link } from "react-router-dom";
import { assignAgentToRepOrCollegeAPI } from "../../../API/admin/AssignAgentToRepAPI";

export const RepTableRowAssign = ({ rep, i, agentId }) => {
  const handleAssignToRep = async () => {
    try {
      // Display a confirmation dialog
      const userConfirmed = window.confirm(
        "Are you sure you want to assign to this representative?"
      );

      // Proceed only if the user confirmed
      if (userConfirmed) {
        const reference = "representative";
        const formData = {
          repId: rep._id,
          agentId,
        };

        const response = await assignAgentToRepOrCollegeAPI(
          formData,
          reference
        );

        if (response.status === 200) {
          window.location.reload();
        } else {
          alert("soothing failed");
        }
      } else {
        // Handle the case where the user cancels the assignment
        console.log("Assignment canceled by user");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <tr key={i} className="text-gray-700">
      <td className="py-3 px-4">
        <Link
          to={`/admin/representatives/${rep.name}`}
          state={{ repId: rep._id }}
          className="hover:underline"
        >
          {rep.name}
        </Link>
        <ol>
          {rep.colleges.length > 0 ? (
            rep.colleges?.map((col) => <li>{col}</li>)
          ) : (
            <li>No Colleges</li>
          )}
        </ol>
      </td>
      <td className="py-3 px-4">
        <button
          className="bg-blue-300 text-white px-4 py-1 rounded hover:bg-blue-600  text-sm font-semibold"
          onClick={() => handleAssignToRep()}
        >
          Assign
        </button>
      </td>
    </tr>
  );
};
