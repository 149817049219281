import React from "react";
import DoneIcon from "@mui/icons-material/Done";
import { Link } from "react-router-dom";

const LandingPage = () => {
  return (
    <>
      <div className="w-full h-[100vh]">
        <div className="w-[90%] mx-auto">
          <div className="flex flex-col justify-center h-[100vh]">
            <div className="pb-6 flex items-center">
              <div className="w-[60px] h-auto">
                <img
                  className="w-[100%] h-[100%] object-cover"
                  src="/assets/img/logo-01.png"
                  alt=""
                />
              </div>
              <div className=" sm:text-lg md:text-2xl lg:text-3xl  text-2xl text-gray-700 font-semibold drop-shadow-md">
                Admission Management System
              </div>
            </div>
            <div className="flex ">
              <div className=" sm:flex-col md:flex md:flex-row md:justify-between md:items-center md:gap-x-10">
                <div className="shadow-md px-10 py-6 rounded-md">
                  <div className=" sm:text-md md:text-xl lg:text-xl  text-2xl text-red-600 font-semibold drop-shadow-md pb-6">
                    <h5>Admin</h5>
                  </div>
                  <div className="flex flex-col gap-y-2">
                    <div className="flex gap-x-4 items-center ">
                      <div className="bg-red-600 w-1 h-1 px-3 py-3 rounded-full flex items-center justify-center text-white text-[12px]">
                        <DoneIcon sx={{ fontSize: 16 }} />
                      </div>
                      <div className="text-gray-700 font-semibold  text-[14px]">
                        <p>
                          Simplify student enrollment with an intuitive platform
                          designed to handle applications, document submissions,
                          and admission tracking all in one place.
                        </p>
                      </div>
                    </div>
                    <div className="flex gap-x-4 items-center">
                      <div className="bg-red-600 w-1 h-1 px-3 py-3  rounded-full flex items-center justify-center text-white text-[12px]">
                        <DoneIcon sx={{ fontSize: 16 }} />
                      </div>
                      <div className="text-gray-700 font-semibold  text-[14px]">
                        <p>
                        Provide a dedicated portal for agents to manage student applications, monitor status, and facilitate communication between institutions and applicants.
                        </p>
                      </div>
                    </div>
                    <div className="flex gap-x-4 items-center">
                      <div className="bg-red-600 w-1 h-1 px-3 py-3  rounded-full flex items-center justify-center text-white text-[12px]">
                        <DoneIcon sx={{ fontSize: 16 }} />
                      </div>
                      <div className="text-gray-700  font-semibold text-[14px]">
                        <p>
                        Easily upload, store, and organize student documents with a secure, centralized system that makes document retrieval simple and efficient.  
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="w-[200px] pt-6 sm:pt-6  md:pt-10 pb-8 sm:pb-0 md:pb-0">
                    <Link to={"/admin/dashboard"}>
                      <button className="sm:text-md  px-4 rounded-full border border-1 border-red-600  text-red-600 font-bold py-2  focus:outline-none focus:shadow-outline shadow-lg hover:bg-red-500 hover:text-white transition duration-500 text-[13px]">
                        Go To Admin Dashboard
                      </button>
                    </Link>
                  </div>
                </div>
                <div>
                  <div className="shadow-md px-10 py-6 rounded-md">
                    <div className=" sm:text-md md:text-xl lg:text-xl  text-2xl text-orange-600 font-semibold drop-shadow-md pb-6">
                      <h4>Agent</h4>
                    </div>
                    <div className="flex flex-col gap-y-2">
                      <div className="flex gap-x-4 items-center ">
                        <div className="bg-orange-600 w-1 h-1 px-3 py-3 rounded-full flex items-center justify-center text-white text-[12px]">
                          <DoneIcon sx={{ fontSize: 16 }} />
                        </div>
                        <div className="text-gray-700 font-semibold  text-[14px]">
                          <p>
                          Simplify student enrollment with an intuitive platform designed to handle applications, document submissions, and admission tracking all in one place.
                          </p>
                        </div>
                      </div>
                      <div className="flex gap-x-4 items-center ">
                        <div className="bg-orange-600 w-1 h-1 px-3 py-3  rounded-full flex items-center justify-center text-white text-[12px]">
                          <DoneIcon sx={{ fontSize: 16 }} />
                        </div>
                        <div className="text-gray-600 font-semibold  text-[14px]">
                          <p>
                          Provide a dedicated portal for agents to manage student applications, monitor status, and facilitate communication between institutions and applicants.
                          </p>
                        </div>
                      </div>
                      <div className="flex gap-x-4 items-center">
                        <div className="bg-orange-600 w-1 h-1 px-3 py-3 rounded-full flex items-center justify-center text-white text-[12px]">
                          <DoneIcon sx={{ fontSize: 16 }} />
                        </div>
                        <div className="text-gray-700 font-semibold  text-[14px]">
                          <p>
                          Easily upload, store, and organize student documents with a secure, centralized system that makes document retrieval simple and efficient.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="w-[200px] pt-6 sm:pt-6  md:pt-10">
                      <Link to={"/agent/dashboard"}>
                        <button className="text-[13px] px-4 rounded-full border border-1 border-orange-600  text-orange-600 font-bold py-2  focus:outline-none focus:shadow-outline shadow-lg hover:bg-orange-500 hover:text-white transition duration-500 ">
                          Go To Agent Dashboard
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
