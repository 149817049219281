import { API } from "../axios";

export const dashTopRowAPI = async () => {
  try {
    const { data, status } = await API.get("/admin/dashboard/top-row-data");
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

export const dashCollegesDataAPI = async () => {
  try {
    const { data, status } = await API.get("/admin/dashboard/colleges");
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

export const dashAgentsDataAPI = async () => {
  try {
    const { data, status } = await API.get("/admin/dashboard/agents");
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

export const dashRepDataAPI = async () => {
  try {
    const { data, status } = await API.get("/admin/dashboard/representatives");
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

export const fetchHeavyPaymentsAPI = async () => {
  try {
    const { data, status } = await API.get("/admin/dashboard/heavy-payments");
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

export const fetchGraphDataAPI = async () => {
  try {
    const { data, status } = await API.get(
      `/admin/dashboard/fetch-bar-graph-data`
    );
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};
