import React, { useState } from "react";
import { Link } from "react-router-dom";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import PaymentInvoice from "../PaymentsTable/PaymentInvoice";

const LogTabTr = ({ paymentLog }) => {
  const [openInvoice, setIsInvoiceModal] = useState(false);
  const openInvoiceModal = async () => {
    setIsInvoiceModal(true);
  };
  return (
    <>
      <tr>
        <td className="border border-1 border-solid text-center text-xs p-5 whitespace-nowrap">
          {paymentLog?.date}
        </td>
        <td
          className="border border-1 border-solid text-center text-sm p-5 whitespace-nowrap capitalize"
          style={{
            maxWidth: "300px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            wordWrap: "break-word",
            lineHeight: "1.2",
          }}
        >
          <Link
            className="hover:underline"
            to={`/admin/student/${paymentLog?.student}`}
            state={{ studentId: paymentLog?.studentId }}
          >
            {paymentLog?.student}
          </Link>
        </td>
        <td
          className="border border-1 border-solid text-center text-sm p-5 whitespace-nowrap capitalize"
          style={{
            maxWidth: "300px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            wordWrap: "break-word",
            lineHeight: "1.2",
          }}
        >
          {paymentLog?.collegeName}
        </td>
        <td className="border border-1 border-solid text-center text-sm p-5 whitespace-nowrap">
          <span className="flex gap-1">
            {paymentLog?.currency === "INR" ? (
              <p className="">
                <CurrencyRupeeIcon sx={{ fontSize: 15 }} />
              </p>
            ) : (
              <p className="">{paymentLog?.currency}.</p>
            )}
            <p className="font-extrabold ">{paymentLog?.totalFee}</p>
          </span>
        </td>
        <td className="border border-1 border-solid text-center text-sm p-5 whitespace-nowrap">
          <span className="flex gap-1">
            {paymentLog?.currency === "INR" ? (
              <p className="">
                <CurrencyRupeeIcon sx={{ fontSize: 15 }} />
              </p>
            ) : (
              <p className="">{paymentLog?.currency}.</p>
            )}
            <p className="font-extrabold ">{paymentLog?.amountToCollege}</p>
          </span>
        </td>
        <td className="border border-1 border-solid text-center text-sm p-5 whitespace-nowrap">
          <span className="flex gap-1">
            {paymentLog?.currency === "INR" ? (
              <p className="">
                <CurrencyRupeeIcon sx={{ fontSize: 15 }} />
              </p>
            ) : (
              <p className="">{paymentLog?.currency}.</p>
            )}
            <p className="font-extrabold ">{paymentLog?.repCommission}</p>
          </span>
        </td>
        <td className="border border-1 border-solid text-center text-sm p-5 whitespace-nowrap">
          <span className="flex gap-1">
            {paymentLog?.currency === "INR" ? (
              <p className="">
                <CurrencyRupeeIcon sx={{ fontSize: 15 }} />
              </p>
            ) : (
              <p className="">{paymentLog?.currency}.</p>
            )}
            <p className="font-extrabold ">{paymentLog?.agentCommission}</p>
          </span>
        </td>
        {/* <td className="border border-1 border-solid text-center text-sm p-5 whitespace-nowrap">
          <span className="flex gap-1">
            {paymentLog?.currency === "INR" ? (
              <p className="">
                <CurrencyRupeeIcon sx={{ fontSize: 15 }} />
              </p>
            ) : (
              <p className="">{paymentLog?.currency}.</p>
            )}
            <p className="font-extrabold ">{paymentLog?.adminCommission}</p>
          </span>
        </td> */}
        <td className="text-sm p-5 text-center flex flex-col gap-2 border border-1 border-solid">
          <Link
            to={`/admin/student-invoice/${paymentLog?.paymentId}`}
            target="_blank"
          >
            <button
              // onClick={openInvoiceModal}
              className="p-1 px-3 bg-blue-300 text-black hover:text-white rounded-sm text-xs font-bold hover:bg-blue-600 transition cursor-pointer"
            >
              Course fee Receipt
            </button>
          </Link>
          <>
            {paymentLog.applicationFees != null ? (
              <Link
                to={`/admin/student-applicationFee/${paymentLog?.paymentId}`}
                target="_blank"
              >
                <button className="p-1 px-3 bg-blue-300 text-black hover:text-white rounded-sm text-xs font-bold hover:bg-blue-600 transition cursor-pointer">
                  <span>Application Fee Receipt</span>
                </button>
              </Link>
            ) : (
              <button
                disabled
                className="cursor-not-allowed p-1 px-3 bg-gray-300 text-gray-500 rounded-sm text-xs font-bold transition"
              >
                <span className="">Application Fee Receipt</span>
              </button>
            )}
          </>
        </td>
      </tr>
      {openInvoice && (
        <>
          <div className="fixed z-10 inset-0 overflow-y-auto top-16">
            <div className="flex items-center justify-center h-full  px-4">
              <div
                className="fixed inset-0 bg-gray-200 bg-opacity-75 transition-opacity"
                aria-hidden="true"
              ></div>

              <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:w-1/2 lg:w-1/3">
                {/* Modal content */}
                {/* <div className="p-4 overflow-y-auto">
                  <button
                    onClick={() => setIsInvoiceModal(false)}
                    className="float-right text-gray-500 hover:text-gray-800"
                  >
                    <CloseIcon />
                  </button>
                </div> */}
                <PaymentInvoice
                  paymentReceipt={paymentLog.paymentId}
                  setIsInvoiceModal={setIsInvoiceModal}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default LogTabTr;
