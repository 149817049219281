import React, { useRef, useState } from "react";
import {
  editProfileAPI,
  // fetchCountriesAPI
} from "../../../API/admin/AdminAPI";
import Loader from "../CreateForms/Loader";
import RemoveRedEyeSharpIcon from "@mui/icons-material/RemoveRedEyeSharp";
import VisibilityOffSharpIcon from "@mui/icons-material/VisibilityOffSharp";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useDispatch } from "react-redux";

const EditProfile = ({ setAdminData, handleCloseModal, adminData }) => {
  // const [countries, setCountries] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [adminExists, setAdminExists] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const [formData, setFormData] = useState({
    _id: adminData._id,
    fullName: adminData?.name,
    email: adminData?.email,
    logo: null,
    password:"",
    addressLine1: adminData?.address?.addressLine1,
    addressLine2: adminData?.address?.addressLine2,
    city: adminData?.address?.city,
    state: adminData?.address?.state,
    country: adminData?.address?.country,
    zip: adminData?.address?.zip,
  });
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(formData.email);
    setIsEmailValid(isValidEmail);
  };
    const handlePassChange = (event) => {
      const { name, value } = event.target;
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      // Validate password
      const isValidPassword = value.length >= 8;
      setIsPasswordValid(isValidPassword);
    };

  // useEffect(() => {
  //   fetchCountries();
  // }, []);
   const handleFileChange = (event) => {
    const { name, files } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: files[0],
    }));

    // Get the selected image element
    const selectedImage = document.getElementById("selected-image");

    if (files[0]) {
      const reader = new FileReader();

      reader.onload = function (e) {
        // Set the src attribute of the <img> element to the selected image's data URL
        selectedImage.src = e.target.result;

        // Make the <img> element visible
        selectedImage.classList.remove("hidden");
      };

      // Read the selected file as a data URL
      reader.readAsDataURL(files[0]);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const errors = validateForm();
    if (isEmailValid && isPasswordValid && Object.keys(errors).length === 0) {
      setIsLoader(true);
      try {
        const response = await editProfileAPI(formData);
        if (response.status === 200) {
          setIsLoader(false);
          // Reset form after successful submission
          setAdminData(response?.data);
          // window.location.reload()
          handleCloseModal();
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setIsLoader(false);
      setFormErrors(errors);
    }
  };
  const validateForm = () => {
    const errors = {};
    setIsPasswordValid(true);
    // Validate each input here
    if (formData.fullName.trim() === "") {
      errors.fullName = "Full Name is required";
    }
    if (formData.email.trim() === "") {
      errors.email = "Email is required";
    }
    // if (formData.password.trim() === "") {
    //   errors.password = "Password is required";
    // }
    if (formData.addressLine1.trim() === "") {
      errors.addressLine1 = "Address Line1 is required";
    }
    if (formData.addressLine2.trim() === "") {
      errors.addressLine2 = "Address Line2 is required";
    }
    if (formData.city.trim() === "") {
      errors.city = "City is required";
    }
    if (formData.state.trim() === "") {
      errors.state = "State is required";
    }
    if (formData.country.trim() === "") {
      errors.country = "Country is required";
    }
    if (formData.zip.trim() === "") {
      errors.zip = "Zip is required";
    }
    return errors;
  };

  // const fetchCountries = async () => {
  //   const country = await fetchCountriesAPI();
  //   setCountries(country);
  // };

   // copy assword to clipboard
   const inputRef = useRef(null);

   const handleCopy = () => {
     const inputElement = inputRef.current;
 
     if (inputElement) {
       inputElement.select();
 
       // Use the Clipboard API to copy the text to the clipboard
       navigator.clipboard
         .writeText(inputElement.value)
         .then(() => {
           // Success!
           alert("Copied to clipboard!");
         })
         .catch((err) => {
           // Unable to copy
           console.error("Failed to copy: ", err);
         });
     }
   };
  return (
    <div className="flex justify-center items-center md:flex py-[20px] bg-white">
      <div className="w-full py-[10px] rounded-2xl shadow-lg shadow-gray-500">
        <div className="w-[95%] mx-auto">
          {/* heading */}
          <div className=" text-center md:py-[20px] text-[25px] md:text-left">
            <p className="underline underline-offset-8">Edit Profile</p>
          </div>
          <div className="grid grid-cols-3 gap-3">
           
            
            {/* name */}
            <div>
              <label className="block mb-2 font-medium" htmlFor="fullName">
                Full Name:
              </label>
              <input
                className="block w-full px-4 py-2 border placeholder:text-gray-500  border-gray-300 rounded-lg"
                type="text"
                id="fullName"
                name="fullName"
                placeholder="Full Name"
                required
                value={formData.fullName}
                onChange={handleInputChange}
              />
              {formErrors.fullName && (
                <span className="text-xs text-red-600">
                  {formErrors.fullName}
                </span>
              )}
            </div>
            {/* phone */}
            {/* mail id */}
            <div>
              <label className="block mb-2 font-medium" htmlFor="emailAddress">
                Email Address:
              </label>
              <input
                className="block w-full px-4 py-2 border placeholder:text-gray-500  border-gray-300 rounded-lg"
                type="email"
                id="emailAddress"
                name="email"
                placeholder="Email Address"
                required
                value={formData.email}
                onChange={handleInputChange}
                onBlur={validateEmail}
              />
              {!isEmailValid && (
                <span className="text-xs text-red-600">
                  Please enter a valid email address.
                </span>
              )}
              {adminExists && (
                <span className="text-xs text-red-600">
                  Email already exist !
                </span>
              )}
              {formErrors.email && (
                <span className="text-xs text-red-600">{formErrors.email}</span>
              )}
            </div>
            <div>
              <label className="block mb-2 font-medium">Password:</label>
              <div className="flex gap-1">
                <input
                  className=" block w-10/12 px-4 py-2 border placeholder:text-gray-500 placeholder:text-xs  border-gray-300 rounded-lg"
                  type={isVisible ? "text" : "password"}
                  id="password"
                  name="password"
                  placeholder="Set empty for continue with old password"
                  value={formData.password}
                  onChange={handlePassChange}
                  ref={inputRef}
                  required
                />
                <div className="flex gap-1">
                  <button
                    className=" right-0 text-black border border-gray-300  hover:bg-black hover:text-white font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center "
                    onClick={() => setIsVisible(!isVisible)}
                  >
                    {!isVisible ? (
                      <RemoveRedEyeSharpIcon sx={{ fontSize: 20 }} />
                    ) : (
                      <VisibilityOffSharpIcon sx={{ fontSize: 20 }} />
                    )}
                  </button>
                  <button
                    className="right-0 text-black border border-gray-300  hover:bg-black hover:text-white font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center "
                    onClick={handleCopy}
                  >
                    <ContentPasteIcon sx={{ fontSize: 20 }} />
                  </button>
                </div>
              </div>
              {!isPasswordValid && (
                <span className="text-xs text-red-600">
                  Password must be at least 8 characters long.
                </span>
              )}
              {formErrors.password && (
                <span className="text-xs text-red-600">
                  {formErrors.password}
                </span>
              )}
            </div>
          </div>
           {/* image */}
           <div className="flex items-center justify-center w-full my-4">
              <label
                htmlFor="dropzone-file"
                className="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <CloudUploadIcon className="opacity-40" />
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span className="font-semibold">Click to upload Logo</span> or
                    drag and drop
                  </p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    SVG, PNG, JPG or GIF (MAX. 800x400px)
                  </p>
                </div>
                <input
                  onChange={handleFileChange}
                  id="dropzone-file"
                  type="file"
                  name="logo"
                  className="hidden"
                />
              </label>
 
              <img
                id="selected-image"
                src={`${process.env.REACT_APP_BACKEND_URL}\\uploads\\${adminData?.logo_name}`}
                alt="SelectedImage"
                className=" h-24"
              />
            </div>  
          <p className="block my-3 font-medium text-sm" htmlFor="Address">
            Address:
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 my-[10px]">
            <div>
              <input
                className="block w-full px-4 py-2 border placeholder:text-gray-500  border-gray-300 rounded-lg"
                type="text"
                id="addressLine1"
                name="addressLine1"
                placeholder="Address Line 1"
                value={formData.addressLine1}
                onChange={handleInputChange}
                required
              />
              {formErrors.addressLine1 && (
                <span className="text-xs text-red-600">
                  {formErrors.addressLine1}
                </span>
              )}
            </div>
            <div className="">
              <input
                className="block w-full px-4 py-2 border placeholder:text-gray-500  border-gray-300 rounded-lg"
                type="text"
                id="addressLine2"
                name="addressLine2"
                placeholder="Address Line 2"
                value={formData.addressLine2}
                onChange={handleInputChange}
                required
              />
              {formErrors.addressLine2 && (
                <span className="text-xs text-red-600">
                  {formErrors.addressLine2}
                </span>
              )}
            </div>
            <div className="">
              <input
                className="block w-full px-4 py-2 border placeholder:text-gray-500  border-gray-300 rounded-lg"
                type="text"
                id="city"
                name="city"
                placeholder="City"
                value={formData.city}
                onChange={handleInputChange}
                required
              />
              {formErrors.city && (
                <span className="text-xs text-red-600">{formErrors.city}</span>
              )}
            </div>
            <div>
              <input
                className="block w-full px-4 py-2 border placeholder:text-gray-500  border-gray-300 rounded-lg"
                type="text"
                id="state"
                name="state"
                placeholder="State"
                value={formData.state}
                onChange={handleInputChange}
                required
              />
              {formErrors.state && (
                <span className="text-xs text-red-600">{formErrors.state}</span>
              )}
            </div>
            <div>
              {/* <select
                className="block w-full px-4 py-2 border placeholder:text-gray-500  border-gray-300 rounded-lg"
                id="country"
                name="country"
                value={formData.country}
                onChange={handleInputChange}
                required
              >
                <option value="" className="text-gray-500" selected>
                  Select a country
                </option>
                {countries.map((country) => (
                  <option key={country} value={country}>
                    {country}
                  </option>
                ))}
                <option value="" className="text-gray-500" selected>
                  None
                </option>
              </select> */}
              <input
                className="block w-full px-4 py-2 border placeholder:text-gray-500  border-gray-300 rounded-lg"
                type="text"
                id="country"
                name="country"
                placeholder="country"
                value={formData.country}
                onChange={handleInputChange}
                required
              />
              {formErrors.country && (
                <span className="text-xs text-red-600">
                  {formErrors.country}
                </span>
              )}
            </div>
            <div>
              <input
                className="block w-full px-4 py-2 border placeholder:text-gray-500  border-gray-300 rounded-lg"
                type="text"
                id="zip"
                name="zip"
                placeholder="Zip"
                value={formData.zip}
                onChange={handleInputChange}
                required
              />
              {formErrors.zip && (
                <span className="text-xs text-red-600">{formErrors.zip}</span>
              )}
            </div>
          </div>
          {/* section six */}
          <div className="flex justify-end gap-x-[20px]">
            <div className="flex justify-end gap-x-[20px]">
              <button
                onClick={handleCloseModal}
                className="py-[8px] md:ease-in duration-300 mt-4 bg-black hover:bg-red-600  text-sm text-white font-bold px-2  rounded md:w-40"
                type="submit"
              >
                Cancel
              </button>
              <button
                onClick={handleSubmit}
                className=" ease-in duration-300 mt-4 bg-red-500 hover:bg-green-600  text-sm text-white font-bold  py-2 px-3  rounded md:w-40"
                type="submit"
                disabled={isLoader}
              >
                {!isLoader ? <span>Save</span> : <Loader />}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
