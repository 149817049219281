import React, { useState } from "react";
import SideBar from "../SideBar/SideBar";
import NavBar from "../NavBar/NavBar";

const Layout = ({ children }) => {
  const [navButton, setNavButton] = useState(false);
  return (
    <div className="flex">
      <div className="hidden md:block">
        <div className="lg:w-2/12 md:3/12 py-8 fixed border-r-2 z-50 bg-white">
          <SideBar navButton={navButton} setNavButton={setNavButton} />
        </div>
      </div>
      {navButton && (
        <div className="w-10/12 md:hidden py-8 fixed border-r-2 z-50 bg-white ">
          <SideBar navButton={navButton} setNavButton={setNavButton} />
        </div>
      )}
      <div className="w-full md:w-9/12 lg:w-10/12 ml-auto">
        <NavBar setNavButton={setNavButton} navButton={navButton} />
        <div className="py-5 px-5 md:px-5 mt-28">{children}</div>
      </div>
    </div>
  );
};

export default Layout;
