import React, { useEffect, useState } from "react";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import Groups2OutlinedIcon from "@mui/icons-material/Groups2Outlined";
import DomainOutlinedIcon from "@mui/icons-material/DomainOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import HowToRegOutlinedIcon from "@mui/icons-material/HowToRegOutlined";
import {
  dashTopRowAPI,
  fetchHeavyPaymentsAPI,
} from "../../../API/admin/AdminDashBoardApi";
import HeavyPaymentCard from "./HeavyPaymentCard";
import { Link } from "react-router-dom";
// import SortIcon from "@mui/icons-material/Sort";

const DashboardContent = () => {
  // const [chartLimit, setChartLimit] = useState(true);
  const [topRowData, setTopRowData] = useState({});
  // const [representativesData, setRepresentativesData] = useState([]);
  // const [agents, setAgentsData] = useState([]);
  const [paymentsData, setPaymentsData] = useState([]);
  const [componentLoader, setComponentLoader] = useState(true);

  const fetchTopRowDetails = async () => {
    try {
      const response = await dashTopRowAPI();
      if (response?.status === 200) {
        setTopRowData(response?.data);
      } else {
        setTopRowData({});
      }
    } catch (error) {
      setTopRowData({});
    }
  };

  const fetchHeavyPayments = async () => {
    try {
      const response = await fetchHeavyPaymentsAPI();
      if (response?.status === 200) {
        setPaymentsData(response?.data);
      } else {
        setPaymentsData([]);
      }
    } catch (error) {
      setPaymentsData([]);
    }
  };

  useEffect(() => {
    // fetchColleges();
    fetchTopRowDetails();
    // fetchAgentsData();
    fetchHeavyPayments();
  }, []);

  return (
    <>
      {/* section 1 */}
      <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 py-4 transition">
        {/* college */}
        <Link to={"/admin/colleges"}>
          <div className="flex shadow-gray-300 bg-white hover:border-1 hover:ring-red-500 hover:ring-[3px] hover:rounded-lg hover:shadow  px-[20px] py-[10px] shadow-md rounded-sm shadow-gary-600 justify-start  md:flex md:items-center md:gap-x-[20px] md:mb-[0px] transition">
            <div className="bg-gray-50 px-[10px] text-black  text-[20px] rounded flex justify-between items-center shadow-sm ">
              <DomainOutlinedIcon sx={{ fontSize: 50 }} />
            </div>
            <div className="flex flex-col items-center">
              <p className="text-[12px] text-black font-bold">
                {" "}
                Schools
              </p>
              <p className="text-[30px] font-bold">
                {topRowData?.colleges ? topRowData?.colleges : 0}
              </p>
            </div>
          </div>
        </Link>
        <Link to={"/admin/representatives"}>
          <div className="flex shadow-gray-300 bg-white hover:border-1 hover:ring-red-500 hover:ring-[3px] hover:rounded-lg hover:shadow  px-[20px] py-[10px] shadow-md rounded-sm shadow-gary-600 justify-start  md:flex md:items-center md:gap-x-[20px] md:mb-[0px] transition">
            <div className="bg-gray-50 px-[10px] text-black  text-[20px] rounded flex justify-between items-center shadow-sm ">
              <PeopleAltOutlinedIcon sx={{ fontSize: 50 }} />
            </div>
            <div className="flex flex-col items-center">
              <p className="text-[12px] text-black font-bold ">
                {" "}
                Total Representatives
              </p>
              <p className="text-[30px] font-bold">
                {topRowData?.representatives ? topRowData?.representatives : 0}
              </p>
            </div>
          </div>
        </Link>
        <Link to={"/admin/agents"}>
          <div className="flex shadow-gray-300 bg-white hover:border-1 hover:ring-red-500 hover:ring-[3px] hover:rounded-lg hover:shadow  px-[20px] py-[10px] shadow-md rounded-sm shadow-gary-600 justify-start  md:flex md:items-center md:gap-x-[20px] md:mb-[0px] transition">
            <div className="bg-gray-50 px-[10px] text-black  text-[20px] rounded flex justify-between items-center shadow-sm ">
              <Groups2OutlinedIcon sx={{ fontSize: 50 }} />
            </div>
            <div className="flex flex-col items-center">
              <p className="text-[12px] text-black font-bold"> Total Agents</p>
              <p className="text-[30px] font-bold">
                {topRowData?.agents ? topRowData?.agents : 0}
              </p>
            </div>
          </div>
        </Link>
        <Link to={"/admin/agent-applications"}>
          <div className=" flex shadow-gray-300 bg-white hover:border-1 hover:ring-red-500 hover:ring-[3px] hover:rounded-lg hover:shadow  px-[20px] py-[10px] shadow-md rounded-sm shadow-gary-600 justify-start  md:flex md:items-center md:gap-x-[20px] md:mb-[0px] transition">
            <div className="relative bg-gray-50 px-[10px] text-black  text-[20px] rounded flex justify-between items-center shadow-sm ">
              {topRowData?.agentApplication ? (
                <span class="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
                  New
                </span>
              ) : null}
              <HowToRegOutlinedIcon sx={{ fontSize: 50 }} />
            </div>
            <div className="flex flex-col items-center">
              <p className="text-[12px] text-black font-bold">
                {" "}
                Agent Applications
              </p>
              <p className="text-[30px] font-bold">
                {topRowData?.agentApplication
                  ? topRowData?.agentApplication
                  : 0}
              </p>
            </div>
          </div>
        </Link>
      </div>
      {/* section 3 */}
      <div>
        <p className="text-[25px] mt-10 md:mt-10 text-black font-bold pb-[20px] md:text-[25px]  md:text-center ">
          Payments{" "}
          <span className="text-red-500 cursor-pointer hover:underline underline-offset-4">
            Pending
          </span>
          <p className="text-xs text-gray-400 font-light">
            Based on total fees
          </p>
        </p>
      </div>
      <div className="bg-gray-100 shadow-md shadow-gray-400 mb-2 flex flex-col justify-center items-center sm:grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 mt-[20px]  md:mt-[20px] md:p-[25px]  mt:mt-[30px]  rounded-xl">
        {paymentsData.length > 0 ? (
          paymentsData.map((payment) => (
            <HeavyPaymentCard key={payment._id} payment={payment} />
          ))
        ) : (
          <p>No payments</p>
        )}
      </div>
      {/* <div className="flex-col justify-center items-center h-2/3 animate-pulse">
        <p className="text-center text-[30px] opacity-10 font-bold break-words animate-pulse">
          Dashboard Under Maintenance
        </p>
        <p className="text-center text-[20px] opacity-20 font-bold break-words">
          We currently working on it. We hope to have the revised version ready
          soon.
        </p>
      </div> */}
    </>
  );
};

export default DashboardContent;
