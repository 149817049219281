import { API } from "../axios";
import axios from "axios";


export const addStudentDocumentData = async (formData) => {
    try {
        console.log(formData,"haloo");
        
      const { data, status } = await API.post("/admin/document-data/add", formData);
      return { data, status };
    } catch (error) {
      return error?.response;
    }
  };

  export const fetchStudentDocumentData = async (studentId) => {
    try {
      const { data, status } = await API.get(`/admin/document-data/${studentId}`);
      return { data, status };
    } catch (error) {
      return error?.message;
    }
  };

  export const editStudentDocumentData = async (formData, studentId) => {
    try {
      const { data, status } = await API.put(`/admin/document-data/edit/${studentId}`, formData);
      return { data, status };
    } catch (error) {
      return error?.response;
    }
  };