import React, { useEffect, useRef } from "react";
import { Chart } from "chart.js/auto";

const MiniLineGraph = ({ data }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    let chartInstance = null;
    const ctx = chartRef.current.getContext("2d");
    const dateArray = data.reverse().map((elm) => {
      const splitDate = elm._id.split("-");
      return `${splitDate[2]}-${splitDate[1]}`;
    });

    const valueArray = data?.map((elm) => elm?.totalCommission);

    if (chartRef.current && !chartInstance) {
      chartInstance = new Chart(ctx, {
        type: "line",
        data: {
          labels: [...dateArray],
          datasets: [
            {
              label: "Commission progress",
              data: [...valueArray],
              borderColor: "#FF2D00",
              backgroundColor: "#FF8F77",
              tension: 0.4,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              grid: {
                display: false,
              },
            },
            y: {
              grid: {
                color: "rgba(0, 0, 0, 0.1)",
                borderColor: "rgba(0, 0, 0, 0.1)",
              },
              ticks: {
                callback: (value) => `${value}`,
              },
            },
          },
        },
      });
    }

    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [data]);

  return (
    <div className="flex flex-col items-start hover:border shadow-gray-300 bg-white hover:ring-red-500 hover:ring-[3px] hover:rounded-lg hover:shadow  px-[20px] py-[10px] shadow-md rounded-sm shadow-gary-600 justify-start  md:flex md:items-start">
      <div className="w-full h-full">
        <canvas ref={chartRef} />
      </div>
    </div>
  );
};

export default MiniLineGraph;
