import React from "react";
import { Link } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

const StudentDocs = ({ studentData }) => {
  return (
    <>
      <div className="mt-10 p-3 rounded-md shadow-lg shadow-gray-300 bg-gray-50 max-h-[400px] overflow-y-scroll ">
        <table className="w-full border-collapse uppercase">
          <thead>
            <tr>
              <th className="text-gray-500 text-[16px]  py-[10px] border border-1 border-solid text-left p-[8px]">
                Documents
              </th>
              <th className="text-gray-500 text-[16px]  py-[10px] border border-1 border-solid text-left p-[8px]">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border border-1 border-solid text-left p-[8px] hover:underline capitalize font-bold text-md">
                <Link
                  to={`/admin/student-offer-letter/${studentData._id}`}
                  // state={{ studentId: studentData._id }}
                  target="_blank"
                >
                  Conditional letter of offer (CLOO)
                </Link>
              </td>
              <td className="border border-1 border-solid text-left text-sm p-[8px] flex">
                <Link
                  to={`/admin/student-offer-letter/${studentData._id}`}
                  // state={{ studentId: studentData._id }}
                  target="_blank"
                >
                  <div
                    // onClick={handelOfferLetterModal}
                    className="flex items-center justify-center h-10 w-10 rounded-full bg-gray-50 "
                  >
                    <RemoveRedEyeIcon
                      sx={{ fontSize: 25 }}
                      className="h-6 w-6 text-black hover:animate-pulse"
                    />
                  </div>
                </Link>
              </td>
            </tr>
            <tr>
              <td className="border border-1 border-solid text-left p-[8px] hover:underline capitalize font-bold text-md">
                <Link
                  to={`/admin/letter-of-offer-student/${studentData._id}`}
                  // state={{ studentId: studentData._id }}
                  target="_blank"
                >
                  letter of offer (LOO) & Acceptance Of Offer
                </Link>
              </td>
              <td className="border border-1 border-solid text-left text-sm p-[8px] flex">
                <Link
                  to={`/admin/letter-of-offer-student/${studentData._id}`}
                  // state={{ studentId: studentData._id }}
                  target="_blank"
                >
                  <div
                    // onClick={handelOfferLetterModal}
                    className="flex items-center justify-center h-10 w-10 rounded-full bg-gray-50 "
                  >
                    <RemoveRedEyeIcon
                      sx={{ fontSize: 25 }}
                      className="h-6 w-6 text-black hover:animate-pulse"
                    />
                  </div>
                </Link>
              </td>
            </tr>
            <tr>
              <td className="border border-1 border-solid text-left p-[8px] hover:underline capitalize font-bold text-md">
                <Link
                  to={`/admin/student-advisory-note/${studentData._id}`}
                  // state={{ studentId: studentData._id }}
                  target="_blank"
                >
                  Advisory Note
                </Link>
              </td>
              <td className="border border-1 border-solid text-left text-sm p-[8px] flex">
                <Link
                  to={`/admin/student-advisory-note/${studentData._id}`}
                  // state={{ studentId: studentData._id }}
                  target="_blank"
                >
                  <div
                    // onClick={handelOfferLetterModal}
                    className="flex items-center justify-center h-10 w-10 rounded-full bg-gray-50 "
                  >
                    <RemoveRedEyeIcon
                      sx={{ fontSize: 25 }}
                      className="h-6 w-6 text-black hover:animate-pulse"
                    />
                  </div>
                </Link>
              </td>
            </tr>
            <tr>
              <td className="border border-1 border-solid text-left p-[8px] hover:underline capitalize font-bold text-md">
                <Link
                  to={`/admin/student-pre-contract-letter/${studentData._id}`}
                  // state={{ studentId: studentData._id }}
                  target="_blank"
                >
                  Student contract
                </Link>
              </td>
              <td className="border border-1 border-solid text-left text-sm p-[8px] flex">
                <Link
                  to={`/admin/student-pre-contract-letter/${studentData._id}`}
                  // state={{ studentId: studentData._id }}
                  target="_blank"
                >
                  <div
                    // onClick={handelStudentContractModal}
                    className="flex items-center justify-center h-10 w-10 rounded-full bg-gray-50 "
                  >
                    <RemoveRedEyeIcon
                      sx={{ fontSize: 25 }}
                      className="h-6 w-6 text-black hover:animate-pulse"
                    />
                  </div>
                </Link>
              </td>
            </tr>
            <tr>
              <td className="border border-1 border-solid text-left p-[8px] capitalize font-bold text-md line-through text-gray-400 italic cursor-not-allowed">
                Student letter
              </td>
              {/* <td className="border border-1 border-solid text-left text-sm p-[8px] flex">
                <div
                  // onClick={handelStudentLetterModal}
                  className="flex items-center justify-center h-10 w-10 rounded-full bg-gray-50 "
                >
                  <RemoveRedEyeIcon
                    sx={{ fontSize: 25 }}
                    className="h-6 w-6 text-black hover:animate-pulse"
                  />
                </div>
              </td> */}
              <td className="font-bold text-md line-through text-gray-400 italic cursor-not-allowed">
                <p>Not yet</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default StudentDocs;
