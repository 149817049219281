import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const InnerProtectedRoute = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const agentTokens = localStorage.getItem("agent-auth-token");

    if (!agentTokens) {
      // Redirect to login if agentToken is not present
      navigate("/agent/login");
    } else {
      navigate("/agent/dashboard");
    }
  }, [location.pathname]);

  return <>{props.children}</>;
};

export default InnerProtectedRoute;
