import React, { useState } from "react";
import { Link } from "react-router-dom";
// import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
// import RepSideOption from "./RepSideOption";
// import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

const RepTableRows = ({ rep, i }) => {
  // const [showModal, setShowModal] = useState(false);

  // const openModal = () => {
  //   setShowModal(!showModal);
  // };

  // const closeModal = () => {
  //   setShowModal(false);
  // };
  // const active =
  //   "py-1 px-3 bg-green-500 text-white rounded-sm text-xs font-bold  capitalize";
  // const deactivate =
  //   "py-1 px-3 bg-red-500 rounded-sm text-xs font-bold text-white capitalize";
  return (
    <tr key={i}>
      <td className="border border-1 border-solid text-center text-sm p-[8px] capitalize">
        <Link
          to={`${rep.name}`}
          state={{ repId: rep._id }}
          className="hover:underline"
        >
          {rep.name}
        </Link>
      </td>
      <td className="border border-1 border-solid text-center text-sm p-[8px]">
        {rep.email}
      </td>
      <td className="border border-1 border-solid text-center text-sm p-[8px]">
        {rep.agents.length}
      </td>
      {/* <td className="border border-1 border-solid text-center text-sm p-[8px]">
        {rep.status ? (
          <span className={active}>active</span>
        ) : (
          <span className={deactivate}>disabled</span>
        )}
      </td> */}
      {/* <td className="w-40 relative text-[30px] text-gray-500 text-center border border-1 border-solid  p-[8px]">
        <button className="" onClick={openModal}>
          <MoreHorizIcon 
            className={showModal && "text-red-400"}
            sx={showModal ? { fontSize: 40 } : { fontSize: 40 }}
          />
        </button>
        <div className="relative">
          {showModal && <RepSideOption rep={rep} closeModal={closeModal} />}
        </div>
      </td> */}
      <td className="w-40 relative text-[30px] text-gray-500 text-center border border-1 border-solid  p-[8px]">
        ...
      </td>
    </tr>
  );
};

export default RepTableRows;
