import React, { useEffect, useState } from "react";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { useLocation, useParams } from "react-router-dom";
import { fetchStudentLetterOfOfferDataAPI } from "../../API/admin/GenerateStudentDocuments";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LanguageIcon from '@mui/icons-material/Language';
import EditField from "./EditingContact";
import { addStudentDocumentData, editStudentDocumentData, fetchStudentDocumentData } from "../../API/admin/DocumentData";
function LetterOfOffer() {
  const handleDownload = () => {
    window.print();
  };
  const [studentOfferOfLetterData, setStudentOfferOfLetterData] = useState({});
  const [showSave, setShowSave] = useState(false); // Toggle for Save button
  
  
  // const studentId = useParams();
  const { studentId } = useParams();



  useEffect(() => {
    fetchStudentLetterOfOffer();
    fetchStudentDocument()

  }, []);

    const today = new Date();
    const formattedDate = today.toLocaleDateString("en-GB");
  const isoFormattedDate = today.toISOString().split('T')[0]; // "YYYY-MM-DD"



  const [values, setValues] = useState({
    looNO :`ATC/LOO/${formattedDate}`,
    looDate: isoFormattedDate,
  });  
  console.log(values?.looNO);
  

    const fetchStudentLetterOfOffer = async () => {
      try {
        const response = await fetchStudentLetterOfOfferDataAPI(studentId);
        console.log(response);
        if (response.status === 200) {
            setStudentOfferOfLetterData(response.data);
          document.title = `${response?.data?.name}-conditional-offer-letter`;
        }
      } catch (error) {
        console.log(error);
      }
    };
    const fetchStudentDocument = async () => {
      try {
        try {
          const response = await fetchStudentDocumentData(studentId);
          if (response.status === 200) {
            const data = response.data[0];

  
            if (response.data.length > 0) {
              // Data exists, update state
              setValues({
                looNO:data?.looNO||`ATC/LOO/${formattedDate}`,
                looDate:data?.looDate||isoFormattedDate,
              });
  
             
              console.log("Existing document data found", response.data);
            } else {
              addStudentDocument();
            }
          }
        } catch (error) {
          console.error("Error fetching student document data:", error);
        }
      } catch (error) {
        console.error("Error fetching student document data:", error);
      }
    };

    const addStudentDocument = async () => {
      try {
        const formData = {
          looNO:values.looNO,
          looDate:values.looDate,
          studentId
        };
        const response = await addStudentDocumentData(formData);
  
        if (response.status === 200) {
          console.log("Document Added Successfully");
        }
      } catch (error) {
        console.error("Error adding student document data:", error);
      }
    };
    const handleSave = async () => {
      try {
        const formData = {
          looNO:values.looNO,
          looDate:values.looDate,
          studentId
        };
        const response = await editStudentDocumentData(formData, studentId);
        if (response.status === 200) {
          setShowSave(false); // Hide Save button after saving
        }
      } catch (error) {
        console.error("Error updating student document data:", error);
      }
      // Call API to save document data
    };
  

    const handleFieldChange = (key, newValue) => {
      setValues((prevValues) => ({
        ...prevValues,
        [key]: newValue, 
        // Update only the field corresponding to 'key'
      }));
      setShowSave(true); // Hide Save button after saving

    }

  // Create a new Date object representing the current date and time



  
  return (
    <>
      {Object.keys(studentOfferOfLetterData).length > 0 ? (
      <>
    <div className=" px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse no-print fixed right-0">
            {showSave && (
              <button
                className="mt-3 w-full inline-flex justify-center rounded-md border border-blue-700 shadow-sm px-4 py-2 bg-blue-600 text-white text-base font-medium"
                onClick={handleSave}
              >
                Save
              </button>
            )}

            {/* Show Download button only if no edits are pending */}
            {!showSave && (
              <button
                className="mt-3 w-full inline-flex justify-center rounded-md border border-green-700 shadow-sm px-4 py-2 bg-green-600 text-white text-base font-medium"
                onClick={handleDownload}
              >
                Download
              </button>
            )}
          </div>
        <div className="bg-slate-300 w-full h-full flex flex-col justify-center items-center">
          {/* offerletter */}
          <div className="w-[210mm]  bg-white " id="offerLetter">
            {/* header */}
            <div className="flex gap-1 justify-center mt-2">
                <div>

                <div className=" flex  flex-col">
                  <img
                    src="/assets/img/Acetek_logo.jpg"
                    alt="Acetek Logo"
                    width={140}
                  />
                </div>
                <div className="text-[8px] text-center  text-[#2C3178] ">
                      PEI Registration No: 200821327E <br />
                      (21 June 2023 to 20 June 2027) <br />
                </div>
                </div>

                <div>
                  <div className="flex gap-[17rem]">
                    <div className="text-[9px]   text-[#2C3178] mt-5">
                     
                    </div>
                    <div className="flex-col  gap-1 flex">
                      <span className="text-[#2C3178]  font-bold text-[21px] flex justify-start">
                        AceTek College Pte Ltd
                      </span>
                      <div className="flex gap-[21px] font-semibold">
                        {/* email icon */}
                        <div className="flex items-center gap-1">
                          <div className="bg-[#2C3178] w-3 h-3 flex justify-center items-center rounded-xl">
                            <EmailIcon
                              className="text-white"
                              style={{ fontSize: 8 }}
                            />
                          </div>
                          <span className="text-[9px] text-[#2C3178]">
                            info@acetek.edu.sg
                          </span>
                        </div>
                        {/* phone icon */}
                        <div className="flex items-center gap-1">
                          <div className="bg-[#2C3178] w-3 h-3 flex justify-center items-center rounded-xl">
                            <LocalPhoneIcon
                              className="text-white"
                              style={{ fontSize: 8 }}
                            />
                          </div>
                          <span className="text-[9px] text-[#2C3178]">
                            +(65) 8111 0897
                          </span>
                        </div>
                        {/* web icon */}
                        <div className="flex items-center gap-1">
                          <div className="bg-[#2C3178] w-3 h-3 flex justify-center items-center rounded-xl">
                            <LanguageIcon
                              className="text-white"
                              style={{ fontSize: 8 }}
                            />
                          </div>
                          <span className="text-[9px] text-[#2C3178]">
                            www.acetek.edu.sg
                          </span>
                        </div>
                      </div>
                      <div className="flex justify-end  font-semibold gap-1">
                      <div className="bg-[#2C3178] w-3 h-3  flex justify-center items-center rounded-xl">
                            <LocationOnIcon
                              className="text-white"
                              style={{ fontSize: 8 }}
                            />
                          </div>
                        <span className="text-[8.5px] text-[#2C3178] flex-col">
                            Blk 167 Jalan Bukit Merah, #02-12
                          Connection One Tower 5, Singapore 150167
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="bg-yellow-500 w-full h-1 mt-2"></div>
                </div>
              </div>

            {/* body */}
            <div className=" m-10 text-[13px]">
              <div className="text-[12px] flex justify-between">
                <span> <EditField
                fieldKey="looDate"
                value={values?.looDate}
                handleChange={handleFieldChange} // Pass the change handler
                isDateField={true}
                /></span>
                <span className="flex items-center !text-[12px]" style={{fontSize:"12px !important"}}>Ref:
                  <EditField
                fieldKey="looNO"
                value={values.looNO}
                handleChange={handleFieldChange} // Pass the change handler
                /></span>
              </div>
              <div className="flex flex-col mt-2 gap-4">
                <span>
                  <span className="font-bold uppercase">
                    {studentOfferOfLetterData?.salutation}.&nbsp;{studentOfferOfLetterData?.name}  <br />
                    PASSPORT NO: {
                      studentOfferOfLetterData?.passport
                    }  <br />
                    <span className="font-normal">

                    {studentOfferOfLetterData?.address} <br />
                    {studentOfferOfLetterData?.country}
                    </span>
                    {/* <span className="font-normal">
                      NO 13 KABARAYE STREET MANDALAY TOWNSHIP <br />
                      MYANMAR
                    </span> */}
                  </span>
                </span>
                <span>Dear &nbsp;{studentOfferOfLetterData.salutation}.&nbsp;{studentOfferOfLetterData?.name.toUpperCase()}</span>

                <span className="font-semibold">
                  Letter of Offer for <span className="uppercase"> {studentOfferOfLetterData?.course} </span>

                </span>
                <span>
                  Thank you for your application to join the above-named course.
                  We are pleased to inform you that your application documents
                  are in order and that a place has been reserved for you based
                  on your application and supporting documents submitted.{" "}
                </span>

                <span>
                  This Letter of Offer for your application to enrol in the said
                  course is subject to the clearance of all formalities by the
                  Immigration and Checkpoints Authority (ICA) of Singapore and
                  upon successful issuance of the Student Pass to you
                </span>
                <span>
                  You are encouraged to bring your own laptop/tablet to complete
                  and submit your course work in a timely manner
                </span>
                <span>
                  The next step is to accept the offer by signing below. If
                  there is no response from you within two weeks of <br />
                  <span className="font-bold">
                    {" "}
                    the date of this letter, we will treat this application as
                    void.{" "}
                  </span>
                </span>
                <span className="font-bold">
                  The following are provided for your information:{" "}
                </span>
              </div>
              <div className="mt-6">
                <div className="flex flex-col">
                  <table className="w-full border-collapse">
                    <tbody>
                      <tr className="mb-4">
                        <td className="pb-3 pr-3 text-left">Course:</td>
                        <td className="pb-4 text-left">
                          <span className="font-bold  uppercase">
                            {studentOfferOfLetterData?.course}
                          </span>
                        </td>
                      </tr>
                      <tr className="mb-4">
                        <td className="pb-4 pr-4 text-left w-48 flex">
                          Commencement Date:
                        </td>
                        <td className="pb-4 text-left whitespace-nowrap">
                        {new Date(
                              studentOfferOfLetterData?.commencementDate
                            ).toLocaleDateString("en-GB")}                   
                        </td>
                      </tr>
                      <tr className="mb-4">
                        <td className="pb-36 pr-4 text-left">
                          Duration of Course:
                        </td>
                        <td className="text-left pt-6">
                        {studentOfferOfLetterData?.durationOfCourse} 
                          <br />
                          Participation in an industrial attachment is mandatory
                          and forms an essential component of the course.
                          <br />
                          <p className="mt-5">
                            The Industrial Attachment is subject to the approval
                            and issuance of Training Work Permit by Ministry of
                            Manpower, Singapore. In the event that a student is
                            unable to secure a suitable internship due to
                            circumstances beyond the college’s control, the
                            alternative requirement will be the completion of a
                            Project Work (Project in lieu of IA will be of 3
                            months duration).
                          </p>
                        </td>
                      </tr>
                      <tr className="mb-4 ">
                        <td className=" mt-3  pr-4 text-left">
                          Application Fee:
                        </td>
                        <td className=" text-left pt-3  ">
                          <span className="font-bold">S${studentOfferOfLetterData.applicationFee} </span> inclusive
                          of 9% GST (payable upon application)
                        </td>
                      </tr>
                      <tr className="mb-4">
                        <td className="pb-12 pr-4 text-left">Course Fee:</td>
                        <td className=" text-left pt-4">
                          <span className="font-semibold">S${studentOfferOfLetterData.courseFee} </span>{" "}
                          inclusive of 9% GST (payable in --- 1 instalment){" "}
                          <br />
                          <span className="">
                            <div className="mt-4">
                              Course Fee is payable after Successful application
                              of the Student pass issued by Immigration &
                              Checkpoints Authority, Singapore, and the signing
                              of the Student Contract between you and AceTek
                              College
                            </div>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="flex justify-end text-slate-500 text-xs">Page 1|3</div>
              </div>
            </div>

            {/* //NextPage */}
            {/* header */}
            <div className="flex gap-1 justify-center mt-2">
                <div>

                <div className=" flex  flex-col">
                  <img
                    src="/assets/img/Acetek_logo.jpg"
                    alt="Acetek Logo"
                    width={140}
                  />
                </div>
                <div className="text-[8px] text-center  text-[#2C3178] ">
                pei Registration No: 200821327E <br />
                      (21 June 2023 to 20 June 2027) <br />
                </div>
                </div>

                <div>
                  <div className="flex gap-[17rem]">
                    <div className="text-[9px]   text-[#2C3178] mt-5">
                     
                    </div>
                    <div className="flex-col  gap-1 flex">
                      <span className="text-[#2C3178]  font-bold text-[21px] flex justify-start">
                        AceTek College Pte Ltd
                      </span>
                      <div className="flex gap-[21px] font-semibold">
                        {/* email icon */}
                        <div className="flex items-center gap-1">
                          <div className="bg-[#2C3178] w-3 h-3 flex justify-center items-center rounded-xl">
                            <EmailIcon
                              className="text-white"
                              style={{ fontSize: 8 }}
                            />
                          </div>
                          <span className="text-[9px] text-[#2C3178]">
                            info@acetek.edu.sg
                          </span>
                        </div>
                        {/* phone icon */}
                        <div className="flex items-center gap-1">
                          <div className="bg-[#2C3178] w-3 h-3 flex justify-center items-center rounded-xl">
                            <LocalPhoneIcon
                              className="text-white"
                              style={{ fontSize: 8 }}
                            />
                          </div>
                          <span className="text-[9px] text-[#2C3178]">
                            +(65) 8111 0897
                          </span>
                        </div>
                        {/* web icon */}
                        <div className="flex items-center gap-1">
                          <div className="bg-[#2C3178] w-3 h-3 flex justify-center items-center rounded-xl">
                            <LanguageIcon
                              className="text-white"
                              style={{ fontSize: 8 }}
                            />
                          </div>
                          <span className="text-[9px] text-[#2C3178]">
                            www.acetek.edu.sg
                          </span>
                        </div>
                      </div>
                      <div className="flex justify-end  font-semibold gap-1">
                      <div className="bg-[#2C3178] w-3 h-3  flex justify-center items-center rounded-xl">
                            <LocationOnIcon
                              className="text-white"
                              style={{ fontSize: 8 }}
                            />
                          </div>
                        <span className="text-[8.5px] text-[#2C3178] flex-col">
                            Blk 167 Jalan Bukit Merah, #02-12
                          Connection One Tower 5, Singapore 150167
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="bg-yellow-500 w-full h-1 mt-2"></div>
                </div>
              </div>

            {/* body */}

            <div className="m-10 text-[13px]">
              <div className="flex flex-col">
                <table>
                  <tbody>
                    <tr className="mb-4">
                      <td className="pb-1 pt-2 pr-4 text-left">Award:</td>
                      <td className="pb-4 text-left pt-5">
                      {studentOfferOfLetterData?.course}
                      </td>
                    </tr>
                    <tr className="mb-4">
                      <td className="pt-2 pr-20 text-left">Awarding Body:</td>
                      <td className="pb-4 text-left pt-5">AceTek College</td>
                    </tr>
                  </tbody>
                </table>

                <div className="mt-10">
                  <span className="font-bold">
                    <u> PAYMENT MODE </u>{" "}
                  </span>
                  <br />
                  <span className="mt-11">
                    The student needs to make the Course Fee Payment after
                    signing the Advisory Note and the Standard PEI-Student
                    contract. After the standard PEI-Student Contract is signed,
                    students can choose to pay the fees via Telegraphic Transfer
                    / Bank Transfer.
                  </span>
                  <table>
                    <tbody>
                      <tr className="border w-full  border-black">
                        <td className="border w-[22rem] pl-2 font-bold pb-3 border-black">
                          Account Name:
                        </td>
                        <td className="border w-[22rem] pb-3 pl-2 border-black">
                          ACETEK COLLEGE PTE LTD
                        </td>
                      </tr>
                      <tr className="border w-full  border-black">
                        <td className="border w-[22rem] pl-2 font-bold pb-3 border-black">
                          BANK NAME
                        </td>
                        <td className="border w-[22rem] pb-3 pl-2 border-black">
                           DBS Bank Ltd
                        </td>
                      </tr>
                      <tr className="border w-full  border-black">
                        <td className="border w-[22rem] pl-2 font-bold pb-3 border-black">
                          BANK ACCOUNT NUMBER
                        </td>
                        <td className="border w-[22rem] pb-3 pl-2 border-black">
                          0727605607
                        </td>
                      </tr>
                      <tr className="border w-full  border-black">
                        <td className="border w-[22rem] pl-2 font-bold pb-3 border-black">
                          Bank Code
                        </td>
                        <td className="border w-[22rem] pb-3 pl-2 border-black">
                          7171
                        </td>
                      </tr>
                      <tr className="border w-full  border-black">
                        <td className="border w-[22rem] pl-2 font-bold pb-3 border-black">
                          BRANCH CODE
                        </td>
                        <td className="border w-[22rem] pb-3 pl-2 border-black">
                          072 (Raffles Place)
                        </td>
                      </tr>
                      <tr className="border w-full  border-black">
                        <td className="border w-[22rem] pl-2 font-bold pb-3 border-black">
                          Name of Beneficiary Bank
                        </td>
                        <td className="border w-[22rem] pb-3 pl-2 border-black">
                          DBS Bank
                        </td>
                      </tr>
                           
                      <tr className="border w-full  border-black">
                        <td className="border w-[22rem] pl-2 font-bold pb-3 border-black">
                          Address of Beneficiary Bank
                        </td>
                        <td className="border w-[22rem] pb-3 pl-2 border-black">
                          12 Marina Boulevard, DBS Asia Central, Marina Bay
                          Financial Centre Tower 3, Singapore
                        </td>
                      </tr>
                      <tr className="border w-full  border-black">
                        <td className="border w-[22rem] pl-2 font-bold pb-3 border-black">
                          SWIFT Address/Code
                        </td>
                        <td className="border w-[22rem] pb-3 pl-2 border-black">
                          DBSSSGSG{" "}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <span className="p-1 pt-5">
                  AceTek College Pte Ltd will relentlessly strive to offer you
                  an educational journey that facilitates your learning
                  experience to meet your aspiration and career needs. We look
                  forward to your successful academic and career advancement
                  through participation in our programme and courses.
                </span>

                <span className="pt-10">Yours sincerely</span>

                <span className="pt-32 pb-10">
                  Sudeep Sreedharan <br />
                  Managing Director
                </span>
              </div>
              <div className="flex justify-end text-slate-500 text-xs">Page 2|3</div>

            </div>

            {/* //nextpage */}
            {/* //header */}
            <div className="flex gap-1 justify-center mt-[5.75rem]">
                <div>

                <div className=" flex  flex-col">
                  <img
                    src="/assets/img/Acetek_logo.jpg"
                    alt="Acetek Logo"
                    width={140}
                  />
                </div>
                <div className="text-[8px] text-center  text-[#2C3178] ">
                      cpe Registration No: 200821327E <br />
                      (21 June 2023 to 20 June 2027) <br />
                </div>
                </div>

                <div>
                  <div className="flex gap-[17rem]">
                    <div className="text-[9px]   text-[#2C3178] mt-5">
                     
                    </div>
                    <div className="flex-col  gap-1 flex">
                      <span className="text-[#2C3178]  font-bold text-[21px] flex justify-start">
                        AceTek College Pte Ltd
                      </span>
                      <div className="flex gap-[21px] font-semibold">
                        {/* email icon */}
                        <div className="flex items-center gap-1">
                          <div className="bg-[#2C3178] w-3 h-3 flex justify-center items-center rounded-xl">
                            <EmailIcon
                              className="text-white"
                              style={{ fontSize: 8 }}
                            />
                          </div>
                          <span className="text-[9px] text-[#2C3178]">
                            info@acetek.edu.sg
                          </span>
                        </div>
                        {/* phone icon */}
                        <div className="flex items-center gap-1">
                          <div className="bg-[#2C3178] w-3 h-3 flex justify-center items-center rounded-xl">
                            <LocalPhoneIcon
                              className="text-white"
                              style={{ fontSize: 8 }}
                            />
                          </div>
                          <span className="text-[9px] text-[#2C3178]">
                            +(65) 8111 0897
                          </span>
                        </div>
                        {/* web icon */}
                        <div className="flex items-center gap-1">
                          <div className="bg-[#2C3178] w-3 h-3 flex justify-center items-center rounded-xl">
                            <LanguageIcon
                              className="text-white"
                              style={{ fontSize: 8 }}
                            />
                          </div>
                          <span className="text-[9px] text-[#2C3178]">
                            www.acetek.edu.sg
                          </span>
                        </div>
                      </div>
                      <div className="flex justify-end  font-semibold gap-1">
                      <div className="bg-[#2C3178] w-3 h-3  flex justify-center items-center rounded-xl">
                            <LocationOnIcon
                              className="text-white"
                              style={{ fontSize: 8 }}
                            />
                          </div>
                        <span className="text-[8.5px] text-[#2C3178] flex-col">
                            Blk 167 Jalan Bukit Merah, #02-12
                          Connection One Tower 5, Singapore 150167
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="bg-yellow-500 w-full h-1 mt-2"></div>
                </div>
              </div>

            {/* //page */}
            <div className="m-10 text-[13px]">
              <div className="flex flex-col">
                <div className="mt-10 w-full flex flex-col gap-14 p-5">
                  <div className="flex justify-center">
                    <span className="font-bold text-[15px] text-center">
                      <u>ACCEPTANCE OF OFFER</u>{" "}
                    </span>
                  </div>
                  <span className="">
                    Regarding the Offer Letter, Reference No.: <span className="font-semibold">
                    {values.looNO }, </span> I,  <span className="font-semibold">{studentOfferOfLetterData?.salutation}. &nbsp;{studentOfferOfLetterData?.name} </span>, holding {studentOfferOfLetterData?.country}
                    <span className="font-semibold"> Passport No: {studentOfferOfLetterData?.passport} </span>, hereby confirm my acceptance of the
                    offer extended by AceTek College Pte Ltd for enrollment in
                    the following course:
                  </span> 
                  <span className="font-semibold uppercase">
                    Course:{studentOfferOfLetterData?.course}

                  </span>

                  <span>
                    I have thoroughly reviewed and agreed to the terms and
                    conditions outlined in your Letter of Offer and Payment
                    Details. I am aware that I will also need to execute a
                    Student's Contract with AceTek College.
                  </span>
                  <span className="flex gap-1">
                    <span className="flex">
                      Signature :{" "}
                      <div className="h-[0.5px] w-[16rem] mt-4 bg-black"></div>
                    </span>
                    <span className="flex">
                      Date:{" "}
                      <div className="h-[0.5px] w-[12rem] mt-4 bg-black"></div>
                    </span>
                  </span>
                  <span>
                    If the aforementioned individual is under 18 years of age or
                    lacks independent means, their parent/guardian must complete
                    the following declaration:
                  </span>

                  <span className="flex  flex-col">
                   <span className="flex"> I, <div className="h-[0.5px] w-[12rem] mt-4 bg-black"></div> 
                    [Full Name and ID No]. of Parent/Guardian,
                    acting as the   </span> 
                    <span className="flex">parent/guardian of <div className="h-[0.5px] w-[12rem] mt-4 bg-black"></div>[Student's
                    Full Name], agree to the aforementioned </span> terms and conditions
                    on behalf of my child/ward.
                  </span>


                  <span className="flex gap-1">
                    <span className="flex">
                      Signature :{" "}
                      <div className="h-[0.5px] w-[16rem] mt-4 bg-black"></div>
                    </span>
                    <span className="flex">
                      Date:{" "}
                      <div className="h-[0.5px] w-[12rem] mt-4 bg-black"></div>
                    </span>
                  </span>
                  <div className="flex justify-end text-slate-500 text-xs mt-24 relative">Page 3|3</div>

                </div>

              
              </div>
            </div>
          </div>
        </div>
      </>
     ) : (
        <p>Fetching.....</p>
      )} 
    </>
  );
}

export default LetterOfOffer;
