import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { settlePaymentAPI } from "../../../API/admin/PaymentsAPI";
import PaymentInvoice from "./PaymentInvoice";
import CloseIcon from "@mui/icons-material/Close";
import PaymentSuccessModal from "./SuccessPayment";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

// import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const SettlePayment = ({ paymentData, handleRefresh }) => {
  const [paymentReceipt, setPaymentReceipt] = useState({});
  const [amountInput, setAmountInput] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState("cash");
  const [amountLimit, setAmountLimit] = useState(false);
  const [isSelectEmi, setIsSelectEmi] = useState(false);
  const [isInvoiceModal, setIsInvoiceModal] = useState(false);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const [message, setMessage] = useState(false);
  const [inputBlank, setInputBlank] = useState(false);
  const [applicationMessage, setApplicationMessage] = useState(false);
  const [isAddApplicationAmount, setIsAddApplicationAmount] = useState(false);
  // const [minimumAmount, setMinimumAmount] = useState(false);
  // const [emiDates, setEmiDates] = useState([]);
  const [applicationFeesInput, setApplicationFeesInput] = useState(
    paymentData.applicationFees
  );
  const [checkboxValues, setCheckboxValues] = useState(paymentData.tenureDates);

  // useEffect(() => {
  //   console.log(paymentData.tenure.split("-")[0],paymentData.outstandingAmount);
  //   if (paymentData.tenure.split("-")[0] <= 1) {
  //     setAmountInput(paymentData.outstandingAmount);
  //   }
  // }, [paymentData]);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const limit = paymentData.outstandingAmount;

    if (inputValue <= limit) {
      setAmountInput(inputValue);
      setMessage(false);
    } else {
      setAmountInput(inputValue);
      setMessage(true);
    }
  };
  const handleApplicationFeesInputChange = (event) => {
    const inputValue = event.target.value;
    const limit = paymentData.applicationFees;

    if (inputValue <= limit) {
      setApplicationFeesInput(inputValue);
      setApplicationMessage(false);
    } else {
      setApplicationFeesInput(inputValue);
      setApplicationMessage(true);
    }
  };

  const closeModal = () => {
    setIsPaymentSuccess(false);
  };

  const handlePayButton = async () => {
    if (
      amountInput === "" ||
      (amountInput <= 0 && applicationFeesInput <= 0) ||
      message
    ) {
      return setInputBlank(true);
    }
    // if (amountInput < 1000) {
    //   return setMinimumAmount(true);
    // }
    setInputBlank(false);
    const formData = {
      _id: paymentData._id,
      paidAmount: amountInput,
      tenure: paymentData.tenure,
      applicationFees: isAddApplicationAmount,
      paymentMethod: paymentMethod,
      emiTimes: checkboxValues.filter((value) => value === true).length,
      emiDates: checkboxValues.map(
        (value, index) => value === true && paymentData.tenureDates[index]
      ),
    };
    try {
      if (paymentData.tenure.split("-")[0] > 1 && formData.emiTimes === 0) {
        setIsSelectEmi(true);
        return;
      }
      setIsSelectEmi(false);
      setAmountLimit(false);
      const response = await settlePaymentAPI(formData);
      if (response?.status === 200) {
        handleRefresh();
        setIsPaymentSuccess(true);
        setPaymentReceipt(response?.data);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // Update the total amount and tenure when payment prop changes
    if (paymentData.tenure.split("-")[0] > 1) {
      // Generate an array of checkbox values based on the tenure
      const values = Array.from(
        { length: paymentData.tenureDates.length },
        () => false
      );
      setCheckboxValues(values);
    } else {
      setAmountInput(paymentData.outstandingAmount);
    }
  }, [paymentData]);

  useEffect(() => {
    if (paymentData.tenure.split("-")[0] > 1) {
      calculateInstallmentAmount();
    } else {
      setAmountInput(paymentData.outstandingAmount);
    }
  }, [checkboxValues]);

  const calculateInstallmentAmount = () => {
    const emiTimes = checkboxValues.filter((value) => value === true).length;
    const paymentTenure = paymentData.tenure.split("-")[0];
    const totalAmount = paymentData.outstandingAmount;
    const emiOneTime = totalAmount / Number(paymentTenure);
    const selectedAmount = emiOneTime * emiTimes;
    setAmountInput(
      isAddApplicationAmount
        ? paymentData.applicationFees + Math.round(selectedAmount)
        : Math.round(selectedAmount)
    );
    return;
  };

  const handleCheckboxChange = (index, amount) => {
    const updatedValues = [...checkboxValues];
    updatedValues[index] = !updatedValues[index];
    setCheckboxValues(updatedValues);
    calculateInstallmentAmount();
    // setEmiDates([...emiDates, paymentData.tenureDates[index]]);
  };

  const handleDivClick = (index, amount) => {
    handleCheckboxChange(index, amount);
  };

  const handleAddApplicationFees = (e) => {
    setIsAddApplicationAmount(true);
    setAmountInput(Number(amountInput) + Number(paymentData.applicationFees));
  };
  const handleLessApplicationFees = (e) => {
    setIsAddApplicationAmount(false);
    setAmountInput(Number(amountInput) - Number(paymentData.applicationFees));
  };
  return (
    <div>
      <div className="w-[90%] mx-auto ">
        <div className=" sm:w-[400px] bg-white  md:w-[900px] md:py-[30px] md:px-5  md:rounded-2xl md:shadow-lg md:shadow-gray-500">
          {/* Student name */}
          <div className="  text-center text-[25px] font-semibold text-gray-800 capitalize">
            <h4>{paymentData.studentName}</h4>
            <p className="text-[20px]">{paymentData.collegeName}</p>
          </div>
          <div className=" md:flex md:justify-between md:items-center px-[30px]">
            <div>
              <div className="my-3">
                <div>
                  <p className="text-xs text-gray-500 font-medium">Date:</p>
                </div>
                <div>
                  <p className="text-[16px] font-medium ">{paymentData.date}</p>
                </div>
              </div>
              <div className="my-3">
                <div>
                  <p className="text-xs text-gray-500 font-medium">
                    College Name :
                  </p>
                </div>
                <div>
                  <p className="text-[16px] font-medium ">
                    {paymentData.collegeName}
                  </p>
                </div>
              </div>
              <div className="my-3">
                <div>
                  <p className="text-xs text-gray-500 font-medium">
                    Total fee:
                  </p>
                </div>
                <div>
                  <p className="text-[16px] font-medium flex">
                    {paymentData?.currency === "INR" ? (
                      <p className="text-sm">
                        <CurrencyRupeeIcon sx={{ fontSize: 15 }} />
                      </p>
                    ) : (
                      <p className="">{paymentData?.currency}.</p>
                    )}
                    {paymentData.totalFees}
                  </p>
                </div>
              </div>
              <div className="my-3">
                <div>
                  <p className="text-xs text-gray-500 font-medium">
                    Commissions:
                  </p>
                </div>
                <div className="flex gap-3">
                  <span className="bg-green-100 py-1 px-5 rounded-md">
                    <p className="text-xs">Rep</p>

                    <p className="text-center whitespace-nowrap flex ">
                      {paymentData?.currency === "INR" ? (
                        <p className="text-sm">
                          <CurrencyRupeeIcon sx={{ fontSize: 15 }} />
                        </p>
                      ) : (
                        <p className="">{paymentData?.currency}.</p>
                      )}
                      {paymentData.repCommission
                        ? paymentData.repCommission
                        : 0}
                    </p>
                  </span>
                  <span className="bg-green-100 py-1 px-5 rounded-md">
                    <p className="text-xs">Agent</p>

                    <p className="text-center whitespace-nowrap flex ">
                      {paymentData?.currency === "INR" ? (
                        <p className="text-sm">
                          <CurrencyRupeeIcon sx={{ fontSize: 15 }} />
                        </p>
                      ) : (
                        <p className="">{paymentData?.currency}.</p>
                      )}
                      {paymentData.agentCommission}
                    </p>
                  </span>
                  {/* <span className="bg-green-100 py-1 px-5 rounded-md">
                    <p className="text-xs">Admin</p>
                    <p className="text-center whitespace-nowrap flex ">
                      {paymentData?.currency === "INR" ? (
                        <p className="text-sm">
                          <CurrencyRupeeIcon sx={{ fontSize: 15 }} />
                        </p>
                      ) : (
                        <p className="">{paymentData?.currency}.</p>
                      )}
                      {paymentData.adminCommission}
                    </p>
                  </span> */}
                </div>
              </div>

              <div className="my-3">
                <div>
                  <p className="text-xs text-gray-500 font-medium">
                    To College:
                  </p>
                </div>
                <div>
                  <p className="text-md text-center font-semibold bg-green-100 p-5 rounded-md whitespace-nowrap flex justify-center">
                    {paymentData?.currency === "INR" ? (
                      <p className="text-sm">
                        <CurrencyRupeeIcon sx={{ fontSize: 15 }} />
                      </p>
                    ) : (
                      <p className="">{paymentData?.currency}.</p>
                    )}
                    {paymentData.college}
                  </p>
                </div>
              </div>
            </div>
            <div className="border-l-2 border-gray-400  h-[300px] hidden md:block" />
            <div className=" sm: mt-[30px]  md: w-[400px]  py-[30px] px-5 rounded-2xl shadow-md shadow-gray-300 flex flex-col gap-y-[20px] justify-center">
              <>
                {paymentData.tenure.split("-")[0] > 1 ? (
                  <div>
                    {/* <h4>Total Amount: {totalAmount}</h4> */}
                    <h4 className="text-[17px] text-gray-500 font-medium whitespace-nowrap capitalize">
                      Outstanding Amount: {paymentData.outstandingAmount}
                    </h4>

                    {checkboxValues.length > 0 && (
                      <div>
                        <h4>Select:</h4>
                        <div className="grid grid-cols-3 gap-3">
                          {checkboxValues.map((checked, index) => (
                            <div
                              key={index}
                              className={`bg-green-100 py-1 px-5 rounded-md ${
                                checked ? "selected" : ""
                              }`}
                              onClick={() => handleDivClick(index)}
                            >
                              <input
                                type="checkbox"
                                className="mx-1"
                                checked={checked}
                                onChange={() => handleCheckboxChange(index)}
                              />
                              <label>
                                <span>EMI: {index + 1}</span>
                                {/* <span>
                                  date:{" "}
                                  {paymentData.tenureDates[index].tenureDate -
                                    `${index + 1}`}
                                </span> */}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {isSelectEmi && (
                      <p className="text-red-600 text-xs text-center mb-2 capitalize">
                        Select an emi
                      </p>
                    )}
                  </div>
                ) : (
                  <div className="">
                    <div>
                      <p className="text-[17px] text-gray-500 font-medium whitespace-nowrap capitalize">
                        One Time settlement
                      </p>
                    </div>
                    <div className="bg-gray-50 shadow-gray-400 shadow-inner w-20 p-2">
                      <p className="text-ms font-medium ">
                        {paymentData.outstandingAmount}
                      </p>
                    </div>
                  </div>
                )}
                <div>
                  <div className="">
                    <div>
                      <p className="text-[17px] text-gray-500 font-medium whitespace-nowrap capitalize">
                        Payment method
                      </p>
                    </div>
                    <div className="">
                      <select
                        className="block  px-4 py-1 border placeholder:text-gray-500  border-gray-300 rounded-lg outline-none"
                        name="method"
                        id="method"
                        value={paymentMethod}
                        onChange={(e) => setPaymentMethod(e.target.value)}
                      >
                        <option value="Cash" selected>Cash</option>
                        <option value="Bank Deposit/Transfer Slip" >
                          Bank Deposit/Transfer Slip
                        </option>
                        <option value="Telegraphic Transfer">
                          Telegraphic Transfer
                        </option>
                        <option value="Paynow">Paynow</option>
                        <option value="Cheque">Cheque</option>
                        <option value="Others">Others</option>
                      </select>
                    </div>
                  </div>
                </div>

                {/* <div className="flex gap-4">
                  <div>
                    <p className="text-[17px] text-gray-500 font-medium whitespace-nowrap">
                      Application fees:
                    </p>
                  </div>
                  {paymentData.applicationFees !== 0 ? (
                    <div>
                      <input
                        className="block w-[150px]  px-4 py-1 border placeholder:text-gray-500  border-gray-300 rounded-lg outline-none"
                        type="number"
                        id="appFees"
                        name="appFees"
                        value={applicationFeesInput}
                        onChange={handleApplicationFeesInputChange}
                        required
                        // readOnly
                      />
                      {applicationMessage && (
                        <p className="text-red-600 text-xs my-1 capitalize">{`total amount is ${paymentData.applicationFees}`}</p>
                      )}
                    </div>
                  ) : (
                    <p className="text-green-800 text-md font-bold ">Paid</p>
                  )}
                </div> */}
                {paymentData.applicationFees > 0 && (
                  <div>
                    {!isAddApplicationAmount ? (
                      <button
                        onClick={handleAddApplicationFees}
                        className="bg-blue-600 py-1 px-5 rounded-md"
                      >
                        <p className="text-center w-full text-sm font-bold text-white">
                          Add Application Fees
                        </p>
                      </button>
                    ) : (
                      <button
                        onClick={handleLessApplicationFees}
                        className="bg-blue-600 py-1 px-5 rounded-md"
                      >
                        <p className="text-center w-full text-sm font-bold text-white">
                          Less Application Fees
                        </p>
                      </button>
                    )}
                  </div>
                )}
                <div className="flex gap-4">
                  <div>
                    <p className="text-[17px] text-gray-500 font-medium whitespace-nowrap">
                      Payable amount:
                    </p>
                  </div>
                  <div>
                    <input
                      className="block w-[150px] px-4 py-1 border placeholder:text-gray-500  border-gray-300 rounded-lg outline-none"
                      type="number"
                      id="fees"
                      name="fees"
                      value={amountInput}
                      onChange={handleInputChange}
                      required
                      // readOnly
                    />
                    {message && (
                      <p className="text-red-600 text-xs my-1 capitalize">{`total amount is ${paymentData.outstandingAmount}`}</p>
                    )}
                  </div>
                </div>
              </>

              {/* {paymentData.status === "pending" ? ( */}
              <div className="mt-[30px] ">
                {inputBlank && (
                  <p className="text-red-600 text-xs text-center mb-2 capitalize">
                    field is blank or Amount is 0!
                  </p>
                )}

                <div className="flex justify-center items-center gap-x-[20px]">
                  <Link to={"/admin/payments"}>
                    <button className="bg-red-600 hover:bg-red-800 text-white font-bold py-1 px-2 rounded-lg w-[100px]">
                      close
                    </button>
                  </Link>
                  <button
                    onClick={handlePayButton}
                    className="bg-green-600 hover:bg-green-800 text-white font-bold py-1 px-2 rounded-lg w-[100px]"
                  >
                    Pay
                  </button>
                </div>
              </div>
            </div>
            {isInvoiceModal && (
              <div className="fixed z-10 inset-0 overflow-y-auto top-16 ">
                <div className="flex items-center justify-center h-full  px-4">
                  <div
                    className="fixed inset-0 bg-gray-200 bg-opacity-75 transition-opacity"
                    aria-hidden="true"
                  ></div>

                  <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:w-1/2 lg:w-1/3">
                    Modal content
                    <div className="p-4 overflow-y-auto">
                      <button
                        onClick={() => setIsInvoiceModal(false)}
                        className="float-right text-gray-500 hover:text-gray-800"
                      >
                        <CloseIcon />
                      </button>
                      Your modal content here
                    </div>
                    <PaymentInvoice
                      paymentReceipt={paymentData._id}
                      setIsInvoiceModal={setIsInvoiceModal}
                    />
                  </div>
                </div>
              </div>
            )}
            {isPaymentSuccess && (
              <PaymentSuccessModal
                // handleRefresh={handleRefresh}
                isOpen={isPaymentSuccess}
                onClose={closeModal}
                // setIsInvoiceModal={setIsInvoiceModal}
                paymentReceipt={paymentData._id}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettlePayment;
