import React, { useEffect, useState } from "react";
import StudentProfile from "../../../components/AdminComponents/Profiles/StudentProfile";
import Layout from "../../../components/AdminComponents/Layout/Layout";
import { useLocation } from "react-router-dom";
import { fetchSingleStudentAPI } from "../../../API/admin/AdminAPI";
import ComponentLoading from "../../CommonPreloader/ComponentLoading";

const StudePro = () => {
  const { state } = useLocation();
  const [studentData, setStudentDta] = useState({});
  const [commissionData, setCommissionData] = useState({});
  const [refreshComponent, setRefreshComponent] = useState(false);
  console.log(state);
  
  const [componentLoader, setComponentLoader] = useState(true);
  useEffect(() => {
    if (componentLoader) {
      // Set a delay of 3 seconds
      const timer = setTimeout(() => {
        setComponentLoader(false);
      }, 500);

      // Cleanup function to clear the timer if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [componentLoader]);

  const handleRefresh = () => {
    setRefreshComponent(true);
  };

  useEffect(() => {
    if (refreshComponent) {
      window.location.reload();
    }
  }, [refreshComponent]);

  useEffect(() => {
    const fetchSingleStudent = async () => {
      const response = await fetchSingleStudentAPI(state?.studentId);
      if (response.status === 200) {
        setStudentDta(response?.data?.student);
        setCommissionData(response?.data?.commission);
      } else {
        setStudentDta({});
      }
    };
    try {
      fetchSingleStudent();
    } catch (error) {
      console.log(error);
    }
  }, [state]);
  return (
    <Layout>
      {!componentLoader ? (
        <>
          {Object.keys(studentData).length !== 0 ? (
            <StudentProfile
              studentData={studentData}
              commission={commissionData}
              handleRefresh={handleRefresh}
              
            />
          ) : (
            <ComponentLoading />
          )}
        </>
      ) : (
        <ComponentLoading />
      )}
    </Layout>
  );
};

export default StudePro;
