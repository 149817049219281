import React from "react";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { recruitmentApi } from "../../../API/Agent/AgentAPI";
import Loader from "../../AgentComponents/Loader/Loader";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import ErrorIcon from "@mui/icons-material/Error";
import VerifiedIcon from "@mui/icons-material/Verified";

const StudentsRecruitment = ({
  applicationSteps,
  setApplicationSteps,
  formfilledPan,
  setformfilledPan,
}) => {
  const agentTokens = localStorage.getItem("agentToken");
  const [submitted, setSubmitted] = useState(false);
  const [isOpenPan, setIsOpenPan] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  const [formPanData, setFormPanData] = useState({
    agentTokens: agentTokens,
    proposedregionsthatyouwishtorecruitstudentstoAvisfrom: "",
    proposedregiontorepresentAvisforstudentrecruitment: "",
    proposednoofstudentstoberecruitedforAvisinthenext12months: "",
  });
  const [formPanErrors, setFormPanErrors] = useState({});
  const handlePanInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "proposedregionsthatyouwishtorecruitstudentstoAvisfrom") {
      // Split the value into an array for the specified input
      const arrayValue = value.split(",");

      // Update the form data
      setFormPanData((prevData) => ({
        ...prevData,
        [name]: arrayValue,
      }));
    } else if (name === "proposedregiontorepresentAvisforstudentrecruitment") {
      // Split the value into an array for the specified input
      const arrayValue = value.split(",");

      // Update the form data
      setFormPanData((prevData) => ({
        ...prevData,
        [name]: arrayValue,
      }));
    } else {
      // For other inputs, update the form data directly
      setFormPanData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handlePanSubmit = async (e) => {
    e.preventDefault();
    const errors = {};

    // Validation logic

    if (!formPanData.proposedregionsthatyouwishtorecruitstudentstoAvisfrom) {
      errors.proposedregionsthatyouwishtorecruitstudentstoAvisfrom =
        "This Field is required.";
    }
    if (!formPanData.proposedregiontorepresentAvisforstudentrecruitment) {
      errors.proposedregiontorepresentAvisforstudentrecruitment =
        "This Field is required.";
    }
    if (
      !formPanData.proposednoofstudentstoberecruitedforAvisinthenext12months
    ) {
      errors.proposednoofstudentstoberecruitedforAvisinthenext12months =
        "This Field is required.";
    }

    if (Object.keys(errors).length === 0) {
      try {
        setIsLoader(true);
        const response = await recruitmentApi(formPanData);

        if (response.status === 201) {
          setIsLoader(false);

          setSubmitted(true);

          console.log(response);
          setApplicationSteps((prevData) => ({
            ...prevData,
            isStudentRecruitment: true,
          }));
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert severity="success">
              This is a success alert — check it out!
            </Alert>
          </Stack>;
          // Set the submitted data in the state
          setformfilledPan({
            agentTokens: agentTokens,
            proposedRegionsToRecruitStudents:
              response.data.data
                .proposedregionsthatyouwishtorecruitstudentstoAvisfrom,
            proposedRegionsToRepresentStudents:
              response.data.data
                .proposedregiontorepresentAvisforstudentrecruitment,
            proposedNumberOfStudentsToBeRecruited:
              response.data.data
                .proposednoofstudentstoberecruitedforAvisinthenext12months,
          });
        } else {
          alert("Failed to submit form data.");
        }
      } catch (error) {
        console.error("API request failed:", error);
      } finally {
        setIsLoader(false);
      }
    } else {
      setFormPanErrors(errors);
      setIsLoader(false);
    }
  };
  const toggleAccordionPan = () => {
    setIsOpenPan(!isOpenPan);
  };
  return (
    <div>
      <div>
        {applicationSteps.isStudentRecruitment ? (
          <div className={`accordion ${isOpenPan ? "open" : ""}mb-6`}>
            <div
              className="accordion-header  w-[374px]  md:w-[814px] rounded-sm  shadow-lg"
              onClick={toggleAccordionPan}
            >
              <div className="flex justify-center items-center gap-x-4">
                {formfilledPan.isRejected ? (
                  <div className="flex  items-center gap-x-2 bg-red-500 px-1 py-1 rounded ">
                    <p className="text-white font-bold text-sm">STEP 3</p>
                  </div>
                ) : (
                  <div className="flex  items-center gap-x-2 bg-green-500 px-1 py-1 rounded ">
                    <p className="text-white font-bold text-sm">STEP 3</p>
                  </div>
                )}

                <div>
                  <p className="text-md font-semibold ">STUDENT RECRUITMENT</p>
                </div>
              </div>
              <div className="flex justify-end items-end">
                <i className="fa-solid fa-angle-up" />
                <span className="icon">
                  {isOpenPan ? <RemoveIcon /> : <AddIcon />}
                </span>
              </div>
            </div>
            {isOpenPan && (
              <form>
                <div className="accordion-content text-black">
                  <div className="panel w-full sm:w-full md:w-[814px]  flex justify-center sm:flex sm:justify-content  md:flex-row  md:justify-center md:item-center shadow-lg">
                    <div className="py-10">
                      <div className=" flex-col md:flex-row flex justify-between items-center gap-x-6">
                        <div className="mb-4 ">
                          <label
                            htmlFor="name"
                            className="block text-black  text-[14px] mb-2 leading-6"
                          >
                            Proposed regions that you wish to recruit <br></br>{" "}
                            students to Avis from
                          </label>
                          <input
                            type="text"
                            id="proposedregionsthatyouwishtorecruitstudentstoAvisfrom"
                            name="proposedregionsthatyouwishtorecruitstudentstoAvisfrom"
                            value={
                              formfilledPan?.proposedRegionsToRecruitStudents ||
                              ""
                            }
                            onChange={handlePanInputChange}
                            placeholder=" 
                           Avis student recruitment focus areas."
                            disabled={submitted && formfilledPan.isRejected}
                            className="border border-gray-300 rounded-md w-[350px] py-2 px-3 text-[13px] text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          {formPanErrors.proposedregionsthatyouwishtorecruitstudentstoAvisfrom && (
                            <p className="text-red-500 text-sm">
                              {
                                formPanErrors.proposedregionsthatyouwishtorecruitstudentstoAvisfrom
                              }
                            </p>
                          )}
                        </div>
                        <div className="mb-4">
                          <label
                            htmlFor="email"
                            className="block text-gray-700 text-sm mb-2 leading-6"
                          >
                            Proposed region to represent Avis for <br></br>{" "}
                            student recruitment
                          </label>
                          <input
                            type="text"
                            id="proposedregiontorepresentAvisforstudentrecruitment"
                            name="proposedregiontorepresentAvisforstudentrecruitment"
                            value={
                              formfilledPan?.proposedRegionsToRepresentStudents ||
                              ""
                            }
                            onChange={handlePanInputChange}
                            placeholder="
                           Target area for Avis student recruitment."
                            disabled={submitted && formfilledPan.isRejected}
                            className="border border-gray-300 rounded-md w-[350px] py-2 px-3 text-[13px] text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          {formPanErrors.proposedregiontorepresentAvisforstudentrecruitment && (
                            <p className="text-red-500 text-sm">
                              {
                                formPanErrors.proposedregiontorepresentAvisforstudentrecruitment
                              }
                            </p>
                          )}
                        </div>
                      </div>
                      <div className=" flex-col md:flex-row  flex justify-between gap-x-6 ">
                        <div className="mb-4">
                          <label
                            htmlFor="name"
                            className="block text-black  text-sm mb-2 leading-6"
                          >
                            Proposed no of students to be recruited for Avis{" "}
                            <br></br>in the next 12 months
                          </label>
                          <input
                            type="number"
                            id="proposednoofstudentstoberecruitedforAvisinthenext12months"
                            name="proposednoofstudentstoberecruitedforAvisinthenext12months"
                            value={
                              formfilledPan?.proposedNumberOfStudentsToBeRecruited ||
                              ""
                            }
                            onChange={handlePanInputChange}
                            disabled={submitted && formfilledPan.isRejected}
                            placeholder="
                           Avis student recruitment goal next 12 months."
                            className="border border-gray-300 rounded-md w-[350px] py-2 text-[13px]  px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          {formPanErrors.proposednoofstudentstoberecruitedforAvisinthenext12months && (
                            <p className="text-red-500 text-sm w-80">
                              {
                                formPanErrors.proposednoofstudentstoberecruitedforAvisinthenext12months
                              }
                            </p>
                          )}
                        </div>
                      </div>
                      {formfilledPan.isRejected ? (
                        <div className="flex flex-end justify-end pb-10">
                          <button
                            type="submit"
                            className="text-sm sm:text-md border sm:w-full md:w-[250px] text-red-600 border-red-500 font-bold py-2 px-10 rounded focus:outline-none focus:shadow-outline shadow-lg "
                            onClick={handlePanSubmit}
                            disabled={isLoader}
                          >
                            {isLoader ? <Loader /> : "Submit Application"}
                          </button>
                        </div>
                      ) : null}
                      <p>
                        {formfilledPan.isRejected ? (
                          <div className="bg-red-100 rounded-md ">
                            <div>
                              <p className="text-lg font-semibold text-red-600 px-8 pt-1 ">
                                Error
                              </p>
                            </div>
                            <div className=" flex items-center pb-2  px-6">
                              <div className="text-red-600">
                                <ErrorIcon />
                              </div>
                              <div>
                                <p className="text-[13px] text-red-500 font-semibold px-2">
                                  Your Applicant Information Rejected By Admin
                                </p>
                                <p className="text-[13px] text-red-500 font-semibold px-2">
                                  Admin Message:
                                </p>
                              </div>
                            </div>
                            <div className="h-1 rounded-lg bg-red-400"></div>
                          </div>
                        ) : null}
                      </p>
                      <p>
                        {formfilledPan.isApproved ? (
                          <div className="bg-green-100 rounded-md ">
                            <div>
                              <p className="text-lg font-semibold text-green-600 px-8 pt-1 ">
                                Error
                              </p>
                            </div>
                            <div className=" flex items-center pb-2  px-6">
                              <div className="text-green-600">
                                <ErrorIcon />
                              </div>
                              <div>
                                <p className="text-[13px] text-green-500 font-semibold px-2">
                                  Your Applicant Information Approved By Admin
                                </p>
                                <p className="text-[13px] text-green-500 font-semibold px-2">
                                  Admin Message:
                                </p>
                              </div>
                            </div>
                            <div className="h-1 rounded-lg bg-green-400"></div>
                          </div>
                        ) : null}
                      </p>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </div>
        ) : (
          <div className={`accordion ${isOpenPan ? "open" : ""}mb-6`}>
            <div
              className="accordion-header  w-[374px]  md:w-[814px] rounded-sm  shadow-lg"
              onClick={toggleAccordionPan}
            >
              <div className="flex justify-center items-center gap-x-4">
                <div className="    flex  items-center gap-x-2 bg-red-500 px-1 py-1 rounded ">
                  <p className="text-white font-bold text-sm">STEP 3</p>
                </div>
                <div>
                  <p className="text-md font-semibold ">STUDENT RECRUITMENT</p>
                </div>
              </div>
              <div className="flex justify-end items-end">
                <i className="fa-solid fa-angle-up" />
                <span className="icon">
                  {isOpenPan ? <RemoveIcon /> : <AddIcon />}
                </span>
              </div>
            </div>
            {isOpenPan && (
              <form>
                <div className="accordion-content text-black">
                  <div className="panel w-full sm:w-full md:w-[814px]  flex justify-center sm:flex sm:justify-content  md:flex-row  md:justify-center md:item-center shadow-lg">
                    <div className="py-10  ">
                      <div className=" flex-col md:flex-row flex justify-between items-center gap-x-6">
                        <div className="mb-4 ">
                          <label
                            htmlFor="name"
                            className="block text-black  text-sm mb-2 leading-6"
                          >
                            Proposed regions that you wish to recruit <br></br>{" "}
                            students to Avis from
                          </label>
                          <input
                            type="text"
                            id="proposedregionsthatyouwishtorecruitstudentstoAvisfrom"
                            name="proposedregionsthatyouwishtorecruitstudentstoAvisfrom"
                            value={
                              formPanData.proposedregionsthatyouwishtorecruitstudentstoAvisfrom
                            }
                            onChange={handlePanInputChange}
                            placeholder=" 
                           Avis student recruitment focus areas."
                            // disabled={submitted}
                            className="border border-gray-300 rounded-md w-[350px] py-2 px-3 text-[13px] text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          {formPanErrors.proposedregionsthatyouwishtorecruitstudentstoAvisfrom && (
                            <p className="text-red-500 text-sm">
                              {
                                formPanErrors.proposedregionsthatyouwishtorecruitstudentstoAvisfrom
                              }
                            </p>
                          )}
                        </div>
                        <div className="mb-4">
                          <label
                            htmlFor="email"
                            className="block text-gray-700 text-sm mb-2 leading-6"
                          >
                            Proposed region to represent Avis for <br></br>{" "}
                            student recruitment
                          </label>
                          <input
                            type="text"
                            id="proposedregiontorepresentAvisforstudentrecruitment"
                            name="proposedregiontorepresentAvisforstudentrecruitment"
                            value={
                              formPanData.proposedregiontorepresentAvisforstudentrecruitment
                            }
                            onChange={handlePanInputChange}
                            placeholder="
                           Target area for Avis student recruitment."
                            // disabled={submitted}
                            className="border border-gray-300 rounded-md w-[350px] py-2 px-3 text-[13px]  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          {formPanErrors.proposedregiontorepresentAvisforstudentrecruitment && (
                            <p className="text-red-500 text-sm">
                              {
                                formPanErrors.proposedregiontorepresentAvisforstudentrecruitment
                              }
                            </p>
                          )}
                        </div>
                      </div>
                      <div className=" flex-col md:flex-row  flex justify-between gap-x-6 ">
                        <div className="mb-4">
                          <label
                            htmlFor="name"
                            className="block text-black  text-sm mb-2 leading-6"
                          >
                            Proposed no of students to be recruited for Avis{" "}
                            <br></br>in the next 12 months
                          </label>
                          <input
                            type="number"
                            id="proposednoofstudentstoberecruitedforAvisinthenext12months"
                            name="proposednoofstudentstoberecruitedforAvisinthenext12months"
                            value={
                              formPanData.proposednoofstudentstoberecruitedforAvisinthenext12months
                            }
                            onChange={handlePanInputChange}
                            // disabled={submitted}
                            placeholder="
                           Avis student recruitment goal next 12 months."
                            className="border border-gray-300 rounded-md w-[350px] py-2 text-[13px]  px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          {formPanErrors.proposednoofstudentstoberecruitedforAvisinthenext12months && (
                            <p className="text-red-500 text-sm w-80">
                              {
                                formPanErrors.proposednoofstudentstoberecruitedforAvisinthenext12months
                              }
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="mb-4 flex justify-center sm:justify-center md:justify-end md:mr-10">
                        <div>
                          {submitted ? (
                            // Display a message or any other component after submission
                            <button
                              type="submit"
                              className="text-sm sm:text-md border sm:w-full md:w-[250px] text-red-600 border-red-500 font-bold py-2 px-10 rounded focus:outline-none focus:shadow-outline shadow-lg hidden"
                              onClick={handlePanSubmit}
                              disabled={isLoader}
                            >
                              {isLoader ? <Loader /> : "Submit Application"}
                            </button>
                          ) : (
                            // Render the submit button only if the form hasn't been submitted
                            <button
                              type="submit"
                              className="text-sm sm:text-md border sm:w-full md:w-[250px] text-red-600 border-red-500 font-bold py-2 px-10 rounded focus:outline-none focus:shadow-outline shadow-lg"
                              onClick={handlePanSubmit}
                              disabled={isLoader}
                            >
                              {isLoader ? <Loader /> : "Submit Application"}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default StudentsRecruitment;
