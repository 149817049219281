import React, { useState } from 'react';
import Swal from 'sweetalert2';
const CourseDetailsCompo = () => {
  const [lessons, setLessons] = useState([
    { id: 1, number: '1', name: 'Introduction to React', description: 'Learn the basics of React' },
    { id: 2, number: '2', name: 'State and Props', description: 'Understand state and props in React' },
    { id: 3, number: '3', name: 'Hooks', description: 'Explore React hooks' },
  ]);
  const [newLesson, setNewLesson] = useState({
    number: '',
    name: '',
    description: '',
  });
  const [editingLesson, setEditingLesson] = useState(null); // State to hold the lesson being edited
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editingLesson) {
      // If editing, update editingLesson state
      setEditingLesson({
        ...editingLesson,
        [name]: value,
      });
    } else {
      // If adding new lesson, update newLesson state
      setNewLesson({
        ...newLesson,
        [name]: value,
      });
    }
  };
  const handleAddLesson = () => {
    if (newLesson.number.trim() === '' || newLesson.name.trim() === '' || newLesson.description.trim() === '') {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please fill out all fields!',
      });
      return;
    }
    const id = lessons.length + 1;
    const lessonToAdd = {
      id,
      number: newLesson.number,
      name: newLesson.name,
      description: newLesson.description,
    };
    setLessons([...lessons, lessonToAdd]);
    // Reset the input fields after adding
    setNewLesson({
      number: '',
      name: '',
      description: '',
    });
  };
  const handleDelete = (lesson) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this lesson!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#000',
      cancelButtonColor: '#000',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        const updatedLessons = lessons.filter(l => l.id !== lesson.id);
        setLessons(updatedLessons);
        Swal.fire(
          'Deleted!',
          'Your lesson has been deleted.',
          'success'
        );
      }
    });
  };
  const handleEdit = (lesson) => {
    setEditingLesson(lesson); // Set the lesson to edit
  };
  const handleUpdateLesson = () => {
    const updatedLesson = {
      id: editingLesson.id,
      number: editingLesson.number,
      name: editingLesson.name,
      description: editingLesson.description,
    };
    const updatedLessons = lessons.map((lesson) =>
      lesson.id === editingLesson.id ? updatedLesson : lesson
    );
    setLessons(updatedLessons);
    setEditingLesson(null); // Clear editing state after update
    // No need to reset newLesson state here
  };
  return (
    <div className="container mx-auto px-4 py-8">
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-md overflow-hidden">
          <thead className="bg-gray-200 text-xs text-gray-600 uppercase">
            <tr>
              <th className="py-3 px-6 text-left">Number</th>
              <th className="py-3 px-6 text-left">Name</th>
              <th className="py-3 px-6 text-left">Description</th>
              <th className="py-3 px-6 text-left">Actions</th>
            </tr>
          </thead>
          <tbody className="text-sm text-gray-800 divide-y divide-gray-200 font-custom">
            {lessons.map((lesson) => (
              <React.Fragment key={lesson.id}>
                {editingLesson && editingLesson.id === lesson.id ? ( // If editing this lesson
                  <tr className="bg-gray-200">
                    <td className="py-4 px-6">
                      <input type="text" name="number" value={editingLesson.number} onChange={handleInputChange} className="w-full py-2 px-3 border border-gray-300 rounded-lg focus:outline-none focus:border-gray-500" placeholder="Number" />
                    </td>
                    <td className="py-4 px-6">
                      <input type="text" name="name" value={editingLesson.name} onChange={handleInputChange} className="w-full py-2 px-3 border border-gray-300 rounded-lg focus:outline-none focus:border-gray-500" placeholder="Name" />
                    </td>
                    <td className="py-4 px-6">
                      <input type="text" name="description" value={editingLesson.description} onChange={handleInputChange} className="w-full py-2 px-3 border border-gray-300 rounded-lg focus:outline-none focus:border-gray-500" placeholder="Description" />
                    </td>
                    <td className="py-4 px-6">
                      <button onClick={handleUpdateLesson} className="text-xs py-1 px-2 bg-gray-200 hover:bg-gray-300 rounded-lg shadow-md">
                        Save
                      </button>
                    </td>
                  </tr>
                ) : ( // If not editing, display regular row
                  <tr className={`${lesson.id % 2 === 0 ? 'bg-white' : 'bg-gray-50'} hover:bg-gray-100 shadow-md`}>
                    <td className="py-4 px-6">{lesson.number}</td>
                    <td className="py-4 px-6">{lesson.name}</td>
                    <td className="py-4 px-6">{lesson.description}</td>
                    <td className="py-2 px-4 sm:py-1 sm:px-2 flex space-x-2">
                      <button className="text-xs py-1 px-2 bg-gray-200 hover:bg-gray-300 rounded-lg shadow-md" onClick={() => handleEdit(lesson)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit">
                          <path d="M12 20h9"></path>
                          <path d="M16 5L3 18 5 20 18 7z"></path>
                        </svg>
                      </button>
                      <button onClick={() => handleDelete(lesson)} className="text-xs py-1 px-2 bg-gray-200 hover:bg-gray-300 rounded-lg shadow-md">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2">
                          <polyline points="3 6 5 6 21 6"></polyline>
                          <path d="M16 6l-1 14a2 2 0 0 1-2 2H11a2 2 0 0 1-2-2L8 6"></path>
                          <path d="M2 6h20"></path>
                        </svg>
                      </button>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
            {/* Add row for new lessons */}
            <tr className="bg-gray-200">
              <td className="py-4 px-6">
                <input type="text" name="number" value={newLesson.number} onChange={handleInputChange} className="w-full py-2 px-3 border border-gray-300 rounded-lg focus:outline-none focus:border-gray-500" placeholder="Number" />
              </td>
              <td className="py-4 px-6">
                <input type="text" name="name" value={newLesson.name} onChange={handleInputChange} className="w-full py-2 px-3 border border-gray-300 rounded-lg focus:outline-none focus:border-gray-500" placeholder="Name" />
              </td>
              <td className="py-4 px-6">
                <input type="text" name="description" value={newLesson.description} onChange={handleInputChange} className="w-full py-2 px-3 border border-gray-300 rounded-lg focus:outline-none focus:border-gray-500" placeholder="Description" />
              </td>
              <td className="py-4 px-6">
                <button onClick={handleAddLesson} className="text-xs py-1 px-2 bg-gray-200 hover:bg-gray-300 rounded-lg shadow-md">
                  Add
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default CourseDetailsCompo;