import React from "react";
// import SettingsIcon from "@mui/icons-material/Settings";
import ViewListIcon from "@mui/icons-material/ViewList";
import { Link, useLocation } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// import SettingsList from "../../AdminComponents/NavBar/SettingsList";

const AgentNavBar = ({ navButton, setNavButton }) => {
  const location = useLocation();
  const pathname = location.pathname;
  const parts = pathname.split("/");
  // const [isListOpen, setIsListOpen] = useState(false);
  // const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  // const handleSettingsOpen = () => {
  //   setIsListOpen(false);
  //   setIsSettingsOpen(!isSettingsOpen);
  // };
  return (
    <div className="relative">
      <div className="ml-auto fixed w-full md:w-10/12  bg-white z-40">
        <div className="flex justify-between shadow-md shadow-gray-200 py-5 px-5 mt-3">
          <div>
            <div className="flex gap-3 ">
              <div className="md:hidden hover:text-red-600 transition">
                <span onClick={() => setNavButton(!navButton)} className=" ">
                  <ViewListIcon sx={{ fontSize: 35 }} />
                </span>
              </div>
              <div>
                <h4 className="font-bold text-xl sm:text-2xl md:text-3xl capitalize">
                  {pathname === "/payment"
                    ? "payment"
                    : decodeURIComponent(parts[parts.length - 1])}
                </h4>
                <div className="flex gap-1 mt-1">
                  {parts.map(
                    (elem, i) =>
                      i !== 0 && (
                        <Link
                          to={
                            i === 1
                              ? "/agent/dashboard"
                              : i === parts.length - 1
                              ? "#"
                              : `/agent/${elem}`
                          }
                          key={i + 1}
                          className={
                            parts.length - 1 === i
                              ? "text-red-600 font-light text-xs whitespace-nowrap hover:underline capitalize"
                              : "text-black font-light text-xs whitespace-nowrap hover:underline capitalize"
                          }
                        >
                          {decodeURIComponent(elem).split(" ")[0]}
                          {parts.length - 1 !== i && (
                            <span>
                              <ArrowForwardIosIcon sx={{ fontSize: 12 }} />
                            </span>
                          )}
                        </Link>
                      )
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="hidden md:block"> */}
          <div className="flex gap-x-[13px] md:gap-x-[30px]">
            {/* <div
              className="text-[20px]  md:text-[27px]"
              onClick={handleSettingsOpen}
            >
              {!isSettingsOpen ? (
                <SettingsIcon sx={{ fontSize: 25 }} />
              ) : (
                <CloseIcon className="text-red-500" sx={{ fontSize: 30 }} />
              )}
            </div> */}
            {/* <div
              className="text-[20px]  md:text-[27px]"
              onClick={() => {
                setIsSettingsOpen(false);
                setIsListOpen(!isListOpen);
              }}
            >
              {!isListOpen ? (
                <MenuIcon sx={{ fontSize: 30 }} />
              ) : (
                <CloseIcon className="text-red-500" sx={{ fontSize: 30 }} />
              )}
            </div> */}
            {/* <div className="text-[20px] md:text-[27px] ">
              <NotificationsActiveIcon />
            </div>
            <div className="text-[20px] md:text-[27px] ">
              <SearchIcon />
            </div> */}
          </div>
          {/* </div> */}
        </div>
      </div>
      {/* {isListOpen && (
        <div className="absolute">
          <AgentSideList />
        </div>
      )}
      {isSettingsOpen && (
        <div className="absolute">
          <AgentSettingsList />
        </div>
      )} */}
    </div>
  );
};

export default AgentNavBar;
