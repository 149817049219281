import { API } from "../axios";

// Function to send login data to the backend
export const LoginAPI = async (loginData) => {
  try {
    const { data, status } = await API.post("/admin/auth/login", loginData);
    return { data, status };
  } catch (error) {
    return error?.response;
  }
};

// check jwt token not expired
export const checkTokenExpires = async (token) => {
  try {
    // Set the token in the headers of the Axios request
    API.defaults.headers.common["Authorization"] = `Bearer ${token}`;

    // Make the request to your backend API
    const { data, status } = await API.get(
      "/admin/auth/check-token-expiration"
    );

    // Handle the response data
    return { data, status }; 
  } catch (error) {
    return error?.response;
  }
};
