import { createSlice } from "@reduxjs/toolkit";

const adminData = createSlice({
  name: "admin_data",
  initialState: {
    adminData: "",
  },
  reducers: {
    addAdminData(state, actions) {
      const adminData = actions.payload;
      state.adminData = adminData;
    },
    removeAdminData(state, actions) {
      state.adminData = "";
    },
  },
});

export const { addAdminData, removeAdminData ,editAdminvValue} = adminData.actions;
export default adminData;
