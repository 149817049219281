import React, { useEffect, useState } from "react";
import Layout from "../../../components/AdminComponents/Layout/Layout";
import SettlePayment from "../../../components/AdminComponents/PaymentsTable/SettlePayment";
import { useLocation } from "react-router-dom";
import { fetchSinglePaymentAPI } from "../../../API/admin/PaymentsAPI";
import ComponentLoading from "../../CommonPreloader/ComponentLoading";

const SettlePayPage = () => {
  const { state } = useLocation();
  const [paymentData, setPaymentData] = useState({});
  const [refreshKey, setRefreshKey] = useState(0);

  const [componentLoader, setComponentLoader] = useState(true);
  useEffect(() => {
    if (componentLoader) {
      // Set a delay of 3 seconds
      const timer = setTimeout(() => {
        setComponentLoader(false);
      }, 2000);

      // Cleanup function to clear the timer if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [componentLoader]);

  const handleRefresh = () => {
    // Increment the refreshKey value to trigger component refresh
    setRefreshKey((prevKey) => prevKey + 1);
  };
  useEffect(() => {
    const fetchPayment = async () => {
      try {
        const response = await fetchSinglePaymentAPI(state.paymentId);
        if (response.status === 200) {
          setPaymentData(response.data);
        } else {
          setPaymentData({});
        }
      } catch (error) {
        setPaymentData({});
      }
    };
    fetchPayment();
  }, [state, refreshKey]);
  return (
    <Layout>
      {!componentLoader ? (
        <>
          {Object.keys(paymentData).length > 0 ? (
            <SettlePayment
              paymentData={paymentData}
              // key={refreshKey}
              handleRefresh={handleRefresh}
            />
          ) : (
            <p>No payment</p>
          )}
        </>
      ) : (
        <ComponentLoading />
      )}
    </Layout>
  );
};

export default SettlePayPage;
