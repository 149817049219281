import React from "react";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { documentsCompanyApi } from "../../../API/Agent/AgentAPI";
import { documentsIndividualApi } from "../../../API/Agent/AgentAPI";
import Loader from "../../AgentComponents/Loader/Loader";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import EsighServices from "../EsighServices/EsighServices";
const SupportingDocuments = ({
  setApplicationSteps,
  applicationSteps,
  formfilledDocument,
  setFormfilledDocument,
  setFormIndividualDocuments,
  formIndividualDocuments,
  finalApproval,
  setFinalApproval,
}) => {
  const [isOpenDocuments, setIsOpenDocuments] = useState(false);
  const agentTokens = localStorage.getItem("agentToken");
  const [isLoader, setIsLoader] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submittedDocuments, setSubmittedDocuments] = useState(false);
  const [formDataIndividual, setformDataIndividual] = useState({
    agentTokens: agentTokens,
    tradeLicenseOrNationalId: "",
    CV: "",
    photoOfOfficePremisesExternalIndividual: "",
    photoOfOfficePremisesInteriorIndividual: "",
    copyOfMarketingCollateralsifanyIndividual: "",
    marketingPlanForAvisIndividual: "",
  });
  const [formData, setFormData] = useState({
    agentTokens: agentTokens,
    companyRegistrationCertificate: "",
    companyProfile: "",
    photoOfOfficePremisesExternal: "",
    photoOfOfficePremisesInterior: "",
    copyOfMarketingCollateralsifany: "",
    marketingPlanForAvis: "",
  });
  const [selectedFileNames, setSelectedFileNames] = useState({
    companyRegistrationCertificate: "",
    companyProfile: "",
    photoOfOfficePremisesInterior: "",
    photoOfOfficePremisesExternal: "",
    copyOfMarketingCollateralsifany: "",
    marketingPlanForAvis: "",
    tradeLicenseOrNationalId: "",
    CV: "",
    photoOfOfficePremisesInteriorIndividual: "",
    copyOfMarketingCollateralsifanyIndividual: "",
    marketingPlanForAvisIndividual: "",
    photoOfOfficePremisesExternalIndividual: "",
  });
  const [formErrors, setFormErrors] = useState({});

  const toggleAccordionDocuments = () => {
    setIsOpenDocuments(!isOpenDocuments);
  };
  const handleFileChangeIndividual = (event, inputName) => {
    const { name, files } = event.target;
    if (!submittedDocuments) {
      setformDataIndividual((prevState) => ({
        ...prevState,
        [name]: files[0],
      }));
    }
    const fileName = files[0] ? files[0].name : "";

    setSelectedFileNames((prevState) => ({
      ...prevState,
      [inputName]: fileName,
    }));

    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  };

  const handleFileChange = (event, inputName) => {
    const { name, files } = event.target;
    if (!submitted) {
      setFormData((prevState) => ({
        ...prevState,
        [name]: files[0],
      }));
    }
    const fileName = files[0] ? files[0].name : "";

    setSelectedFileNames((prevState) => ({
      ...prevState,
      [inputName]: fileName,
    }));
    const imageElement = document.getElementById(`selected-image-${inputName}`);
    if (imageElement) {
      imageElement.src = URL.createObjectURL(files[0]);
    }

    setFormErrors({
      ...formErrors,
      [name]: "",
    });

    // Get the selected image element
    // const selectedImage = document.getElementById("selected-image");

    // if (files[0]) {
    //   const reader = new FileReader();

    //   reader.onload = function (e) {
    //     // Set the src attribute of the <img> element to the selected image's data URL
    //     selectedImage.src = e.target.result;

    //     // Make the <img> element visible
    //     selectedImage.classList.remove("hidden");
    //   };

    //   // Read the selected file as a data URL
    //   reader.readAsDataURL(files[0]);
    // }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = {};

    if (!formData.companyRegistrationCertificate) {
      errors.companyRegistrationCertificate = "This Field  is required";
    }
    if (!formData.companyProfile) {
      errors.companyProfile = "This Field  is required";
    }
    if (!formData.photoOfOfficePremisesExternal) {
      errors.photoOfOfficePremisesExternal = "This Field  is required";
    }
    if (!formData.photoOfOfficePremisesInterior) {
      errors.photoOfOfficePremisesInterior = "This Field  is required";
    }
    if (!formData.copyOfMarketingCollateralsifany) {
      errors.copyOfMarketingCollateralsifany = "This Field  is required";
    }
    if (!formData.marketingPlanForAvis) {
      errors.marketingPlanForAvis = "This Field  is required";
    }

    if (Object.keys(errors).length === 0) {
      try {
        setIsLoader(true);

        console.log(formData, "formData");
        const formDataToSend = new FormData();
        formDataToSend.append("agentTokens", formData.agentTokens);
        console.log(
          "companyRegistrationCertificate:",
          formData.companyRegistrationCertificate
        );
        formDataToSend.append(
          "companyRegistrationCertificate",
          formData.companyRegistrationCertificate
        );
        formDataToSend.append("CompanyProfile", formData.companyProfile);
        formDataToSend.append(
          "photoOfOfficePremisesExternal",
          formData.photoOfOfficePremisesExternal
        );
        formDataToSend.append(
          " photoOfOfficePremisesInterior",
          formData.photoOfOfficePremisesInterior
        );
        formDataToSend.append(
          "copyOfMarketingCollateralsifany",
          formData.copyOfMarketingCollateralsifany
        );
        formDataToSend.append(
          "marketingPlanForAvis",
          formData.marketingPlanForAvis
        );

        try {
          console.log(formDataIndividual, "formDataToSend");
          const response = await documentsCompanyApi(formData);
          setIsLoader(false);
          console.log(response, "response");
          if (response.status === 201) {
            console.log(response);
            setSubmitted(true);

            // alert("Form data submitted successfully!");
            setSubmitted(true);

            setFormfilledDocument({
              agentTokens: agentTokens,
              PhotoOfOfficePremisesExternalView:
                response.data.data.PhotoOfOfficePremisesExternalView,
              PhotoOfOfficePremisesInternalView:
                response.data.data.PhotoOfOfficePremisesInternalView,
              companyProfile: response.data.data.companyProfile,
              companyRegistrationCertificate:
                response.data.data.companyRegistrationCertificate,
              marketingCollateralsCopy:
                response.data.data.marketingCollateralsCopy,
              marketingPlanForAvisIndividual:
                response.data.data.marketingPlanForAvisIndividual,
            });
            setSelectedFileNames({
              companyRegistrationCertificate:
                response.data.data.companyRegistrationCertificate || "",
              companyProfile: response.data.data.companyProfile || "",
              photoOfOfficePremisesExternal:
                response.data.data.PhotoOfOfficePremisesExternalView || "",
              photoOfOfficePremisesInterior:
                response.data.data.PhotoOfOfficePremisesInternalView || "",
              copyOfMarketingCollateralsifany:
                response.data.data.marketingPlanForAvis || "",
              marketingPlanForAvis:
                response.data.data.marketingPlanForAvisIndividual || "",
            });
          } else {
            alert("Failed to submit form data.");
          }
        } catch (error) {
          console.error("API request failed:", error);
        }
      } catch (error) {
        console.error("API request failed:", error);
      } finally {
        setIsLoader(false);
      }
    } else {
      setFormErrors(errors);
      setIsLoader(false);
    }
  };
  const handleDocSubmit = async (e) => {
    e.preventDefault();
    const errors = {};

    if (!formDataIndividual.tradeLicenseOrNationalId) {
      errors.tradeLicenseOrNationalId = "This Field  is required";
    }
    if (!formDataIndividual.CV) {
      errors.CV = "This Field  is required";
    }
    if (!formDataIndividual.photoOfOfficePremisesExternalIndividual) {
      errors.photoOfOfficePremisesExternalIndividual =
        "This Field  is required";
    }
    if (!formDataIndividual.photoOfOfficePremisesInteriorIndividual) {
      errors.photoOfOfficePremisesInteriorIndividual =
        "This Field  is required";
    }
    if (!formDataIndividual.copyOfMarketingCollateralsifanyIndividual) {
      errors.copyOfMarketingCollateralsifanyIndividual =
        "This Field  is required";
    }
    if (!formDataIndividual.marketingPlanForAvisIndividual) {
      errors.marketingPlanForAvisIndividual = "This Field  is required";
    }

    if (Object.keys(errors).length === 0) {
      try {
        setIsLoader(true);

        const formDataToSend = new FormData();
        formDataToSend.append("agentTokens", formDataIndividual.agentTokens);
        formDataToSend.append(
          "tradeLicenseOrNationalId",
          formDataIndividual.tradeLicenseOrNationalId
        );
        formDataToSend.append("CV", formDataIndividual.CV);
        formDataToSend.append(
          "photoOfOfficePremisesExternalIndividual",
          formDataIndividual.photoOfOfficePremisesExternalIndividual
        );
        formDataToSend.append(
          "photoOfOfficePremisesInteriorIndividual",
          formDataIndividual.photoOfOfficePremisesInteriorIndividual
        );
        formDataToSend.append(
          "copyOfMarketingCollateralsifanyIndividual",
          formDataIndividual.copyOfMarketingCollateralsifanyIndividual
        );
        formDataToSend.append(
          "marketingPlanForAvisIndividual",
          formDataIndividual.marketingPlanForAvisIndividual
        );

        const response = await documentsIndividualApi(formDataToSend);
        setIsLoader(false);

        if (response.status === 201) {
          setSubmittedDocuments(true);
          // Display a success message
          // Note: The following code won't render an alert. You may need to show the alert in your component.
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert severity="success">
              This is a success alert — check it out!
            </Alert>
          </Stack>;
          setFormIndividualDocuments({
            agentTokens: agentTokens,
            CV: response.data.data.CV,
            copyOfMarketingCollateralsifanyIndividual:
              response.data.data.copyOfMarketingCollateralsifanyIndividual,
            marketingPlanForAvisIndividual:
              response.data.data.marketingPlanForAvisIndividual,
            photoOfOfficePremisesExternalIndividual:
              response.data.data.photoOfOfficePremisesExternalIndividual,
            photoOfOfficePremisesInteriorIndividual:
              response.data.data.photoOfOfficePremisesInteriorIndividual,
            tradeLicenseOrNationalID:
              response.data.data.tradeLicenseOrNationalID,
          });
          setSelectedFileNames({
            CV: response.data.data.CV || "",
            copyOfMarketingCollateralsifanyIndividual:
              response.data.data.copyOfMarketingCollateralsifanyIndividual ||
              "",
            marketingPlanForAvisIndividual:
              response.data.data.marketingPlanForAvisIndividual || "",
            photoOfOfficePremisesExternalIndividual:
              response.data.data.photoOfOfficePremisesExternalIndividual || "",
            photoOfOfficePremisesInteriorIndividual:
              response.data.data.photoOfOfficePremisesInteriorIndividual || "",
            tradeLicenseOrNationalId:
              response.data.data.tradeLicenseOrNationalID || "",
          });
          setApplicationSteps((prevData) => ({
            ...prevData,
            isSupportingDocuments: true,
          }));
        } else {
          alert("Failed to submit form data.");
        }
      } catch (error) {
        console.error("API request failed:", error);
      } finally {
        setIsLoader(false);
      }
    } else {
      setFormErrors(errors);
      setIsLoader(false);
    }
  };

  return (
    <div>
      {applicationSteps.isSupportingDocumentsTwo ? (
        <div>
          <div className={`accordion ${isOpenDocuments ? "open" : ""} mb-6`}>
            <div
              className="accordion-header w-[374px] md:w-[814px] rounded-sm shadow-lg"
              onClick={toggleAccordionDocuments}
            >
              <div className="flex justify-center items-center gap-x-4">
                <div className="flex items-center gap-x-2 bg-green-500 px-1 py-1 rounded">
                  <p className="text-white font-bold text-sm">STEP 5</p>
                </div>
                <div>
                  <p className="text-md font-semibold">SUPPORTING DOCUMENTS</p>
                </div>
              </div>
              <div className="flex justify-end items-end">
                <i className="fa-solid fa-angle-up" />
                <span className="icon">
                  {isOpenDocuments ? <RemoveIcon /> : <AddIcon />}
                </span>
              </div>
            </div>
            {isOpenDocuments && (
              <form>
                <div className="accordion-content text-black">
                  <div className="panel w-full sm:w-full md:w-[814px] flex-col  flex justify-center sm:flex sm:justify-content  md:flex-col  md:justify-center md:item-center shadow-lg px-6">
                    <div className="py-10">
                      <p className="pb-10 text-md opacity-70 font-semibold px-4">
                        FOR APPLICATION AS COMPANY
                      </p>
                      <div className="flex-col md:flex-row flex justify-between items-center">
                        <div className="mb-4" id="voter-section">
                          <label
                            htmlFor="CompanyRegistrationCertificate"
                            className="block text-black text-sm mb-2"
                          >
                            Company Registration Certificate
                            <div className="relative border-dashed border border-gray-400 rounded-md overflow-hidden w-[350px] flex justify-center">
                              <input
                                type="file"
                                id="companyRegistrationCertificate"
                                disabled={submitted}
                                name="companyRegistrationCertificate"
                                className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                onChange={(e) =>
                                  handleFileChange(
                                    e,
                                    "companyRegistrationCertificate"
                                  )
                                }
                              />
                              <div className="flex flex-col  items-center gap-x-2 py-2 px-3 bg-white">
                                <div className="text-red-600 pt-4 pb-4 flex justify-center items-center text-5xl  font-semibold">
                                  <InsertDriveFileIcon />
                                </div>
                                {formfilledDocument.companyRegistrationCertificate ? (
                                  <div className="text-sm text-gray-500">
                                    <input
                                      type="text"
                                      disabled={submitted}
                                      value={
                                        formfilledDocument?.companyRegistrationCertificate ||
                                        ""
                                      }
                                      readOnly
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                      <span className="font-semibold">
                                        Click to upload Logo
                                      </span>{" "}
                                      or drag and drop
                                    </p>
                                    <p className="text-xs text-gray-500 dark:text-gray-400">
                                      SVG, PNG, JPG or GIF (MAX. 800x400px)
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <img
                              id="selected-image-companyRegistrationCertificate"
                              src=""
                              alt="selectedImagecompanyRegistrationCertificate"
                              className=" hidden h-24"
                            />
                          </label>
                          <div className="text-red-500 text-sm">
                            {formErrors.companyRegistrationCertificate}
                          </div>
                          <div>
                            {formfilledDocument?.companyRegistrationCertificate
                              ?.isRejected ? (
                              <div className="flex gap-2 items-center justify-center">
                                <div>
                                  <p className="text-[13px] text-red-500">
                                    Error Please Reupload This Field
                                  </p>
                                </div>
                                <div>
                                  {" "}
                                  <button
                                    type="submit"
                                    className="text-sm sm:text-md border sm:w-full md:w-[100px] text-red-600 border-red-500 font-semibold py-1 px-6 rounded focus:outline-none focus:shadow-outline shadow-lg"
                                    onClick={handleSubmit}
                                    disabled={isLoader}
                                  >
                                    {isLoader ? <Loader /> : "Submit"}
                                  </button>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="mb-4" id="voter-section">
                          <label
                            htmlFor="CompanyProfile"
                            className="block text-black text-sm mb-2"
                          >
                            Company Profile
                            <div className="relative border-dashed border border-gray-400 rounded-md overflow-hidden w-[350px] flex justify-center">
                              <input
                                type="file"
                                id="companyProfile"
                                name="companyProfile"
                                disabled={submitted}
                                className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                onChange={(e) =>
                                  handleFileChange(e, "companyProfile")
                                }
                              />
                              <div className="flex flex-col  items-center gap-x-2 py-2 px-3 bg-white">
                                <div className="text-red-600 pt-4 pb-4 flex justify-center items-center text-5xl  font-semibold">
                                  <InsertDriveFileIcon />
                                </div>
                                {formfilledDocument.companyProfile ? (
                                  <div className="text-sm text-gray-500">
                                    <input
                                      type="text"
                                      disabled={submitted}
                                      value={
                                        formfilledDocument?.companyProfile || ""
                                      }
                                      readOnly
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                      <span className="font-semibold">
                                        Click to upload Logo
                                      </span>{" "}
                                      or drag and drop
                                    </p>
                                    <p className="text-xs text-gray-500 dark:text-gray-400">
                                      SVG, PNG, JPG or GIF (MAX. 800x400px)
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <img
                              id="selected-Image-companyProfile"
                              src=""
                              alt="selectedImagecompanyProfile"
                              className="hidden h-24"
                            />
                          </label>
                          <div className="text-red-500 text-sm">
                            {formErrors.companyProfile}
                          </div>
                          <div>
                            {formfilledDocument?.companyProfile?.isRejected ? (
                              <div className="flex gap-2 items-center justify-center">
                                <div>
                                  <p className="text-[13px] text-red-500">
                                    Error Please Reupload This Field
                                  </p>
                                </div>
                                <div>
                                  {" "}
                                  <button
                                    type="submit"
                                    className="text-sm sm:text-md border sm:w-full md:w-[100px] text-red-600 border-red-500 font-semibold py-1 px-6 rounded focus:outline-none focus:shadow-outline shadow-lg"
                                    onClick={handleSubmit}
                                    disabled={isLoader}
                                  >
                                    {isLoader ? <Loader /> : "Submit"}
                                  </button>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="flex-col md:flex-row flex  items-center justify-between">
                        <div className="mb-4">
                          <label
                            htmlFor="photoofOfficepremisesExternal"
                            className="block text-black text-sm mb-2"
                          >
                            Photo of Office premises External
                            <div className="relative border-dashed border border-gray-400 rounded-md overflow-hidden w-[350px] flex justify-center">
                              <input
                                type="file"
                                id="photoOfOfficePremisesExternal"
                                disabled={submitted}
                                name="photoOfOfficePremisesExternal"
                                className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                onChange={(e) =>
                                  handleFileChange(
                                    e,
                                    "photoOfOfficePremisesExternal"
                                  )
                                }
                              />
                              <div className="flex flex-col  items-center gap-x-2 py-2 px-3 bg-white">
                                <div className="text-red-600 pt-4 pb-4 flex justify-center items-center text-5xl  font-semibold">
                                  <InsertDriveFileIcon />
                                </div>
                                {formfilledDocument.PhotoOfOfficePremisesExternalView ? (
                                  <div className="text-sm text-gray-500">
                                    <input
                                      type="text"
                                      disabled={submitted}
                                      value={
                                        formfilledDocument?.PhotoOfOfficePremisesExternalView ||
                                        ""
                                      }
                                      readOnly
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                      <span className="font-semibold">
                                        Click to upload Logo
                                      </span>{" "}
                                      or drag and drop
                                    </p>
                                    <p className="text-xs text-gray-500 dark:text-gray-400">
                                      SVG, PNG, JPG or GIF (MAX. 800x400px)
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <img
                              id="selected-Image-photoOfOfficePremisesExternal"
                              src=""
                              alt="selectedImagephotoOfOfficePremisesExternal"
                              className="hidden h-24"
                            />
                          </label>
                          <div className="text-red-500 text-sm">
                            {formErrors.photoOfOfficePremisesExternal}
                          </div>
                          <div>
                            {formfilledDocument
                              ?.PhotoOfOfficePremisesExternalView
                              ?.isRejected ? (
                              <div className="flex gap-2 items-center justify-center">
                                <div>
                                  <p className="text-[13px] text-red-500">
                                    Error Please Reupload This Field
                                  </p>
                                </div>
                                <div>
                                  {" "}
                                  <button
                                    type="submit"
                                    className="text-sm sm:text-md border sm:w-full md:w-[100px] text-red-600 border-red-500 font-semibold py-1 px-6 rounded focus:outline-none focus:shadow-outline shadow-lg"
                                    onClick={handleSubmit}
                                    disabled={isLoader}
                                  >
                                    {isLoader ? <Loader /> : "Submit"}
                                  </button>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="mb-4">
                          <label
                            htmlFor="bank-passbook-back"
                            className="block text-black text-sm mb-2"
                          >
                            Photo of Office premises Interior
                            <div className="relative border-dashed border border-gray-400 rounded-md overflow-hidden w-[350px] flex justify-center">
                              <input
                                type="file"
                                id="photoOfOfficePremisesInterior"
                                disabled={submitted}
                                name="photoOfOfficePremisesInterior"
                                className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                onChange={(e) =>
                                  handleFileChange(
                                    e,
                                    "photoOfOfficePremisesInterior"
                                  )
                                }
                              />
                              <div className="flex flex-col  items-center gap-x-2 py-2 px-3 bg-white">
                                <div className="text-red-600 pt-4 pb-4 flex justify-center items-center text-5xl  font-semibold">
                                  <InsertDriveFileIcon />
                                </div>
                                {formfilledDocument.PhotoOfOfficePremisesInternalView ? (
                                  <div className="text-sm text-gray-500">
                                    <input
                                      type="text"
                                      disabled={submitted}
                                      value={
                                        formfilledDocument?.PhotoOfOfficePremisesInternalView ||
                                        ""
                                      }
                                      readOnly
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                      <span className="font-semibold">
                                        Click to upload Logo
                                      </span>{" "}
                                      or drag and drop
                                    </p>
                                    <p className="text-xs text-gray-500 dark:text-gray-400">
                                      SVG, PNG, JPG or GIF (MAX. 800x400px)
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <img
                              id="selected-image-photoOfOfficePremisesInterior"
                              src=""
                              alt="selectedImagephotoOfOfficePremisesInterior"
                              className="hidden h-24"
                            />
                          </label>
                          <div className="text-red-500 text-sm">
                            {formErrors.photoOfOfficePremisesInterior}
                          </div>
                          <div>
                            {formfilledDocument
                              ?.PhotoOfOfficePremisesInternalView
                              ?.isRejected ? (
                              <div className="flex gap-2 items-center justify-center">
                                <div>
                                  <p className="text-[13px] text-red-500">
                                    Error Please Reupload This Field
                                  </p>
                                </div>
                                <div>
                                  {" "}
                                  <button
                                    type="submit"
                                    className="text-sm sm:text-md border sm:w-full md:w-[100px] text-red-600 border-red-500 font-semibold py-1 px-6 rounded focus:outline-none focus:shadow-outline shadow-lg"
                                    onClick={handleSubmit}
                                    disabled={isLoader}
                                  >
                                    {isLoader ? <Loader /> : "Submit"}
                                  </button>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      <div className="flex-col md:flex-row flex  items-center justify-between">
                        <div className="mb-4">
                          <label
                            htmlFor="bank-passbook-front"
                            className="block text-black text-sm mb-2"
                          >
                            Copy of Marketing collaterals if any
                            <div className="relative border-dashed border border-gray-400 rounded-md overflow-hidden w-[350px] flex justify-center">
                              <input
                                type="file"
                                id="copyOfMarketingCollateralsifany"
                                disabled={submitted}
                                name=" copyOfMarketingCollateralsifany"
                                className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                onChange={(e) =>
                                  handleFileChange(
                                    e,
                                    " copyOfMarketingCollateralsifany"
                                  )
                                }
                              />
                              <div className="flex flex-col  items-center gap-x-2 py-2 px-3 bg-white">
                                <div className="text-red-600 pt-4 pb-4 flex justify-center items-center text-5xl  font-semibold">
                                  <InsertDriveFileIcon />
                                </div>
                                {formfilledDocument.marketingCollateralsCopy ? (
                                  <div className="text-sm text-gray-500">
                                    <input
                                      type="text"
                                      disabled={submitted}
                                      value={
                                        formfilledDocument?.marketingCollateralsCopy ||
                                        ""
                                      }
                                      readOnly
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                      <span className="font-semibold">
                                        Click to upload Logo
                                      </span>{" "}
                                      or drag and drop
                                    </p>
                                    <p className="text-xs text-gray-500 dark:text-gray-400">
                                      SVG, PNG, JPG or GIF (MAX. 800x400px)
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <img
                              id="selected-Image-copyOfMarketingCollateralsifany"
                              src=""
                              alt="selectedImagecopyOfMarketingCollateralsifany"
                              className="hidden h-24"
                            />
                          </label>
                          <div className="text-red-500 text-sm">
                            {formErrors.copyOfMarketingCollateralsifany}
                          </div>
                          <div>
                            {formfilledDocument?.marketingCollateralsCopy
                              ?.isRejected ? (
                              <div className="flex gap-2 items-center justify-center">
                                <div>
                                  <p className="text-[13px] text-red-500">
                                    Error Please Reupload This Field
                                  </p>
                                </div>
                                <div>
                                  {" "}
                                  <button
                                    type="submit"
                                    className="text-sm sm:text-md border sm:w-full md:w-[100px] text-red-600 border-red-500 font-semibold py-1 px-6 rounded focus:outline-none focus:shadow-outline shadow-lg"
                                    onClick={handleSubmit}
                                    disabled={isLoader}
                                  >
                                    {isLoader ? <Loader /> : "Submit"}
                                  </button>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="mb-4">
                          <label
                            htmlFor="bank-passbook-back"
                            className="block text-black text-sm mb-2"
                          >
                            Marketing plan for Avis
                            <div className="relative border-dashed border border-gray-400 rounded-md overflow-hidden w-[350px] flex justify-center">
                              <input
                                type="file"
                                id="marketingPlanForAvis"
                                disabled={submitted}
                                name="marketingPlanForAvis"
                                className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                onChange={(e) =>
                                  handleFileChange(e, "marketingPlanForAvis")
                                }
                              />
                              <div className="flex flex-col  items-center gap-x-2 py-2 px-3 bg-white">
                                <div className="text-red-600 pt-4 pb-4 flex justify-center items-center text-5xl  font-semibold">
                                  <InsertDriveFileIcon />
                                </div>
                                {formfilledDocument.marketingPlanForAvis ? (
                                  <div className="text-sm text-gray-500">
                                    <input
                                      type="text"
                                      disabled={submitted}
                                      value={
                                        formfilledDocument?.marketingPlanForAvis ||
                                        ""
                                      }
                                      readOnly
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                      <span className="font-semibold">
                                        Click to upload Logo
                                      </span>{" "}
                                      or drag and drop
                                    </p>
                                    <p className="text-xs text-gray-500 dark:text-gray-400">
                                      SVG, PNG, JPG or GIF (MAX. 800x400px)
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <img
                              id="selected-image-marketingPlanForAvis"
                              src=""
                              alt="selectedImagemarketingPlanForAvis"
                              className="hidden h-24"
                            />
                          </label>
                          <div className="text-red-500 text-sm">
                            {formErrors.marketingPlanForAvis}
                          </div>
                          <div>
                            {formfilledDocument?.marketingPlanForAvis
                              ?.isRejected ? (
                              <div className="flex gap-2 items-center justify-center">
                                <div>
                                  <p className="text-[13px] text-red-500">
                                    Error Please Reupload This Field
                                  </p>
                                </div>
                                <div>
                                  {" "}
                                  <button
                                    type="submit"
                                    className="text-sm sm:text-md border sm:w-full md:w-[100px] text-red-600 border-red-500 font-semibold py-1 px-6 rounded focus:outline-none focus:shadow-outline shadow-lg"
                                    onClick={handleSubmit}
                                    disabled={isLoader}
                                  >
                                    {isLoader ? <Loader /> : "Submit"}
                                  </button>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      <p className="font-bold opacity-70 pb-10 pt-4 px-1 ">
                        FOR APPLICATION AS INDIVIDUAL
                      </p>
                      <div className="flex-col md:flex-row flex  items-center justify-between">
                        <div className="mb-4">
                          <label
                            htmlFor="PhotoofOfficepremisesExternal2"
                            className="block text-black text-sm mb-2"
                          >
                            Trade license or National ID
                            <div className="relative border-dashed border border-gray-400 rounded-md overflow-hidden w-[350px] flex justify-center">
                              <input
                                type="file"
                                id="tradeLicenseOrNationalId"
                                name="tradeLicenseOrNationalId"
                                disabled={submittedDocuments}
                                className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                onChange={(e) =>
                                  handleFileChangeIndividual(
                                    e,
                                    "tradeLicenseOrNationalId"
                                  )
                                }
                              />
                              <div className="flex flex-col  items-center gap-x-2 py-2 px-3 bg-white">
                                <div className="text-red-600 pt-4 pb-4 flex justify-center items-center text-5xl  font-semibold">
                                  <InsertDriveFileIcon />
                                </div>
                                {formIndividualDocuments.tradeLicenseOrNationalID ? (
                                  <div className="text-sm text-gray-500">
                                    <input
                                      type="text"
                                      disabled={submittedDocuments}
                                      value={
                                        formIndividualDocuments?.tradeLicenseOrNationalID ||
                                        ""
                                      }
                                      readOnly
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                      <span className="font-semibold">
                                        Click to upload Logo
                                      </span>{" "}
                                      or drag and drop
                                    </p>
                                    <p className="text-xs text-gray-500 dark:text-gray-400">
                                      SVG, PNG, JPG or GIF (MAX. 800x400px)
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <img
                              id="selected-Image-tradeLicenseOrNationalId"
                              src=""
                              alt="selectedImagetradeLicenseOrNationalId"
                              className="hidden h-24"
                            />
                          </label>
                          <div className="text-red-500 text-sm">
                            {formErrors.tradeLicenseOrNationalId}
                          </div>

                          <div>
                            {formIndividualDocuments?.tradeLicenseOrNationalID
                              ?.isRejected ? (
                              <div className="flex gap-2 items-center justify-center">
                                <div>
                                  <p className="text-[13px] text-red-500">
                                    Error Please Reupload This Field
                                  </p>
                                </div>
                                <div>
                                  {" "}
                                  <button
                                    type="submit"
                                    className="text-sm sm:text-md border sm:w-full md:w-[100px] text-red-600 border-red-500 font-semibold py-1 px-6 rounded focus:outline-none focus:shadow-outline shadow-lg"
                                    onClick={handleDocSubmit}
                                    disabled={isLoader}
                                  >
                                    {isLoader ? <Loader /> : "Submit"}
                                  </button>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="mb-4">
                          <label
                            htmlFor="cv"
                            className="block text-black text-sm mb-2"
                          >
                            CV
                            <div className="relative border-dashed border border-gray-400 rounded-md overflow-hidden w-[350px] flex justify-center">
                              <input
                                type="file"
                                id="CV"
                                name="CV"
                                disabled={submittedDocuments}
                                className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                onChange={(e) =>
                                  handleFileChangeIndividual(e, "CV")
                                }
                              />
                              <div className="flex flex-col  items-center gap-x-2 py-2 px-3 bg-white">
                                <div className="text-red-600 pt-4 pb-4 flex justify-center items-center text-5xl  font-semibold">
                                  <InsertDriveFileIcon />
                                </div>
                                {formIndividualDocuments.CV ? (
                                  <div className="text-sm text-gray-500">
                                    <input
                                      type="text"
                                      disabled={submittedDocuments}
                                      value={formIndividualDocuments?.CV || ""}
                                      readOnly
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                      <span className="font-semibold">
                                        Click to upload Logo
                                      </span>{" "}
                                      or drag and drop
                                    </p>
                                    <p className="text-xs text-gray-500 dark:text-gray-400">
                                      SVG, PNG, JPG or GIF (MAX. 800x400px)
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <img
                              id="selected-CV"
                              src=""
                              alt="selectedImageCV"
                              className="hidden h-24"
                            />
                          </label>
                          <div className="text-red-500 text-sm">
                            {formErrors.CV}
                          </div>
                          <div>
                            {formIndividualDocuments?.CV?.isRejected ? (
                              <div className="flex gap-2 items-center justify-center">
                                <div>
                                  <p className="text-[13px] text-red-500">
                                    Error Please Reupload This Field
                                  </p>
                                </div>
                                <div>
                                  {" "}
                                  <button
                                    type="submit"
                                    className="text-sm sm:text-md border sm:w-full md:w-[100px] text-red-600 border-red-500 font-semibold py-1 px-6 rounded focus:outline-none focus:shadow-outline shadow-lg"
                                    onClick={handleDocSubmit}
                                    disabled={isLoader}
                                  >
                                    {isLoader ? <Loader /> : "Submit"}
                                  </button>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="flex-col md:flex-row flex  items-center justify-between">
                        <div className="mb-4">
                          <label
                            htmlFor="PhotoofOfficepremisesExternal2"
                            className="block text-black text-sm mb-2"
                          >
                            Photo of Office premises External
                            <div className="relative border-dashed border border-gray-400 rounded-md overflow-hidden w-[350px] flex justify-center">
                              <input
                                type="file"
                                id="photoOfOfficePremisesExternalIndividual"
                                name="photoOfOfficePremisesExternalIndividual"
                                disabled={submittedDocuments}
                                className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                onChange={(e) =>
                                  handleFileChangeIndividual(
                                    e,
                                    "photoOfOfficePremisesExternalIndividual"
                                  )
                                }
                              />
                              <div className="flex flex-col  items-center gap-x-2 py-2 px-3 bg-white">
                                <div className="text-red-600 pt-4 pb-4 flex justify-center items-center text-5xl  font-semibold">
                                  <InsertDriveFileIcon />
                                </div>
                                {formIndividualDocuments.photoOfOfficePremisesExternalIndividual ? (
                                  <div className="text-sm text-gray-500">
                                    <input
                                      type="text"
                                      disabled={submittedDocuments}
                                      value={
                                        formIndividualDocuments?.photoOfOfficePremisesExternalIndividual ||
                                        ""
                                      }
                                      readOnly
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                      <span className="font-semibold">
                                        Click to upload Logo
                                      </span>{" "}
                                      or drag and drop
                                    </p>
                                    <p className="text-xs text-gray-500 dark:text-gray-400">
                                      SVG, PNG, JPG or GIF (MAX. 800x400px)
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <img
                              id="selected-Image-photoOfOfficePremisesExternalIndividual"
                              src=""
                              alt="selectedImagephotoOfOfficePremisesExternalIndividual"
                              className="hidden h-24"
                            />
                          </label>
                          <div className="text-red-500 text-sm">
                            {formErrors.photoOfOfficePremisesExternalIndividual}
                          </div>
                          <div>
                            {formIndividualDocuments
                              ?.photoOfOfficePremisesExternalIndividual
                              ?.isRejected ? (
                              <div className="flex gap-2 items-center justify-center">
                                <div>
                                  <p className="text-[13px] text-red-500">
                                    Error Please Reupload This Field
                                  </p>
                                </div>
                                <div>
                                  {" "}
                                  <button
                                    type="submit"
                                    className="text-sm sm:text-md border sm:w-full md:w-[100px] text-red-600 border-red-500 font-semibold py-1 px-6 rounded focus:outline-none focus:shadow-outline shadow-lg"
                                    onClick={handleDocSubmit}
                                    disabled={isLoader}
                                  >
                                    {isLoader ? <Loader /> : "Submit"}
                                  </button>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="mb-4">
                          <label
                            htmlFor="PhotoofOfficepremisesInterior2"
                            className="block text-black text-sm mb-2"
                          >
                            Photo of Office premises Interior
                            <div className="relative border-dashed border border-gray-400 rounded-md overflow-hidden w-[350px] flex justify-center">
                              <input
                                type="file"
                                id="photoOfOfficePremisesInteriorIndividual"
                                name="photoOfOfficePremisesInteriorIndividual"
                                disabled={submittedDocuments}
                                className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                onChange={(e) =>
                                  handleFileChangeIndividual(
                                    e,
                                    "photoOfOfficePremisesInteriorIndividual"
                                  )
                                }
                              />
                              <div className="flex flex-col  items-center gap-x-2 py-2 px-3 bg-white">
                                <div className="text-red-600 pt-4 pb-4 flex justify-center items-center text-5xl  font-semibold">
                                  <InsertDriveFileIcon />
                                </div>
                                {formIndividualDocuments.photoOfOfficePremisesInternalIndividual ? (
                                  <div className="text-sm text-gray-500">
                                    <input
                                      type="text"
                                      disabled={submittedDocuments}
                                      value={
                                        formIndividualDocuments?.photoOfOfficePremisesInternalIndividual ||
                                        ""
                                      }
                                      readOnly
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                      <span className="font-semibold">
                                        Click to upload Logo
                                      </span>{" "}
                                      or drag and drop
                                    </p>
                                    <p className="text-xs text-gray-500 dark:text-gray-400">
                                      SVG, PNG, JPG or GIF (MAX. 800x400px)
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <img
                              id="selected-image-photoOfOfficePremisesInteriorIndividual"
                              src=""
                              alt="selectedImagephotoOfOfficePremisesInteriorIndividual"
                              className="hidden h-24"
                            />
                          </label>
                          <div className="text-red-500 text-sm">
                            {formErrors.photoOfOfficePremisesInteriorIndividual}
                          </div>
                          <div>
                            {formIndividualDocuments
                              ?.photoOfOfficePremisesInteriorIndividual
                              ?.isRejected ? (
                              <div className="flex gap-2 items-center justify-center">
                                <div>
                                  <p className="text-[13px] text-red-500">
                                    Error Please Reupload This Field
                                  </p>
                                </div>
                                <div>
                                  {" "}
                                  <button
                                    type="submit"
                                    className="text-sm sm:text-md border sm:w-full md:w-[100px] text-red-600 border-red-500 font-semibold py-1 px-6 rounded focus:outline-none focus:shadow-outline shadow-lg"
                                    onClick={handleDocSubmit}
                                    disabled={isLoader}
                                  >
                                    {isLoader ? <Loader /> : "Submit"}
                                  </button>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="flex-col md:flex-row flex  items-center justify-between">
                        <div className="mb-4">
                          <label
                            htmlFor="CopyofMarketingcollateralsifany2"
                            className="block text-black text-sm mb-2"
                          >
                            Copy of Marketing collaterals if any
                            <div className="relative border-dashed border border-gray-400 rounded-md overflow-hidden w-[350px] flex justify-center">
                              <input
                                type="file"
                                id="copyOfMarketingCollateralsifanyIndividual"
                                disabled={submittedDocuments}
                                name="copyOfMarketingCollateralsifanyIndividual"
                                className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                onChange={(e) =>
                                  handleFileChangeIndividual(
                                    e,
                                    "copyOfMarketingCollateralsifanyIndividual"
                                  )
                                }
                              />
                              <div className="flex flex-col  items-center gap-x-2 py-2 px-3 bg-white">
                                <div className="text-red-600 pt-4 pb-4 flex justify-center items-center text-5xl  font-semibold">
                                  <InsertDriveFileIcon />
                                </div>
                                {formIndividualDocuments.marketingCollateralsCopyIndividual ? (
                                  <div className="text-sm text-gray-500">
                                    <input
                                      type="text"
                                      disabled={submittedDocuments}
                                      value={
                                        formIndividualDocuments?.marketingCollateralsCopyIndividual ||
                                        ""
                                      }
                                      readOnly
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                      <span className="font-semibold">
                                        Click to upload Logo
                                      </span>{" "}
                                      or drag and drop
                                    </p>
                                    <p className="text-xs text-gray-500 dark:text-gray-400">
                                      SVG, PNG, JPG or GIF (MAX. 800x400px)
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <img
                              id="selected-Image-copyOfMarketingCollateralsifanyIndividual"
                              src=""
                              alt="selectedImagecopyOfMarketingCollateralsifanyIndividual"
                              className="hidden h-24"
                            />
                          </label>
                          <div className="text-red-500 text-sm">
                            {
                              formErrors.copyOfMarketingCollateralsifanyIndividual
                            }
                          </div>
                          <div>
                            {formIndividualDocuments
                              ?.marketingCollateralsCopyIndividua
                              ?.isRejected ? (
                              <div className="flex gap-2 items-center justify-center">
                                <div>
                                  <p className="text-[13px] text-red-500">
                                    Error Please Reupload This Field
                                  </p>
                                </div>
                                <div>
                                  {" "}
                                  <button
                                    type="submit"
                                    className="text-sm sm:text-md border sm:w-full md:w-[100px] text-red-600 border-red-500 font-semibold py-1 px-6 rounded focus:outline-none focus:shadow-outline shadow-lg"
                                    onClick={handleDocSubmit}
                                    disabled={isLoader}
                                  >
                                    {isLoader ? <Loader /> : "Submit"}
                                  </button>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="mb-4">
                          <label
                            htmlFor="bank-passbook-back"
                            className="block text-black text-sm mb-2"
                          >
                            Marketing plan for Avis
                            <div className="relative border-dashed border border-gray-400 rounded-md overflow-hidden w-[350px] flex justify-center">
                              <input
                                type="file"
                                id="marketingPlanForAvisIndividual"
                                disabled={submittedDocuments}
                                name="marketingPlanForAvisIndividual"
                                className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                onChange={(e) =>
                                  handleFileChangeIndividual(
                                    e,
                                    "marketingPlanForAvisIndividual"
                                  )
                                }
                              />
                              <div className="flex flex-col  items-center gap-x-2 py-2 px-3 bg-white">
                                <div className="text-red-600 pt-4 pb-4 flex justify-center items-center text-5xl  font-semibold">
                                  <InsertDriveFileIcon />
                                </div>
                                {formIndividualDocuments.marketingPlanForAvisIndividual ? (
                                  <div className="text-sm text-gray-500">
                                    <input
                                      type="text"
                                      disabled={submittedDocuments}
                                      value={
                                        formIndividualDocuments?.marketingPlanForAvisIndividual ||
                                        ""
                                      }
                                      readOnly
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                      <span className="font-semibold">
                                        Click to upload Logo
                                      </span>{" "}
                                      or drag and drop
                                    </p>
                                    <p className="text-xs text-gray-500 dark:text-gray-400">
                                      SVG, PNG, JPG or GIF (MAX. 800x400px)
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <img
                              id="selected-image-marketingPlanForAvisIndividual"
                              src=""
                              alt="selectedImagemarketingPlanForAvisIndividual"
                              className="hidden h-24"
                            />
                          </label>
                          <div className="text-red-500 text-sm">
                            {formErrors.marketingPlanForAvisIndividual}
                          </div>
                          <div>
                            {formIndividualDocuments
                              ?.marketingPlanForAvisIndividual?.isRejected ? (
                              <div className="flex gap-2 items-center justify-center">
                                <div>
                                  <p className="text-[13px] text-red-500">
                                    Error Please Reupload This Field
                                  </p>
                                </div>
                                <div>
                                  {" "}
                                  <button
                                    type="submit"
                                    className="text-sm sm:text-md border sm:w-full md:w-[100px] text-red-600 border-red-500 font-semibold py-1 px-6 rounded focus:outline-none focus:shadow-outline shadow-lg"
                                    onClick={handleDocSubmit}
                                    disabled={isLoader}
                                  >
                                    {isLoader ? <Loader /> : "Submit"}
                                  </button>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      ) : (
        <div>
          <div className={`accordion ${isOpenDocuments ? "open" : ""} mb-6`}>
            <div
              className="accordion-header w-[374px] md:w-[814px] rounded-sm shadow-lg"
              onClick={toggleAccordionDocuments}
            >
              <div className="flex justify-center items-center gap-x-4">
                <div className="flex items-center gap-x-2 bg-red-500 px-1 py-1 rounded">
                  <p className="text-white font-bold text-sm">STEP 5</p>
                </div>
                <div>
                  <p className="text-md font-semibold">SUPPORTING DOCUMENTS</p>
                </div>
              </div>
              <div className="flex justify-end items-end">
                <i className="fa-solid fa-angle-up" />
                <span className="icon">
                  {isOpenDocuments ? <RemoveIcon /> : <AddIcon />}
                </span>
              </div>
            </div>
            {isOpenDocuments && (
              <form>
                <div className="accordion-content text-black">
                  <div className="panel w-full sm:w-full md:w-[814px] flex-col  flex justify-center sm:flex sm:justify-content  md:flex-col  md:justify-center md:item-center shadow-lg md:px-6">
                    <div className="py-10">
                      <p className="pb-10 text-md opacity-70 font-semibold ">
                        FOR APPLICATION AS COMPANY
                      </p>
                      <div className="flex-col md:flex-row flex justify-between items-center">
                        <div className="mb-4" id="voter-section">
                          <label
                            htmlFor="CompanyRegistrationCertificate"
                            className="block text-black text-sm mb-2"
                          >
                            Company Registration Certificate
                            <div className="relative border-dashed border border-gray-400 rounded-md overflow-hidden w-[350px] flex justify-center">
                              <input
                                type="file"
                                id="companyRegistrationCertificate"
                                name="companyRegistrationCertificate"
                                className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                onChange={(e) =>
                                  handleFileChange(
                                    e,
                                    "companyRegistrationCertificate"
                                  )
                                }
                              />
                              <div className="flex flex-col  items-center gap-x-2 py-2 px-3 bg-white">
                                <div className="text-red-600 pt-4 pb-4 flex justify-center items-center text-5xl  font-semibold">
                                  <InsertDriveFileIcon />
                                </div>
                                {selectedFileNames.companyRegistrationCertificate ? (
                                  <div className="text-sm text-gray-500">
                                    <input
                                      type="text"
                                      value={
                                        selectedFileNames.companyRegistrationCertificate
                                      }
                                      readOnly
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                      <span className="font-semibold">
                                        Click to upload Logo
                                      </span>{" "}
                                      or drag and drop
                                    </p>
                                    <p className="text-xs text-gray-500 dark:text-gray-400">
                                      SVG, PNG, JPG or GIF (MAX. 800x400px)
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <img
                              id="selected-image-companyRegistrationCertificate"
                              src=""
                              alt="selectedImagecompanyRegistrationCertificate"
                              className="hidden h-24"
                            />
                          </label>
                          <div className="text-red-500 text-sm">
                            {formErrors.companyRegistrationCertificate}
                          </div>
                        </div>
                        <div className="mb-4" id="voter-section">
                          <label
                            htmlFor="CompanyProfile"
                            className="block text-black text-sm mb-2"
                          >
                            Company Profile
                            <div className="relative border-dashed border border-gray-400 rounded-md overflow-hidden w-[350px] flex justify-center">
                              <input
                                type="file"
                                id="companyProfile"
                                name="companyProfile"
                                className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                onChange={(e) =>
                                  handleFileChange(e, "companyProfile")
                                }
                              />
                              <div className="flex flex-col  items-center gap-x-2 py-2 px-3 bg-white">
                                <div className="text-red-600 pt-4 pb-4 flex justify-center items-center text-5xl  font-semibold">
                                  <InsertDriveFileIcon />
                                </div>
                                {selectedFileNames.companyProfile ? (
                                  <div className="text-sm text-gray-500">
                                    <input
                                      type="text"
                                      value={selectedFileNames.companyProfile}
                                      readOnly
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                      <span className="font-semibold">
                                        Click to upload Logo
                                      </span>{" "}
                                      or drag and drop
                                    </p>
                                    <p className="text-xs text-gray-500 dark:text-gray-400">
                                      SVG, PNG, JPG or GIF (MAX. 800x400px)
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <img
                              id="selectedImagecompanyProfile"
                              src=""
                              alt="selectedImagecompanyProfile"
                              className="hidden h-24"
                            />
                          </label>
                          <div className="text-red-500 text-sm">
                            {formErrors.companyProfile}
                          </div>
                        </div>
                      </div>
                      <div className="flex-col md:flex-row flex  items-center justify-between">
                        <div className="mb-4">
                          <label
                            htmlFor="photoofOfficepremisesExternal"
                            className="block text-black text-sm mb-2"
                          >
                            Photo of Office premises External
                            <div className="relative border-dashed border border-gray-400 rounded-md overflow-hidden w-[350px] flex justify-center">
                              <input
                                type="file"
                                id="photoOfOfficePremisesExternal"
                                name="photoOfOfficePremisesExternal"
                                className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                onChange={(e) =>
                                  handleFileChange(
                                    e,
                                    "photoOfOfficePremisesExternal"
                                  )
                                }
                              />
                              <div className="flex flex-col  items-center gap-x-2 py-2 px-3 bg-white">
                                <div className="text-red-600 pt-4 pb-4 flex justify-center items-center text-5xl  font-semibold">
                                  <InsertDriveFileIcon />
                                </div>
                                {selectedFileNames.photoOfOfficePremisesExternal ? (
                                  <div className="text-sm text-gray-500">
                                    <input
                                      type="text"
                                      value={
                                        selectedFileNames.photoOfOfficePremisesExternal
                                      }
                                      readOnly
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                      <span className="font-semibold">
                                        Click to upload Logo
                                      </span>{" "}
                                      or drag and drop
                                    </p>
                                    <p className="text-xs text-gray-500 dark:text-gray-400">
                                      SVG, PNG, JPG or GIF (MAX. 800x400px)
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <img
                              id="selected-Image-photoOfOfficePremisesExternal"
                              src=""
                              alt="selectedImagephotoOfOfficePremisesExternal"
                              className="hidden h-24"
                            />
                          </label>
                          <div className="text-red-500 text-sm">
                            {formErrors.photoOfOfficePremisesExternal}
                          </div>
                        </div>

                        <div className="mb-4">
                          <label className="block text-black text-sm mb-2">
                            Photo of Office premises Interior
                            <div className="relative border-dashed border border-gray-400 rounded-md overflow-hidden w-[350px] flex justify-center">
                              <input
                                type="file"
                                id="photoOfOfficePremisesInterior"
                                name="photoOfOfficePremisesInterior"
                                className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                onChange={(e) =>
                                  handleFileChange(
                                    e,
                                    "photoOfOfficePremisesInterior"
                                  )
                                }
                              />
                              <div className="flex flex-col  items-center gap-x-2 py-2 px-3 bg-white">
                                <div className="text-red-600 pt-4 pb-4 flex justify-center items-center text-5xl  font-semibold">
                                  <InsertDriveFileIcon />
                                </div>
                                {selectedFileNames.photoOfOfficePremisesInterior ? (
                                  <div className="text-sm text-gray-500">
                                    <input
                                      type="text"
                                      value={
                                        selectedFileNames.photoOfOfficePremisesInterior
                                      }
                                      readOnly
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                      <span className="font-semibold">
                                        Click to upload Logo
                                      </span>{" "}
                                      or drag and drop
                                    </p>
                                    <p className="text-xs text-gray-500 dark:text-gray-400">
                                      SVG, PNG, JPG or GIF (MAX. 800x400px)
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <img
                              id="selected-image-photoOfOfficePremisesInterior"
                              src=""
                              alt="selectedImagephotoOfOfficePremisesInterior"
                              className="hidden h-24"
                            />
                          </label>
                          <div className="text-red-500 text-sm">
                            {formErrors.photoOfOfficePremisesInterior}
                          </div>
                        </div>
                      </div>

                      <div className="flex-col md:flex-row flex  items-center justify-between">
                        <div className="mb-4">
                          <label
                            htmlFor="bank-passbook-front"
                            className="block text-black text-sm mb-2"
                          >
                            Copy of Marketing collaterals if any
                            <div className="relative border-dashed border border-gray-400 rounded-md overflow-hidden w-[350px] flex justify-center">
                              <input
                                type="file"
                                id="copyOfMarketingCollateralsifany"
                                name="copyOfMarketingCollateralsifany"
                                className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                onChange={(e) =>
                                  handleFileChange(
                                    e,
                                    "copyOfMarketingCollateralsifany"
                                  )
                                }
                              />
                              <div className="flex flex-col  items-center gap-x-2 py-2 px-3 bg-white">
                                <div className="text-red-600 pt-4 pb-4 flex justify-center items-center text-5xl  font-semibold">
                                  <InsertDriveFileIcon />
                                </div>
                                {selectedFileNames.copyOfMarketingCollateralsifany ? (
                                  <div className="text-sm text-gray-500">
                                    <input
                                      type="text"
                                      value={
                                        selectedFileNames.copyOfMarketingCollateralsifany
                                      }
                                      readOnly
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                      <span className="font-semibold">
                                        Click to upload Logo
                                      </span>{" "}
                                      or drag and drop
                                    </p>
                                    <p className="text-xs text-gray-500 dark:text-gray-400">
                                      SVG, PNG, JPG or GIF (MAX. 800x400px)
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <img
                              id="selected-Image-copyOfMarketingCollateralsifany"
                              src=""
                              alt="selectedImagecopyOfMarketingCollateralsifany"
                              className="hidden h-24"
                            />
                          </label>
                          <div className="text-red-500 text-sm">
                            {formErrors.copyOfMarketingCollateralsifany}
                          </div>
                        </div>

                        <div className="mb-4">
                          <label
                            htmlFor="bank-passbook-back"
                            className="block text-black text-sm mb-2"
                          >
                            Marketing plan for Avis
                            <div className="relative border-dashed border border-gray-400 rounded-md overflow-hidden w-[350px] flex justify-center">
                              <input
                                type="file"
                                id="marketingPlanForAvis"
                                name="marketingPlanForAvis"
                                className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                onChange={(e) =>
                                  handleFileChange(e, "marketingPlanForAvis")
                                }
                              />
                              <div className="flex flex-col  items-center gap-x-2 py-2 px-3 bg-white">
                                <div className="text-red-600 pt-4 pb-4 flex justify-center items-center text-5xl  font-semibold">
                                  <InsertDriveFileIcon />
                                </div>
                                {selectedFileNames.marketingPlanForAvis ? (
                                  <div className="text-sm text-gray-500">
                                    <input
                                      type="text"
                                      value={
                                        selectedFileNames.marketingPlanForAvis
                                      }
                                      readOnly
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                      <span className="font-semibold">
                                        Click to upload Logo
                                      </span>{" "}
                                      or drag and drop
                                    </p>
                                    <p className="text-xs text-gray-500 dark:text-gray-400">
                                      SVG, PNG, JPG or GIF (MAX. 800x400px)
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <img
                              id="selected-image-marketingPlanForAvis"
                              src=""
                              alt="selectedImagemarketingPlanForAvis"
                              className="hidden h-24"
                            />
                          </label>
                          <div className="text-red-500 text-sm">
                            {formErrors.marketingPlanForAvis}
                          </div>
                        </div>
                      </div>
                      <div className="mb-4 flex justify-center sm:justify-center md:justify-end md:mr-10">
                        {submitted ? (
                          // Display a message or any other component after submission
                          <button
                            type="submit"
                            className="text-sm sm:text-md border sm:w-full md:w-[250px] text-red-600 border-red-500 font-bold py-2 px-10 rounded focus:outline-none focus:shadow-outline shadow-lg hidden"
                            onClick={handleSubmit}
                            disabled={isLoader}
                          >
                            {isLoader ? <Loader /> : "Submit Application"}
                          </button>
                        ) : (
                          // Render the submit button only if the form hasn't been submitted
                          <button
                            type="submit"
                            className="text-sm sm:text-md border sm:w-full md:w-[250px] text-red-600 border-red-500 font-bold py-2 px-10 rounded focus:outline-none focus:shadow-outline shadow-lg"
                            onClick={handleSubmit}
                            disabled={isLoader}
                          >
                            {isLoader ? <Loader /> : "Submit Application"}
                          </button>
                        )}
                      </div>
                      <p className="font-bold opacity-70 pb-10 pt-4 ">
                        FOR APPLICATION AS INDIVIDUAL
                      </p>
                      <div className="flex-col md:flex-row flex  items-center justify-between">
                        <div className="mb-4">
                          <label
                            htmlFor="PhotoofOfficepremisesExternal2"
                            className="block text-black text-sm mb-2"
                          >
                            Trade license or National ID
                            <div className="relative border-dashed border border-gray-400 rounded-md overflow-hidden w-[350px] flex justify-center">
                              <input
                                type="file"
                                id="tradeLicenseOrNationalId"
                                name="tradeLicenseOrNationalId"
                                className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                onChange={(e) =>
                                  handleFileChangeIndividual(
                                    e,
                                    "tradeLicenseOrNationalId"
                                  )
                                }
                              />
                              <div className="flex flex-col  items-center gap-x-2 py-2 px-3 bg-white">
                                <div className="text-red-600 pt-4 pb-4 flex justify-center items-center text-5xl  font-semibold">
                                  <InsertDriveFileIcon />
                                </div>
                                {selectedFileNames.tradeLicenseOrNationalId ? (
                                  <div className="text-sm text-gray-500">
                                    <input
                                      type="text"
                                      value={
                                        selectedFileNames.tradeLicenseOrNationalId
                                      }
                                      readOnly
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                      <span className="font-semibold">
                                        Click to upload Logo
                                      </span>{" "}
                                      or drag and drop
                                    </p>
                                    <p className="text-xs text-gray-500 dark:text-gray-400">
                                      SVG, PNG, JPG or GIF (MAX. 800x400px)
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <img
                              id="selected-Image-tradeLicenseOrNationalId"
                              src=""
                              alt="selectedImagetradeLicenseOrNationalId"
                              className="hidden h-24"
                            />
                          </label>
                          <div className="text-red-500 text-sm">
                            {formErrors.tradeLicenseOrNationalId}
                          </div>
                        </div>

                        <div className="mb-4">
                          <label
                            htmlFor="cv"
                            className="block text-black text-sm mb-2"
                          >
                            CV
                            <div className="relative border-dashed border border-gray-400 rounded-md overflow-hidden w-[350px] flex justify-center">
                              <input
                                type="file"
                                id="CV"
                                name="CV"
                                className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                onChange={(e) =>
                                  handleFileChangeIndividual(e, "CV")
                                }
                              />
                              <div className="flex flex-col  items-center gap-x-2 py-2 px-3 bg-white">
                                <div className="text-red-600 pt-4 pb-4 flex justify-center items-center text-5xl  font-semibold">
                                  <InsertDriveFileIcon />
                                </div>
                                {selectedFileNames.CV ? (
                                  <div className="text-sm text-gray-500">
                                    <input
                                      type="text"
                                      value={selectedFileNames.CV}
                                      readOnly
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                      <span className="font-semibold">
                                        Click to upload Logo
                                      </span>{" "}
                                      or drag and drop
                                    </p>
                                    <p className="text-xs text-gray-500 dark:text-gray-400">
                                      SVG, PNG, JPG or GIF (MAX. 800x400px)
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <img
                              id="selected-CV"
                              src=""
                              alt="selectedImageCV"
                              className="hidden h-24"
                            />
                          </label>
                          <div className="text-red-500 text-sm">
                            {formErrors.CV}
                          </div>
                        </div>
                      </div>
                      <div className="flex-col md:flex-row flex  items-center justify-between">
                        <div className="mb-4">
                          <label
                            htmlFor="PhotoofOfficepremisesExternal2"
                            className="block text-black text-sm mb-2"
                          >
                            Photo of Office premises External
                            <div className="relative border-dashed border border-gray-400 rounded-md overflow-hidden w-[350px] flex justify-center">
                              <input
                                type="file"
                                id="photoOfOfficePremisesExternalIndividual"
                                name="photoOfOfficePremisesExternalIndividual"
                                className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                onChange={(e) =>
                                  handleFileChangeIndividual(
                                    e,
                                    "photoOfOfficePremisesExternalIndividual"
                                  )
                                }
                              />
                              <div className="flex flex-col  items-center gap-x-2 py-2 px-3 bg-white">
                                <div className="text-red-600 pt-4 pb-4 flex justify-center items-center text-5xl  font-semibold">
                                  <InsertDriveFileIcon />
                                </div>
                                {selectedFileNames.photoOfOfficePremisesExternalIndividual ? (
                                  <div className="text-sm text-gray-500">
                                    <input
                                      type="text"
                                      value={
                                        selectedFileNames.photoOfOfficePremisesExternalIndividual
                                      }
                                      readOnly
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                      <span className="font-semibold">
                                        Click to upload Logo
                                      </span>{" "}
                                      or drag and drop
                                    </p>
                                    <p className="text-xs text-gray-500 dark:text-gray-400">
                                      SVG, PNG, JPG or GIF (MAX. 800x400px)
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <img
                              id="selected-Image-photoOfOfficePremisesExternalIndividual"
                              src=""
                              alt="selectedImagephotoOfOfficePremisesExternalIndividual"
                              className="hidden h-24"
                            />
                          </label>
                          <div className="text-red-500 text-sm">
                            {formErrors.photoOfOfficePremisesExternalIndividual}
                          </div>
                        </div>

                        <div className="mb-4">
                          <label
                            htmlFor="PhotoofOfficepremisesInterior2"
                            className="block text-black text-sm mb-2"
                          >
                            Photo of Office premises Interior
                            <div className="relative border-dashed border border-gray-400 rounded-md overflow-hidden w-[350px] flex justify-center">
                              <input
                                type="file"
                                id="photoOfOfficePremisesInteriorIndividual"
                                name="photoOfOfficePremisesInteriorIndividual"
                                className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                onChange={(e) =>
                                  handleFileChangeIndividual(
                                    e,
                                    "photoOfOfficePremisesInteriorIndividual"
                                  )
                                }
                              />
                              <div className="flex flex-col  items-center gap-x-2 py-2 px-3 bg-white">
                                <div className="text-red-600 pt-4 pb-4 flex justify-center items-center text-5xl  font-semibold">
                                  <InsertDriveFileIcon />
                                </div>
                                {selectedFileNames.photoOfOfficePremisesInteriorIndividual ? (
                                  <div className="text-sm text-gray-500">
                                    <input
                                      type="text"
                                      value={
                                        selectedFileNames.photoOfOfficePremisesInteriorIndividual
                                      }
                                      readOnly
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                      <span className="font-semibold">
                                        Click to upload Logo
                                      </span>{" "}
                                      or drag and drop
                                    </p>
                                    <p className="text-xs text-gray-500 dark:text-gray-400">
                                      SVG, PNG, JPG or GIF (MAX. 800x400px)
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <img
                              id="selected-image-photoOfOfficePremisesInteriorIndividual"
                              src=""
                              alt="selectedImagephotoOfOfficePremisesInteriorIndividual"
                              className="hidden h-24"
                            />
                          </label>
                          <div className="text-red-500 text-sm">
                            {formErrors.photoOfOfficePremisesInteriorIndividual}
                          </div>
                        </div>
                      </div>
                      <div className="flex-col md:flex-row flex  items-center justify-between">
                        <div className="mb-4">
                          <label
                            htmlFor="CopyofMarketingcollateralsifany2"
                            className="block text-black text-sm mb-2"
                          >
                            Copy of Marketing collaterals if any
                            <div className="relative border-dashed border border-gray-400 rounded-md overflow-hidden w-[350px] flex justify-center">
                              <input
                                type="file"
                                id="copyOfMarketingCollateralsifanyIndividual"
                                name="copyOfMarketingCollateralsifanyIndividual"
                                className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                onChange={(e) =>
                                  handleFileChangeIndividual(
                                    e,
                                    "copyOfMarketingCollateralsifanyIndividual"
                                  )
                                }
                              />
                              <div className="flex flex-col  items-center gap-x-2 py-2 px-3 bg-white">
                                <div className="text-red-600 pt-4 pb-4 flex justify-center items-center text-5xl  font-semibold">
                                  <InsertDriveFileIcon />
                                </div>
                                {selectedFileNames.copyOfMarketingCollateralsifanyIndividual ? (
                                  <div className="text-sm text-gray-500">
                                    <input
                                      type="text"
                                      value={
                                        selectedFileNames.copyOfMarketingCollateralsifanyIndividual
                                      }
                                      readOnly
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                      <span className="font-semibold">
                                        Click to upload Logo
                                      </span>{" "}
                                      or drag and drop
                                    </p>
                                    <p className="text-xs text-gray-500 dark:text-gray-400">
                                      SVG, PNG, JPG or GIF (MAX. 800x400px)
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <img
                              id="selected-Image-copyOfMarketingCollateralsifanyIndividual"
                              src=""
                              alt="selectedImagecopyOfMarketingCollateralsifanyIndividual"
                              className="hidden h-24"
                            />
                          </label>
                          <div className="text-red-500 text-sm">
                            {
                              formErrors.copyOfMarketingCollateralsifanyIndividual
                            }
                          </div>
                        </div>

                        <div className="mb-4">
                          <label
                            htmlFor="bank-passbook-back"
                            className="block text-black text-sm mb-2"
                          >
                            Marketing plan for Avis
                            <div className="relative border-dashed border border-gray-400 rounded-md overflow-hidden w-[350px] flex justify-center">
                              <input
                                type="file"
                                id="marketingPlanForAvisIndividual"
                                name="marketingPlanForAvisIndividual"
                                className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                onChange={(e) =>
                                  handleFileChangeIndividual(
                                    e,
                                    "marketingPlanForAvisIndividual"
                                  )
                                }
                              />
                              <div className="flex flex-col  items-center gap-x-2 py-2 px-3 bg-white">
                                <div className="text-red-600 pt-4 pb-4 flex justify-center items-center text-5xl  font-semibold">
                                  <InsertDriveFileIcon />
                                </div>
                                {selectedFileNames.marketingPlanForAvisIndividual ? (
                                  <div className="text-sm text-gray-500">
                                    <input
                                      type="text"
                                      value={
                                        selectedFileNames.marketingPlanForAvisIndividual
                                      }
                                      readOnly
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                      <span className="font-semibold">
                                        Click to upload Logo
                                      </span>{" "}
                                      or drag and drop
                                    </p>
                                    <p className="text-xs text-gray-500 dark:text-gray-400">
                                      SVG, PNG, JPG or GIF (MAX. 800x400px)
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <img
                              id="selected-image-marketingPlanForAvisIndividual"
                              src=""
                              alt="selectedImagemarketingPlanForAvisIndividual"
                              className="hidden h-24"
                            />
                          </label>
                          <div className="text-red-500 text-sm">
                            {formErrors.marketingPlanForAvisIndividual}
                          </div>
                        </div>
                      </div>
                      <div className="mb-4 flex justify-center sm:justify-center md:justify-end md:mr-10">
                        {submittedDocuments ? (
                          // Display a message or any other component after submission
                          <button
                            type="submit"
                            className="text-sm sm:text-md border sm:w-full md:w-[250px] text-red-600 border-red-500 font-bold py-2 px-10 rounded focus:outline-none focus:shadow-outline shadow-lg hidden"
                            onClick={handleDocSubmit}
                            disabled={isLoader}
                          >
                            {isLoader ? <Loader /> : "Submit Application"}
                          </button>
                        ) : (
                          // Render the submit button only if the form hasn't been submitted
                          <button
                            type="submit"
                            className="text-sm sm:text-md border sm:w-full md:w-[250px] text-red-600 border-red-500 font-bold py-2 px-10 rounded focus:outline-none focus:shadow-outline shadow-lg"
                            onClick={handleDocSubmit}
                            disabled={isLoader}
                          >
                            {isLoader ? <Loader /> : "Submit Application"}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      )}
      <div>
        {finalApproval?.isApproved ? (
          <div>
            <EsighServices />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SupportingDocuments;
