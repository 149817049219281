import React from "react";
import "./ComponentLoading.css";

const ComponentLoading = () => {
  return (
    <div className="w-full h-[80vh] flex justify-center items-center">
      <div className="com-loader"></div>
    </div>
  );
};

export default ComponentLoading;
