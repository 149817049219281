import React, { useEffect, useState } from "react";
// import SearchIcon from "@mui/icons-material/Search";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import ReportTh from "./ReportTh";
import ReportTr from "./ReportTr";
import ReportTopRow from "./ReportTopRow";
import {
  fetchReportTableDataAPI,
  fetchReportTopRowDataAPI,
} from "../../../API/admin/ReportAPI";
import { fetchCollegesAPI } from "../../../API/admin/AdminAPI";
import { useSelector } from "react-redux";
import CollegeReportRow from "./CollegeReportRow";
import ComponentLoading from "../../../pages/CommonPreloader/ComponentLoading";

const ReportComponent = () => {
  const [commissionData, setCommissionData] = useState({});
  const [filterOptions, setFilterOptions] = useState("rep");
  const [reportTable, setReportTable] = useState([]);
  const [collegeOptions, setCollegeOptions] = useState([]);
  const [collegeInput, setCollegeInput] = useState("none");
  const [daysInput, setDaysInput] = useState("3");
  const [isFromTo, setIsFromTo] = useState(false);
  const adminData = useSelector((state) => state.adminData.adminData);

  useEffect(() => {
    const fetchCOmmissionDetails = async () => {
      try {
        const response = await fetchReportTopRowDataAPI(
          collegeInput,
          daysInput
        );
        if (response?.status === 200) {
          setCommissionData(response?.data);
        } else {
          setCommissionData({});
        }
      } catch (error) {
        setCommissionData({});
      }
    };
    fetchCOmmissionDetails();
  }, [collegeInput, daysInput]);

  useEffect(() => {
    const fetchTableData = async () => {
      const response = await fetchReportTableDataAPI(
        `${filterOptions}=${collegeInput}`,
        daysInput
      );
      if (response?.status === 200) {
        setReportTable(response?.data);
      }
    };
    fetchTableData();
  }, [filterOptions, collegeInput, daysInput]);

  useEffect(() => {
    const fetchCollegeNames = async () => {
      const response = await fetchCollegesAPI();
      if (response?.status === 200) {
        setCollegeOptions(response?.data);
      }
    };
    fetchCollegeNames();
  }, []);

  const handleDateChange = (e) => {
    if (e.target.value === "custom range") {
      setIsFromTo(true);
      setDaysInput(e.target.value);
    } else {
      setIsFromTo(false);
      setDaysInput(e.target.value);
    }
  };

  return (
    <>
      <div>
        <div className="flex justify-between ">
          <div className="flex flex-col gap-y-[10px]">
            <div className=" text-[25px] font-semibold">
              <p>Hi {adminData?.name}</p>
            </div>
            <div className="text-[15px]">
              <p>
                You have the option to choose a college and obtain a
                comprehensive report detailing the distribution of funds to
                Agents, Admin, and Representatives.
              </p>
            </div> 
          </div>
          {/* <div>
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-50">
              <DownloadForOfflineIcon
                sx={{ fontSize: 35 }}
                className="h-8 w-8 text-red-600 hover:animate-bounce"
              />
            </div>
          </div> */}
        </div>
        <div className="grid md:grid-cols-2 xl:grid-cols-4 mt-4 gap-4">
          <div>
            <label
              htmlFor="toDate"
              className="block text-gray-700 font-bold mb-2"
            >
              College
            </label>
            <select
              className="text-sm w-full px-[30px] py-1  border border-gray-300 rounded-md text-gray-500 outline-none"
              id="college"
              name="college"
              value={collegeInput}
              onChange={(e) => setCollegeInput(e.target.value)}
              required
            >
              <option className="text-black" value={"none"} selected>
                Choose college
              </option>
              {collegeOptions.length > 0 &&
                collegeOptions.map((eml) => (
                  <option className="text-black" key={eml._id} value={eml._id}>
                    {eml.name}
                  </option>
                ))}
              {/* Add more options as needed */}
            </select>
          </div>
          <div>
            <label
              htmlFor="toDate"
              className="block text-gray-700 font-bold mb-2"
            >
              By days
            </label>
            <select
              className="text-sm w-full px-[30px] py-1  border border-gray-300 rounded-md text-gray-500 outline-none"
              id="courses"
              name="courses"
              value={daysInput}
              onChange={handleDateChange}
              required
            >
              {/* <option className="text-black" value={"1"} selected>
                Last 24 hr
              </option> */}
              <option className="text-black" value={"3"} selected>
                Last 3 days
              </option>
              <option className="text-black" value={"7"}>
                Last 7 days
              </option>
              <option className="text-black" value={"30"}>
                Last 30 days
              </option>
              <option className="text-black" value={"90"}>
                Last 3 Months
              </option>
              <option className="text-black" value={"180"}>
                Last 6 Months
              </option>
              <option className="text-black" value={"365"}>
                Last 1 year
              </option>
              {/* <option className="text-black" value={"custom range"}>
                custom range
              </option> */}
              {/* Add more options as needed */}
            </select>
          </div>
          {/* {isFromTo && (
            <>
              <div className="mb-4">
                <label
                  htmlFor="fromDate"
                  className="block text-gray-700 font-bold mb-2"
                >
                  From Date:
                </label>
                <input
                  type="date"
                  id="fromDate"
                  // value={fromDate}
                  // onChange={(e) => setFromDate(e.target.value)}
                  className="text-sm w-full  px-[30px] py-1  border border-gray-300 rounded-md text-gray-500 outline-none"
                />
              </div>
              <div className="mb-6">
                <label
                  htmlFor="toDate"
                  className="block text-gray-700 font-bold mb-2"
                >
                  To Date:
                </label>
                <input
                  type="date"
                  id="toDate"
                  // value={toDate}
                  // onChange={(e) => setToDate(e.target.value)}
                  className="text-sm w-full  px-[30px] py-1  border border-gray-300 rounded-md text-gray-500 outline-none"
                />
              </div>
            </>
          )} */}
        </div>
        {/* {Object.keys(commissionData).length > 0 ? (
          <CollegeReportRow commissionData={commissionData} />
        ) : (
          <ComponentLoading />
        )} */}
        {Object.keys(commissionData).length > 0 ? (
          <ReportTopRow commissionData={commissionData} />
        ) : (
          <ComponentLoading />
        )}

        <div className="flex flex-col gap-y-[30px] sm:flex-row justify-between">
          <div className="flex justify-center gap-x-[20px] md:gap-x-[30px]">
            <div className="font-bold text-[20px] capitalize">
              <p>{filterOptions}</p>
            </div>
          </div>
          <div className="flex gap-x-[20px] justify-center sm:flex-row gap-y-[20px] ">
            {/* <div className="relative">
              <div className="absolute top-[1px]   px-[10px] text-gray-500">
                <SearchIcon sx={{ fontSize: 22 }} />
              </div>
              <input
                className="text-sm  w-full sm:w-[270px] lg:w-[270px] pl-10 py-1 border placeholder:text-gray-500  border-gray-300 rounded-md outline-none"
                type="search"
                id="search"
                name="search"
                placeholder="Search here"
                required
              />
            </div> */}
            <div>
              <select
                className="text-sm w-full sm:w-[270px] lg:w-[270px] px-[30px] py-1  border border-gray-300 rounded-md text-gray-500 outline-none"
                id="courses"
                name="courses"
                value={filterOptions}
                onChange={(e) => setFilterOptions(e.target.value)}
                required
              >
                <option className="text-black" value="rep" selected>
                  Rep
                </option>
                <option className="text-black" value="agent">
                  Agent
                </option>
                <option className="text-black" value="admin">
                  Admin
                </option>
              </select>
            </div>
          </div>
        </div>
        <div className="p-3 rounded-2xl  shadow-md shadow-gray-400 mt-2 overflow-x-scroll w-[400px] sm:w-full">
          <ReportTh>
            {reportTable.length > 0 ? (
              reportTable.map((row) => <ReportTr tableData={row} />)
            ) : (
              <p className="px-2">No reports</p>
            )}
          </ReportTh>
        </div>
        {/* <div className="flex-col justify-center items-center h-2/3 animate-pulse">
          <p className="text-center text-[30px] opacity-10 font-bold break-words animate-pulse">
            Report Under Maintenance
          </p>
          <p className="text-center text-[20px] opacity-20 font-bold break-words">
            We currently working on it. We hope to have the revised version
            ready soon.
          </p>
        </div> */}
      </div>
    </>
  );
};

export default ReportComponent;
