import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function Home() {
  const navigate = useNavigate();
  const location = useLocation()

  useEffect(() => {
    if(location.pathname === '/'){
      navigate('/admin')
    }else{      
      navigate('dashboard'); // Redirect to /dashboard when component mounts
    }
  }, [navigate,location]);

  return null; // or any other component you want to render temporarily
}

export default Home
