import React, { useState, useEffect } from "react";
import {
  Home as HomeIcon,
  BorderColorRounded as BorderColorRoundedIcon,
  DeleteRounded as DeleteRoundedIcon,
  Flag as FlagIcon,
  AttachEmail as AttachEmailIcon,
  Person as PersonIcon,
  CalendarToday as CalendarTodayIcon,
  School as SchoolIcon,
} from "@mui/icons-material";
import {
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Grid,
} from "@mui/material";
import StudentDocs from "../StudentDocGeneration/StudentDocs";
import EditStudent from "../EditForms.jsx/EditStudent";

const StudentProfile = ({ studentData, commission, handleRefresh, onDelete }) => {
  const [commissionData, setCommissionData] = useState({});
  const [editStudentModal, setEditStudentModal] = useState(false);
  const [status, setStatus] = useState("pending");
  const [openDialog, setOpenDialog] = useState(false);
  const [newStatus, setNewStatus] = useState("");
  const [deleteDialog, setDeleteDialog] = useState(false); // New state for delete confirmation
  
  
  useEffect(() => {
    setCommissionData(commission);
  }, [commission]);

  const handleStatusChange = (event, newStatus) => {
    if (newStatus !== null) {
      setNewStatus(newStatus);
      setOpenDialog(true);
    }
  };

  const handleCloseDialog = (confirmed) => {
    if (confirmed) {
      setStatus(newStatus);
    }
    setOpenDialog(false);
  };

  // New handleDelete function
  const handleDelete = () => {
    setDeleteDialog(true); // Open delete confirmation dialog
  };

  const confirmDelete = (confirmed) => {
    if (confirmed && onDelete) {
      onDelete(studentData.id); // Call the delete function with the student ID
    }
    setDeleteDialog(false); // Close delete confirmation dialog
  };

  return (
    <Box className="relative w-full p-4 bg-gray-50">
      {/* Header with Edit and Delete Icons */}
      <Box className="flex justify-between mb-6">
        <Typography variant="h4" fontWeight="bold">
          Student Profile
        </Typography>
        <Box className="flex gap-4">
          <div onClick={() => setEditStudentModal(true)} className="icon-button">
            <BorderColorRoundedIcon fontSize="large" />
          </div>
          <div onClick={handleDelete} className="icon-button"> {/* Added onClick for delete */}
            <DeleteRoundedIcon fontSize="large" />
          </div>
        </Box>
      </Box>

      {/* Information Grid (2-Column Layout) */}
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Box className="p-4 bg-white shadow-md rounded-md">
            <Box className="flex flex-col gap-2 mt-2">
              <Typography variant="subtitle2" color="textSecondary">Address</Typography>
              <Box className="flex items-center mt-2">
                <HomeIcon color="primary" />
                <Typography className="ml-2">
                  {studentData?.address?.address}, {studentData?.address?.country}
                </Typography>
              </Box>
              <Typography variant="subtitle2" color="textSecondary">Guardian Details</Typography>
              <Box className="flex flex-col gap-2 mt-2">
                <Box className="flex items-center">
                  <PersonIcon color="primary" />
                  <Typography className="ml-2">Name: {studentData?.guardianName || "N/A"}</Typography>
                </Box>
                <Box className="flex items-center">
                  <PersonIcon color="primary" />
                  <Typography className="ml-2">Passport: {studentData?.guardianPassport || "N/A"}</Typography>
                </Box>
              </Box>
              <Typography variant="subtitle2" color="textSecondary">Academic Information</Typography>
              <Box className="flex flex-col gap-2 mt-2">
                <Box className="flex items-center">
                  <SchoolIcon color="primary" />
                  <Typography className="ml-2">College: {studentData?.college?.name || "N/A"}</Typography>
                </Box>
                <Box className="flex items-center">
                  <CalendarTodayIcon color="primary" />
                  <Typography className="ml-2">Course: {studentData?.course?.name || "N/A"}</Typography>
                </Box>
                <Box className="flex items-center">
                  <CalendarTodayIcon color="primary" />
                  <Typography className="ml-2">Intake: {studentData?.intake || "N/A"}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box className="p-4 bg-white shadow-md rounded-md">
            <Typography variant="subtitle2" color="textSecondary">Personal Details</Typography>
            <Box className="flex flex-col gap-2 mt-2">
              <Box className="flex items-center">
                <PersonIcon color="primary" />
                <Typography className="ml-2">Passport: {studentData?.passport || "N/A"}</Typography>
              </Box>
              <Box className="flex items-center">
                <CalendarTodayIcon color="primary" />
                <Typography className="ml-2">Age: {studentData?.age || "N/A"}</Typography>
              </Box>
              <Typography variant="subtitle2" color="textSecondary">Representative & Agent</Typography>
              <Box className="flex flex-col gap-2 mt-2">
                <Box className="flex items-center">
                  <PersonIcon color="primary" />
                  <Typography className="ml-2">Representative: {studentData?.representative?.name || "N/A"}</Typography>
                </Box>
                <Box className="flex items-center">
                  <PersonIcon color="primary" />
                  <Typography className="ml-2">Agent: {studentData?.agent?.name || "N/A"}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>

        {/* Full Width StudentDocs */}
        <Grid item xs={12}>
          <StudentDocs studentData={studentData} />
        </Grid>
      </Grid>

      {/* Centered Edit Modal */}
      <Dialog 
  open={editStudentModal} 
  onClose={() => setEditStudentModal(false)} 
  maxWidth="md" 
  fullWidth
  sx={{
    '& .MuiDialogPaper-root': {
      backgroundColor: 'transparent',  // Transparent background for the dialog
      boxShadow: 'none',
      borderRadius: '50px',  // Set the border radius for curved corners
      // Optional: Remove the default shadow if you want
    },
  }}
>
  <DialogContent sx={{ padding: 0, backgroundColor: 'transparent' }}>
    <EditStudent
      collegeData={studentData.college}
      setEditStudentModal={setEditStudentModal}
      student={studentData}
      handleRefresh={handleRefresh}
    />
  </DialogContent>
</Dialog>


      <Dialog open={openDialog} onClose={() => handleCloseDialog(false)}>
        <DialogTitle>Confirm Status Change</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to change the student's status to <strong>{newStatus}</strong>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={() => handleCloseDialog(true)} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteDialog} onClose={() => confirmDelete(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this student profile? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => confirmDelete(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={() => confirmDelete(true)} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default StudentProfile;
