import React, { useEffect, useState } from "react";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LanguageIcon from '@mui/icons-material/Language';
import { fetchStudentOfferLetterDataAPI } from "../../API/admin/GenerateStudentDocuments";
import { useParams } from "react-router-dom";
import EditField from "./EditingContact";
import htmlDocx from "html-docx-js/dist/html-docx";
import { 
  Document, 
  Packer, 
  Paragraph, 
  TextRun, 
  AlignmentType, 
  Table,
  TableRow,
  TableCell,
  WidthType,
  BorderStyle,
  VerticalAlign,
  Header
} from "docx";
import { saveAs } from "file-saver";
import { fetchStudentDocumentData,addStudentDocumentData,editStudentDocumentData } from "../../API/admin/DocumentData";

function OfferLetter() {
  const [showDropdown, setShowDropdown] = useState(false);
  const [studentOfferLetterData, setStudentOfferLetterData] = useState({});
  const [showSave, setShowSave] = useState(false); // Toggle for Save button

  const { studentId } = useParams();

  useEffect(() => {
    fetchStudentOfferLetterData();
    fetchStudentDocument()
  }, []);

  const today = new Date();
  const formattedDate = today.toLocaleDateString("en-GB");
  const isoFormattedDate = today.toISOString().split('T')[0];


  const fetchStudentOfferLetterData = async () => {
    try {
      const response = await fetchStudentOfferLetterDataAPI(studentId);
      if (response.status === 200) {
        setStudentOfferLetterData(response.data);
        document.title = `${response?.data?.name}-conditional-offer-letter`;
      }
    } catch (error) {
      console.log(error);
    }
  };


   
  const fetchStudentDocument = async () => {
    try {
      try {
        const response = await fetchStudentDocumentData(studentId);
        if (response.status === 200) {
          const data = response.data[0];
          console.log(data,"response.data");

          if (response.data.length > 0) {
            // Data exists, update state
            setValues({
              clooNO:data?.clooNO||` ATC/CLOO/${formattedDate}`,
              clooDate:data?.clooDate||isoFormattedDate,
            });

           
            console.log("Existing document data found", response.data);
          } else {
            addStudentDocument();
          }
        }
      } catch (error) {
        console.error("Error fetching student document data:", error);
      }
    } catch (error) {
      console.error("Error fetching student document data:", error);
    }
  };



  
  const addStudentDocument = async () => {
    try {
      const formData = {
        clooNO:values.clooNO,
        clooDate:values.clooDate,
        studentId
      };
      const response = await addStudentDocumentData(formData);

      if (response.status === 200) {
        console.log("Document Added Successfully");
      }
    } catch (error) {
      console.error("Error adding student document data:", error);
    }
  };


  const handleSave = async () => {
    try {
      const formData = {
        clooNO:values.clooNO,
        clooDate:values.clooDate,
        studentId
      };
      const response = await editStudentDocumentData(formData, studentId);
      if (response.status === 200) {
        setShowSave(false); // Hide Save button after saving
      }
    } catch (error) {
      console.error("Error updating student document data:", error);
    }
    // Call API to save document data
  };
  // Function to toggle between view and edit mode



  const handleDownload = () => {
      window.print();
  };


 
  // const downloadDocxFile = () => {
  //   const content = `
  //     <div style="font-family: Arial; font-size: 12pt;">
  //       <div style="text-align: center; margin-bottom: 20pt;">
  //         <img src="/assets/img/Acetek_logo.jpg" alt="AceTek Logo" width="140" />
  //         <p style="font-size: 10pt;">cpe Registration No: 200821327E</p>
  //         <p style="font-size: 10pt;">(21 June 2023 to 20 June 2027)</p>
  //         <p style="font-size: 14pt; font-weight: bold;">AceTek College Pte Ltd</p>
  //         <p style="font-size: 10pt;">info@acetek.edu.sg +(65) 8111 0897 www.acetek.edu.sg</p>
  //         <p style="font-size: 10pt;">Blk 167 Jalan Bukit Merah, #02-12 Connection One Tower 5, Singapore 150167</p>
  //       </div>
  //       <div style="margin-bottom: 20pt;">
  //         <p style="font-size: 12pt;">${formattedDate}</p>
  //         <p style="font-size: 12pt;">Ref: ATC/CLOO/${formattedDate}</p>
  //       </div>
  //       <div style="margin-bottom: 20pt;">
  //         <p style="font-size: 12pt;">Dear Ms. ${studentOfferLetterData?.name},</p>
  //         <p style="font-size: 12pt;">Passport No: ${studentOfferLetterData?.passport}</p>
  //         <p style="font-size: 12pt;">${studentOfferLetterData?.country}</p>
  //       </div>
  //       <div style="margin-bottom: 20pt;">
  //         <p style="font-size: 14pt; font-weight: bold;">CONDITIONAL LETTER OF OFFER FOR ${studentOfferLetterData?.course}</p>
  //         <p style="font-size: 12pt;">Thank you for your interest in the above-mentioned course.</p>
  //         <p style="font-size: 12pt;">With reference to your documents submitted for review, we are pleased to inform you that you have met the minimum entry requirements for the above course and are eligible to apply for admission. Kindly complete and submit the documents (with a certified translation in the English Language if necessary) listed in the attached admission checklist for application purposes. If there is no response from you within two weeks from the date of this letter, we will treat this application as void.</p>
  //       </div>
  //       <div style="margin-bottom: 20pt;">
  //         <p style="font-size: 14pt; font-weight: bold;">Details of the above course are as follows:</p>
  //         <table style="border-collapse: collapse; width: 100%;">
  //           <tr>
  //             <th style="border: 1pt solid black; padding: 5pt;">Course:</th>
  //             <td style="border: 1pt solid black; padding: 5pt;">${studentOfferLetterData?.course}</td>
  //           </tr>
  //           <tr>
  //             <th style="border: 1pt solid black; padding: 5pt;">Commencement Date:</th>
  //             <td style="border: 1pt solid black; padding: 5pt;">${new Date(studentOfferLetterData?.commencementDate).toLocaleDateString("en-GB")}</td>
  //           </tr>
  //           <tr>
  //             <th style="border: 1pt solid black; padding: 5pt;">Duration of Course:</th>
  //             <td style="border: 1pt solid black; padding: 5pt;">${studentOfferLetterData?.durationOfCourse}</td>
  //           </tr>
  //           <tr>
  //             <th style="border: 1pt solid black; padding: 5pt;">Application Fee:</th>
  //             <td style="border: 1pt solid black; padding: 5pt;">S$${studentOfferLetterData?.applicationFee} inclusive of 9% GST</td >
  //           </tr>
  //           <tr>
  //             <th style="border: 1pt solid black; padding: 5pt;">Course Fee:</th>
  //             <td style="border: 1pt solid black; padding: 5pt;">S$${studentOfferLetterData?.courseFee} inclusive of 9% GST</td>
  //           </tr>
  //           <tr>
  //             <th style="border: 1pt solid black; padding: 5pt;">Award:</th>
  //             <td style="border: 1pt solid black; padding: 5pt;">DIPLOMA IN HOSPITALITY MANAGEMENT</td>
  //           </tr>
  //           <tr>
  //             <th style="border: 1pt solid black; padding: 5pt;">Awarding Body:</th>
  //             <td style="border: 1pt solid black; padding: 5pt;">AceTek College Pte Ltd</td>
  //           </tr>
  //         </table>
  //       </div>
  //       <div style="margin-bottom: 20pt;">
  //         <p style="font-size: 12pt;">Please email info@acetek.edu.sg if you have any questions.</p>
  //       </div>
  //       <div style="margin-bottom: 20pt;">
  //         <p style="font-size: 12pt;">Yours sincerely,</p>
  //         <p style="font-size: 12pt;">Sudeep Sreedharan</p>
  //         <p style="font-size: 12pt;">Managing Director</p>
  //       </div>
  //     </div>
  //   `;
  //   const docx = htmlDocx.asBlob(content);
  //   saveAs(docx, "offer-letter.docx");
  // };

  // const downloadHtmlFile = () => {
  //   // Implement your .html download logic here
  //   console.log("Downloading HTML file...");
  // };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  // Create a new Date object representing the current date and time
 // This will give you the format "YYYY-MM-DD"

  const[values,setValues] = useState({
    clooNO :` ATC/CLOO/${formattedDate}`,
    clooDate:isoFormattedDate,
  })
  const handleFieldChange = (key, newValue) => {
    setValues((prevValues) => ({
      ...prevValues,
      [key]: newValue, // Update only the field corresponding to 'key'
    }));
    setShowSave(true); // Hide Save button after saving

  }

  return (
    <>
      {Object.keys(studentOfferLetterData).length > 0 ? (
        <>
        <div className=" px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse no-print fixed right-0">
            {showSave && (
              <button
                className="mt-3 w-full inline-flex justify-center rounded-md border border-blue-700 shadow-sm px-4 py-2 bg-blue-600 text-white text-base font-medium"
                onClick={handleSave}
              >
                Save
              </button>
            )}

            {/* Show Download button only if no edits are pending */}
            {!showSave && (
              <button
                className="mt-3 w-full inline-flex justify-center rounded-md border border-green-700 shadow-sm px-4 py-2 bg-green-600 text-white text-base font-medium"
                onClick={handleDownload}
              >
                Download
              </button>
            )}
          </div>
          <div className="bg-slate-300 w-full h-full flex flex-col justify-center items-center">
            {/* offerletter */}
            <div className="w-[210mm]  bg-white " id="offerLetter">
              {/* header */}
              <div className="flex gap-1 justify-center mt-2">
                <div>

                <div className=" flex  flex-col">
                  <img
                    src="/assets/img/Acetek_logo.jpg"
                    alt="Acetek Logo"
                    width={140}
                  />
                </div>
                <div className="text-[8px] text-center  text-[#2C3178] ">
                PEI Registration No: 200821327E <br />
                      (21 June 2023 to 20 June 2027) <br />
                </div>
                </div>

                <div>
                  <div className="flex gap-[17rem]">
                    <div className="text-[9px]   text-[#2C3178] mt-5">
                     
                    </div>
                    <div className="flex-col  gap-1 flex">
                      <span className="text-[#2C3178]  font-bold text-[21px] flex justify-start">
                        AceTek College Pte Ltd
                      </span>
                      <div className="flex gap-[21px] font-semibold">
                        {/* email icon */}
                        <div className="flex items-center gap-1">
                          <div className="bg-[#2C3178] w-3 h-3 flex justify-center items-center rounded-xl">
                            <EmailIcon
                              className="text-white"
                              style={{ fontSize: 8 }}
                            />
                          </div>
                          <span className="text-[9px] text-[#2C3178]">
                            info@acetek.edu.sg
                          </span>
                        </div>
                        {/* phone icon */}
                        <div className="flex items-center gap-1">
                          <div className="bg-[#2C3178] w-3 h-3 flex justify-center items-center rounded-xl">
                            <LocalPhoneIcon
                              className="text-white"
                              style={{ fontSize: 8 }}
                            />
                          </div>
                          <span className="text-[9px] text-[#2C3178]">
                            +(65) 8111 0897
                          </span>
                        </div>
                        {/* web icon */}
                        <div className="flex items-center gap-1">
                          <div className="bg-[#2C3178] w-3 h-3 flex justify-center items-center rounded-xl">
                            <LanguageIcon
                              className="text-white"
                              style={{ fontSize: 8 }}
                            />
                          </div>
                          <span className="text-[9px] text-[#2C3178]">
                            www.acetek.edu.sg
                          </span>
                        </div>
                      </div>
                      <div className="flex justify-end  font-semibold gap-1">
                      <div className="bg-[#2C3178] w-3 h-3  flex justify-center items-center rounded-xl">
                            <LocationOnIcon
                              className="text-white"
                              style={{ fontSize: 8 }}
                            />
                          </div>
                        <span className="text-[8.5px] text-[#2C3178] flex-col">
                            Blk 167 Jalan Bukit Merah, #02-12
                          Connection One Tower 5, Singapore 150167
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="bg-yellow-500 w-full h-1 mt-2"></div>
                </div>
              </div>

              {/* body */}
              <div className="m-10 text-[12px]">
                <div className="text-[12px] flex justify-between">
                  <span> <EditField
                fieldKey="clooDate"
                value={values?.clooDate}
                handleChange={handleFieldChange} // Pass the change handler
                isDateField={true}
                /></span>
                  <span>
                  <EditField
                fieldKey="clooNO"
                value={values.clooNO}
                handleChange={handleFieldChange} // Pass the change handler
                />
                  </span>
                </div>
                <div className="flex flex-col gap-4">
                  <span>
                    Dear&nbsp;{studentOfferLetterData?.salutation}.&nbsp;{studentOfferLetterData?.name} <br />
                    Passport No: {studentOfferLetterData?.passport} <br />
                     {studentOfferLetterData?.address} <br />
                    {studentOfferLetterData?.country} <br />
                  </span>
                  <span className="font-semibold uppercase">
                    CONDITIONAL LETTER OF OFFER FOR{" "}
                    {studentOfferLetterData?.course}
                  </span>
                  <span>
                    Thank you for your interest in the above-mentioned course.
                  </span>

                  <span className="me-10 flex flex-col gap-1">
                    With reference to your documents submitted for review, we
                    are pleased to inform you that you have met the minimum
                    entry requirements for the above course and are eligible to
                    apply for admission. Kindly complete and submit the
                    documents (with a certified translation in the English
                    Language if necessary) listed in the attached admission
                    checklist for application purposes. If there is no response
                    from you within two weeks from the date of this letter, we
                    will treat this application as void.
                    <br />
                    <span className="font-semibold">
                      Details of the above course are as follows:
                    </span>
                  </span>
                </div>
                <div className="mt-6">
                  <div className="flex flex-col">
                    <table className="w-full border-collapse">
                      <tbody>
                        <tr className="mb-4">
                          <td className="pb-3 pr-3 text-left">Course:</td>
                          <td className="pb-4 text-left">
                            <span className="font-bold  uppercase">
                              {studentOfferLetterData?.course}
                            </span>
                          </td>
                        </tr>
                        <tr className="mb-4">
                          <td className="pb-4 pr-4 text-left w-48 flex">
                            Commencement Date:
                          </td>
                          <td className="pb-4 text-left whitespace-nowrap">
                            {new Date(
                              studentOfferLetterData?.commencementDate
                            ).toLocaleDateString("en-GB")}
                          </td>
                        </tr>
                        <tr className="mb-4">
                          <td className="pb-8 pr-4 text-left">
                            Duration of Course:
                          </td>
                          <td className="text-left pt-5">
                            {studentOfferLetterData?.durationOfCourse}        
                            <br />
                            The Industrial Attachment is subject to the approval and issuance <br />
                            of a Training Work Permit by the Ministry of Manpower, Singapore
                          </td>
                        </tr>
                        <tr className="mb-4">
                          <td className="pb-7 pr-4 text-left">
                            Application Fee:
                          </td>
                          <td className="pb-4 text-left pt-8">
                            S${studentOfferLetterData?.applicationFee} inclusive
                            of 9% GST
                            <br />
                            (payable upon application
                            <br />
                            nonrefundable fees)
                          </td>
                        </tr>
                        <tr className="mb-4">
                          <td className="pb-10 pr-4 text-left">Course Fee:</td>
                          <td className="pb-4 text-left pt-4">
                            <span className="font-semibold">
                              S${studentOfferLetterData?.courseFee}{" "}
                            </span>{" "}
                            inclusive of 9% GST <br /> Course Fee is payable
                            after signing the Student Contract between <br />{" "}
                            you and AceTek.
                          </td>
                        </tr>
                        <tr className="mb-4">
                          <td className="pb-1 pt-2 pr-4 text-left">Award:</td>
                          <td className="pb-4 text-left pt-5">
                          {studentOfferLetterData?.course}
                          </td>
                        </tr>
                        <tr className="mb-4">
                          <td className="pt-2 pr-4 text-left">
                            Awarding Body:
                          </td>
                          <td className="pb-4 text-left pt-5">
                            AceTek College Pte Ltd
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <span className="mt-7">
                      Please email info@acetek.edu.sg if you have any questions.
                    </span>
                    <br />
                    <span>Yours sincerely</span>
                    <br />
                    <span className="mt-11">
                      Sudeep Sreedharan
                      <br />
                      Managing Director
                    </span>
                  </div>
                </div>
              </div>
              {/* <button onClick={handleDownload}>download</button>/ */}

              {/* close body */}
            </div>
          </div>
        </>
      ) : (
        <p>Fetching.....</p>
      )}
    </>
  );
}

export default OfferLetter;
