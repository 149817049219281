import React from "react";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useNavigate } from "react-router-dom";
// import CloseIcon from "@mui/icons-material/Close";
// import PaymentInvoice from "./PaymentInvoice";

const LatestPaymentCard = ({ payment }) => {
  // const [openInvoice, setIsInvoiceModal] = useState(false);
  // const [paymentReceipt, setPaymentReceipt] = useState({});

  // const openInvoiceModal = async () => {
  //   setIsInvoiceModal(true);
  // };
  const navigate = useNavigate();
  // Function to handle the payment status check
  const handlePaymentStatus = () => {
    if (payment.status === "pending") {
      // Navigate to the link
      navigate(
        `/admin/settle-payment/${payment?.studentName?.split(" ")[0]}-payment`,
        {
          state: { paymentId: payment._id },
        }
      );
    } else {
      // Call a function
      // openInvoiceModal();
      window.open(`/admin/student-invoice/${payment._id}`, "_blank");
    }
  };
  const paidBadge =
    "py-1 px-3 bg-green-600 rounded-lg text-xs font-bold text-white capitalize";
  const pendingBadge =
    "py-1 px-3 bg-red-600 rounded-lg text-xs font-bold text-white capitalize";
  return (
    <>
      <div className="">
        <div onClick={handlePaymentStatus}>
          <div className="bg-white w-44 shadow-gary-200 shadow-md p-[35px] relative rounded-[10px] m-[20px]  md:p-[35px] md:m-[0px]  hover:border-gray-500 hover:ring ring-gray-300 cursor-pointer transition">
            <div className="w-[70px] h-[70px] overflow-hidden absolute mt-[-65px] ml-[20px]">
              <img
                className="w-[100%] h-[100%] object-cover"
                src="/assets/img/dollar.png"
                alt="amount icon"
              />
            </div>
            <div className="flex flex-col gap-y-[5px] justify-center items-center mt-[10px]  ">
              <p
                className=" text-black text-sm text-center whitespace-nowrap capitalize my-1"
                style={{
                  maxWidth: "150px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  wordWrap: "break-word",
                  lineHeight: "1.2",
                }}
              >
                {payment?.studentName}
              </p>
              <p className=" text-gray-500 text-sm my-1">
                <span
                  className={
                    payment?.status === "paid" ? paidBadge : pendingBadge
                  }
                >
                  {payment?.status}
                </span>
              </p>
              <div className="">
                <p className="text-black whitespace-nowrap flex gap-2 text-sm">
                  Fees:
                  <span className="flex gap-1">
                    {payment?.currency === "INR" ? (
                      <p className="">
                        <CurrencyRupeeIcon sx={{ fontSize: 20 }} />
                      </p>
                    ) : (
                      <p className="">{payment?.currency}</p>
                    )}
                    <p className="font-extrabold ">{payment?.totalFees}</p>
                  </span>
                </p>
                <p className="text-black whitespace-nowrap flex gap-2 text-sm">
                  <p className="">App fees:</p>
                  <span className="flex gap-1">
                    {payment?.currency === "INR" ? (
                      <p className="">
                        <CurrencyRupeeIcon sx={{ fontSize: 20 }} />
                      </p>
                    ) : (
                      <p className="">{payment?.currency}</p>
                    )}
                    <p className="font-extrabold ">
                      {payment?.applicationFees}
                    </p>
                  </span>
                </p>
              </div>
              <div className="border-2 border-black w-full mt-[10px]" />
              <div className="flex items-center mt-[10px] gap-1">
                {payment?.outstandingAmount + payment.applicationFees !== 0 ? (
                  <>
                    {payment?.currency === "INR" ? (
                      <p className="">
                        <CurrencyRupeeIcon sx={{ fontSize: 20 }} />
                      </p>
                    ) : (
                      <p className="">{payment?.currency}</p>
                    )}
                    <p className="text-2xl font-extrabold ">
                      {payment?.outstandingAmount + payment.applicationFees}
                    </p>
                  </>
                ) : (
                  <p className="text-xl font-bold text-green-700 whitespace-nowrap">
                    Completed!
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {openInvoice && (
        <>
          <div className="fixed z-10 inset-0 overflow-y-auto top-16">
            <div className="flex items-center justify-center h-full  px-4">
              <div
                className="fixed inset-0 bg-gray-200 bg-opacity-75 transition-opacity"
                aria-hidden="true"
              ></div>

              <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:w-1/2 lg:w-1/3">
                Modal content
                <div className="p-4 overflow-y-auto">
                  <button
                    onClick={() => setIsInvoiceModal(false)}
                    className="float-right text-gray-500 hover:text-gray-800"
                  >
                    <CloseIcon />
                  </button>
                </div>
                <PaymentInvoice
                  paymentReceipt={payment._id}
                  setIsInvoiceModal={setIsInvoiceModal}
                />
              </div>
            </div>
          </div>
        <div className="absolute top-0 z-20 w-7/12 bg-white">
          <PaymentInvoice
            paymentReceipt={payment._id}
            setIsInvoiceModal={setIsInvoiceModal}
          />
        </div>
        </>
      )} */}
    </>
  );
};

export default LatestPaymentCard;
