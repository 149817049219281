import React from "react";
import { Link } from "react-router-dom";
import KeyIcon from "@mui/icons-material/Key";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";

const SettingsList = () => {
  return (
    <div className="fixed z-50 bg-gray-50 shadow-sm shadow-gray-300 rounded-sm right-5 top-20 w-60 pl-3 pr-3 py-2">
      <div className="flex gap-2 justify-between pl-4 pr-4">
        <span className="font-semibold text-sm mt-1">Settings List</span>
        <SettingsIcon />
      </div>
      <div className="border my-2 border-black"></div>
      <div className="grid grid-cols-1 gap-2">
        <Link
          to={"#"}
          className="gap-4 relative inline-flex items-center justify-start py-2 pl-4 pr-12 overflow-hidden font-semibold text-black transition-all duration-150 ease-in-out  hover:pl-10 hover:pr-6 bg-gray-50 group w-full"
        >
          <span class="absolute bottom-0 left-0 w-full h-[1px] transition-all duration-150 ease-in-out bg-black group-hover:h-full"></span>
          <span class="absolute right-0 pr-4 duration-200 ease-out group-hover:translate-x-12">
            <div className="">
              <KeyIcon />
            </div>
          </span>
          <span class="absolute left-0 pl-2.5 -translate-x-12 group-hover:translate-x-0 ease-out duration-200">
            <div className="text-white">
              <KeyboardArrowRightIcon />
            </div>
          </span>
          <span class="relative w-full text-left transition-colors duration-200 ease-in-out group-hover:text-white text-sm">
            Manage credentials
          </span>
        </Link>
        <Link
          to={"#"}
          className="gap-4 relative inline-flex items-center justify-start py-2 pl-4 pr-12 overflow-hidden font-semibold text-black transition-all duration-150 ease-in-out  hover:pl-10 hover:pr-6 bg-gray-50 group w-full"
        >
          <span class="absolute bottom-0 left-0 w-full h-[1px] transition-all duration-150 ease-in-out bg-black group-hover:h-full"></span>
          <span class="absolute right-0 pr-4 duration-200 ease-out group-hover:translate-x-12">
            <div className="">
              <LogoutIcon />
            </div>
          </span>
          <span class="absolute left-0 pl-2.5 -translate-x-12 group-hover:translate-x-0 ease-out duration-200">
            <div className="text-white">
              <KeyboardArrowRightIcon />
            </div>
          </span>
          <span class="relative w-full text-left transition-colors duration-200 ease-in-out group-hover:text-white text-sm">
            Logout
          </span>
        </Link>
      </div>
    </div>
  );
};

export default SettingsList;
