import React from "react";
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import CommissionPresetCompo from "../CommissionPre/CommissionPresetCompo";
import AssignAgentToRepCollege from "../AssignAgentToRep/AssignAgentToRepCollege";
import { deleteAgentAPI } from "../../../API/admin/AdminAPI";
import { useNavigate } from "react-router-dom";

const AgentPro = ({
  agentData,
  setIsEditAgent,
  handleCertificateCloseModal,
  setIsUploadCertificate,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleDeleteAgent = async () => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this Agent?"
    );

    if (confirmed) {
      const response = await deleteAgentAPI(agentData?._id);
      if (response.status) {
        alert("Agent deleted successfully.");
        navigate("/admin/agents");
      } else {
        alert("Something went wrong.");
      }
    }
  };

  return (
    <Box p={3} borderRadius={2} boxShadow={2} bgcolor="white">
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            Agent
          </Typography>
        </Grid>
        <Grid item>
          <Box display="flex" gap={1}>
            <IconButton
              color="primary"
              onClick={() => setIsUploadCertificate(true)}
              aria-label="Upload Certificate"
            >
              <UploadFileOutlinedIcon fontSize="medium" />
            </IconButton>
            <IconButton
              color="info"
              onClick={() => setIsEditAgent(true)}
              aria-label="Edit Agent"
            >
              <BorderColorRoundedIcon fontSize="medium" />
            </IconButton>
            <IconButton
              color="error"
              onClick={handleDeleteAgent}
              aria-label="Delete Agent"
            >
              <DeleteRoundedIcon fontSize="medium" />
            </IconButton>
          </Box>
        </Grid>
      </Grid>

      <Divider sx={{ my: 2 }} />

      <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography variant="body1" color="textSecondary" display="flex" alignItems="center">
              <HomeIcon sx={{ marginRight: 1 }} />
              {agentData?.address?.state}, {agentData?.address?.country}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="body1" color="textSecondary" display="flex" alignItems="center">
              <EmailIcon sx={{ marginRight: 1 }} />
              {agentData?.email}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="body1" color="textSecondary" display="flex" alignItems="center">
              <LocalPhoneIcon sx={{ marginRight: 1 }} />
              {agentData?.phone}
            </Typography>
          </Grid>
        </Grid>

      <Divider sx={{ my: 2 }} />

      <Box>
        {agentData.isRepOrCollegeExist ? (
          <CommissionPresetCompo personData={agentData} />
        ) : (
          <AssignAgentToRepCollege agentData={agentData} />
        )}
      </Box>
    </Box>
  );
};

export default AgentPro;
