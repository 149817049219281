import React, { useState, useEffect } from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AssignmentIcon from '@mui/icons-material/Assignment';
import RuleIcon from '@mui/icons-material/Rule';
import EditCourseModal from '../EditForms.jsx/EditCourse'; // Ensure path is correct
import { editCourseAPI, fetchSingleCourseAPI } from '../../../API/admin/AdminAPI';

const CourseProfile = ({ courseData, handleRefresh,fetchSingleCourse }) => {
  const [openModal, setOpenModal] = useState(false);
  const [editCourseData, setEditCourseData] = useState(courseData);
  
  const [feesTypes, setFeesType] = useState({
    tuitionFee: courseData?.feesTypes?.tuitionFee || 4500.0,
    medicalInsurance: courseData?.feesTypes?.medicalInsurance || 60.0,
    resourceFee: courseData?.feesTypes?.resourceFee || 440.0,
    feeProtection: courseData?.feesTypes?.feeProtection || 0.0,
  });
  
  const [isEdited, setIsEdited] = useState(false);
  const [courseFeesBeforeGST, setCourseFeesBeforeGST] = useState(0);
  const [gst, setGst] = useState(0);
  const [totalFeesPayable, setTotalFeesPayable] = useState(0);
  const [numberOfInstallments, setNumberOfInstallments] = useState(1);

  useEffect(() => {
    const totalCourseFees = feesTypes.tuitionFee + feesTypes.medicalInsurance + feesTypes.resourceFee + feesTypes.feeProtection;
    setCourseFeesBeforeGST(totalCourseFees);
    const calculatedGst = totalCourseFees * 0.09; // 9% GST
    setGst(calculatedGst);
    setTotalFeesPayable(totalCourseFees + calculatedGst);
  }, [feesTypes]);

  const handleInputChangeFees = (e, field) => {
    setFeesType({
      ...feesTypes,
      [field]: parseFloat(e.target.value) || 0,
    });
    setIsEdited(true);
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const requirementsArray = courseData.requirementDescription.split('\n');

  const handleFeeChange = (key, value) => {
    setFeesType({ ...feesTypes, [key]: value });
    setIsEdited(true);
  };

  const handleSubmit = async () => {
    try {
      const updatedCourseData = {
        ...editCourseData,
        fees:totalFeesPayable,
        feesTypes,
      };

      const response = await editCourseAPI(updatedCourseData, courseData._id);
      
      if (response.status === 200) {
        setEditCourseData(response.data.course); // Update local data with response data
        setFeesType(response.data.course.feesTypes); // Update local fees with the response data
        alert("Course updated successfully");
        fetchSingleCourse()
        handleRefresh(); // Trigger parent refresh after save
        setIsEdited(false);
      } else {
        alert("Failed to update course. Please try again.");
      }
    } catch (error) {
      console.error("Error updating course:", error);
      alert("An error occurred. Please check the console for more details.");
    }
  };

  return (
    <div className="max-w-full mx-auto p-8 bg-gray-50 rounded-lg shadow-lg">
      <h1 className="text-2xl font-semibold text-gray-800 text-center mb-6">
        {courseData.course}
      </h1>

      <div className="flex flex-col md:flex-row md:justify-between gap-6 mb-8">
        {/* Duration */}
        <div className="bg-white p-6 rounded-md shadow-md border border-gray-200 w-full md:w-1/3 flex items-center">
          <AccessTimeIcon className="text-blue-500 mr-3" />
          <div>
            <h3 className="text-gray-700 font-medium text-sm mb-1">Duration</h3>
            <p className="text-gray-800 text-lg">{courseData.duration}</p>
          </div>
        </div>

        {/* Course Fees */}
        <div className="bg-white p-6 rounded-md shadow-md border border-gray-200 w-full md:w-1/3 flex items-center">
          <AttachMoneyIcon className="text-green-500 mr-3" />
          <div>
            <h3 className="text-gray-700 font-medium text-sm mb-1">Course Fees</h3>
            <p className="text-gray-800 text-lg">{courseData.fees}</p>
          </div>
        </div>

        {/* Application Fees */}
        <div className="bg-white p-6 rounded-md shadow-md border border-gray-200 w-full md:w-1/3 flex items-center">
          <AssignmentIcon className="text-purple-500 mr-3" />
          <div>
            <h3 className="text-gray-700 font-medium text-sm mb-1">Application Fees</h3>
            <p className="text-gray-800 text-lg">{courseData.applicationFees}</p>
          </div>
        </div>
      </div>

      {/* Course Requirements */}
      <div className="bg-white p-6 rounded-md shadow-md border border-gray-200 mb-8">
        <div className="flex items-center mb-3">
          <RuleIcon className="text-indigo-500 mr-3" />
          <h2 className="text-xl font-semibold text-gray-800">Course Requirements</h2>
        </div>
        <ul className="space-y-3">
          {requirementsArray.map((requirement, index) => (
            <li key={index} className="text-gray-700">
              {requirement}
            </li>
          ))}
        </ul>
      </div>

      {/* Editable Fees Breakdown Table */}
      <div className='w-full flex justify-center'>
        <div className="bg-white w-1/2 p-6 rounded-md shadow-md border border-gray-200 mb-8">
          <h2 className="text-xl font-semibold text-gray-800 mb-3">Fees Breakdown</h2>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Description</th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Amount (S$)</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              <tr>
                <td className="px-6 py-4 whitespace-nowrap">Tuition Fee</td>
                <td className="px-6 py-4 whitespace-nowrap text-right">
                  <input
                    type="number"
                    value={feesTypes.tuitionFee}
                    onChange={(e) => handleFeeChange('tuitionFee', parseFloat(e.target.value))}
                    className="border rounded-md p-1 w-24 text-right"
                  />
                </td>
              </tr>
              <tr>
                <td className="px-6 py-4 whitespace-nowrap">Medical Insurance</td>
                <td className="px-6 py-4 whitespace-nowrap text-right">
                  <input
                    type="number"
                    value={feesTypes.medicalInsurance}
                    onChange={(e) => handleFeeChange('medicalInsurance', parseFloat(e.target.value))}
                    className="border rounded-md p-1 w-24 text-right"
                  />
                </td>
              </tr>
              <tr>
                <td className="px-6 py-4 whitespace-nowrap">Resource Fee</td>
                <td className="px-6 py-4 whitespace-nowrap text-right">
                  <input
                    type="number"
                    value={feesTypes.resourceFee}
                    onChange={(e) => handleFeeChange('resourceFee', parseFloat(e.target.value))}
                    className="border rounded-md p-1 w-24 text-right"
                  />
                </td>
              </tr>
              <tr>
                <td className="px-6 py-4 whitespace-nowrap">Fee Protection Scheme Fee</td>
                <td className="px-6 py-4 whitespace-nowrap text-right">
                  <input
                    type="number"
                    value={feesTypes.feeProtection}
                    onChange={(e) => handleFeeChange('feeProtection', parseFloat(e.target.value))}
                    className="border rounded-md p-1 w-24 text-right"
                  />
                </td>
              </tr>
              <tr>
                <td className="px-6 py-4 font-bold">Course Fees (Before GST)</td>
                <td className="px-6 py-4 whitespace-nowrap text-right font-bold">
                  {courseFeesBeforeGST.toFixed(2)}
                </td>
              </tr>
              <tr>
                <td className="px-6 py-4 font-bold">GST (9%)</td>
                <td className="px-6 py-4 whitespace-nowrap text-right font-bold">
                  {gst.toFixed(2)}
                </td>
              </tr>
              <tr>
                <td className="px-6 py-4 font-bold">Total Fees Payable</td>
                <td className="px-6 py-4 whitespace-nowrap text-right font-bold">
                  {totalFeesPayable.toFixed(2)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Update Button */}
      <div className="flex justify-center">
        <button
          className={`bg-blue-500 text-white px-4 py-2 rounded-md ${!isEdited && 'opacity-50 cursor-not-allowed'}`}
          onClick={handleSubmit}
          disabled={!isEdited}
        >
          Update Course
        </button>
      </div>

      {/* Edit Course Modal */}
      {openModal && (
        <EditCourseModal
          open={openModal}
          onClose={handleCloseModal}
          onSubmit={handleSubmit}
          editCourseData={editCourseData}
          setEditCourseData={setEditCourseData}
        />
      )}
    </div>
  );
};

export default CourseProfile;
