import React from "react";

const CollegeTableAssign = ({ children }) => {
  return (
    <table className="overflow-hidden rounded w-full">
      <thead className="text-black bg-gray-200">
        <tr>
          <th className="py-3 px-4 text-left">College Name</th>
          <th className="py-3 px-4 text-left">Action</th>
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
};

export default CollegeTableAssign;
