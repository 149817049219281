import React from "react";
// import SettlePayment from "./SettlePayment";
import { Link } from "react-router-dom";
// import PaymentInvoice from "./PaymentInvoice";
// import CloseIcon from "@mui/icons-material/Close";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

const PaymentTableRow = ({ payment }) => {
  // const [openInvoice, setIsInvoiceModal] = useState(false);
  // const openInvoiceModal = async () => {
  //   setIsInvoiceModal(true);
  // };
  const paidBadge =
    "py-1 px-3 bg-green-500 text-white rounded-sm text-xs font-bold  capitalize";
  const pendingBadge =
    "py-1 px-3 bg-red-500 rounded-sm text-xs font-bold text-white capitalize";
  return (
    <>
      <tr>
        <td className="border border-1 border-solid text-left text-xs p-5 whitespace-nowrap">
          {payment?.date}
        </td>
        <td
          className="border border-1 border-solid text-left text-sm p-5 whitespace-nowrap capitalize"
          style={{
            maxWidth: "300px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            wordWrap: "break-word",
            lineHeight: "1.2",
          }}
        >
          <Link
            className="hover:underline"
            to={`/admin/student/${payment?.studentName}`}
            state={{ studentId: payment?.studentId }}
          >
            {payment?.studentName}
          </Link>
        </td>
        <td className="border border-1 border-solid text-left text-sm p-5 whitespace-nowrap capitalize">
          {payment?.collegeName}
        </td>
        <td className="border border-1 border-solid text-left text-sm p-5 whitespace-nowrap">
          <span className="flex gap-1">
            {payment?.currency === "INR" ? (
              <p className="">
                <CurrencyRupeeIcon sx={{ fontSize: 15 }} />
              </p>
            ) : (
              <p className="">{payment?.currency}.</p>
            )}
            <p className="font-extrabold ">{payment?.totalFees}</p>
          </span>
        </td>
        <td className="border border-1 border-solid text-left text-sm p-5 whitespace-nowrap">
          <span className="flex gap-1">
            {payment?.currency === "INR" ? (
              <p className="">
                <CurrencyRupeeIcon sx={{ fontSize: 15 }} />
              </p>
            ) : (
              <p className="">{payment?.currency}.</p>
            )}
            <p className="font-extrabold ">{payment?.college}</p>
          </span>
        </td>
        <td className="border border-1 border-solid text-left text-sm p-5 whitespace-nowrap">
          {payment.repCommission ? (
            <span className="flex gap-1">
              {payment?.currency === "INR" ? (
                <p className="">
                  <CurrencyRupeeIcon sx={{ fontSize: 15 }} />
                </p>
              ) : (
                <p className="">{payment?.currency}.</p>
              )}
              <p className="font-extrabold ">{payment?.repCommission}</p>
            </span>
          ) : (
            <p className="font-extrabold text-red-600">No rep</p>
          )}
        </td>
        <td className="border border-1 border-solid text-left text-sm p-5 whitespace-nowrap">
          <span className="flex gap-1">
            {payment?.currency === "INR" ? (
              <p className="">
                <CurrencyRupeeIcon sx={{ fontSize: 15 }} />
              </p>
            ) : (
              <p className="">{payment?.currency}.</p>
            )}
            <p className="font-extrabold ">{payment?.agentCommission}</p>
          </span>
        </td>
        {/* <td className="border border-1 border-solid text-left text-sm p-5 whitespace-nowrap">
          <span className="flex gap-1">
            {payment?.currency === "INR" ? (
              <p className="">
                <CurrencyRupeeIcon sx={{ fontSize: 15 }} />
              </p>
            ) : (
              <p className="">{payment?.currency}.</p>
            )}
            <p className="font-extrabold ">{payment?.adminCommission}</p>
          </span>
        </td> */}
        <td className="border border-1 border-solid text-center text-sm p-5 whitespace-nowrap">
          <span
            className={payment?.status === "paid" ? paidBadge : pendingBadge}
          >
            {payment?.status}
          </span>
        </td>
        <td className="text-sm p-5 text-center border border-1 border-solid">
          {payment?.status === "pending" ? (
            <Link
              to={`/admin/settle-payment/${
                payment?.studentName?.split(" ")[0]
              } -${"payment"}`}
              state={{ paymentId: payment._id }}
            >
              <span className="p-1 px-3 bg-green-300 text-black hover:text-white rounded-sm text-xs font-bold hover:bg-green-600 transition cursor-pointer">
                Settle
              </span>
            </Link>
          ) : (
            <div className="flex flex-col gap-3">
              <Link
                to={`/admin/student-invoice/${payment._id}`}
                target="_blank"
              >
                <button
                  //  onClick={openInvoiceModal}
                  className="bg-blue-300 text-center text-black hover:text-white rounded-sm text-xs font-bold hover:bg-blue-600 transition cursor-pointer"
                >
                  Course fee Receipt
                </button>
              </Link>

              <Link
                to={`/admin/student-applicationFee/${payment._id}`}
                target="_blank"
              >
                <button className=" bg-blue-300 text-center text-black hover:text-white rounded-sm text-xs font-bold hover:bg-blue-600 transition cursor-pointer">
                  Application Fee Receipt
                </button>
              </Link>
            </div>
          )}
        </td>
      </tr>
      {/* {openInvoice && (
        <>
          <div className="fixed z-10 inset-0 overflow-y-auto top-16">
            <div className="flex items-center justify-center h-full  px-4">
              <div
                className="fixed inset-0 bg-gray-200 bg-opacity-75 transition-opacity"
                aria-hidden="true"
              ></div>

              <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:w-1/2 lg:w-1/3">
                Modal content
                <div className="p-4 overflow-y-auto">
                  <button
                    onClick={() => setIsInvoiceModal(false)}
                    className="float-right text-gray-500 hover:text-gray-800"
                  >
                    <CloseIcon />
                  </button>
                </div>
                <PaymentInvoice
                  paymentReceipt={payment._id}
                  setIsInvoiceModal={setIsInvoiceModal}
                />
              </div>
            </div>
          </div>
        </>
      )} */}
    </>
  );
};

export default PaymentTableRow;
