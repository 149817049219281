import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const AgentProtectedRoute = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const agentBasicTokens = localStorage.getItem("agentToken");
    console.log(agentBasicTokens);

    if (!agentBasicTokens) {
      navigate("/agent/registration");
    } else {
      navigate("/agent/agentmainapplication");
      // localStorage.removeItem("agentToken");
      // navigate("/agent/login");
    }
  }, []);

  return <>{props.children}</>;
};

export default AgentProtectedRoute;
