import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function AgentHome() {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/agent");
    } else {
      navigate('dashboard');
    }
  }, [navigate, location]);
  return null;
}
export default AgentHome;
