import React, { useEffect, useState } from "react";
import { editRepData, fetchCollegesAPI } from "../../../API/admin/AdminAPI";
import Loader from "../CreateForms/Loader";
import { useNavigate } from "react-router-dom";

const EditRep = ({ repData, handleCloseModal }) => {
  // const [countries, setCountries] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [repExists, setRepExists] = useState(false);
  const [colleges, setColleges] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [formData, setFormData] = useState({
    id: repData._id,
    fullName: repData?.name,
    email: repData?.email,
    phone: repData?.phone,
    state: repData?.address?.state,
    country: repData?.address?.country,
  });
  const navigate = useNavigate();
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(formData.email);
    setIsEmailValid(isValidEmail);
  };
  const handlePassChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    const fetchCollegesName = async () => {
      try {
        const collegeName = await fetchCollegesAPI();
        if (collegeName) {
          setColleges(collegeName?.data);
        } else {
          setColleges([]);
        }
      } catch (error) {
        setColleges([]);
      }
    };
    fetchCollegesName();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const errors = validateForm();
    if (isEmailValid && Object.keys(errors).length === 0) {
      setIsLoader(true);
      try {
        const response = await editRepData(formData, formData.id);
        if (response.status === 200) {
          setIsLoader(false);
          navigate("/admin/representatives");
        } else if (response.status === 409) {
          setRepExists(true);
        }
      } catch (error) {
        setIsLoader(false);
        console.log(error);
      }
    } else {
      setIsLoader(false);
      setFormErrors(errors);
    }
  };
  const validateForm = () => {
    const errors = {};
    // Validate each input here
    if (formData.fullName.trim() === "") {
      errors.fullName = "Full Name is required";
    }
    if (formData.email.trim() === "") {
      errors.email = "Email is required";
    }
    if (formData.phone.trim() === "") {
      errors.phone = "Phone is required";
    }
    if (formData.state.trim() === "") {
      errors.state = "State is required";
    }
    if (formData.country.trim() === "") {
      errors.country = "Country is required";
    }
    return errors;
  };
  return (
    <div className="flex justify-center items-center md:flex py-[20px] bg-white">
      <div className="w-full py-[10px] rounded-2xl shadow-lg shadow-gray-500">
        <div className="w-[95%] mx-auto">
          {/* heading */}
          <div className=" text-center md:py-[20px] text-[25px] md:text-left">
            <p className="underline underline-offset-8">Add Representatives</p>
          </div>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-3 justify-between">
            {/* name */}
            <div>
              <label
                className="block mb-2 font-medium text-sm"
                htmlFor="fullName"
              >
                Full Name:
              </label>
              <input
                className="block px-4 py-2 w-full border placeholder:text-gray-500  border-gray-300 rounded-lg"
                type="text"
                id="fullName"
                name="fullName"
                placeholder="Full Name"
                required
                value={formData.fullName}
                onChange={handleInputChange}
              />
              {formErrors.fullName && (
                <span className="text-xs text-red-600">
                  {formErrors.fullName}
                </span>
              )}
            </div>
            {/* mail id */}
            <div>
              <label
                className="block mb-2 font-medium text-sm"
                htmlFor="emailAddress"
              >
                Email Address:
              </label>
              <input
                className="block px-4 py-2 w-full border placeholder:text-gray-500  border-gray-300 rounded-lg"
                type="email"
                id="emailAddress"
                name="email"
                placeholder="Email Address"
                required
                value={formData.email}
                onChange={handleInputChange}
                onBlur={validateEmail}
                readOnly
              />
              {!isEmailValid && (
                <span className="text-xs text-red-600">
                  Please enter a valid email address.
                </span>
              )}
              {repExists && (
                <span className="text-xs text-red-600">
                  Email already exist !
                </span>
              )}
              {formErrors.email && (
                <span className="text-xs text-red-600">{formErrors.email}</span>
              )}
            </div>
            <div>
              <label
                className="block mb-2 font-medium text-sm"
                htmlFor="contactNumber"
              >
                Phone:
              </label>
              <input
                className="block px-4 py-2 w-full border placeholder:text-gray-500  border-gray-300 rounded-lg"
                type="phone"
                id="phone"
                name="phone"
                placeholder="Contact Number"
                value={formData.phone}
                onChange={handlePassChange}
                required
              />
              {formErrors.phone && (
                <span className="text-xs text-red-600">{formErrors.phone}</span>
              )}
            </div>
          </div>
          <p className="block my-3 font-medium text-sm" htmlFor="Address">
            Address:
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-3 my-[10px]">
            <div>
              <input
                className="block w-full px-4 py-2 border placeholder:text-gray-500  border-gray-300 rounded-lg"
                type="text"
                id="state"
                name="state"
                placeholder="State"
                value={formData.state}
                onChange={handleInputChange}
                required
              />
              {formErrors.state && (
                <span className="text-xs text-red-600">{formErrors.state}</span>
              )}
            </div>
            <div>
              <input
                className="block w-full px-4 py-2 border placeholder:text-gray-500  border-gray-300 rounded-lg"
                type="text"
                id="country"
                name="country"
                placeholder="country"
                value={formData.country}
                onChange={handleInputChange}
                required
              />
              {formErrors.country && (
                <span className="text-xs text-red-600">
                  {formErrors.country}
                </span>
              )}
            </div>
          </div>
          {/* section six */}
          <div className="flex justify-end gap-x-[20px]">
            <button
              onClick={handleCloseModal}
              className=" py-[8px] md:ease-in duration-300 mt-4 bg-black hover:bg-red-600  text-sm text-white font-bold px-2  rounded"
              type="submit"
            >
              cancel
            </button>
            <button
              onClick={handleSubmit}
              className=" ease-in duration-300 mt-4 bg-red-500 hover:bg-green-600  text-sm text-white font-bold  py-2 px-3  rounded"
              type="submit"
              disabled={isLoader}
            >
              {!isLoader ? <span>save</span> : <Loader />}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditRep;
