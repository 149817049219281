import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { deleteCoursesAPI } from "../../../API/admin/AdminAPI";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material";
import { Link } from "react-router-dom";
import EditCourseModal from "../EditForms.jsx/EditCourse";

const CoursesTable = ({ course, onUpdateCourse, fetchCourse }) => {
  const [openModal, setOpenModal] = useState(false); // State to control modal visibility
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false); // State for delete confirmation dialog
  const [editCourseData, setEditCourseData] = useState({
    ...course,
    courseType: course.courseType || "",
    requirementDescription: course.requirementDescription || "",
  });

  // Open and close modal handlers
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  // Open and close delete dialog handlers
  const handleOpenDeleteDialog = () => setOpenDeleteDialog(true);
  const handleCloseDeleteDialog = () => setOpenDeleteDialog(false);

  // Handle course deletion
  const handleDeleteCourse = async () => {
    try {
      const response = await deleteCoursesAPI(course._id);
      if (response.status === 200) {
        alert("Course deleted successfully");
        fetchCourse();
      }
      handleCloseDeleteDialog();
    } catch (error) {
      console.log("Error deleting course:", error);
    }
  };

  // Update handler for course edit
  const handleCourseUpdated = (updatedCourse) => {
    onUpdateCourse(updatedCourse); // Update the course list
  };

  // Check if the course has no fees or fees are zero
  const isFeesMissingOrZero = !course.fees || course.fees === 0;

  return (
    <>
      <tr key={course?._id}>
        <td className="border border-1 border-solid text-left text-sm p-[8px] capitalize hover:underline">
          <Link to={`/admin/course/${course.course}`} state={{ courseId: course?._id }}>
            {course.course}
          </Link>
          {isFeesMissingOrZero && (
            <div className="text-sm text-red-500 mt-1">⚠️ Fees not set</div> // Warning div next to course name
          )}
        </td>
        <td className="border border-1 border-solid text-left text-sm p-[8px]">
          {course.duration}
        </td>
        <td className="border border-1 border-solid text-left text-sm p-[8px]">
          {course.fees}
        </td>
        <td className="border border-1 border-solid text-left text-sm p-[8px]">
          {course.applicationFees}
        </td>
        <td className="border border-1 border-solid text-left text-sm p-[8px]">
          <ol>
            {course?.requirementDescription
              ?.split("\n")
              .filter((requirement) => requirement.trim() !== "")
              .map((requirement, index) => (
                <li key={index} className="flex gap-2">
                  <span>{index + 1}.</span>
                  <p>{requirement.trim()}</p>
                </li>
              ))}
          </ol>
        </td>
        <td className="text-[30px] text-gray-500 text-center border border-1 border-solid p-[8px]">
          <button
            onClick={handleOpenModal}
            className="bg-blue-500 hover:bg-blue-400 text-black font-bold py-2 px-2 rounded inline-flex items-center"
          >
            <EditIcon />
          </button>
          <button
            onClick={handleOpenDeleteDialog}
            className="ml-2 bg-red-500 hover:bg-red-400 text-white font-bold py-2 px-2 rounded inline-flex items-center"
          >
            <DeleteIcon />
          </button>
        </td>
      </tr>

      {/* Edit Course Modal */}
      <EditCourseModal
        open={openModal}
        onClose={handleCloseModal}
        editCourseData={editCourseData}
        setEditCourseData={setEditCourseData}
        onCourseUpdated={handleCourseUpdated} // Pass update handler
      />

      {/* Confirmation Dialog for Deletion */}
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete this course? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteCourse} color="secondary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CoursesTable;
