import React from "react";
import { useEffect, useState } from "react";
import FormDetailsNavBar from "../FormDetailsNav/FormDetailsNavBar";
import { ApplicationCheckAPI } from "../../../API/Agent/AgentAPI";
import ApplicantInformation from "../ApplicantInformation/ApplicantInformation";
import BusinessInformation from "../BusinessInformation/BusinessInformation";
import StudentsRecruitment from "../StudentsRecruitment/StudentsRecruitment";
import BusinessReference from "../BusinessReference/BusinessReference";
import ApplicationVerification from "../ApplicationVerification/ApplicationVerification";
import SupportingDocuments from "../SupportingDocuments/SupportingDocuments";

const AgentMainApplication = () => {
  const adminToken = localStorage.getItem("agentToken");
  const [applicationSteps, setApplicationSteps] = useState({});
  const [formfilledData, setformfilledData] = useState({});
  const [formfilledPan, setformfilledPan] = useState({});
  const [formfilledBank, setformfilledBank] = useState({});
  const [formfillesReferance, setFormfilledReference] = useState({});
  const [formfillesBusinessReferance, setFormfilledBusinessReference] =
    useState({});
  const [formfilledDocument, setFormfilledDocument] = useState({});
  const [formIndividualDocuments, setFormIndividualDocuments] = useState({});
  const [approved, setApproved] = useState({});
 
  const [referenceRejected, setReferenceRejected] = useState({});
  const [referenceApproved, setReferenceApproved] = useState({});
  const[finalApproval,setFinalApproval]=useState({})

  useEffect(() => {
    const handleStatusCheck = async () => {
      try {
        const response = await ApplicationCheckAPI(adminToken);
        if (response && response.data) {
          const { data } = response;
          console.log(data, "data");
          setApplicationSteps(data.applicationSteps);
          setFinalApproval(data)
          setformfilledData(data.applicantInformation);
          setformfilledPan(data.studentRecruitment);
          setformfilledBank(data.businessInformation);
          setFormfilledReference(data.businessReference.referee1);
          setFormfilledBusinessReference(data.businessReference.referee2);
          setFormfilledDocument(data.supportingDocuments.applicationAsCompany);
          setFormIndividualDocuments(data.supportingDocuments.applicationAsIndividual);
          setApproved(data?.applicationSteps);
       
          setReferenceApproved(data.businessReference);
          setReferenceRejected(data.businessReference);
        } else {
          console.error("Invalid response from the API");
        }
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
      }
    };
    handleStatusCheck();
  }, [adminToken]);

  return (
    <div>
      <FormDetailsNavBar />
      <div className="flex flex-col justify-center items-center">
        <ApplicationVerification Status={applicationSteps} />
        <form>
          <div className="flex flex-col justify-center items-center pt-10">
            <ApplicantInformation
              setApplicationSteps={setApplicationSteps}
              applicationSteps={applicationSteps}
              formfilledData={formfilledData}
              setformfilledData={setformfilledData}
            
            />
            <BusinessInformation
              setApplicationSteps={setApplicationSteps}
              applicationSteps={applicationSteps}
              formfilledBank={formfilledBank}
              setformfilledBank={setformfilledBank}
          
            />
            <StudentsRecruitment
              setApplicationSteps={setApplicationSteps}
              applicationSteps={applicationSteps}
              formfilledPan={formfilledPan}
              setformfilledPan={setformfilledPan}
            
            />
            <BusinessReference
              setApplicationSteps={setApplicationSteps}
              applicationSteps={applicationSteps}
              formfillesReferance={formfillesReferance}
              setFormfilledReference={setFormfilledReference}
              formfillesBusinessReferance={formfillesBusinessReferance}
              setformfillesBusinessReferance={setFormfilledBusinessReference}
              setReferenceApproved={setReferenceApproved}
              setReferenceRejected={setReferenceRejected}
              referenceApproved={referenceApproved}
              referenceRejected={referenceRejected}
            />
            <SupportingDocuments
              setApplicationSteps={setApplicationSteps}
              applicationSteps={applicationSteps}
              formfilledDocument={formfilledDocument}
              setFormfilledDocument={setFormfilledDocument}
              formIndividualDocuments={formIndividualDocuments}
              setFormIndividualDocuments={setFormIndividualDocuments}
              finalApproval={finalApproval}
              setFinalApproval={setFinalApproval}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AgentMainApplication;
