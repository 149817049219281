import React, { useEffect, useState } from "react";
import HomeIcon from "@mui/icons-material/Home";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import PhoneIcon from "@mui/icons-material/Phone";

import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import FlagIcon from "@mui/icons-material/Flag";
const AgentCollageDetails = ({ collegesData: college }) => {
  //   const navigate = useNavigate();
  const [isEditCollege, setIsEditCollege] = useState(false);
  //   const handleOpenEditModal = () => {
  //     isAddCourse(false);
  //     isAddStudent(false);
  //     setIsEditCollege(true);
  //   };
  //   const handleEditModal = () => {
  //     setIsEditCollege(false);
  //   };
  const [collegesData, setCollegeData] = useState({});
  useEffect(() => {
    setCollegeData(college);
  }, [college]);

  //   const handleDeleteCollege = async () => {
  //     const shouldDelete = window.confirm(
  //       "Are you sure you want to delete this college?"
  //     );
  //     if (!shouldDelete) {
  //       return; // User canceled the deletion
  //     }

  //     try {
  //       const response = await deleteCollegeAPI(collegesData._id);
  //       if (response?.status === 200) {
  //         window.alert("College deleted successfully!");
  //         navigate("/admin/colleges");
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  return (
    <div>
      <div className="relative">
        <div
          className={`flex flex-col cursor-default ${
            isEditCollege && `blur-md`
          }`}
        >
          <div className="flex w-full md:justify-between">
            <div>
              <img
                className="h-20"
                src={`${process.env.REACT_APP_BACKEND_URL}\\uploads\\${collegesData?.logo_name}`}
                alt="Logo here"
              />
            </div>

            {/* <div className="md:flex gap-2">
            <div
              onClick={handleOpenEditModal}
              className=" flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 hover:bg-black transition group"
            >
              <BorderColorRoundedIcon
                sx={{ fontSize: 25 }}
                className="text-black-600 group-hover:text-white transition"
              />
            </div>
            <div
              onClick={handleDeleteCollege}
              className=" flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 hover:bg-red-600 transition group"
            >
              <DeleteRoundedIcon
                sx={{ fontSize: 25 }}
                className="text-black-600 group-hover:text-white transition"
              />
            </div>
          </div> */}
          </div>
          <div className="py-4">
            <div className="flex gap-x-[10px] pb-[15px] items-center">
              <div className="text-[20px]">
                <AttachMoneyIcon />
              </div>
              <div>
                <p className="text-gray-800">{collegesData?.currency}</p>
              </div>
            </div>
            <div className="flex gap-x-[10px] pb-[15px] items-center">
              <div className="text-[20px]">
                <HomeIcon />
              </div>
              <div>
                <p className="text-gray-800">{collegesData?.address?.state}</p>
              </div>
            </div>
            <div className="flex gap-x-[10px] pb-[15px] items-center">
              <div className="text-[20px]">
                <FlagIcon />
              </div>
              <div>
                <p className="text-gray-800">
                  {collegesData?.address?.country}
                </p>
              </div>
            </div>
            <div className="flex gap-x-[15px] ">
              <div className="flex gap-x-[10px]">
                <div className="text-[20px]">
                  <PhoneIcon />
                </div>
                <div>
                  <p className="text-gray-800">{collegesData?.phone}</p>
                </div>
              </div>
              <div className="flex items-center ">
                <div className="text-[20px]">
                  <AttachEmailIcon />
                </div>
                <div>
                  <p className="text-gray-800 px-2">{collegesData?.email}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* {isEditCollege && (
          <div className="absolute top-0 z-10 w-full">
            <EditColleges
              handleCloseModal={handleEditModal}
              data={collegesData}
              setCollegeData={setCollegeData}
            />
          </div>
        )} */}
      </div>
    </div>
  );
};

export default AgentCollageDetails;
