import React, { useEffect, useState } from "react";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LanguageIcon from "@mui/icons-material/Language";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { fetchStudentApplicationInvoiceAPI } from "../../API/admin/GenerateStudentDocuments";
import { useParams } from "react-router-dom";

const ApplicationFee = () => {
  const handleDownload = () => {
    window.print();
  };

  const [studentInvoice, setStudentInvoice] = useState([]);
  const [studentInvoiceData, setStudentInvoiceData] = useState({});

  const { paymentId } = useParams();

  const fetchStudentOfferLetterData = async () => {
    try {
      const response = await fetchStudentApplicationInvoiceAPI(paymentId);

      if (response.status === 200) {
        setStudentInvoice(response.data);

        if (response.data.length > 0) {
          setStudentInvoiceData(response.data[0]);
        }
        document.title = `${response.data[0]?.studentName}-payment-receipt`;
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchStudentOfferLetterData();
  }, [paymentId]);

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectDropdownValue = (data) => {
    setStudentInvoiceData(data);
  };

  return (
    <>
        <div className=" px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse no-print fixed right-0">
          <button
            className="mt-3 w-full inline-flex justify-center rounded-md border border-green-700 shadow-sm px-4 py-2 bg-green-600 text-white text-base font-medium hover:text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={handleDownload}
          >
            Download
          </button>
        </div>
      <div className="bg-slate-300 w-full h-full flex flex-col justify-center items-center">
        <div className="w-[210mm] bg-white " id="applicationFee">
          {/* header */}
          <div className="flex gap-1 justify-center mt-2">
            <div>
              <div className=" flex  flex-col">
                <img
                  src="/assets/img/Acetek_logo.jpg"
                  alt="Acetek Logo"
                  width={140}
                />
              </div>
              <div className="text-[8px] text-center  text-[#2C3178] ">
                PEI Registration No: 200821327E <br />
                (21 June 2023 to 20 June 2027) <br />
              </div>
            </div>

            <div >
              <div className="w-[37rem]">

              </div>

            </div>
          </div>

            {/* body */}

          <div className="mt-8 m-4 mb-36 p-6">
            <div className="flex justify-center">
              <u>
                <h3 className="font-bold">APLICATION FEE RECEIPT</h3>
              </u>
            </div>

            <div className="flex flex-col font-semibold  items-end text-[12px]">
              <span>
                Receipt No.: &nbsp; {studentInvoiceData?.applicationNo}A{" "}
              </span>
              <span className="relative right-[4.99rem]">
                Date:
                {new Date(studentInvoiceData?.date).toLocaleDateString("en-GB")}
              </span>
            </div>

            <div>
              <table>
                <tbody className="text-[12px] mt-10 font-semibold">
                  <tr>
                    <td className="border p-2 border-black w-[200px] h-10">
                      Student Name
                    </td>
                    <td className="border border-black w-[560px] p-2">
                      {studentInvoiceData?.studentName}
                    </td>
                  </tr>
                  <tr>
                    <td className="border p-2 border-black w-[200px] h-10">
                      Student NRIC /FIN/ <br />
                      Passport No.
                    </td>
                    <td className="border border-black w-[560px] p-2">
                      {studentInvoiceData?.passportNo}
                    </td>
                  </tr>
                  <tr>
                    <td className="border p-2 relative  border-black w-[200px] h-10">
                      Being Payment for
                    </td>
                    <td className="border border-black w-[560px]">
                      <div className="p-3 pl-9">
                        <span className="font-normal flex gap-2 items-center">
                          <input type="checkbox" checked /> Application fee
                          (including GST){" "}
                          <span className="font-semibold">
                            (Non-refundable)
                          </span>
                        </span>
                        <span className="font-normal flex gap-2 items-center">
                          <input
                            type="checkbox"
                            checked={!!studentInvoiceData?.studentPassExtension}
                          />{" "}
                          Course fee (including GST)
                        </span>
                        <span className="font-normal flex gap-2 items-center">
                          <input
                            type="checkbox"
                            checked={!!studentInvoiceData?.studentPassExtension}
                          />{" "}
                          Re-Assessment fee (including GST){" "}
                          <span className="font-semibold">
                            (Non-refundable)
                          </span>
                        </span>
                        <span className="font-normal flex gap-2 items-center">
                          <input
                            type="checkbox"
                            checked={!!studentInvoiceData?.studentPassExtension}
                          />{" "}
                          Student Pass Extension (including GST){" "}
                          <span className="font-semibold">
                            (Non-refundable)
                          </span>
                        </span>
                        <span className="font-normal flex gap-2 items-center">
                          <input
                            type="checkbox"
                            checked={!!studentInvoiceData?.others}
                          />{" "}
                          Others
                          <div className="h-[1px] bg-black w-full mt-5"></div>
                        </span>
                        <span className="font-normal flex gap-2 items-center">
                          <input
                            type="checkbox"
                            checked={!!studentInvoiceData?.remarks}
                          />{" "}
                          Remarks
                          <div className="h-[1px] bg-black w-full mt-5"></div>
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="border p-2 border-black w-[200px] h-10">
                      Amount Received $SGD
                    </td>
                    <td className="border border-black w-[560px] p-2">
                      {studentInvoiceData?.applicationFee}
                    </td>
                  </tr>
                  <tr>
                    <td className="border p-2 border-black w-[200px] h-10">
                      Outstanding Balance $SGD
                    </td>
                    <td className="border border-black w-[560px] p-2">
                      {studentInvoiceData?.outstandingAmount}
                    </td>
                  </tr>
                  <tr>
                    <td className="border p-2 relative bottom-12 border-black w-[200px] h-10">
                      Payment mode
                    </td>
                    <td className="border border-black w-[560px]">
                      <div className="p-3 pl-9">
                        <span className="font-normal flex gap-2 items-center">
                          <input type="checkbox" /> Application fee (including
                          GST)
                        </span>
                        <span className="font-normal flex gap-2 items-center">
                          <input type="checkbox" /> Bank Deposit/Transfer Slip
                        </span>
                        <span className="font-normal flex gap-2 items-center">
                          <input type="checkbox" /> Telegraphic Transfer
                        </span>
                        <span className="font-normal flex gap-2 items-center">
                          <input type="checkbox" /> Paynow
                        </span>
                        <span className="font-normal flex gap-2 items-center">
                          <input type="checkbox" /> Cheque No./Date
                          <div className="flex">
                            <div className="h-[1px] w-[70px] mt-4 bg-black"></div>
                            /
                            <div className="h-[1px] w-[70px] bg-black mt-4"></div>
                          </div>
                        </span>
                        <span className="font-normal flex gap-2 items-center">
                          <input type="checkbox" /> Others
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="border p-2 border-black w-[200px] h-10">
                      Course Enrolled
                    </td>
                    <td className="border border-black w-[560px] p-2">
                      {studentInvoiceData?.courseEnrolled}
                    </td>
                  </tr>
                  <tr>
                    <td className="border p-2 border-black w-[200px] h-10">
                      Issuer’s Name
                    </td>
                    <td className="border border-black w-[560px] p-2">
                      {studentInvoiceData?.issuerName}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplicationFee;
