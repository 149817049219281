import React, { useEffect, useState } from "react";
import Layout from "../../../components/AdminComponents/Layout/Layout";
import PaymentLogs from "../../../components/AdminComponents/PaymentLogs/PaymentLogs";
import ComponentLoading from "../../CommonPreloader/ComponentLoading";

const PaymentLogPage = () => {
  const [componentLoader, setComponentLoader] = useState(true);
  useEffect(() => {
    if (componentLoader) {
      // Set a delay of 3 seconds
      const timer = setTimeout(() => {
        setComponentLoader(false);
      }, 500);

      // Cleanup function to clear the timer if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [componentLoader]);
  return (
      <Layout>
        {!componentLoader ? <PaymentLogs /> : <ComponentLoading />}
      </Layout>
  );
};

export default PaymentLogPage;
