import React from "react";
import { Link } from "react-router-dom";

const AgentListTbody = ({ agents }) => {
  return (
    <>
        <tr key={agents._id}>
          <td className="border border-1 border-solid text-center text-sm p-[8px] capitalize333 whitespace-nowrap capitalize">
            <Link
              to={`${agents.name}`}
              state={{ agentId: agents._id }}
              className="hover:underline"
            >
              {agents?.name}
            </Link>
          </td>
          <td className="border border-1 border-solid text-center text-sm p-[8px]">
            {agents?.email}
          </td>
          <td className="border border-1 border-solid text-center text-sm p-[8px]">
            {agents?.phone}
          </td>
          <td className="border border-1 border-solid text-center text-sm p-[8px]">
            {agents?.type}
          </td>
          <td className="border border-1 border-solid text-center text-sm p-[8px]">
            {agents?.representatives?.length}
          </td>
          {/* <td className="border border-1 border-solid text-center text-sm p-[8px]">
          {agents.status ? (
            <span className={active}>active</span>
          ) : (
            <span className={deactivate}>disabled</span>
          )}
        </td> */}
          {/* <td className="w-40 relative text-[30px] text-gray-500 text-center border border-1 border-solid  p-[8px]">
          <button onClick={openModal} className="">
            <MoreHorizIcon
              className={showModal && "text-red-400"}
              sx={showModal ? { fontSize: 40 } : { fontSize: 35 }}
            />
          </button>
          <div className="relative">
            {showModal && (
              <SideOptions agent={agents} closeModal={closeModal} />
            )}
          </div>
        </td> */}
          <td className="w-40 relative text-[30px] text-gray-500 text-center border border-1 border-solid  p-[8px]">
            ...
          </td>
        </tr>
    </>
  );
};

export default AgentListTbody;
