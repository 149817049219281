import React from "react";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
const FormDetailsNavBar = () => {
  return (
    <div>
      <div className="bg-white shadow-lg ">
        <div className="w-[90%] my-0 mx-auto">
          <div className="flex justify-between items-center py-2">
            <div className="w-16 h-auto">
              <img src="/assets/img/logo-01.png" alt="logo"/>
            </div>
            <div>
              <div className=" flex  items-center gap-x-4">
                <div className="border rounded-full py-2 px-2 w-[40px] h-[40px]  bg-red-500 font-bold ">
                  <svg className=" w-6 h-6 ... text-white ">
                    <ExitToAppIcon />
                  </svg>
                </div>
                <div className="font-semibold">Signout</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormDetailsNavBar;
