import React from "react";

const AgentCourseTableRow = ({ course }) => {
  return (
    
      <tr key={course?._id}>
        <td className="border border-1 border-solid text-left text-sm p-[8px] capitalize">
          {course.course}
        </td>
        <td className="border border-1 border-solid text-left text-sm p-[8px]">
          {course.duration}
        </td>
        <td className="border border-1 border-solid text-left text-sm p-[8px]">
          {course.fees}
        </td>
        <td className="border border-1 border-solid text-left text-sm p-[8px]">
          {course.applicationFees}
        </td>
        {/* <td className="text-[30px] text-gray-500 text-center border border-1 border-solid p-[8px]">
          <button class="bg-blue-300 hover:bg-blue-400 text-black font-bold py-2 px-2 rounded inline-flex items-center">
            <EditIcon />
          </button>
          <button
            onClick={handleDeleteCourse}
            class="ml-2 bg-red-300 hover:bg-red-400 text-white font-bold py-2 px-2 rounded inline-flex items-center"
          >
            <DeleteIcon />
          </button>
        </td> */}
      </tr>
      
  );
};

export default AgentCourseTableRow;
