import React, { Fragment, useState } from "react";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { Alert, Button, IconButton, Snackbar } from "@mui/material";

import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  approveDocumentAPI,
  approveSupportingDocumentsAPI,
  rejectDocumentsApi,
  rejectSupportingDocumentsAPI,
} from "../../../API/admin/AgentRecruitment";

const AgentRecruitmentDocuments = ({ agentData }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    applicantInformationResponse: "",
    businessInformationResponse: "",
    studentRecruitmentResponse: "",
    businessReferenceResponse: "",
    supportingDocumentsResponse: "",
  });
  console.log("hello");
  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
  };

  const handleCloseModal = () => {
    setSelectedImageIndex(null);
  };

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    window.location.reload();
    setOpen(false);
  };

  //   const handleApprovalButton = async (reference) => {
  //     try {
  //       // Display a confirmation alert
  //       const userConfirmed = window.confirm(
  //         "Are you sure you want to approve the document?"
  //       );

  //       // Check if the user confirmed the action
  //       if (!userConfirmed) {
  //         return; // If not confirmed, exit the function
  //       }

  //       console.log("hello");

  //       const response = await approveDocumentAPI(agentData?._id, reference, {
  //         data: "data",
  //       });

  //       console.log(response);

  //       if (response.status === 200) {
  //         handleClick();
  //         // window.location.reload();
  //       }
  //     } catch (error) {
  //       // Handle errors here if needed
  //       console.error(error);
  //     }
  //   };

  //   const handleRejectionsButton = async (reference) => {
  //     try {
  //       // Display a confirmation alert
  //       const userConfirmed = window.confirm(
  //         "Are you sure you want to approve the document?"
  //       );

  //       // Check if the user confirmed the action
  //       if (!userConfirmed) {
  //         return; // If not confirmed, exit the function
  //       }

  //       console.log("hello");

  //       const response = await rejectDocumentsApi(agentData?._id, reference, {
  //         data: "data",
  //       });

  //       console.log(response);

  //       if (response.status === 200) {
  //         handleClick();
  //         // window.location.reload();
  //       }
  //     } catch (error) {
  //       // Handle errors here if needed
  //       console.error(error);
  //     }
  //   };

  //   const handleSupportingDocumentsApproval = async (reference) => {
  //     try {
  //       // Display a confirmation alert
  //       const userConfirmed = window.confirm(
  //         "Are you sure you want to approve the document?"
  //       );

  //       // Check if the user confirmed the action
  //       if (!userConfirmed) {
  //         return; // If not confirmed, exit the function
  //       }

  //       console.log("hello");

  //       const response = await approveSupportingDocumentsAPI(
  //         agentData?._id,
  //         reference,
  //         {
  //           data: "data",
  //         }
  //       );

  //       console.log(response);

  //       if (response.status === 200) {
  //         handleClick();
  //         // window.location.reload();
  //       }
  //     } catch (error) {
  //       // Handle errors here if needed
  //       console.error(error);
  //     }
  //   };

  //   const handleSupportingDocumentsRejection = async (reference) => {
  //     try {
  //       // Display a confirmation alert
  //       const userConfirmed = window.confirm(
  //         "Are you sure you want to approve the document?"
  //       );

  //       // Check if the user confirmed the action
  //       if (!userConfirmed) {
  //         return; // If not confirmed, exit the function
  //       }

  //       console.log("hello");

  //       const response = await rejectSupportingDocumentsAPI(
  //         agentData?._id,
  //         reference,
  //         {
  //           data: "data",
  //         }
  //       );

  //       console.log(response);

  //       if (response.status === 200) {
  //         handleClick();
  //         // window.location.reload();
  //       }
  //     } catch (error) {
  //       // Handle errors here if needed
  //       console.error(error);
  //     }
  //   };

  //   const action = (
  //     <Fragment>
  //       <Button color="secondary" size="small" onClick={handleClose}>
  //         UNDO
  //       </Button>
  //       <IconButton
  //         size="small"
  //         aria-label="close"
  //         color="inherit"
  //         onClick={handleClose}
  //       >
  //         <CloseIcon fontSize="small" />
  //       </IconButton>
  //     </Fragment>
  //   );
  return (
    <>
      {/* <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Response successfully updated!
        </Alert>
      </Snackbar> */}
      <div>
        <div className="mb-3">
          <h4 className="font-bold text-[12px] md:text-[20px] capitalize">
            {`Agent Application of ${agentData?.name}`}
          </h4>
        </div>
        <div>
          <div className="">
            <div className="flex gap-x-[10px] pb-[15px] items-center">
              <div className="text-[20px]">
                <EmailIcon />
              </div>
              <div>
                <p className="text-gray-400">{agentData?.email}</p>
              </div>
            </div>
            <div className="flex gap-x-[15px]">
              <div className="flex gap-x-[10px]">
                <div className="text-[20px]">
                  <LocalPhoneIcon />
                </div>
                <div>
                  <p className="text-gray-400">{agentData?.phone}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <div>
            <div className="w-full my-0 mx-auto">
              <div className="">
                <div className="py-10">
                  {/* APPLICANT INFORMATION */}
                  <div className="accordion rounded-sm shadow-lg py-3">
                    <div className="flex justify-center items-center gap-x-4">
                      <div className="w-14 flex  items-center gap-x-2 bg-green-500 px-1 py-1 rounded">
                        <p className="text-white font-bold text-sm">STEP 1</p>
                      </div>
                      <div>
                        <p className="text-md font-semibold ">
                          APPLICANT INFORMATION
                        </p>
                      </div>
                    </div>
                    {/* <div className="flex justify-end items-end">
                    <i className="fa-solid fa-angle-up" />
                  </div> */}
                  </div>
                  {agentData?.applicantInformation ? (
                    <div className="my-5 px-5 py-10 shadow-md shadow-gray-200">
                      <div className="grid grid-cols-3 gap-3">
                        <div className="mb-4">
                          <label className="block text-gray-700 text-md mb-2">
                            Name of Applicant/Representative
                          </label>
                          <input
                            type="text"
                            className="border border-gray-200 rounded-md w-full text-[15px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={agentData?.applicantInformation?.name}
                            readOnly
                          />
                        </div>
                        <div className="mb-4">
                          <label className="block text-gray-700 text-md mb-2">
                            Designation
                          </label>
                          <input
                            type="text"
                            className="border border-gray-200 rounded-md w-full text-[15px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={agentData?.applicantInformation?.designation}
                            readOnly
                          />
                        </div>
                        <div className="mb-4">
                          <label
                            htmlFor="email"
                            className="block text-gray-700 text-md mb-2"
                          >
                            Type of applicant (Individual/Company)
                          </label>
                          <input
                            type="text"
                            className="border border-gray-200 rounded-md w-full text-[15px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={
                              agentData?.applicantInformation?.typeOfApplicant
                            }
                            readOnly
                          />
                        </div>

                        <div className="mb-4">
                          <label className="block text-gray-700 text-md mb-2">
                            Name of Business
                          </label>
                          <input
                            type="text"
                            className="border border-gray-200 rounded-md w-full text-[15px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={
                              agentData?.applicantInformation?.nameOfBusiness
                            }
                            readOnly
                          />
                        </div>
                        <div className="mb-4">
                          <label className="block text-gray-700 text-md mb-2">
                            Country of Business Incorporation
                          </label>
                          <input
                            type="text"
                            className="border border-gray-200 rounded-md w-full text-[15px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={
                              agentData?.applicantInformation?.countryOfBusiness
                            }
                            readOnly
                          />
                        </div>
                        <div className="mb-4">
                          <label className="block text-gray-700 text-md mb-2">
                            Year(s) of Operation
                          </label>
                          <input
                            type="text"
                            className="border border-gray-200 rounded-md w-full text-[15px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={
                              agentData?.applicantInformation?.yearsOfOperation
                            }
                            readOnly
                          />
                        </div>
                        <div className="mb-4">
                          <label className="block text-gray-700 text-md mb-2">
                            Contact number
                          </label>
                          <input
                            type="text"
                            className="border border-gray-200 rounded-md w-full text-[15px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={
                              agentData?.applicantInformation?.contactNumber
                            }
                            readOnly
                          />
                        </div>
                        <div className="mb-4">
                          <label className="block text-gray-700 text-md mb-2">
                            Email address
                          </label>
                          <input
                            type="text"
                            className="border border-gray-200 rounded-md w-full text-[15px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={agentData?.applicantInformation?.email}
                            readOnly
                          />
                        </div>
                        <div className="mb-4">
                          <label className="block text-gray-700 text-md mb-2">
                            Address
                          </label>
                          <input
                            type="text"
                            className="border border-gray-200 rounded-md w-full text-[15px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={agentData?.applicantInformation?.address}
                            readOnly
                          />
                        </div>
                      </div>
                      {/* <div className="w-full bg-gray-50 p-5 shadow-inner">
                        {agentData?.applicantInformation?.isApproved ===
                          false &&
                        agentData?.applicantInformation?.isRejected ===
                          false ? null : (
                          <div>
                            {agentData?.applicantInformation?.isApproved ===
                            true ? (
                              <p className="text-green-700 font-semibold text-sm">
                                You have previously given your approval, but you
                                have the option to modify your decision.
                              </p>
                            ) : (
                              <p className="text-red-600 font-semibold text-sm">
                                You have previously given your rejection, but
                                you have the option to modify your decision.
                              </p>
                            )}
                          </div>
                        )}
                        <div className="w-full flex justify-between gap-3">
                          <div className="w-3/4">
                            <input
                              type="text"
                              name="applicantInformationResponse"
                              placeholder="Any text response to applicants...(optional)"
                              // value={agentData?.applicantInformation?.responseMessage}
                              className="border border-gray-200 rounded-md w-full text-[15px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                          </div>
                          <div className="flex gap-3 w-1/4">
                            {agentData?.applicantInformation?.isApproved ===
                              false &&
                            agentData?.applicantInformation?.isRejected ===
                              false ? (
                              <>
                                <button
                                  onClick={() =>
                                    handleRejectionsButton(
                                      "applicantInformation"
                                    )
                                  }
                                  className="relative inline-flex items-center justify-center px-12 py-2 overflow-hidden font-medium text-red-600 transition duration-300 ease-out border-2 border-red-500 rounded shadow-md group text-xs"
                                >
                                  <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-red-500 group-hover:translate-x-0 ease">
                                    <ThumbDownIcon />
                                  </span>
                                  <span className="absolute flex items-center justify-center w-full h-full text-red-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                    Reject
                                  </span>
                                  <span className="relative invisible">
                                    Reject
                                  </span>
                                </button>
                                <button
                                  onClick={() =>
                                    handleApprovalButton("applicantInformation")
                                  }
                                  className="relative inline-flex items-center justify-center px-9 py-2 overflow-hidden font-medium text-green-600 transition duration-300 ease-out border-2 border-green-500 rounded shadow-md group text-xs"
                                >
                                  <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-green-500 group-hover:translate-x-0 ease">
                                    <ThumbUpIcon />
                                  </span>
                                  <span className="absolute flex items-center justify-center w-full h-full text-green-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                    Approve
                                  </span>
                                  <span className="relative invisible">
                                    Approve
                                  </span>
                                </button>
                              </>
                            ) : (
                              <>
                                {agentData?.applicantInformation?.isApproved ===
                                true ? (
                                  <p>
                                    <button
                                      onClick={() =>
                                        handleRejectionsButton(
                                          "applicantInformation"
                                        )
                                      }
                                      className="relative inline-flex items-center justify-center px-12 py-2 overflow-hidden font-medium text-red-600 transition duration-300 ease-out border-2 border-red-500 rounded shadow-md group text-xs"
                                    >
                                      <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-red-500 group-hover:translate-x-0 ease">
                                        <ThumbDownIcon />
                                      </span>
                                      <span className="absolute flex items-center justify-center w-full h-full text-red-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                        Reject
                                      </span>
                                      <span className="relative invisible">
                                        Reject
                                      </span>
                                    </button>
                                  </p>
                                ) : (
                                  <p>
                                    <button
                                      onClick={() =>
                                        handleApprovalButton(
                                          "applicantInformation"
                                        )
                                      }
                                      className="relative inline-flex items-center justify-center px-9 py-2 overflow-hidden font-medium text-green-600 transition duration-300 ease-out border-2 border-green-500 rounded shadow-md group text-xs"
                                    >
                                      <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-green-500 group-hover:translate-x-0 ease">
                                        <ThumbUpIcon />
                                      </span>
                                      <span className="absolute flex items-center justify-center w-full h-full text-green-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                        Approve
                                      </span>
                                      <span className="relative invisible">
                                        Approve
                                      </span>
                                    </button>
                                  </p>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div> */}
                    </div>
                  ) : (
                    <div className="accordion rounded-sm shadow-lg py-3 bg-gray-50 mb-3">
                      <div className="flex justify-center items-center gap-x-4 py-5">
                        <h2 className="text-[30px] font-extrabold text-gray-200">
                          Applicant in this field has not completed yet.
                        </h2>
                      </div>
                    </div>
                  )}

                  {/* BUSINESS INFORMATION */}
                  <div className="accordion rounded-sm shadow-lg py-3">
                    <div className="flex justify-center items-center gap-x-4">
                      <div className=" w-14 flex  items-center gap-x-2 bg-green-500 px-1 py-1 rounded">
                        <p className="text-white font-bold text-sm">STEP 2</p>
                      </div>
                      <div>
                        <p className="text-md font-semibold ">
                          BUSINESS INFORMATION
                        </p>
                      </div>
                    </div>
                    {/* <div className="flex justify-end items-end">
                    <i className="fa-solid fa-angle-up" />
                  </div> */}
                  </div>
                  {agentData.businessInformation ? (
                    <div className="px-5 my-5 py-10 shadow-md shadow-gray-200">
                      <div className="grid grid-cols-2 gap-3">
                        <div className="mb-4">
                          <label className="block text-gray-700 text-md mb-2">
                            Number of Employees
                          </label>
                          <input
                            type="text"
                            className="border border-gray-200 rounded-md w-full text-[15px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={
                              agentData?.businessInformation?.numberOfEmployees
                            }
                            readOnly
                          />
                        </div>
                        <div className="mb-4">
                          <label className="block text-gray-700 text-md mb-2">
                            Where is your office located
                          </label>
                          <input
                            type="text"
                            className="border border-gray-200 rounded-md w-full text-[15px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={
                              agentData?.businessInformation?.officeLocation
                            }
                            readOnly
                          />
                        </div>
                        <div className="mb-4">
                          <label className="block text-gray-700 text-md mb-2">
                            List of countries that you recruit students to
                          </label>
                          <input
                            type="text"
                            className="border border-gray-200 rounded-md w-full text-[15px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={
                              agentData?.businessInformation?.listOfCountries
                                ?.length !== 0
                                ? agentData?.businessInformation?.listOfCountries.join(
                                    ", "
                                  )
                                : "No Countries"
                            }
                            readOnly
                          />
                        </div>
                        <div className="mb-4">
                          <label className="block text-gray-700 text-md mb-2">
                            Number of students recruited by you last 12 months
                          </label>
                          <input
                            type="text"
                            className="border border-gray-200 rounded-md w-full text-[15px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={
                              agentData?.businessInformation
                                ?.numberOfStudentsInLastYear
                            }
                            readOnly
                          />
                        </div>
                        <div className="mb-4">
                          <label className="block text-gray-700 text-md mb-2">
                            List of Major Education Institute That You Currently{" "}
                            <br />
                            Represent And Their Location
                          </label>
                          <input
                            type="text"
                            className="border border-gray-200 rounded-md w-full text-[15px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={
                              agentData?.businessInformation
                                ?.majorEducationInstitute?.length !== 0
                                ? agentData?.businessInformation?.majorEducationInstitute.join(
                                    ", "
                                  )
                                : "No Institutes  Available"
                            }
                            readOnly
                          />
                        </div>
                        <div className="mb-4">
                          <label className="block text-gray-700 text-md mb-2">
                            List of Major Education Institute in Mauritius{" "}
                            <br />
                            Represent And Their Location
                          </label>
                          <input
                            type="text"
                            className="border border-gray-200 rounded-md w-full text-[15px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={
                              agentData?.businessInformation
                                ?.listOfInStituteInMauritius?.length !== 0
                                ? agentData?.businessInformation?.listOfInStituteInMauritius.join(
                                    ", "
                                  )
                                : "No Institutes  Available"
                            }
                            readOnly
                          />
                        </div>
                      </div>
                      {/* <div className="w-full bg-gray-50 p-5 shadow-inner">
                        {agentData?.businessInformation?.isApproved === false &&
                        agentData?.businessInformation?.isRejected ===
                          false ? null : (
                          <div>
                            {agentData?.businessInformation?.isApproved ===
                            true ? (
                              <p className="text-green-700 font-semibold text-sm">
                                You have previously given your approval, but you
                                have the option to modify your decision.
                              </p>
                            ) : (
                              <p className="text-red-600 font-semibold text-sm">
                                You have previously given your rejection, but
                                you have the option to modify your decision.
                              </p>
                            )}
                          </div>
                        )}
                        <div className="w-full flex justify-between gap-3">
                          <div className="w-3/4">
                            <input
                              type="text"
                              name="businessInformationResponse"
                              placeholder="Any text response to applicants...(optional)"
                              className="border border-gray-200 rounded-md w-full text-[15px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                          </div>
                          <div className="flex gap-3 w-1/4">
                            {agentData?.businessInformation?.isApproved ===
                              false &&
                            agentData?.businessInformation?.isRejected ===
                              false ? (
                              <>
                                <button
                                  onClick={() =>
                                    handleRejectionsButton(
                                      "businessInformation"
                                    )
                                  }
                                  className="relative inline-flex items-center justify-center px-12 py-2 overflow-hidden font-medium text-red-600 transition duration-300 ease-out border-2 border-red-500 rounded shadow-md group text-xs"
                                >
                                  <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-red-500 group-hover:translate-x-0 ease">
                                    <ThumbDownIcon />
                                  </span>
                                  <span className="absolute flex items-center justify-center w-full h-full text-red-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                    Reject
                                  </span>
                                  <span className="relative invisible">
                                    Reject
                                  </span>
                                </button>
                                <button
                                  onClick={() =>
                                    handleApprovalButton("businessInformation")
                                  }
                                  className="relative inline-flex items-center justify-center px-9 py-2 overflow-hidden font-medium text-green-600 transition duration-300 ease-out border-2 border-green-500 rounded shadow-md group text-xs"
                                >
                                  <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-green-500 group-hover:translate-x-0 ease">
                                    <ThumbUpIcon />
                                  </span>
                                  <span className="absolute flex items-center justify-center w-full h-full text-green-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                    Approve
                                  </span>
                                  <span className="relative invisible">
                                    Approve
                                  </span>
                                </button>
                              </>
                            ) : (
                              <>
                                {agentData?.businessInformation?.isApproved ===
                                true ? (
                                  <p>
                                    <button
                                      onClick={() =>
                                        handleRejectionsButton(
                                          "businessInformation"
                                        )
                                      }
                                      className="relative inline-flex items-center justify-center px-12 py-2 overflow-hidden font-medium text-red-600 transition duration-300 ease-out border-2 border-red-500 rounded shadow-md group text-xs"
                                    >
                                      <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-red-500 group-hover:translate-x-0 ease">
                                        <ThumbDownIcon />
                                      </span>
                                      <span className="absolute flex items-center justify-center w-full h-full text-red-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                        Reject
                                      </span>
                                      <span className="relative invisible">
                                        Reject
                                      </span>
                                    </button>
                                  </p>
                                ) : (
                                  <p>
                                    <button
                                      onClick={() =>
                                        handleApprovalButton(
                                          "businessInformation"
                                        )
                                      }
                                      className="relative inline-flex items-center justify-center px-9 py-2 overflow-hidden font-medium text-green-600 transition duration-300 ease-out border-2 border-green-500 rounded shadow-md group text-xs"
                                    >
                                      <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-green-500 group-hover:translate-x-0 ease">
                                        <ThumbUpIcon />
                                      </span>
                                      <span className="absolute flex items-center justify-center w-full h-full text-green-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                        Approve
                                      </span>
                                      <span className="relative invisible">
                                        Approve
                                      </span>
                                    </button>
                                  </p>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div> */}
                    </div>
                  ) : (
                    <div className="accordion rounded-sm shadow-lg py-3 bg-gray-50 mb-3">
                      <div className="flex justify-center items-center gap-x-4 py-5">
                        <h2 className="text-[30px] font-extrabold text-gray-200">
                          Applicant in this field has not completed yet.
                        </h2>
                      </div>
                    </div>
                  )}

                  {/* STUDENT RECRUITMENT */}
                  <div className="accordion rounded-sm shadow-lg py-3">
                    <div className="flex justify-center items-center gap-x-4">
                      <div className="   w-14 flex  items-center gap-x-2 bg-green-500 px-1 py-1 rounded ">
                        <p className="text-white font-bold text-sm">STEP 3</p>
                      </div>
                      <div>
                        <p className="text-md font-semibold ">
                          STUDENT RECRUITMENT
                        </p>
                      </div>
                    </div>
                    {/* <div className="flex justify-end items-end">
                    <i className="fa-solid fa-angle-up" />
                  </div> */}
                  </div>
                  {agentData?.studentRecruitment ? (
                    <div className="px-5 my-5 py-10 shadow-md shadow-gray-200">
                      <div className="grid grid-cols-2 gap-3">
                        <div className="mb-4">
                          <label className="block text-gray-700 text-md mb-2">
                            Proposed regions that you wish to recruit students
                            to Avis from
                          </label>
                          <input
                            type="text"
                            className="border border-gray-200 rounded-md w-full text-[15px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={
                              agentData?.studentRecruitment
                                ?.proposedNumberOfStudentsToBeRecruited
                            }
                            readOnly
                          />
                        </div>
                        <div className="mb-4">
                          <label className="block text-gray-700 text-md mb-2">
                            Proposed region to represent Avis for student
                            recruitment
                          </label>
                          <input
                            type="text"
                            className="border border-gray-200 rounded-md w-full text-[15px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={
                              agentData?.studentRecruitment
                                ?.proposedRegionsToRepresentStudents?.length !==
                              0
                                ? agentData?.studentRecruitment?.proposedRegionsToRepresentStudents.join(
                                    ", "
                                  )
                                : "No Countries"
                            }
                            adOnly
                          />
                        </div>
                        <div className="mb-4">
                          <label className="block text-gray-700 text-md mb-2">
                            Proposed no of students to be recruited for <br />
                            Avis in the next 12 months
                          </label>
                          <input
                            type="text"
                            className="border border-gray-200 rounded-md w-full text-[15px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={
                              agentData?.studentRecruitment
                                ?.proposedRegionsToRecruitStudents?.length !== 0
                                ? agentData?.studentRecruitment?.proposedRegionsToRecruitStudents.join(
                                    ", "
                                  )
                                : "No Countries"
                            }
                            readOnly
                          />
                        </div>
                      </div>
                      {/* <div className="w-full  bg-gray-50 p-5 shadow-inner">
                        {agentData?.studentRecruitment?.isApproved === false &&
                        agentData?.studentRecruitment?.isRejected ===
                          false ? null : (
                          <div>
                            {agentData?.studentRecruitment?.isApproved ===
                            true ? (
                              <p className="text-green-700 font-semibold text-sm">
                                You have previously given your approval, but you
                                have the option to modify your decision.
                              </p>
                            ) : (
                              <p className="text-red-600 font-semibold text-sm">
                                You have previously given your rejection, but
                                you have the option to modify your decision.
                              </p>
                            )}
                          </div>
                        )}
                        <div className="w-full flex justify-between gap-3">
                          <div className="w-3/4">
                            <input
                              type="text"
                              name="studentRecruitmentResponse"
                              placeholder="Any text response to applicants...(optional)"
                              className="border border-gray-200 rounded-md w-full text-[15px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                          </div>
                          <div className="flex gap-3 w-1/4">
                            {agentData?.studentRecruitment?.isApproved ===
                              false &&
                            agentData?.studentRecruitment?.isRejected ===
                              false ? (
                              <>
                                <button
                                  onClick={() =>
                                    handleRejectionsButton("studentRecruitment")
                                  }
                                  className="relative inline-flex items-center justify-center px-12 py-2 overflow-hidden font-medium text-red-600 transition duration-300 ease-out border-2 border-red-500 rounded shadow-md group text-xs"
                                >
                                  <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-red-500 group-hover:translate-x-0 ease">
                                    <ThumbDownIcon />
                                  </span>
                                  <span className="absolute flex items-center justify-center w-full h-full text-red-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                    Reject
                                  </span>
                                  <span className="relative invisible">
                                    Reject
                                  </span>
                                </button>
                                <button
                                  onClick={() =>
                                    handleApprovalButton("studentRecruitment")
                                  }
                                  className="relative inline-flex items-center justify-center px-9 py-2 overflow-hidden font-medium text-green-600 transition duration-300 ease-out border-2 border-green-500 rounded shadow-md group text-xs"
                                >
                                  <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-green-500 group-hover:translate-x-0 ease">
                                    <ThumbUpIcon />
                                  </span>
                                  <span className="absolute flex items-center justify-center w-full h-full text-green-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                    Approve
                                  </span>
                                  <span className="relative invisible">
                                    Approve
                                  </span>
                                </button>
                              </>
                            ) : (
                              <>
                                {agentData?.studentRecruitment?.isApproved ===
                                true ? (
                                  <p>
                                    <button
                                      onClick={() =>
                                        handleRejectionsButton(
                                          "studentRecruitment"
                                        )
                                      }
                                      className="relative inline-flex items-center justify-center px-12 py-2 overflow-hidden font-medium text-red-600 transition duration-300 ease-out border-2 border-red-500 rounded shadow-md group text-xs"
                                    >
                                      <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-red-500 group-hover:translate-x-0 ease">
                                        <ThumbDownIcon />
                                      </span>
                                      <span className="absolute flex items-center justify-center w-full h-full text-red-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                        Reject
                                      </span>
                                      <span className="relative invisible">
                                        Reject
                                      </span>
                                    </button>
                                  </p>
                                ) : (
                                  <p>
                                    <button
                                      onClick={() =>
                                        handleApprovalButton(
                                          "studentRecruitment"
                                        )
                                      }
                                      className="relative inline-flex items-center justify-center px-9 py-2 overflow-hidden font-medium text-green-600 transition duration-300 ease-out border-2 border-green-500 rounded shadow-md group text-xs"
                                    >
                                      <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-green-500 group-hover:translate-x-0 ease">
                                        <ThumbUpIcon />
                                      </span>
                                      <span className="absolute flex items-center justify-center w-full h-full text-green-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                        Approve
                                      </span>
                                      <span className="relative invisible">
                                        Approve
                                      </span>
                                    </button>
                                  </p>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div> */}
                    </div>
                  ) : (
                    <div className="accordion rounded-sm shadow-lg py-3 bg-gray-50 mb-3">
                      <div className="flex justify-center items-center gap-x-4 py-5">
                        <h2 className="text-[30px] font-extrabold text-gray-200">
                          Applicant in this field has not completed yet.
                        </h2>
                      </div>
                    </div>
                  )}

                  {/* BUSINESS REFERENCE */}
                  <div className="accordion rounded-sm shadow-lg py-3">
                    <div className="flex justify-center items-center gap-x-4">
                      <div className=" w-14 flex  items-center gap-x-2 bg-green-500 px-1 py-1 rounded">
                        <p className="text-white font-bold text-sm">STEP 4</p>
                      </div>
                      <div>
                        <p className="text-md font-semibold ">
                          BUSINESS REFERENCE
                        </p>
                      </div>
                    </div>
                    {/* <div className="flex justify-end items-end">
                    <i className="fa-solid fa-angle-up" />
                  </div> */}
                  </div>
                  {agentData?.businessReference ? (
                    <div className="px-5 my-5 py-10 shadow-md shadow-gray-200">
                      <div className="flex justify-between gap-3">
                        {agentData?.businessReference?.referee1 ? (
                          <div className="w-1/2">
                            <div className="grid grid-cols-2 gap-3">
                              <div className="mb-4">
                                <label className="block text-gray-700 text-md mb-2">
                                  Name Referee one
                                </label>
                                <input
                                  type="text"
                                  className="border border-gray-200 rounded-md w-full text-[15px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                  value={
                                    agentData?.businessReference?.referee1?.name
                                  }
                                  readOnly
                                />
                              </div>
                              <div className="mb-4">
                                <label
                                  htmlFor="email"
                                  className="block text-gray-700 text-md mb-2"
                                >
                                  Designation
                                </label>
                                <input
                                  type="text"
                                  className="border border-gray-200 rounded-md w-full text-[15px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                  value={
                                    agentData?.businessReference?.referee1
                                      ?.designation
                                  }
                                  readOnly
                                />
                              </div>
                              <div className="mb-4">
                                <label className="block text-gray-700 text-md mb-2">
                                  Name of Institution
                                </label>
                                <input
                                  type="text"
                                  className="border border-gray-200 rounded-md w-full text-[15px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                  value={
                                    agentData?.businessReference?.referee1
                                      ?.nameOfInstitute
                                  }
                                  readOnly
                                />
                              </div>
                              <div className="mb-4">
                                <label className="block text-gray-700 text-md mb-2">
                                  Contact Number
                                </label>
                                <input
                                  type="text"
                                  className="border border-gray-200 rounded-md w-full text-[15px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                  value={
                                    agentData?.businessReference?.referee1
                                      ?.contactNumber
                                  }
                                  readOnly
                                />
                              </div>
                              <div className="mb-4">
                                <label className="block text-gray-700 text-md mb-2">
                                  EmailID
                                </label>
                                <input
                                  type="text"
                                  className="border border-gray-200 rounded-md w-full text-[15px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                  value={
                                    agentData?.businessReference?.referee1
                                      ?.email
                                  }
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div>Referee one not available </div>
                        )}
                        <div className="border-r-2 border-gray" />
                        {agentData?.businessReference?.referee2 ? (
                          <div className="w-1/2">
                            <div className="grid grid-cols-2 gap-3">
                              <div className="mb-4">
                                <label className="block text-gray-700 text-md mb-2">
                                  Name Referee Two
                                </label>
                                <input
                                  type="text"
                                  className="border border-gray-200 rounded-md w-full text-[15px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                  value={
                                    agentData?.businessReference?.referee2?.name
                                  }
                                  readOnly
                                />
                              </div>
                              <div className="mb-4">
                                <label
                                  htmlFor="email"
                                  className="block text-gray-700 text-md mb-2"
                                >
                                  Designation
                                </label>
                                <input
                                  type="text"
                                  className="border border-gray-200 rounded-md w-full text-[15px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                  value={
                                    agentData?.businessReference?.referee2
                                      ?.designation
                                  }
                                  readOnly
                                />
                              </div>
                              <div className="mb-4">
                                <label className="block text-gray-700 text-md mb-2">
                                  Name of Institution
                                </label>
                                <input
                                  type="text"
                                  className="border border-gray-200 rounded-md w-full text-[15px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                  value={
                                    agentData?.businessReference?.referee2
                                      ?.nameOfInstitute
                                  }
                                  readOnly
                                />
                              </div>
                              <div className="mb-4">
                                <label className="block text-gray-700 text-md mb-2">
                                  Contact Number
                                </label>
                                <input
                                  type="text"
                                  className="border border-gray-200 rounded-md w-full text-[15px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                  value={
                                    agentData?.businessReference?.referee2
                                      ?.contactNumber
                                  }
                                  readOnly
                                />
                              </div>
                              <div className="mb-4">
                                <label className="block text-gray-700 text-md mb-2">
                                  EmailID
                                </label>
                                <input
                                  type="text"
                                  className="border border-gray-200 rounded-md w-full text-[15px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                  value={
                                    agentData?.businessReference?.referee2
                                      ?.email
                                  }
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div>Referee two not available </div>
                        )}
                      </div>
                      {/* <div className="w-full  bg-gray-50 p-5 shadow-inner">
                        {agentData?.businessReference?.isApproved === false &&
                        agentData?.businessReference?.isRejected ===
                          false ? null : (
                          <div>
                            {agentData?.businessReference?.isApproved ===
                            true ? (
                              <p className="text-green-700 font-semibold text-sm">
                                You have previously given your approval, but you
                                have the option to modify your decision.
                              </p>
                            ) : (
                              <p className="text-red-600 font-semibold text-sm">
                                You have previously given your rejection, but
                                you have the option to modify your decision.
                              </p>
                            )}
                          </div>
                        )}
                        <div className="w-full flex justify-between gap-3">
                          <div className="w-3/4">
                            <input
                              type="text"
                              name="businessReferenceResponse"
                              placeholder="Any text response to applicants...(optional)"
                              className="border border-gray-200 rounded-md w-full text-[15px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                          </div>
                          <div className="flex gap-3 w-1/4">
                            {agentData?.businessReference?.isApproved ===
                              false &&
                            agentData?.businessReference?.isRejected ===
                              false ? (
                              <>
                                <button
                                  onClick={() =>
                                    handleRejectionsButton("businessReference")
                                  }
                                  className="relative inline-flex items-center justify-center px-12 py-2 overflow-hidden font-medium text-red-600 transition duration-300 ease-out border-2 border-red-500 rounded shadow-md group text-xs"
                                >
                                  <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-red-500 group-hover:translate-x-0 ease">
                                    <ThumbDownIcon />
                                  </span>
                                  <span className="absolute flex items-center justify-center w-full h-full text-red-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                    Reject
                                  </span>
                                  <span className="relative invisible">
                                    Reject
                                  </span>
                                </button>
                                <button
                                  onClick={() =>
                                    handleApprovalButton("businessReference")
                                  }
                                  className="relative inline-flex items-center justify-center px-9 py-2 overflow-hidden font-medium text-green-600 transition duration-300 ease-out border-2 border-green-500 rounded shadow-md group text-xs"
                                >
                                  <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-green-500 group-hover:translate-x-0 ease">
                                    <ThumbUpIcon />
                                  </span>
                                  <span className="absolute flex items-center justify-center w-full h-full text-green-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                    Approve
                                  </span>
                                  <span className="relative invisible">
                                    Approve
                                  </span>
                                </button>
                              </>
                            ) : (
                              <>
                                {agentData?.businessReference?.isApproved ===
                                true ? (
                                  <p>
                                    <button
                                      onClick={() =>
                                        handleRejectionsButton(
                                          "businessReference"
                                        )
                                      }
                                      className="relative inline-flex items-center justify-center px-12 py-2 overflow-hidden font-medium text-red-600 transition duration-300 ease-out border-2 border-red-500 rounded shadow-md group text-xs"
                                    >
                                      <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-red-500 group-hover:translate-x-0 ease">
                                        <ThumbDownIcon />
                                      </span>
                                      <span className="absolute flex items-center justify-center w-full h-full text-red-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                        Reject
                                      </span>
                                      <span className="relative invisible">
                                        Reject
                                      </span>
                                    </button>
                                  </p>
                                ) : (
                                  <p>
                                    <button
                                      onClick={() =>
                                        handleApprovalButton(
                                          "businessReference"
                                        )
                                      }
                                      className="relative inline-flex items-center justify-center px-9 py-2 overflow-hidden font-medium text-green-600 transition duration-300 ease-out border-2 border-green-500 rounded shadow-md group text-xs"
                                    >
                                      <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-green-500 group-hover:translate-x-0 ease">
                                        <ThumbUpIcon />
                                      </span>
                                      <span className="absolute flex items-center justify-center w-full h-full text-green-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                        Approve
                                      </span>
                                      <span className="relative invisible">
                                        Approve
                                      </span>
                                    </button>
                                  </p>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div> */}
                    </div>
                  ) : (
                    <div className="accordion rounded-sm shadow-lg py-3 bg-gray-50 mb-3">
                      <div className="flex justify-center items-center gap-x-4 py-5">
                        <h2 className="text-[30px] font-extrabold text-gray-200">
                          Applicant in this field has not completed yet.
                        </h2>
                      </div>
                    </div>
                  )}

                  {/* SUPPORTING DOCUMENTS */}
                  <div className="accordion rounded-sm shadow-lg py-3">
                    <div className="flex justify-center items-center gap-x-4">
                      <div className="  w-14 flex  items-center gap-x-2 bg-green-500 px-1 py-1 rounded">
                        <p className="text-white font-bold text-sm">STEP 5</p>
                      </div>
                      <div>
                        <p className="text-md font-semibold ">
                          SUPPORTING DOCUMENTS
                        </p>
                      </div>
                    </div>
                    {/* <div className="flex justify-end items-end">
                    <i className="fa-solid fa-angle-up" />
                  </div> */}
                  </div>
                  {agentData?.supportingDocuments ? (
                    <div>
                      <div className="px-5 my-5 py-10 shadow-md shadow-gray-200">
                        <p className="font-semibold pb-6">
                          FOR APPLICATION AS COMPANY - DOCUMENTS
                        </p>
                        {agentData?.applicationSteps
                          ?.isSupportingDocumentsOne ? (
                          <>
                            <div className="grid grid-cols-2 gap-3">
                              {agentData?.supportingDocuments
                                ?.applicationAsCompany
                                ?.companyRegistrationCertificate && (
                                <div className="my-5">
                                  <div className="text-md font-semibold text-center">
                                    <h2>Company Registration Certificate</h2>
                                  </div>
                                  <div className="flex justify-center my-2">
                                    <div
                                      className="w-96 object-cover cursor-pointer"
                                      onClick={() => handleImageClick(1)}
                                    >
                                      <a
                                        href={`${process.env.REACT_APP_BACKEND_URL}\\agent-application-docs\\${agentData?.supportingDocuments?.applicationAsCompany?.companyRegistrationCertificate?.fileName}`}
                                        target="blank"
                                      >
                                        <img
                                          src={`${process.env.REACT_APP_BACKEND_URL}\\agent-application-docs\\${agentData?.supportingDocuments?.applicationAsCompany?.companyRegistrationCertificate.fileName}`}
                                          alt={
                                            agentData?.supportingDocuments
                                              ?.applicationAsCompany
                                              ?.companyRegistrationCertificate
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                  {/* <div className="w-full gap-3 bg-gray-50 p-5 shadow-inner">
                                    {agentData?.supportingDocuments
                                      ?.applicationAsCompany
                                      ?.companyRegistrationCertificate
                                      ?.isApproved === false &&
                                    agentData?.supportingDocuments
                                      ?.applicationAsCompany
                                      ?.companyRegistrationCertificate
                                      ?.isRejected === false ? null : (
                                      <div>
                                        {agentData?.studentRecruitment
                                          ?.isApproved === true ? (
                                          <p className="text-green-700 font-semibold text-sm">
                                            You have previously given your
                                            approval, but you have the option to
                                            modify your decision.
                                          </p>
                                        ) : (
                                          <p className="text-red-600 font-semibold text-sm">
                                            You have previously given your
                                            rejection, but you have the option
                                            to modify your decision.
                                          </p>
                                        )}
                                      </div>
                                    )}
                                    <div className="my-2">
                                      <input
                                        type="text"
                                        placeholder="Any text response to applicants...(optional)"
                                        className="border border-gray-200 rounded-md w-full text-[15px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      />
                                    </div>
                                    <div className="flex gap-3">
                                      {agentData?.supportingDocuments
                                        ?.applicationAsCompany
                                        ?.companyRegistrationCertificate
                                        ?.isApproved === false &&
                                      agentData?.supportingDocuments
                                        ?.applicationAsCompany
                                        ?.companyRegistrationCertificate
                                        ?.isRejected === false ? (
                                        <>
                                          <button
                                            onClick={() =>
                                              handleSupportingDocumentsRejection(
                                                "companyRegistrationCertificate"
                                              )
                                            }
                                            className="w-1/2 relative inline-flex items-center justify-center px-12 py-2 overflow-hidden font-medium text-red-600 transition duration-300 ease-out border-2 border-red-500 rounded shadow-md group text-xs"
                                          >
                                            <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-red-500 group-hover:translate-x-0 ease">
                                              <ThumbDownIcon />
                                            </span>
                                            <span className="absolute flex items-center justify-center w-full h-full text-red-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                              Reject
                                            </span>
                                            <span className="relative invisible">
                                              Reject
                                            </span>
                                          </button>
                                          <button
                                            onClick={() =>
                                              handleSupportingDocumentsApproval(
                                                "companyRegistrationCertificate"
                                              )
                                            }
                                            className="w-1/2 relative inline-flex items-center justify-center px-9 py-2 overflow-hidden font-medium text-green-600 transition duration-300 ease-out border-2 border-green-500 rounded shadow-md group text-xs"
                                          >
                                            <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-green-500 group-hover:translate-x-0 ease">
                                              <ThumbUpIcon />
                                            </span>
                                            <span className="absolute flex items-center justify-center w-full h-full text-green-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                              Approve
                                            </span>
                                            <span className="relative invisible">
                                              Approve
                                            </span>
                                          </button>
                                        </>
                                      ) : (
                                        <>
                                          {agentData?.supportingDocuments
                                            ?.applicationAsCompany
                                            ?.companyRegistrationCertificate
                                            ?.isApproved === true ? (
                                            <p>
                                              <button
                                                onClick={() =>
                                                  handleSupportingDocumentsRejection(
                                                    "companyRegistrationCertificate"
                                                  )
                                                }
                                                className="w-1/2 relative inline-flex items-center justify-center px-12 py-2 overflow-hidden font-medium text-red-600 transition duration-300 ease-out border-2 border-red-500 rounded shadow-md group text-xs"
                                              >
                                                <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-red-500 group-hover:translate-x-0 ease">
                                                  <ThumbDownIcon />
                                                </span>
                                                <span className="absolute flex items-center justify-center w-full h-full text-red-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                                  Reject
                                                </span>
                                                <span className="relative invisible">
                                                  Reject
                                                </span>
                                              </button>
                                            </p>
                                          ) : (
                                            <p>
                                              <button
                                                onClick={() =>
                                                  handleSupportingDocumentsApproval(
                                                    "companyRegistrationCertificate"
                                                  )
                                                }
                                                className="w-1/2 relative inline-flex items-center justify-center px-9 py-2 overflow-hidden font-medium text-green-600 transition duration-300 ease-out border-2 border-green-500 rounded shadow-md group text-xs"
                                              >
                                                <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-green-500 group-hover:translate-x-0 ease">
                                                  <ThumbUpIcon />
                                                </span>
                                                <span className="absolute flex items-center justify-center w-full h-full text-green-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                                  Approve
                                                </span>
                                                <span className="relative invisible">
                                                  Approve
                                                </span>
                                              </button>
                                            </p>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </div> */}
                                </div>
                              )}

                              {agentData?.supportingDocuments
                                ?.applicationAsCompany?.companyProfile && (
                                <div className="my-5">
                                  <div className="text-md font-semibold text-center">
                                    <h2>Company Profile</h2>
                                  </div>
                                  <div className="flex justify-center my-2">
                                    <div className="w-96 object-cover">
                                      <a
                                        href={`${process.env.REACT_APP_BACKEND_URL}\\agent-application-docs\\${agentData?.supportingDocuments?.applicationAsCompany?.companyProfile?.fileName}`}
                                        target="blank"
                                      >
                                        {" "}
                                        <img
                                          src={`${process.env.REACT_APP_BACKEND_URL}\\agent-application-docs\\${agentData?.supportingDocuments?.applicationAsCompany?.companyProfile?.fileName}`}
                                          alt={
                                            agentData?.supportingDocuments
                                              ?.applicationAsCompany
                                              ?.companyProfile
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                  {/* <div className="w-full gap-3 bg-gray-50 p-5 shadow-inner">
                                    {agentData?.supportingDocuments
                                      ?.applicationAsCompany?.companyProfile
                                      ?.isApproved === false &&
                                    agentData?.supportingDocuments
                                      ?.applicationAsCompany?.companyProfile
                                      ?.isRejected === false ? null : (
                                      <div>
                                        {agentData?.supportingDocuments
                                          ?.applicationAsCompany?.companyProfile
                                          ?.isApproved === true ? (
                                          <p className="text-green-700 font-semibold text-sm">
                                            You have previously given your
                                            approval, but you have the option to
                                            modify your decision.
                                          </p>
                                        ) : (
                                          <p className="text-red-600 font-semibold text-sm">
                                            You have previously given your
                                            rejection, but you have the option
                                            to modify your decision.
                                          </p>
                                        )}
                                      </div>
                                    )}
                                    <div className="my-2">
                                      <input
                                        type="text"
                                        placeholder="Any text response to applicants...(optional)"
                                        className="border border-gray-200 rounded-md w-full text-[15px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      />
                                    </div>
                                    <div className="flex gap-3">
                                      {agentData?.supportingDocuments
                                        ?.applicationAsCompany?.companyProfile
                                        ?.isApproved === false &&
                                      agentData?.supportingDocuments
                                        ?.applicationAsCompany?.companyProfile
                                        ?.isRejected === false ? (
                                        <>
                                          <button
                                            onClick={() =>
                                              handleSupportingDocumentsRejection(
                                                "companyProfile"
                                              )
                                            }
                                            className="w-1/2 relative inline-flex items-center justify-center px-12 py-2 overflow-hidden font-medium text-red-600 transition duration-300 ease-out border-2 border-red-500 rounded shadow-md group text-xs"
                                          >
                                            <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-red-500 group-hover:translate-x-0 ease">
                                              <ThumbDownIcon />
                                            </span>
                                            <span className="absolute flex items-center justify-center w-full h-full text-red-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                              Reject
                                            </span>
                                            <span className="relative invisible">
                                              Reject
                                            </span>
                                          </button>
                                          <button
                                            onClick={() =>
                                              handleSupportingDocumentsApproval(
                                                "companyProfile"
                                              )
                                            }
                                            className="w-1/2 relative inline-flex items-center justify-center px-9 py-2 overflow-hidden font-medium text-green-600 transition duration-300 ease-out border-2 border-green-500 rounded shadow-md group text-xs"
                                          >
                                            <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-green-500 group-hover:translate-x-0 ease">
                                              <ThumbUpIcon />
                                            </span>
                                            <span className="absolute flex items-center justify-center w-full h-full text-green-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                              Approve
                                            </span>
                                            <span className="relative invisible">
                                              Approve
                                            </span>
                                          </button>
                                        </>
                                      ) : (
                                        <>
                                          {agentData?.supportingDocuments
                                            ?.applicationAsCompany
                                            ?.companyProfile?.isApproved ===
                                          true ? (
                                            <p>
                                              <button
                                                onClick={() =>
                                                  handleSupportingDocumentsRejection(
                                                    "companyProfile"
                                                  )
                                                }
                                                className="w-1/2 relative inline-flex items-center justify-center px-12 py-2 overflow-hidden font-medium text-red-600 transition duration-300 ease-out border-2 border-red-500 rounded shadow-md group text-xs"
                                              >
                                                <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-red-500 group-hover:translate-x-0 ease">
                                                  <ThumbDownIcon />
                                                </span>
                                                <span className="absolute flex items-center justify-center w-full h-full text-red-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                                  Reject
                                                </span>
                                                <span className="relative invisible">
                                                  Reject
                                                </span>
                                              </button>
                                            </p>
                                          ) : (
                                            <p>
                                              <button
                                                onClick={() =>
                                                  handleSupportingDocumentsApproval(
                                                    "companyProfile"
                                                  )
                                                }
                                                className="w-1/2 relative inline-flex items-center justify-center px-9 py-2 overflow-hidden font-medium text-green-600 transition duration-300 ease-out border-2 border-green-500 rounded shadow-md group text-xs"
                                              >
                                                <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-green-500 group-hover:translate-x-0 ease">
                                                  <ThumbUpIcon />
                                                </span>
                                                <span className="absolute flex items-center justify-center w-full h-full text-green-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                                  Approve
                                                </span>
                                                <span className="relative invisible">
                                                  Approve
                                                </span>
                                              </button>
                                            </p>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </div> */}
                                </div>
                              )}

                              {agentData?.supportingDocuments
                                ?.applicationAsCompany
                                ?.PhotoOfOfficePremisesExternalView && (
                                <div className="my-5">
                                  <div className="text-md font-semibold text-center">
                                    <h2>Photo of Office premises External</h2>
                                  </div>
                                  <div className="flex justify-center my-2">
                                    <div className="w-96 object-cover">
                                      <a
                                        href={`${process.env.REACT_APP_BACKEND_URL}\\agent-application-docs\\${agentData?.supportingDocuments?.applicationAsCompany?.PhotoOfOfficePremisesExternalView?.fileName}`}
                                        target="blank"
                                      >
                                        <img
                                          src={`${process.env.REACT_APP_BACKEND_URL}\\agent-application-docs\\${agentData?.supportingDocuments?.applicationAsCompany?.PhotoOfOfficePremisesExternalView?.fileName}`}
                                          alt={
                                            agentData?.supportingDocuments
                                              ?.applicationAsCompany
                                              ?.PhotoOfOfficePremisesExternalView
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                  {/* <div className="w-full gap-3 bg-gray-50 p-5 shadow-inner">
                                    {agentData?.supportingDocuments
                                      ?.applicationAsCompany
                                      ?.PhotoOfOfficePremisesExternalView
                                      ?.isApproved === false &&
                                    agentData?.supportingDocuments
                                      ?.applicationAsCompany
                                      ?.PhotoOfOfficePremisesExternalView
                                      ?.isRejected === false ? null : (
                                      <div>
                                        {agentData?.supportingDocuments
                                          ?.applicationAsCompany
                                          ?.PhotoOfOfficePremisesExternalView
                                          ?.isApproved === true ? (
                                          <p className="text-green-700 font-semibold text-sm">
                                            You have previously given your
                                            approval, but you have the option to
                                            modify your decision.
                                          </p>
                                        ) : (
                                          <p className="text-red-600 font-semibold text-sm">
                                            You have previously given your
                                            rejection, but you have the option
                                            to modify your decision.
                                          </p>
                                        )}
                                      </div>
                                    )}
                                    <div className="my-2">
                                      <input
                                        type="text"
                                        placeholder="Any text response to applicants...(optional)"
                                        className="border border-gray-200 rounded-md w-full text-[15px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      />
                                    </div>
                                    <div className="flex gap-3">
                                      {agentData?.supportingDocuments
                                        ?.applicationAsCompany
                                        ?.PhotoOfOfficePremisesExternalView
                                        ?.isApproved === false &&
                                      agentData?.supportingDocuments
                                        ?.applicationAsCompany
                                        ?.PhotoOfOfficePremisesExternalView
                                        ?.isRejected === false ? (
                                        <>
                                          <button
                                            onClick={() =>
                                              handleSupportingDocumentsRejection(
                                                "PhotoOfOfficePremisesExternalView"
                                              )
                                            }
                                            className="w-1/2 relative inline-flex items-center justify-center px-12 py-2 overflow-hidden font-medium text-red-600 transition duration-300 ease-out border-2 border-red-500 rounded shadow-md group text-xs"
                                          >
                                            <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-red-500 group-hover:translate-x-0 ease">
                                              <ThumbDownIcon />
                                            </span>
                                            <span className="absolute flex items-center justify-center w-full h-full text-red-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                              Reject
                                            </span>
                                            <span className="relative invisible">
                                              Reject
                                            </span>
                                          </button>
                                          <button
                                            onClick={() =>
                                              handleSupportingDocumentsApproval(
                                                "PhotoOfOfficePremisesExternalView"
                                              )
                                            }
                                            className="w-1/2 relative inline-flex items-center justify-center px-9 py-2 overflow-hidden font-medium text-green-600 transition duration-300 ease-out border-2 border-green-500 rounded shadow-md group text-xs"
                                          >
                                            <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-green-500 group-hover:translate-x-0 ease">
                                              <ThumbUpIcon />
                                            </span>
                                            <span className="absolute flex items-center justify-center w-full h-full text-green-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                              Approve
                                            </span>
                                            <span className="relative invisible">
                                              Approve
                                            </span>
                                          </button>
                                        </>
                                      ) : (
                                        <>
                                          {agentData?.supportingDocuments
                                            ?.applicationAsCompany
                                            ?.PhotoOfOfficePremisesExternalView
                                            ?.isApproved === true ? (
                                            <p>
                                              <button
                                                onClick={() =>
                                                  handleSupportingDocumentsRejection(
                                                    "PhotoOfOfficePremisesExternalView"
                                                  )
                                                }
                                                className="w-1/2 relative inline-flex items-center justify-center px-12 py-2 overflow-hidden font-medium text-red-600 transition duration-300 ease-out border-2 border-red-500 rounded shadow-md group text-xs"
                                              >
                                                <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-red-500 group-hover:translate-x-0 ease">
                                                  <ThumbDownIcon />
                                                </span>
                                                <span className="absolute flex items-center justify-center w-full h-full text-red-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                                  Reject
                                                </span>
                                                <span className="relative invisible">
                                                  Reject
                                                </span>
                                              </button>
                                            </p>
                                          ) : (
                                            <p>
                                              <button
                                                onClick={() =>
                                                  handleSupportingDocumentsApproval(
                                                    "PhotoOfOfficePremisesExternalView"
                                                  )
                                                }
                                                className="w-1/2 relative inline-flex items-center justify-center px-9 py-2 overflow-hidden font-medium text-green-600 transition duration-300 ease-out border-2 border-green-500 rounded shadow-md group text-xs"
                                              >
                                                <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-green-500 group-hover:translate-x-0 ease">
                                                  <ThumbUpIcon />
                                                </span>
                                                <span className="absolute flex items-center justify-center w-full h-full text-green-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                                  Approve
                                                </span>
                                                <span className="relative invisible">
                                                  Approve
                                                </span>
                                              </button>
                                            </p>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </div> */}
                                </div>
                              )}

                              {agentData?.supportingDocuments
                                ?.applicationAsCompany
                                ?.PhotoOfOfficePremisesInternalView && (
                                <div className="my-5">
                                  <div className="text-md font-semibold text-center">
                                    <h2>Photo of Office premises Interior</h2>
                                  </div>
                                  <div className="flex justify-center my-2">
                                    <div className="w-96 object-cover">
                                      <a
                                        href={`${process.env.REACT_APP_BACKEND_URL}\\agent-application-docs\\${agentData?.supportingDocuments?.applicationAsCompany?.PhotoOfOfficePremisesInternalView?.fileName}`}
                                        target="blank"
                                      >
                                        <img
                                          src={`${process.env.REACT_APP_BACKEND_URL}\\agent-application-docs\\${agentData?.supportingDocuments?.applicationAsCompany?.PhotoOfOfficePremisesInternalView?.fileName}`}
                                          alt={
                                            agentData?.supportingDocuments
                                              ?.applicationAsCompany
                                              ?.PhotoOfOfficePremisesInternalView
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                  {/* <div className="w-full gap-3 bg-gray-50 p-5 shadow-inner">
                                    {agentData?.supportingDocuments
                                      ?.applicationAsCompany
                                      ?.PhotoOfOfficePremisesInternalView
                                      ?.isApproved === false &&
                                    agentData?.supportingDocuments
                                      ?.applicationAsCompany
                                      ?.PhotoOfOfficePremisesInternalView
                                      ?.isRejected === false ? null : (
                                      <div>
                                        {agentData?.supportingDocuments
                                          ?.applicationAsCompany
                                          ?.PhotoOfOfficePremisesInternalView
                                          ?.isApproved === true ? (
                                          <p className="text-green-700 font-semibold text-sm">
                                            You have previously given your
                                            approval, but you have the option to
                                            modify your decision.
                                          </p>
                                        ) : (
                                          <p className="text-red-600 font-semibold text-sm">
                                            You have previously given your
                                            rejection, but you have the option
                                            to modify your decision.
                                          </p>
                                        )}
                                      </div>
                                    )}
                                    <div className="my-2">
                                      <input
                                        type="text"
                                        placeholder="Any text response to applicants...(optional)"
                                        className="border border-gray-200 rounded-md w-full text-[15px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      />
                                    </div>
                                    <div className="flex gap-3">
                                      {agentData?.supportingDocuments
                                        ?.applicationAsCompany
                                        ?.PhotoOfOfficePremisesInternalView
                                        ?.isApproved === false &&
                                      agentData?.supportingDocuments
                                        ?.applicationAsCompany
                                        ?.PhotoOfOfficePremisesInternalView
                                        ?.isRejected === false ? (
                                        <>
                                          <button
                                            onClick={() =>
                                              handleSupportingDocumentsRejection(
                                                "PhotoOfOfficePremisesInternalView"
                                              )
                                            }
                                            className="w-1/2 relative inline-flex items-center justify-center px-12 py-2 overflow-hidden font-medium text-red-600 transition duration-300 ease-out border-2 border-red-500 rounded shadow-md group text-xs"
                                          >
                                            <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-red-500 group-hover:translate-x-0 ease">
                                              <ThumbDownIcon />
                                            </span>
                                            <span className="absolute flex items-center justify-center w-full h-full text-red-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                              Reject
                                            </span>
                                            <span className="relative invisible">
                                              Reject
                                            </span>
                                          </button>
                                          <button
                                            onClick={() =>
                                              handleSupportingDocumentsApproval(
                                                "PhotoOfOfficePremisesInternalView"
                                              )
                                            }
                                            className="w-1/2 relative inline-flex items-center justify-center px-9 py-2 overflow-hidden font-medium text-green-600 transition duration-300 ease-out border-2 border-green-500 rounded shadow-md group text-xs"
                                          >
                                            <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-green-500 group-hover:translate-x-0 ease">
                                              <ThumbUpIcon />
                                            </span>
                                            <span className="absolute flex items-center justify-center w-full h-full text-green-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                              Approve
                                            </span>
                                            <span className="relative invisible">
                                              Approve
                                            </span>
                                          </button>
                                        </>
                                      ) : (
                                        <>
                                          {agentData?.supportingDocuments
                                            ?.applicationAsCompany
                                            ?.PhotoOfOfficePremisesInternalView
                                            ?.isApproved === true ? (
                                            <p>
                                              <button
                                                onClick={() =>
                                                  handleSupportingDocumentsRejection(
                                                    "PhotoOfOfficePremisesInternalView"
                                                  )
                                                }
                                                className="w-1/2 relative inline-flex items-center justify-center px-12 py-2 overflow-hidden font-medium text-red-600 transition duration-300 ease-out border-2 border-red-500 rounded shadow-md group text-xs"
                                              >
                                                <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-red-500 group-hover:translate-x-0 ease">
                                                  <ThumbDownIcon />
                                                </span>
                                                <span className="absolute flex items-center justify-center w-full h-full text-red-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                                  Reject
                                                </span>
                                                <span className="relative invisible">
                                                  Reject
                                                </span>
                                              </button>
                                            </p>
                                          ) : (
                                            <p>
                                              <button
                                                onClick={() =>
                                                  handleSupportingDocumentsApproval(
                                                    "PhotoOfOfficePremisesInternalView"
                                                  )
                                                }
                                                className="w-1/2 relative inline-flex items-center justify-center px-9 py-2 overflow-hidden font-medium text-green-600 transition duration-300 ease-out border-2 border-green-500 rounded shadow-md group text-xs"
                                              >
                                                <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-green-500 group-hover:translate-x-0 ease">
                                                  <ThumbUpIcon />
                                                </span>
                                                <span className="absolute flex items-center justify-center w-full h-full text-green-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                                  Approve
                                                </span>
                                                <span className="relative invisible">
                                                  Approve
                                                </span>
                                              </button>
                                            </p>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </div> */}
                                </div>
                              )}

                              {agentData?.supportingDocuments
                                ?.applicationAsCompany
                                ?.marketingCollateralsCopy && (
                                <div className="my-5">
                                  <div className="text-md font-semibold text-center">
                                    <h2>
                                      Copy of Marketing collaterals if any
                                    </h2>
                                  </div>
                                  <div className="flex justify-center my-2">
                                    <div className="w-96 object-cover">
                                      <a
                                        href={`${process.env.REACT_APP_BACKEND_URL}\\agent-application-docs\\${agentData?.supportingDocuments?.applicationAsCompany?.PhotoOfOfficePremisesInternalView?.fileName}`}
                                        target="blank"
                                      >
                                        <img
                                          src={`${process.env.REACT_APP_BACKEND_URL}\\agent-application-docs\\${agentData?.supportingDocuments?.applicationAsCompany?.marketingCollateralsCopy?.fileName}`}
                                          alt={
                                            agentData?.supportingDocuments
                                              ?.applicationAsCompany
                                              ?.marketingCollateralsCopy
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                  {/* <div className="w-full gap-3 bg-gray-50 p-5 shadow-inner">
                                    {agentData?.supportingDocuments
                                      ?.applicationAsCompany
                                      ?.marketingCollateralsCopy?.isApproved ===
                                      false &&
                                    agentData?.supportingDocuments
                                      ?.applicationAsCompany
                                      ?.marketingCollateralsCopy?.isRejected ===
                                      false ? null : (
                                      <div>
                                        {agentData?.supportingDocuments
                                          ?.applicationAsCompany
                                          ?.marketingCollateralsCopy
                                          ?.isApproved === true ? (
                                          <p className="text-green-700 font-semibold text-sm">
                                            You have previously given your
                                            approval, but you have the option to
                                            modify your decision.
                                          </p>
                                        ) : (
                                          <p className="text-red-600 font-semibold text-sm">
                                            You have previously given your
                                            rejection, but you have the option
                                            to modify your decision.
                                          </p>
                                        )}
                                      </div>
                                    )}
                                    <div className="my-2">
                                      <input
                                        type="text"
                                        placeholder="Any text response to applicants...(optional)"
                                        className="border border-gray-200 rounded-md w-full text-[15px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      />
                                    </div>
                                    <div className="flex gap-3">
                                      {agentData?.supportingDocuments
                                        ?.applicationAsCompany
                                        ?.marketingCollateralsCopy
                                        ?.isApproved === false &&
                                      agentData?.supportingDocuments
                                        ?.applicationAsCompany
                                        ?.marketingCollateralsCopy
                                        ?.isRejected === false ? (
                                        <>
                                          <button
                                            onClick={() =>
                                              handleSupportingDocumentsRejection(
                                                "marketingCollateralsCopy"
                                              )
                                            }
                                            className="w-1/2 relative inline-flex items-center justify-center px-12 py-2 overflow-hidden font-medium text-red-600 transition duration-300 ease-out border-2 border-red-500 rounded shadow-md group text-xs"
                                          >
                                            <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-red-500 group-hover:translate-x-0 ease">
                                              <ThumbDownIcon />
                                            </span>
                                            <span className="absolute flex items-center justify-center w-full h-full text-red-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                              Reject
                                            </span>
                                            <span className="relative invisible">
                                              Reject
                                            </span>
                                          </button>
                                          <button
                                            onClick={() =>
                                              handleSupportingDocumentsApproval(
                                                "marketingCollateralsCopy"
                                              )
                                            }
                                            className="w-1/2 relative inline-flex items-center justify-center px-9 py-2 overflow-hidden font-medium text-green-600 transition duration-300 ease-out border-2 border-green-500 rounded shadow-md group text-xs"
                                          >
                                            <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-green-500 group-hover:translate-x-0 ease">
                                              <ThumbUpIcon />
                                            </span>
                                            <span className="absolute flex items-center justify-center w-full h-full text-green-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                              Approve
                                            </span>
                                            <span className="relative invisible">
                                              Approve
                                            </span>
                                          </button>
                                        </>
                                      ) : (
                                        <>
                                          {agentData?.supportingDocuments
                                            ?.applicationAsCompany
                                            ?.marketingCollateralsCopy
                                            ?.isApproved === true ? (
                                            <p>
                                              <button
                                                onClick={() =>
                                                  handleSupportingDocumentsRejection(
                                                    "marketingCollateralsCopy"
                                                  )
                                                }
                                                className="w-1/2 relative inline-flex items-center justify-center px-12 py-2 overflow-hidden font-medium text-red-600 transition duration-300 ease-out border-2 border-red-500 rounded shadow-md group text-xs"
                                              >
                                                <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-red-500 group-hover:translate-x-0 ease">
                                                  <ThumbDownIcon />
                                                </span>
                                                <span className="absolute flex items-center justify-center w-full h-full text-red-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                                  Reject
                                                </span>
                                                <span className="relative invisible">
                                                  Reject
                                                </span>
                                              </button>
                                            </p>
                                          ) : (
                                            <p>
                                              <button
                                                onClick={() =>
                                                  handleSupportingDocumentsApproval(
                                                    "marketingCollateralsCopy"
                                                  )
                                                }
                                                className="w-1/2 relative inline-flex items-center justify-center px-9 py-2 overflow-hidden font-medium text-green-600 transition duration-300 ease-out border-2 border-green-500 rounded shadow-md group text-xs"
                                              >
                                                <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-green-500 group-hover:translate-x-0 ease">
                                                  <ThumbUpIcon />
                                                </span>
                                                <span className="absolute flex items-center justify-center w-full h-full text-green-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                                  Approve
                                                </span>
                                                <span className="relative invisible">
                                                  Approve
                                                </span>
                                              </button>
                                            </p>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </div> */}
                                </div>
                              )}

                              {agentData?.supportingDocuments
                                ?.applicationAsCompany
                                ?.marketingPlanForAvis && (
                                <div className="my-5">
                                  <div className="text-md font-semibold text-center">
                                    <h2>Marketing plan for Avis</h2>
                                  </div>
                                  <div className="flex justify-center my-2">
                                    <div className="w-96 object-cover">
                                      <a
                                        href={`${process.env.REACT_APP_BACKEND_URL}\\agent-application-docs\\${agentData?.supportingDocuments?.applicationAsCompany?.marketingPlanForAvis?.fileName}`}
                                        target="blank"
                                      >
                                        {" "}
                                        <img
                                          src={`${process.env.REACT_APP_BACKEND_URL}\\agent-application-docs\\${agentData?.supportingDocuments?.applicationAsCompany?.marketingPlanForAvis?.fileName}`}
                                          alt={
                                            agentData?.supportingDocuments
                                              ?.applicationAsCompany
                                              ?.marketingPlanForAvis
                                          }
                                        />
                                      </a>
                                    </div>
                                  </div>
                                  {/* <div className="w-full gap-3 bg-gray-50 p-5 shadow-inner">
                                    {agentData?.supportingDocuments
                                      ?.applicationAsCompany
                                      ?.marketingPlanForAvis?.isApproved ===
                                      false &&
                                    agentData?.supportingDocuments
                                      ?.applicationAsCompany
                                      ?.marketingPlanForAvis?.isRejected ===
                                      false ? null : (
                                      <div>
                                        {agentData?.supportingDocuments
                                          ?.applicationAsCompany
                                          ?.marketingPlanForAvis?.isApproved ===
                                        true ? (
                                          <p className="text-green-700 font-semibold text-sm">
                                            You have previously given your
                                            approval, but you have the option to
                                            modify your decision.
                                          </p>
                                        ) : (
                                          <p className="text-red-600 font-semibold text-sm">
                                            You have previously given your
                                            rejection, but you have the option
                                            to modify your decision.
                                          </p>
                                        )}
                                      </div>
                                    )}
                                    <div className="my-2">
                                      <input
                                        type="text"
                                        placeholder="Any text response to applicants...(optional)"
                                        className="border border-gray-200 rounded-md w-full text-[15px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      />
                                    </div>
                                    <div className="flex gap-3">
                                      {agentData?.supportingDocuments
                                        ?.applicationAsCompany
                                        ?.marketingPlanForAvis?.isApproved ===
                                        false &&
                                      agentData?.supportingDocuments
                                        ?.applicationAsCompany
                                        ?.marketingPlanForAvis?.isRejected ===
                                        false ? (
                                        <>
                                          <button
                                            onClick={() =>
                                              handleSupportingDocumentsRejection(
                                                "marketingPlanForAvis"
                                              )
                                            }
                                            className="w-1/2 relative inline-flex items-center justify-center px-12 py-2 overflow-hidden font-medium text-red-600 transition duration-300 ease-out border-2 border-red-500 rounded shadow-md group text-xs"
                                          >
                                            <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-red-500 group-hover:translate-x-0 ease">
                                              <ThumbDownIcon />
                                            </span>
                                            <span className="absolute flex items-center justify-center w-full h-full text-red-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                              Reject
                                            </span>
                                            <span className="relative invisible">
                                              Reject
                                            </span>
                                          </button>
                                          <button
                                            onClick={() =>
                                              handleSupportingDocumentsApproval(
                                                "marketingPlanForAvis"
                                              )
                                            }
                                            className="w-1/2 relative inline-flex items-center justify-center px-9 py-2 overflow-hidden font-medium text-green-600 transition duration-300 ease-out border-2 border-green-500 rounded shadow-md group text-xs"
                                          >
                                            <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-green-500 group-hover:translate-x-0 ease">
                                              <ThumbUpIcon />
                                            </span>
                                            <span className="absolute flex items-center justify-center w-full h-full text-green-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                              Approve
                                            </span>
                                            <span className="relative invisible">
                                              Approve
                                            </span>
                                          </button>
                                        </>
                                      ) : (
                                        <>
                                          {agentData?.supportingDocuments
                                            ?.applicationAsCompany
                                            ?.marketingPlanForAvis
                                            ?.isApproved === true ? (
                                            <p>
                                              <button
                                                onClick={() =>
                                                  handleSupportingDocumentsRejection(
                                                    "marketingPlanForAvis"
                                                  )
                                                }
                                                className="w-1/2 relative inline-flex items-center justify-center px-12 py-2 overflow-hidden font-medium text-red-600 transition duration-300 ease-out border-2 border-red-500 rounded shadow-md group text-xs"
                                              >
                                                <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-red-500 group-hover:translate-x-0 ease">
                                                  <ThumbDownIcon />
                                                </span>
                                                <span className="absolute flex items-center justify-center w-full h-full text-red-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                                  Reject
                                                </span>
                                                <span className="relative invisible">
                                                  Reject
                                                </span>
                                              </button>
                                            </p>
                                          ) : (
                                            <p>
                                              <button
                                                onClick={() =>
                                                  handleSupportingDocumentsApproval(
                                                    "marketingPlanForAvis"
                                                  )
                                                }
                                                className="w-1/2 relative inline-flex items-center justify-center px-9 py-2 overflow-hidden font-medium text-green-600 transition duration-300 ease-out border-2 border-green-500 rounded shadow-md group text-xs"
                                              >
                                                <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-green-500 group-hover:translate-x-0 ease">
                                                  <ThumbUpIcon />
                                                </span>
                                                <span className="absolute flex items-center justify-center w-full h-full text-green-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                                  Approve
                                                </span>
                                                <span className="relative invisible">
                                                  Approve
                                                </span>
                                              </button>
                                            </p>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </div> */}
                                </div>
                              )}
                            </div>
                            {/* <div className="w-full gap-3 bg-gray-50 p-5 shadow-inner">
                            <h2>
                              Please provide a response for all company
                              documents.
                            </h2>
                            <div>
                              <div className="my-2">
                                <input
                                  type="text"
                                  placeholder="Any text response to applicants...(optional)"
                                  className="border border-gray-200 rounded-md w-full text-[15px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                />
                              </div>
                            </div>
                            <div className="flex gap-3">
                              <button
                                // onClick={() =>
                                //   handleSupportingDocumentsRejection(
                                //     "companyRegistrationCertificate"
                                //   )
                                // }
                                className="w-1/2 relative inline-flex items-center justify-center px-12 py-2 overflow-hidden font-medium text-red-600 transition duration-300 ease-out border-2 border-red-500 rounded shadow-md group text-xs"
                              >
                                <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-red-500 group-hover:translate-x-0 ease">
                                  <ThumbDownIcon />
                                </span>
                                <span className="absolute flex items-center justify-center w-full h-full text-red-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                  Reject
                                </span>
                                <span className="relative invisible">
                                  Reject
                                </span>
                              </button>
                              <button
                                // onClick={() =>
                                //   handleSupportingDocumentsApproval(
                                //     "companyRegistrationCertificate"
                                //   )
                                // }
                                className="w-1/2 relative inline-flex items-center justify-center px-9 py-2 overflow-hidden font-medium text-green-600 transition duration-300 ease-out border-2 border-green-500 rounded shadow-md group text-xs"
                              >
                                <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-green-500 group-hover:translate-x-0 ease">
                                  <ThumbUpIcon />
                                </span>
                                <span className="absolute flex items-center justify-center w-full h-full text-green-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                  Approve
                                </span>
                                <span className="relative invisible">
                                  Approve
                                </span>
                              </button>
                            </div>
                          </div> */}
                          </>
                        ) : (
                          <div className="accordion rounded-sm shadow-lg py-3 bg-gray-50 mb-3">
                            <div className="flex justify-center items-center gap-x-4 py-5">
                              <h2 className="text-[20px] font-extrabold text-gray-200">
                                Applicant in this field has not completed yet.
                              </h2>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="px-5 my-5 py-10 shadow-md shadow-gray-200">
                        <p className="font-semibold pb-6">
                          FOR APPLICATION AS INDIVIDUAL - DOCUMENTS
                        </p>
                        {agentData?.applicationSteps
                          ?.isSupportingDocumentsTwo ? (
                          <>
                            <div className="grid grid-cols-2 gap-3">
                              {agentData?.supportingDocuments
                                ?.applicationAsIndividual
                                ?.tradeLicenseOrNationalID && (
                                <div className="my-5">
                                  <div className="text-md font-semibold text-center">
                                    <h2>Trade license or National ID</h2>
                                  </div>
                                  <div className="flex justify-center my-2">
                                    <div className="w-96 object-cover">
                                      <img
                                        src={`${process.env.REACT_APP_BACKEND_URL}\\agent-application-docs\\${agentData?.supportingDocuments?.applicationAsIndividual?.tradeLicenseOrNationalID?.fileName}`}
                                        alt={
                                          agentData?.supportingDocuments
                                            ?.applicationAsIndividual
                                            ?.tradeLicenseOrNationalID?.fileName
                                        }
                                      />
                                    </div>
                                  </div>
                                  {/* <div className="w-full gap-3 bg-gray-50 p-5 shadow-inner">
                                    {agentData?.supportingDocuments
                                      ?.applicationAsIndividual
                                      ?.tradeLicenseOrNationalID?.isApproved ===
                                      false &&
                                    agentData?.supportingDocuments
                                      ?.applicationAsIndividual
                                      ?.tradeLicenseOrNationalID?.isRejected ===
                                      false ? null : (
                                      <div>
                                        {agentData?.supportingDocuments
                                          ?.applicationAsIndividual
                                          ?.tradeLicenseOrNationalID
                                          ?.isApproved === true ? (
                                          <p className="text-green-700 font-semibold text-sm">
                                            You have previously given your
                                            approval, but you have the option to
                                            modify your decision.
                                          </p>
                                        ) : (
                                          <p className="text-red-600 font-semibold text-sm">
                                            You have previously given your
                                            rejection, but you have the option
                                            to modify your decision.
                                          </p>
                                        )}
                                      </div>
                                    )}
                                    <div className="my-2">
                                      <input
                                        type="text"
                                        placeholder="Any text response to applicants...(optional)"
                                        className="border border-gray-200 rounded-md w-full text-[15px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      />
                                    </div>
                                    <div className="flex gap-3">
                                      {agentData?.supportingDocuments
                                        ?.applicationAsIndividual
                                        ?.tradeLicenseOrNationalID
                                        ?.isApproved === false &&
                                      agentData?.supportingDocuments
                                        ?.applicationAsIndividual
                                        ?.tradeLicenseOrNationalID
                                        ?.isRejected === false ? (
                                        <>
                                          <button
                                            onClick={() =>
                                              handleSupportingDocumentsRejection(
                                                "tradeLicenseOrNationalID"
                                              )
                                            }
                                            className="w-1/2 relative inline-flex items-center justify-center px-12 py-2 overflow-hidden font-medium text-red-600 transition duration-300 ease-out border-2 border-red-500 rounded shadow-md group text-xs"
                                          >
                                            <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-red-500 group-hover:translate-x-0 ease">
                                              <ThumbDownIcon />
                                            </span>
                                            <span className="absolute flex items-center justify-center w-full h-full text-red-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                              Reject
                                            </span>
                                            <span className="relative invisible">
                                              Reject
                                            </span>
                                          </button>
                                          <button
                                            onClick={() =>
                                              handleSupportingDocumentsApproval(
                                                "tradeLicenseOrNationalID"
                                              )
                                            }
                                            className="w-1/2 relative inline-flex items-center justify-center px-9 py-2 overflow-hidden font-medium text-green-600 transition duration-300 ease-out border-2 border-green-500 rounded shadow-md group text-xs"
                                          >
                                            <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-green-500 group-hover:translate-x-0 ease">
                                              <ThumbUpIcon />
                                            </span>
                                            <span className="absolute flex items-center justify-center w-full h-full text-green-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                              Approve
                                            </span>
                                            <span className="relative invisible">
                                              Approve
                                            </span>
                                          </button>
                                        </>
                                      ) : (
                                        <>
                                          {agentData?.supportingDocuments
                                            ?.applicationAsIndividual
                                            ?.tradeLicenseOrNationalID
                                            ?.isApproved === true ? (
                                            <p>
                                              <button
                                                onClick={() =>
                                                  handleSupportingDocumentsRejection(
                                                    "tradeLicenseOrNationalID"
                                                  )
                                                }
                                                className="w-1/2 relative inline-flex items-center justify-center px-12 py-2 overflow-hidden font-medium text-red-600 transition duration-300 ease-out border-2 border-red-500 rounded shadow-md group text-xs"
                                              >
                                                <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-red-500 group-hover:translate-x-0 ease">
                                                  <ThumbDownIcon />
                                                </span>
                                                <span className="absolute flex items-center justify-center w-full h-full text-red-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                                  Reject
                                                </span>
                                                <span className="relative invisible">
                                                  Reject
                                                </span>
                                              </button>
                                            </p>
                                          ) : (
                                            <p>
                                              <button
                                                onClick={() =>
                                                  handleSupportingDocumentsApproval(
                                                    "tradeLicenseOrNationalID"
                                                  )
                                                }
                                                className="w-1/2 relative inline-flex items-center justify-center px-9 py-2 overflow-hidden font-medium text-green-600 transition duration-300 ease-out border-2 border-green-500 rounded shadow-md group text-xs"
                                              >
                                                <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-green-500 group-hover:translate-x-0 ease">
                                                  <ThumbUpIcon />
                                                </span>
                                                <span className="absolute flex items-center justify-center w-full h-full text-green-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                                  Approve
                                                </span>
                                                <span className="relative invisible">
                                                  Approve
                                                </span>
                                              </button>
                                            </p>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </div> */}
                                </div>
                              )}

                              {agentData?.supportingDocuments
                                ?.applicationAsIndividual?.CV && (
                                <div className="my-5">
                                  <div className="text-md font-semibold text-center">
                                    <h2>CV</h2>
                                  </div>
                                  <div className="flex justify-center my-2">
                                    <div className="w-96 object-cover">
                                      <img
                                        src={`${process.env.REACT_APP_BACKEND_URL}\\agent-application-docs\\${agentData?.supportingDocuments?.applicationAsIndividual?.CV?.fileName}`}
                                        alt={
                                          agentData?.supportingDocuments
                                            ?.applicationAsIndividual?.CV
                                            ?.fileName
                                        }
                                      />
                                    </div>
                                  </div>
                                  {/* <div className="w-full gap-3 bg-gray-50 p-5 shadow-inner">
                                    {agentData?.supportingDocuments
                                      ?.applicationAsIndividual?.CV
                                      ?.isApproved === false &&
                                    agentData?.supportingDocuments
                                      ?.applicationAsIndividual?.CV
                                      .isRejected === false ? null : (
                                      <div>
                                        {agentData?.supportingDocuments
                                          ?.applicationAsIndividual?.CV
                                          ?.isApproved === true ? (
                                          <p className="text-green-700 font-semibold text-sm">
                                            You have previously given your
                                            approval, but you have the option to
                                            modify your decision.
                                          </p>
                                        ) : (
                                          <p className="text-red-600 font-semibold text-sm">
                                            You have previously given your
                                            rejection, but you have the option
                                            to modify your decision.
                                          </p>
                                        )}
                                      </div>
                                    )}
                                    <div className="my-2">
                                      <input
                                        type="text"
                                        placeholder="Any text response to applicants...(optional)"
                                        className="border border-gray-200 rounded-md w-full text-[15px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      />
                                    </div>
                                    <div className="flex gap-3">
                                      {agentData?.supportingDocuments
                                        ?.applicationAsIndividual?.CV
                                        ?.isApproved === false &&
                                      agentData?.supportingDocuments
                                        ?.applicationAsIndividual?.CV
                                        ?.isRejected === false ? (
                                        <>
                                          <button
                                            onClick={() =>
                                              handleSupportingDocumentsRejection(
                                                "CV"
                                              )
                                            }
                                            className="w-1/2 relative inline-flex items-center justify-center px-12 py-2 overflow-hidden font-medium text-red-600 transition duration-300 ease-out border-2 border-red-500 rounded shadow-md group text-xs"
                                          >
                                            <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-red-500 group-hover:translate-x-0 ease">
                                              <ThumbDownIcon />
                                            </span>
                                            <span className="absolute flex items-center justify-center w-full h-full text-red-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                              Reject
                                            </span>
                                            <span className="relative invisible">
                                              Reject
                                            </span>
                                          </button>
                                          <button
                                            onClick={() =>
                                              handleSupportingDocumentsApproval(
                                                "CV"
                                              )
                                            }
                                            className="w-1/2 relative inline-flex items-center justify-center px-9 py-2 overflow-hidden font-medium text-green-600 transition duration-300 ease-out border-2 border-green-500 rounded shadow-md group text-xs"
                                          >
                                            <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-green-500 group-hover:translate-x-0 ease">
                                              <ThumbUpIcon />
                                            </span>
                                            <span className="absolute flex items-center justify-center w-full h-full text-green-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                              Approve
                                            </span>
                                            <span className="relative invisible">
                                              Approve
                                            </span>
                                          </button>
                                        </>
                                      ) : (
                                        <>
                                          {agentData?.supportingDocuments
                                            ?.applicationAsIndividual?.CV
                                            ?.isApproved === true ? (
                                            <p>
                                              <button
                                                onClick={() =>
                                                  handleSupportingDocumentsRejection(
                                                    "CV"
                                                  )
                                                }
                                                className="w-1/2 relative inline-flex items-center justify-center px-12 py-2 overflow-hidden font-medium text-red-600 transition duration-300 ease-out border-2 border-red-500 rounded shadow-md group text-xs"
                                              >
                                                <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-red-500 group-hover:translate-x-0 ease">
                                                  <ThumbDownIcon />
                                                </span>
                                                <span className="absolute flex items-center justify-center w-full h-full text-red-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                                  Reject
                                                </span>
                                                <span className="relative invisible">
                                                  Reject
                                                </span>
                                              </button>
                                            </p>
                                          ) : (
                                            <p>
                                              <button
                                                onClick={() =>
                                                  handleSupportingDocumentsApproval(
                                                    "CV"
                                                  )
                                                }
                                                className="w-1/2 relative inline-flex items-center justify-center px-9 py-2 overflow-hidden font-medium text-green-600 transition duration-300 ease-out border-2 border-green-500 rounded shadow-md group text-xs"
                                              >
                                                <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-green-500 group-hover:translate-x-0 ease">
                                                  <ThumbUpIcon />
                                                </span>
                                                <span className="absolute flex items-center justify-center w-full h-full text-green-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                                  Approve
                                                </span>
                                                <span className="relative invisible">
                                                  Approve
                                                </span>
                                              </button>
                                            </p>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </div> */}
                                </div>
                              )}

                              {agentData?.supportingDocuments
                                ?.applicationAsIndividual
                                ?.photoOfOfficePremisesExternalIndividual && (
                                <div className="my-5">
                                  <div className="text-md font-semibold text-center">
                                    <h2>Photo of Office premises External</h2>
                                  </div>
                                  <div className="flex justify-center my-2">
                                    <div className="w-96 object-cover">
                                      <img
                                        src={`${process.env.REACT_APP_BACKEND_URL}\\agent-application-docs\\${agentData?.supportingDocuments?.applicationAsIndividual?.photoOfOfficePremisesExternalIndividual?.fileName}`}
                                        alt={
                                          agentData?.supportingDocuments
                                            ?.applicationAsIndividual
                                            ?.photoOfOfficePremisesExternalIndividual
                                            ?.fileName
                                        }
                                      />
                                    </div>
                                  </div>
                                  {/* <div className="w-full gap-3 bg-gray-50 p-5 shadow-inner">
                                    {agentData?.supportingDocuments
                                      ?.applicationAsIndividual
                                      ?.photoOfOfficePremisesExternalIndividual
                                      ?.isApproved === false &&
                                    agentData?.supportingDocuments
                                      ?.applicationAsIndividual
                                      ?.photoOfOfficePremisesExternalIndividual
                                      ?.isRejected === false ? null : (
                                      <div>
                                        {agentData?.supportingDocuments
                                          ?.applicationAsIndividual
                                          ?.photoOfOfficePremisesExternalIndividual
                                          ?.isApproved === true ? (
                                          <p className="text-green-700 font-semibold text-sm">
                                            You have previously given your
                                            approval, but you have the option to
                                            modify your decision.
                                          </p>
                                        ) : (
                                          <p className="text-red-600 font-semibold text-sm">
                                            You have previously given your
                                            rejection, but you have the option
                                            to modify your decision.
                                          </p>
                                        )}
                                      </div>
                                    )}
                                    <div className="my-2">
                                      <input
                                        type="text"
                                        placeholder="Any text response to applicants...(optional)"
                                        className="border border-gray-200 rounded-md w-full text-[15px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      />
                                    </div>
                                    <div className="flex gap-3">
                                      {agentData?.supportingDocuments
                                        ?.applicationAsIndividual
                                        ?.photoOfOfficePremisesExternalIndividual
                                        ?.isApproved === false &&
                                      agentData?.supportingDocuments
                                        ?.applicationAsIndividual
                                        ?.photoOfOfficePremisesExternalIndividual
                                        ?.isRejected === false ? (
                                        <>
                                          <button
                                            onClick={() =>
                                              handleSupportingDocumentsRejection(
                                                "photoOfOfficePremisesExternalIndividual"
                                              )
                                            }
                                            className="w-1/2 relative inline-flex items-center justify-center px-12 py-2 overflow-hidden font-medium text-red-600 transition duration-300 ease-out border-2 border-red-500 rounded shadow-md group text-xs"
                                          >
                                            <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-red-500 group-hover:translate-x-0 ease">
                                              <ThumbDownIcon />
                                            </span>
                                            <span className="absolute flex items-center justify-center w-full h-full text-red-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                              Reject
                                            </span>
                                            <span className="relative invisible">
                                              Reject
                                            </span>
                                          </button>
                                          <button
                                            onClick={() =>
                                              handleSupportingDocumentsApproval(
                                                "photoOfOfficePremisesExternalIndividual"
                                              )
                                            }
                                            className="w-1/2 relative inline-flex items-center justify-center px-9 py-2 overflow-hidden font-medium text-green-600 transition duration-300 ease-out border-2 border-green-500 rounded shadow-md group text-xs"
                                          >
                                            <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-green-500 group-hover:translate-x-0 ease">
                                              <ThumbUpIcon />
                                            </span>
                                            <span className="absolute flex items-center justify-center w-full h-full text-green-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                              Approve
                                            </span>
                                            <span className="relative invisible">
                                              Approve
                                            </span>
                                          </button>
                                        </>
                                      ) : (
                                        <>
                                          {agentData?.supportingDocuments
                                            ?.applicationAsIndividual
                                            ?.photoOfOfficePremisesExternalIndividual
                                            ?.isApproved === true ? (
                                            <p>
                                              <button
                                                onClick={() =>
                                                  handleSupportingDocumentsRejection(
                                                    "photoOfOfficePremisesExternalIndividual"
                                                  )
                                                }
                                                className="w-1/2 relative inline-flex items-center justify-center px-12 py-2 overflow-hidden font-medium text-red-600 transition duration-300 ease-out border-2 border-red-500 rounded shadow-md group text-xs"
                                              >
                                                <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-red-500 group-hover:translate-x-0 ease">
                                                  <ThumbDownIcon />
                                                </span>
                                                <span className="absolute flex items-center justify-center w-full h-full text-red-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                                  Reject
                                                </span>
                                                <span className="relative invisible">
                                                  Reject
                                                </span>
                                              </button>
                                            </p>
                                          ) : (
                                            <p>
                                              <button
                                                onClick={() =>
                                                  handleSupportingDocumentsApproval(
                                                    "photoOfOfficePremisesExternalIndividual"
                                                  )
                                                }
                                                className="w-1/2 relative inline-flex items-center justify-center px-9 py-2 overflow-hidden font-medium text-green-600 transition duration-300 ease-out border-2 border-green-500 rounded shadow-md group text-xs"
                                              >
                                                <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-green-500 group-hover:translate-x-0 ease">
                                                  <ThumbUpIcon />
                                                </span>
                                                <span className="absolute flex items-center justify-center w-full h-full text-green-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                                  Approve
                                                </span>
                                                <span className="relative invisible">
                                                  Approve
                                                </span>
                                              </button>
                                            </p>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </div> */}
                                </div>
                              )}

                              {agentData?.supportingDocuments
                                ?.applicationAsIndividual
                                ?.photoOfOfficePremisesInternalIndividual && (
                                <div className="my-5">
                                  <div className="text-md font-semibold text-center">
                                    <h2>Photo of Office premises Interior</h2>
                                  </div>
                                  <div className="flex justify-center my-2">
                                    <div className="w-96 object-cover">
                                      <img
                                        src={`${process.env.REACT_APP_BACKEND_URL}\\agent-application-docs\\${agentData?.supportingDocuments?.applicationAsIndividual?.photoOfOfficePremisesInternalIndividual?.fileName}`}
                                        alt={
                                          agentData?.supportingDocuments
                                            ?.applicationAsIndividual
                                            ?.photoOfOfficePremisesInternalIndividual
                                            ?.fileName
                                        }
                                      />
                                    </div>
                                  </div>
                                  {/* <div className="w-full gap-3 bg-gray-50 p-5 shadow-inner">
                                    {agentData?.supportingDocuments
                                      ?.applicationAsIndividual
                                      ?.photoOfOfficePremisesInternalIndividual
                                      ?.isApproved === false &&
                                    agentData?.supportingDocuments
                                      ?.applicationAsIndividual
                                      ?.photoOfOfficePremisesInternalIndividual
                                      ?.isRejected === false ? null : (
                                      <div>
                                        {agentData?.supportingDocuments
                                          ?.applicationAsIndividual
                                          ?.photoOfOfficePremisesInternalIndividual
                                          ?.isApproved === true ? (
                                          <p className="text-green-700 font-semibold text-sm">
                                            You have previously given your
                                            approval, but you have the option to
                                            modify your decision.
                                          </p>
                                        ) : (
                                          <p className="text-red-600 font-semibold text-sm">
                                            You have previously given your
                                            rejection, but you have the option
                                            to modify your decision.
                                          </p>
                                        )}
                                      </div>
                                    )}
                                    <div className="my-2">
                                      <input
                                        type="text"
                                        placeholder="Any text response to applicants...(optional)"
                                        className="border border-gray-200 rounded-md w-full text-[15px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      />
                                    </div>
                                    <div className="flex gap-3">
                                      {agentData?.supportingDocuments
                                        ?.applicationAsIndividual
                                        ?.photoOfOfficePremisesInternalIndividual
                                        ?.isApproved === false &&
                                      agentData?.supportingDocuments
                                        ?.applicationAsIndividual
                                        ?.photoOfOfficePremisesInternalIndividual
                                        ?.isRejected === false ? (
                                        <>
                                          <button
                                            onClick={() =>
                                              handleSupportingDocumentsRejection(
                                                "photoOfOfficePremisesInternalIndividual"
                                              )
                                            }
                                            className="w-1/2 relative inline-flex items-center justify-center px-12 py-2 overflow-hidden font-medium text-red-600 transition duration-300 ease-out border-2 border-red-500 rounded shadow-md group text-xs"
                                          >
                                            <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-red-500 group-hover:translate-x-0 ease">
                                              <ThumbDownIcon />
                                            </span>
                                            <span className="absolute flex items-center justify-center w-full h-full text-red-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                              Reject
                                            </span>
                                            <span className="relative invisible">
                                              Reject
                                            </span>
                                          </button>
                                          <button
                                            onClick={() =>
                                              handleSupportingDocumentsApproval(
                                                "photoOfOfficePremisesInternalIndividual"
                                              )
                                            }
                                            className="w-1/2 relative inline-flex items-center justify-center px-9 py-2 overflow-hidden font-medium text-green-600 transition duration-300 ease-out border-2 border-green-500 rounded shadow-md group text-xs"
                                          >
                                            <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-green-500 group-hover:translate-x-0 ease">
                                              <ThumbUpIcon />
                                            </span>
                                            <span className="absolute flex items-center justify-center w-full h-full text-green-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                              Approve
                                            </span>
                                            <span className="relative invisible">
                                              Approve
                                            </span>
                                          </button>
                                        </>
                                      ) : (
                                        <>
                                          {agentData?.supportingDocuments
                                            ?.applicationAsIndividual
                                            ?.photoOfOfficePremisesInternalIndividual
                                            ?.isApproved === true ? (
                                            <p>
                                              <button
                                                onClick={() =>
                                                  handleSupportingDocumentsRejection(
                                                    "photoOfOfficePremisesInternalIndividual"
                                                  )
                                                }
                                                className="w-1/2 relative inline-flex items-center justify-center px-12 py-2 overflow-hidden font-medium text-red-600 transition duration-300 ease-out border-2 border-red-500 rounded shadow-md group text-xs"
                                              >
                                                <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-red-500 group-hover:translate-x-0 ease">
                                                  <ThumbDownIcon />
                                                </span>
                                                <span className="absolute flex items-center justify-center w-full h-full text-red-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                                  Reject
                                                </span>
                                                <span className="relative invisible">
                                                  Reject
                                                </span>
                                              </button>
                                            </p>
                                          ) : (
                                            <p>
                                              <button
                                                onClick={() =>
                                                  handleSupportingDocumentsApproval(
                                                    "photoOfOfficePremisesInternalIndividual"
                                                  )
                                                }
                                                className="w-1/2 relative inline-flex items-center justify-center px-9 py-2 overflow-hidden font-medium text-green-600 transition duration-300 ease-out border-2 border-green-500 rounded shadow-md group text-xs"
                                              >
                                                <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-green-500 group-hover:translate-x-0 ease">
                                                  <ThumbUpIcon />
                                                </span>
                                                <span className="absolute flex items-center justify-center w-full h-full text-green-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                                  Approve
                                                </span>
                                                <span className="relative invisible">
                                                  Approve
                                                </span>
                                              </button>
                                            </p>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </div> */}
                                </div>
                              )}

                              {agentData?.supportingDocuments
                                ?.applicationAsIndividual
                                ?.marketingCollateralsCopyIndividual && (
                                <div className="my-5">
                                  <div className="text-md font-semibold text-center">
                                    <h2>
                                      Copy of Marketing collaterals if any
                                    </h2>
                                  </div>
                                  <div className="flex justify-center my-2">
                                    <div className="w-96 object-cover">
                                      <img
                                        src={`${process.env.REACT_APP_BACKEND_URL}\\agent-application-docs\\${agentData?.supportingDocuments?.applicationAsIndividual?.marketingCollateralsCopyIndividual?.fileName}`}
                                        alt={
                                          agentData?.supportingDocuments
                                            ?.applicationAsIndividual
                                            ?.marketingCollateralsCopyIndividual
                                            ?.fileName
                                        }
                                      />
                                    </div>
                                  </div>
                                  {/* <div className="w-full gap-3 bg-gray-50 p-5 shadow-inner">
                                    {agentData?.supportingDocuments
                                      ?.applicationAsIndividual
                                      ?.marketingCollateralsCopyIndividual
                                      ?.isApproved === false &&
                                    agentData?.supportingDocuments
                                      ?.applicationAsIndividual
                                      ?.marketingCollateralsCopyIndividual
                                      ?.isRejected === false ? null : (
                                      <div>
                                        {agentData?.supportingDocuments
                                          ?.applicationAsIndividual
                                          ?.marketingCollateralsCopyIndividual
                                          ?.isApproved === true ? (
                                          <p className="text-green-700 font-semibold text-sm">
                                            You have previously given your
                                            approval, but you have the option to
                                            modify your decision.
                                          </p>
                                        ) : (
                                          <p className="text-red-600 font-semibold text-sm">
                                            You have previously given your
                                            rejection, but you have the option
                                            to modify your decision.
                                          </p>
                                        )}
                                      </div>
                                    )}
                                    <div className="my-2">
                                      <input
                                        type="text"
                                        placeholder="Any text response to applicants...(optional)"
                                        className="border border-gray-200 rounded-md w-full text-[15px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      />
                                    </div>
                                    <div className="flex gap-3">
                                      {agentData?.supportingDocuments
                                        ?.applicationAsIndividual
                                        ?.marketingCollateralsCopyIndividual
                                        ?.isApproved === false &&
                                      agentData?.supportingDocuments
                                        ?.applicationAsIndividual
                                        ?.marketingCollateralsCopyIndividual
                                        ?.isRejected === false ? (
                                        <>
                                          <button
                                            onClick={() =>
                                              handleSupportingDocumentsRejection(
                                                "marketingCollateralsCopyIndividual"
                                              )
                                            }
                                            className="w-1/2 relative inline-flex items-center justify-center px-12 py-2 overflow-hidden font-medium text-red-600 transition duration-300 ease-out border-2 border-red-500 rounded shadow-md group text-xs"
                                          >
                                            <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-red-500 group-hover:translate-x-0 ease">
                                              <ThumbDownIcon />
                                            </span>
                                            <span className="absolute flex items-center justify-center w-full h-full text-red-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                              Reject
                                            </span>
                                            <span className="relative invisible">
                                              Reject
                                            </span>
                                          </button>
                                          <button
                                            onClick={() =>
                                              handleSupportingDocumentsApproval(
                                                "marketingCollateralsCopyIndividual"
                                              )
                                            }
                                            className="w-1/2 relative inline-flex items-center justify-center px-9 py-2 overflow-hidden font-medium text-green-600 transition duration-300 ease-out border-2 border-green-500 rounded shadow-md group text-xs"
                                          >
                                            <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-green-500 group-hover:translate-x-0 ease">
                                              <ThumbUpIcon />
                                            </span>
                                            <span className="absolute flex items-center justify-center w-full h-full text-green-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                              Approve
                                            </span>
                                            <span className="relative invisible">
                                              Approve
                                            </span>
                                          </button>
                                        </>
                                      ) : (
                                        <>
                                          {agentData?.supportingDocuments
                                            ?.applicationAsIndividual
                                            ?.marketingCollateralsCopyIndividual
                                            ?.isApproved === true ? (
                                            <p>
                                              <button
                                                onClick={() =>
                                                  handleSupportingDocumentsRejection(
                                                    "marketingCollateralsCopyIndividual"
                                                  )
                                                }
                                                className="w-1/2 relative inline-flex items-center justify-center px-12 py-2 overflow-hidden font-medium text-red-600 transition duration-300 ease-out border-2 border-red-500 rounded shadow-md group text-xs"
                                              >
                                                <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-red-500 group-hover:translate-x-0 ease">
                                                  <ThumbDownIcon />
                                                </span>
                                                <span className="absolute flex items-center justify-center w-full h-full text-red-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                                  Reject
                                                </span>
                                                <span className="relative invisible">
                                                  Reject
                                                </span>
                                              </button>
                                            </p>
                                          ) : (
                                            <p>
                                              <button
                                                onClick={() =>
                                                  handleSupportingDocumentsApproval(
                                                    "marketingCollateralsCopyIndividual"
                                                  )
                                                }
                                                className="w-1/2 relative inline-flex items-center justify-center px-9 py-2 overflow-hidden font-medium text-green-600 transition duration-300 ease-out border-2 border-green-500 rounded shadow-md group text-xs"
                                              >
                                                <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-green-500 group-hover:translate-x-0 ease">
                                                  <ThumbUpIcon />
                                                </span>
                                                <span className="absolute flex items-center justify-center w-full h-full text-green-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                                  Approve
                                                </span>
                                                <span className="relative invisible">
                                                  Approve
                                                </span>
                                              </button>
                                            </p>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </div> */}
                                </div>
                              )}

                              {agentData?.supportingDocuments
                                ?.applicationAsIndividual
                                ?.marketingPlanForAvisIndividual && (
                                <div className="my-5">
                                  <div className="text-md font-semibold text-center">
                                    <h2>Marketing plan for Avis</h2>
                                  </div>
                                  <div className="flex justify-center my-2">
                                    <div className="w-96 object-cover">
                                      <img
                                        src={`${process.env.REACT_APP_BACKEND_URL}\\agent-application-docs\\${agentData?.supportingDocuments?.applicationAsIndividual?.marketingPlanForAvisIndividual?.fileName}`}
                                        alt={
                                          agentData?.supportingDocuments
                                            ?.applicationAsIndividual
                                            ?.marketingPlanForAvisIndividual
                                            ?.fileName
                                        }
                                      />
                                    </div>
                                  </div>
                                  {/* <div className="w-full gap-3 bg-gray-50 p-5 shadow-inner">
                                    {agentData?.supportingDocuments
                                      ?.applicationAsIndividual
                                      ?.marketingPlanForAvisIndividual
                                      ?.isApproved === false &&
                                    agentData?.supportingDocuments
                                      ?.applicationAsIndividual
                                      ?.marketingPlanForAvisIndividual
                                      ?.isRejected === false ? null : (
                                      <div>
                                        {agentData?.supportingDocuments
                                          ?.applicationAsIndividual
                                          ?.marketingPlanForAvisIndividual
                                          ?.isApproved === true ? (
                                          <p className="text-green-700 font-semibold text-sm">
                                            You have previously given your
                                            approval, but you have the option to
                                            modify your decision.
                                          </p>
                                        ) : (
                                          <p className="text-red-600 font-semibold text-sm">
                                            You have previously given your
                                            rejection, but you have the option
                                            to modify your decision.
                                          </p>
                                        )}
                                      </div>
                                    )}
                                    <div className="my-2">
                                      <input
                                        type="text"
                                        placeholder="Any text response to applicants...(optional)"
                                        className="border border-gray-200 rounded-md w-full text-[15px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      />
                                    </div>
                                    <div className="flex gap-3">
                                      {agentData?.supportingDocuments
                                        ?.applicationAsIndividual
                                        ?.marketingPlanForAvisIndividual
                                        ?.isApproved === false &&
                                      agentData?.supportingDocuments
                                        ?.applicationAsIndividual
                                        ?.marketingPlanForAvisIndividual
                                        ?.isRejected === false ? (
                                        <>
                                          <button
                                            onClick={() =>
                                              handleSupportingDocumentsRejection(
                                                "marketingPlanForAvisIndividual"
                                              )
                                            }
                                            className="w-1/2 relative inline-flex items-center justify-center px-12 py-2 overflow-hidden font-medium text-red-600 transition duration-300 ease-out border-2 border-red-500 rounded shadow-md group text-xs"
                                          >
                                            <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-red-500 group-hover:translate-x-0 ease">
                                              <ThumbDownIcon />
                                            </span>
                                            <span className="absolute flex items-center justify-center w-full h-full text-red-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                              Reject
                                            </span>
                                            <span className="relative invisible">
                                              Reject
                                            </span>
                                          </button>
                                          <button
                                            onClick={() =>
                                              handleSupportingDocumentsApproval(
                                                "marketingPlanForAvisIndividual"
                                              )
                                            }
                                            className="w-1/2 relative inline-flex items-center justify-center px-9 py-2 overflow-hidden font-medium text-green-600 transition duration-300 ease-out border-2 border-green-500 rounded shadow-md group text-xs"
                                          >
                                            <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-green-500 group-hover:translate-x-0 ease">
                                              <ThumbUpIcon />
                                            </span>
                                            <span className="absolute flex items-center justify-center w-full h-full text-green-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                              Approve
                                            </span>
                                            <span className="relative invisible">
                                              Approve
                                            </span>
                                          </button>
                                        </>
                                      ) : (
                                        <>
                                          {agentData?.supportingDocuments
                                            ?.applicationAsIndividual
                                            ?.marketingPlanForAvisIndividual
                                            ?.isApproved === true ? (
                                            <p>
                                              <button
                                                onClick={() =>
                                                  handleSupportingDocumentsRejection(
                                                    "marketingPlanForAvisIndividual"
                                                  )
                                                }
                                                className="w-1/2 relative inline-flex items-center justify-center px-12 py-2 overflow-hidden font-medium text-red-600 transition duration-300 ease-out border-2 border-red-500 rounded shadow-md group text-xs"
                                              >
                                                <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-red-500 group-hover:translate-x-0 ease">
                                                  <ThumbDownIcon />
                                                </span>
                                                <span className="absolute flex items-center justify-center w-full h-full text-red-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                                  Reject
                                                </span>
                                                <span className="relative invisible">
                                                  Reject
                                                </span>
                                              </button>
                                            </p>
                                          ) : (
                                            <p>
                                              <button
                                                onClick={() =>
                                                  handleSupportingDocumentsApproval(
                                                    "marketingPlanForAvisIndividual"
                                                  )
                                                }
                                                className="w-1/2 relative inline-flex items-center justify-center px-9 py-2 overflow-hidden font-medium text-green-600 transition duration-300 ease-out border-2 border-green-500 rounded shadow-md group text-xs"
                                              >
                                                <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-green-500 group-hover:translate-x-0 ease">
                                                  <ThumbUpIcon />
                                                </span>
                                                <span className="absolute flex items-center justify-center w-full h-full text-green-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                                  Approve
                                                </span>
                                                <span className="relative invisible">
                                                  Approve
                                                </span>
                                              </button>
                                            </p>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </div> */}
                                </div>
                              )}
                            </div>
                            {/* <div className="w-full gap-3 bg-gray-50 p-5 shadow-inner">
                            <h2>
                              Please provide a response for all company
                              documents.
                            </h2>
                            <div>
                              <div className="my-2">
                                <input
                                  type="text"
                                  placeholder="Any text response to applicants...(optional)"
                                  className="border border-gray-200 rounded-md w-full text-[15px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                />
                              </div>
                            </div>
                            <div className="flex gap-3">
                              <button
                                // onClick={() =>
                                //   handleSupportingDocumentsRejection(
                                //     "companyRegistrationCertificate"
                                //   )
                                // }
                                className="w-1/2 relative inline-flex items-center justify-center px-12 py-2 overflow-hidden font-medium text-red-600 transition duration-300 ease-out border-2 border-red-500 rounded shadow-md group text-xs"
                              >
                                <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-red-500 group-hover:translate-x-0 ease">
                                  <ThumbDownIcon />
                                </span>
                                <span className="absolute flex items-center justify-center w-full h-full text-red-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                  Reject
                                </span>
                                <span className="relative invisible">
                                  Reject
                                </span>
                              </button>
                              <button
                                // onClick={() =>
                                //   handleSupportingDocumentsApproval(
                                //     "companyRegistrationCertificate"
                                //   )
                                // }
                                className="w-1/2 relative inline-flex items-center justify-center px-9 py-2 overflow-hidden font-medium text-green-600 transition duration-300 ease-out border-2 border-green-500 rounded shadow-md group text-xs"
                              >
                                <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-green-500 group-hover:translate-x-0 ease">
                                  <ThumbUpIcon />
                                </span>
                                <span className="absolute flex items-center justify-center w-full h-full text-green-500 transition-all duration-300 transform group-hover:translate-x-full ease">
                                  Approve
                                </span>
                                <span className="relative invisible">
                                  Approve
                                </span>
                              </button>
                            </div>
                          </div> */}
                          </>
                        ) : (
                          <div className="accordion rounded-sm shadow-lg py-3 bg-gray-50 mb-3">
                            <div className="flex justify-center items-center gap-x-4 py-5">
                              <h2 className="text-[20px] font-extrabold text-gray-200">
                                Applicant in this field has not completed yet.
                              </h2>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="accordion rounded-sm shadow-lg py-3 bg-gray-50 mb-3">
                      <div className="flex justify-center items-center gap-x-4 py-5">
                        <h2 className="text-[30px] font-extrabold text-gray-200">
                          Applicant in this field has not completed yet.
                        </h2>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgentRecruitmentDocuments;
