import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import { addAdminData } from "../../../redux/Auth/AdminDataSlice";
import { fetchAdminShortData } from "../../../API/admin/AdminAPI";
import { checkTokenExpires } from "../../../API/admin/AuthApi";

const ProtectedRoute = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const tokenExpiresCheck = async () => {
      const adminToken = localStorage.getItem("admin-token");
      if (!adminToken) {
        navigate("/admin/login");
      } else {
        try {
          const response = await checkTokenExpires(adminToken);
          if (response?.status === 200) {
            const decodedToken = jwt_decode(adminToken);
            const adminData = await fetchAdminShortData(decodedToken.adminId);
            dispatch(addAdminData(adminData.data));
          } else {
            localStorage.removeItem("admin-token");
            navigate("/admin/login");
          }
        } catch (error) {
          console.error("Error during token expiration check:", error);
          navigate("/admin/login");
        }
      }
    };

    tokenExpiresCheck();
  }, [dispatch, navigate]);

  if (!props.children) {
    return null;
  }

  return <>{props.children}</>;
};

export default ProtectedRoute;
