import { combineReducers, configureStore } from "@reduxjs/toolkit";
import adminAuth from "./Auth/AdminAuth";
import adminData from "./Auth/AdminDataSlice";

const reducer = combineReducers({
  admin: adminAuth.reducer,
  adminData: adminData.reducer,
});

const store = configureStore({
  reducer,
});

export default store;
