import React, { useRef, useState } from "react";
import { editProfileAPI } from "../../../API/admin/AdminAPI";
import Loader from "../CreateForms/Loader";
import { useNavigate } from "react-router-dom";
import RemoveRedEyeSharpIcon from "@mui/icons-material/RemoveRedEyeSharp";
import VisibilityOffSharpIcon from "@mui/icons-material/VisibilityOffSharp";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";

const EditAdmin = ({ adminData, setAdminData, handleCloseModal }) => {
  const [formErrors, setFormErrors] = useState({});
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [adminExists, setAdminExists] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [formData, setFormData] = useState({
    _id: adminData._id,
    fullName: adminData?.name,
    email: adminData?.email,
    password: "",
    state: adminData?.address?.state,
    country: adminData?.address?.country,
  });
  const navigate = useNavigate();
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(formData.email);
    setIsEmailValid(isValidEmail);
  };
  const handlePassChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    // Validate password
    const isValidPassword = value.length >= 8;
    setIsPasswordValid(isValidPassword);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const errors = validateForm();
    if (isEmailValid && isPasswordValid && Object.keys(errors).length === 0) {
      setIsLoader(true);
      try {
        const response = await editProfileAPI(formData);
        if (response.status === 200) {
          navigate("/admin/admins");
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setIsLoader(false);
      setFormErrors(errors);
    }
  };
  const validateForm = () => {
    const errors = {};
    setIsPasswordValid(true);
    // Validate each input here
    if (formData.fullName.trim() === "") {
      errors.fullName = "Full Name is required";
    }
    if (formData.email.trim() === "") {
      errors.email = "Email is required";
    }
    if (formData.state.trim() === "") {
      errors.state = "State is required";
    }
    if (formData.country.trim() === "") {
      errors.country = "Country is required";
    }
    return errors;
  };

  // copy password to clipboard
  const inputRef = useRef(null);

  const handleCopy = () => {
    const inputElement = inputRef.current;

    if (inputElement) {
      inputElement.select();

      // Use the Clipboard API to copy the text to the clipboard
      navigator.clipboard
        .writeText(inputElement.value)
        .then(() => {
          // Success!
          alert("Copied to clipboard!");
        })
        .catch((err) => {
          // Unable to copy
          console.error("Failed to copy: ", err);
        });
    }
  };

  return (
    <div className="flex justify-center items-center md:flex py-[20px] bg-white">
      <div className="w-full py-[10px] rounded-2xl shadow-lg shadow-gray-500">
        <div className="w-[95%] mx-auto">
          {/* heading */}
          <div className=" text-center md:py-[20px] text-[25px] md:text-left">
            <p className="underline underline-offset-8">Edit Profile</p>
          </div>
          <div className="grid grid-cols-3 gap-3">
            {/* name */}
            <div>
              <label className="block mb-2 font-medium" htmlFor="fullName">
                Full Name:
              </label>
              <input
                className="block w-full px-4 py-2 border placeholder:text-gray-500  border-gray-300 rounded-lg"
                type="text"
                id="fullName"
                name="fullName"
                placeholder="Full Name"
                required
                value={formData.fullName}
                onChange={handleInputChange}
              />
              {formErrors.fullName && (
                <span className="text-xs text-red-600">
                  {formErrors.fullName}
                </span>
              )}
            </div>
            {/* mail id */}
            <div>
              <label className="block mb-2 font-medium" htmlFor="emailAddress">
                Email Address:
              </label>
              <input
                className="block w-full px-4 py-2 border placeholder:text-gray-500  border-gray-300 rounded-lg"
                type="email"
                id="emailAddress"
                name="email"
                placeholder="Email Address"
                required
                value={formData.email}
                onChange={handleInputChange}
                onBlur={validateEmail}
              />
              {!isEmailValid && (
                <span className="text-xs text-red-600">
                  Please enter a valid email address.
                </span>
              )}
              {adminExists && (
                <span className="text-xs text-red-600">
                  Email already exist !
                </span>
              )}
              {formErrors.email && (
                <span className="text-xs text-red-600">{formErrors.email}</span>
              )}
            </div>
            <div>
              <label className="block mb-2 font-medium" htmlFor="contactNumber">
                Password:
              </label>
              <div className="flex gap-1">
                <input
                  className=" block w-10/12 px-4 py-2 border placeholder:text-gray-500 placeholder:text-xs border-gray-300 rounded-lg"
                  type={isVisible ? "text" : "password"}
                  id="password"
                  name="password"
                  placeholder="Set empty for continue with old password"
                  value={formData.password}
                  onChange={handlePassChange}
                  ref={inputRef}
                  required
                />
                <div className="flex gap-1">
                  <button
                    className=" right-0 text-black border border-gray-300  hover:bg-black hover:text-white font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center "
                    onClick={() => setIsVisible(!isVisible)}
                  >
                    {!isVisible ? (
                      <RemoveRedEyeSharpIcon sx={{ fontSize: 20 }} />
                    ) : (
                      <VisibilityOffSharpIcon sx={{ fontSize: 20 }} />
                    )}
                  </button>
                  <button
                    className="right-0 text-black border border-gray-300  hover:bg-black hover:text-white font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center "
                    onClick={handleCopy}
                  >
                    <ContentPasteIcon sx={{ fontSize: 20 }} />
                  </button>
                </div>
              </div>
              {!isPasswordValid && (
                <span className="text-xs text-red-600">
                  Password must be at least 8 characters long.
                </span>
              )}
              {formErrors.password && (
                <span className="text-xs text-red-600">
                  {formErrors.password}
                </span>
              )}
            </div>
          </div>
          <p className="block my-3 font-medium text-sm" htmlFor="Address">
            Address:
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-3 my-[10px]">
            <div>
              <input
                className="block w-full px-4 py-2 border placeholder:text-gray-500  border-gray-300 rounded-lg"
                type="text"
                id="state"
                name="state"
                placeholder="State"
                value={formData.state}
                onChange={handleInputChange}
                required
              />
              {formErrors.state && (
                <span className="text-xs text-red-600">{formErrors.state}</span>
              )}
            </div>
            <div>
              <input
                className="block w-full px-4 py-2 border placeholder:text-gray-500  border-gray-300 rounded-lg"
                type="text"
                id="country"
                name="country"
                placeholder="country"
                value={formData.country}
                onChange={handleInputChange}
                required
              />
              {formErrors.country && (
                <span className="text-xs text-red-600">
                  {formErrors.country}
                </span>
              )}
            </div>
          </div>
          <div className="flex justify-end gap-x-[20px]">
            <div className="flex justify-end gap-x-[20px]">
              <button
                onClick={handleCloseModal}
                className="py-[8px] md:ease-in duration-300 mt-4 bg-black hover:bg-red-600  text-sm text-white font-bold px-2  rounded md:w-40"
                type="submit"
              >
                Cancel
              </button>
              <button
                onClick={handleSubmit}
                className=" ease-in duration-300 mt-4 bg-red-500 hover:bg-green-600  text-sm text-white font-bold  py-2 px-3  rounded md:w-40"
                type="submit"
                disabled={isLoader}
              >
                {!isLoader ? <span>Save</span> : <Loader />}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditAdmin;
