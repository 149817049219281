import React from "react";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { referenceApi } from "../../../API/Agent/AgentAPI";
import { businessreferenceApi } from "../../../API/Agent/AgentAPI";
import Loader from "../../AgentComponents/Loader/Loader";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import ErrorIcon from "@mui/icons-material/Error";
import VerifiedIcon from "@mui/icons-material/Verified";

const BusinessReference = ({
  setApplicationSteps,
  applicationSteps,
  formfillesReferance,
  setFormfilledReference,
  formfillesBusinessReferance,
  setformfillesBusinessReferance,

  referenceApproved,
  referenceRejected,
}) => {
  const agentTokens = localStorage.getItem("agentToken");
  const [submitted, setSubmitted] = useState(false);
  const [submittedBusinessReference, setSubmittedBusinessReference] =
    useState(false); // Separate state for business reference
  const [isOpenPan, setIsOpenPan] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  const [formReference, setFormReference] = useState({
    agentTokens: agentTokens,
    nameReferee: "",
    designation: "",
    nameofInstitution: "",
    emailid: "",
    contactnumber: "",
    refference: 1,
  });
  const [formBusinessReference, setFormBusinessReference] = useState({
    agentTokens: agentTokens,
    nameReferee: "",
    designation: "",
    nameofInstitution: "",
    emailid: "",
    contactnumber: "",
    refference: 2,
  });
  const [formPanErrors, setFormPanErrors] = useState({});
  const [formReferenceErrors, setformReferenceErrors] = useState({});
  const handleReferenceInputChange = (e) => {
    const { name, value } = e.target;
    if (!submitted) {
      setFormReference({
        ...formReference,
        [name]: value,
      });
    }
  };
  const handlePBusinessReferenceInputChange = (e) => {
    const { name, value } = e.target;
    if (!submittedBusinessReference) {
      setFormBusinessReference({
        ...formBusinessReference,
        [name]: value,
      });
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = {};

    if (!formReference.nameReferee) {
      errors.nameReferee = "This Field  is required";
    }
    if (!formReference.designation) {
      errors.designation = "This Field  is required";
    }
    if (!formReference.nameofInstitution) {
      errors.nameofInstitution = "This Field  is required";
    }
    if (!formReference.emailid) {
      errors.emailid = "This Field  is required";
    }
    if (!formReference.contactnumber) {
      errors.contactnumber = "This Field  is required";
    }
    if (Object.keys(errors).length === 0) {
      try {
        setIsLoader(true);
        const response = await referenceApi(formReference);

        if (response.status === 201) {
          setIsLoader(false);
          // alert("Form data submitted successfully!");
          console.log(response, "response");
          setSubmitted(true);

          console.log(response);

          setFormfilledReference({
            agentTokens: agentTokens,
            name: response.data.data.name,
            nameOfInstitute: response.data.data.nameOfInstitute,
            email: response.data.data.email,
            designation: response.data.data.designation,
            contactNumber: response.data.data.contactNumber,
          });
        } else {
          alert("Failed to submit form data.");
        }
      } catch (error) {
        console.error("API request failed:", error);
      } finally {
        setIsLoader(false);
      }
    } else {
      // setFormPanErrors(errors);
      setformReferenceErrors(errors);
      setIsLoader(false);
    }
  };
  const handleBusinessReferenceSubmit = async (e) => {
    e.preventDefault();
    const errors = {};

    if (!formBusinessReference.nameReferee) {
      errors.nameReferee = "This Field  is required";
    }
    if (!formBusinessReference.designation) {
      errors.designation = "This Field  is required";
    }
    if (!formBusinessReference.nameofInstitution) {
      errors.nameofInstitution = "This Field  is required";
    }
    if (!formBusinessReference.emailid) {
      errors.emailid = "This Field  is required";
    }
    if (!formBusinessReference.contactnumber) {
      errors.contactnumber = "This Field  is required";
    }
    if (Object.keys(errors).length === 0) {
      try {
        setIsLoader(true);
        const response = await businessreferenceApi(formBusinessReference);

        if (response.status === 201) {
          setIsLoader(false);
          // alert("Form data submitted successfully!");
          console.log(response, "response");
          setSubmittedBusinessReference(true);
          setApplicationSteps((prevData) => ({
            ...prevData,
            isBusinessReference: true,
          }));

          console.log(response);
          setformfillesBusinessReferance({
            agentTokens: agentTokens,
            name: response.data.data.name,
            nameOfInstitute: response.data.data.nameOfInstitute,
            email: response.data.data.email,
            designation: response.data.data.designation,
            contactNumber: response.data.data.contactNumber,
          });
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert severity="success">
              This is a success alert — check it out!
            </Alert>
          </Stack>;
        } else {
          alert("Failed to submit form data.");
        }
      } catch (error) {
        console.error("API request failed:", error);
      } finally {
        setIsLoader(false);
      }
    } else {
      setFormPanErrors(errors);
      setIsLoader(false);
    }
  };
  const toggleAccordionPan = () => {
    setIsOpenPan(!isOpenPan);
  };
  return (
    <div>
      {applicationSteps.isBusinessReference ? (
        <div>
          <div className={`accordion ${isOpenPan ? "open" : ""}mb-6`}>
            <div
              className="accordion-header  w-[374px]  md:w-[814px] rounded-sm  shadow-lg"
              onClick={toggleAccordionPan}
            >
              <div className="flex justify-center items-center gap-x-4">
                <div>
                  {referenceRejected.isRejected ? (
                    // Display a message or any other component after submission
                    <div className="flex  items-center gap-x-2 bg-red-500 px-1 py-1 rounded ">
                      <p className="text-white font-bold text-sm">STEP 4</p>
                    </div>
                  ) : (
                    <div className="flex  items-center gap-x-2 bg-green-500 px-1 py-1 rounded ">
                      <p className="text-white font-bold text-sm">STEP 4</p>
                    </div>
                  )}
                </div>
                <div>
                  <p className="text-md font-semibold ">BUSINESS REFERENCE</p>
                </div>
              </div>
              <div className="flex justify-end items-end">
                <i className="fa-solid fa-angle-up" />
                <span className="icon">
                  {isOpenPan ? <RemoveIcon /> : <AddIcon />}
                </span>
              </div>
            </div>
            {isOpenPan && (
              <div className="accordion-content text-black">
                <div className="panel w-full sm:w-full md:w-[814px]  flex justify-center sm:flex sm:justify-content  md:flex-row  md:justify-center md:item-center shadow-lg">
                  <div className="py-10">
                    <form>
                      <div className=" flex-col md:flex-row flex justify-between items-center gap-x-6">
                        <div className="mb-4">
                          <label
                            htmlFor="name"
                            className="block text-black  text-sm mb-2"
                          >
                            Name Referee
                          </label>
                          <input
                            type="text"
                            id="nameReferee"
                            name="nameReferee"
                            value={formfillesReferance?.name || ""}
                            onChange={handleReferenceInputChange}
                            placeholder="Name Referee"
                            disabled={
                              submitted && !referenceRejected.isRejected
                            }
                            className="border border-gray-300 rounded-md w-[350px] py-2 px-3 text-[13px] text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          {formReferenceErrors.nameReferee && (
                            <p className="text-red-500 text-sm">
                              {formReferenceErrors.nameReferee}
                            </p>
                          )}
                        </div>
                        <div className="mb-4">
                          <label
                            htmlFor="name"
                            className="block text-black  text-sm mb-2"
                          >
                            Designation
                          </label>
                          <input
                            type="text"
                            id="designation"
                            name="designation"
                            value={formfillesReferance?.designation || ""}
                            onChange={handleReferenceInputChange}
                            placeholder="Designation"
                            disabled={
                              submitted && !referenceRejected.isRejected
                            }
                            className="border border-gray-300 rounded-md w-[350px] py-2 text-[13px] px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          {formReferenceErrors.designation && (
                            <p className="text-red-500 text-sm">
                              {formReferenceErrors.designation}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className=" flex-col md:flex-row  flex justify-between gap-x-6 ">
                        <div className="mb-4">
                          <label
                            htmlFor="name"
                            className="block text-black  text-sm mb-2"
                          >
                            Name of Institution
                          </label>
                          <input
                            type="text"
                            id="nameofInstitution"
                            name="nameofInstitution"
                            value={formfillesReferance?.nameOfInstitute || ""}
                            onChange={handleReferenceInputChange}
                            placeholder=" Name of Institution"
                            disabled={
                              submitted && !referenceRejected.isRejected
                            }
                            className="border border-gray-300 rounded-md w-[350px] py-2 text-[13px]  px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          {formReferenceErrors.nameofInstitution && (
                            <p className="text-red-500 text-sm">
                              {formReferenceErrors.nameofInstitution}
                            </p>
                          )}
                        </div>
                        <div className="mb-4">
                          <label
                            htmlFor="name"
                            className="block text-black  text-sm mb-2"
                          >
                            Contact Number
                          </label>
                          <input
                            type="tel"
                            id="contactnumber"
                            name="contactnumber"
                            value={formfillesReferance?.contactNumber || ""}
                            onChange={handleReferenceInputChange}
                            placeholder="Contact number"
                            disabled={
                              submitted && !referenceRejected.isRejected
                            }
                            className="border border-gray-300 rounded-md w-[350px] py-2 text-[13px]  px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          {formReferenceErrors.contactnumber && (
                            <p className="text-red-500 text-sm">
                              {formReferenceErrors.contactnumber}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className=" flex-col md:flex-row  flex justify-between gap-x-6 ">
                        <div className="mb-4">
                          <label
                            htmlFor="name"
                            className="block text-black  text-sm mb-2"
                          >
                            EmailID
                          </label>
                          <input
                            type="email"
                            id="emailid"
                            name="emailid"
                            value={formfillesReferance?.email || ""}
                            onChange={handleReferenceInputChange}
                            placeholder="emailID"
                            disabled={
                              submitted && !referenceRejected.isRejected
                            }
                            className="border border-gray-300 rounded-md w-[350px] py-2 text-[13px]  px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          {formReferenceErrors.emailid && (
                            <p className="text-red-500 text-sm">
                              {formReferenceErrors.emailid}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="flex justify-center sm:justify-center md:justify-end md:mr-10">
                        <div>
                          {referenceRejected.isRejected ? (
                            // Display a message or any other component after submission
                            <button
                              type="submit"
                              className="text-sm sm:text-md border sm:w-full md:w-[250px] text-red-600 border-red-500 font-bold py-2 px-10 rounded focus:outline-none focus:shadow-outline shadow-lg "
                              onClick={handleSubmit}
                              disabled={isLoader}
                            >
                              {isLoader ? <Loader /> : "Submit Application"}
                            </button>
                          ) : null}
                        </div>
                      </div>
                    </form>
                    {/* <div className=" flex-col md:flex-row  flex justify-between gap-x-6 "></div> */}
                    <form>
                      <div className=" flex-col md:flex-row  flex justify-between gap-x-6 mt-10 ">
                        <div className="mb-4">
                          <label
                            htmlFor="email"
                            className="block text-gray-700 text-sm mb-2"
                          >
                            Name Referee
                          </label>
                          <input
                            type="text"
                            id="nameReferee"
                            name="nameReferee"
                            value={formfillesBusinessReferance?.name || ""}
                            onChange={handlePBusinessReferenceInputChange}
                            placeholder="Name Referee"
                            disabled={
                              submittedBusinessReference &&
                              !referenceRejected.isRejected
                            }
                            className="border border-gray-300 rounded-md w-[350px] py-2 px-3 text-[13px]  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          {formPanErrors.nameReferee && (
                            <p className="text-red-500 text-sm">
                              {formPanErrors.nameReferee}
                            </p>
                          )}
                        </div>
                        <div className="mb-4">
                          <label
                            htmlFor="name"
                            className="block text-black  text-sm mb-2"
                          >
                            Designation
                          </label>
                          <input
                            type="text"
                            id="designation"
                            name="designation"
                            value={
                              formfillesBusinessReferance?.designation || ""
                            }
                            onChange={handlePBusinessReferenceInputChange}
                            placeholder="Designation"
                            disabled={
                              submittedBusinessReference &&
                              !referenceRejected.isRejected
                            }
                            className="border border-gray-300 rounded-md w-[350px] py-2 text-[13px]  px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          {formPanErrors.designation && (
                            <p className="text-red-500 text-sm">
                              {formPanErrors.designation}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className=" flex-col md:flex-row  flex justify-between gap-x-6 ">
                        <div className="mb-4">
                          <label
                            htmlFor="name"
                            className="block text-black  text-sm mb-2"
                          >
                            Name of Institution
                          </label>
                          <input
                            type="text"
                            id="nameofInstitution"
                            name="nameofInstitution"
                            value={
                              formfillesBusinessReferance?.nameOfInstitute || ""
                            }
                            onChange={handlePBusinessReferenceInputChange}
                            placeholder=" Name of Institution"
                            disabled={
                              submittedBusinessReference &&
                              !referenceRejected.isRejected
                            }
                            className="border border-gray-300 rounded-md w-[350px] py-2 text-[13px] px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          {formPanErrors.nameofInstitution && (
                            <p className="text-red-500 text-sm">
                              {formPanErrors.nameofInstitution}
                            </p>
                          )}
                        </div>
                        <div className="mb-4">
                          <label
                            htmlFor="name"
                            className="block text-black  text-sm mb-2"
                          >
                            Contact Number
                          </label>
                          <input
                            type="tel"
                            id="contactnumber"
                            name="contactnumber"
                            value={
                              formfillesBusinessReferance?.contactNumber || ""
                            }
                            onChange={handlePBusinessReferenceInputChange}
                            placeholder="Contact number"
                            disabled={
                              submittedBusinessReference &&
                              !referenceRejected.isRejected
                            }
                            className="border border-gray-300 rounded-md w-[350px] py-2 text-[13px]  px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          {formPanErrors.contactnumber && (
                            <p className="text-red-500 text-sm">
                              {formPanErrors.contactnumber}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className=" flex-col md:flex-row  flex justify-between gap-x-6 ">
                        <div className="mb-4">
                          <label
                            htmlFor="name"
                            className="block text-black  text-sm mb-2"
                          >
                            EmailID
                          </label>
                          <input
                            type="email"
                            id="emailid"
                            name="emailid"
                            value={formfillesBusinessReferance?.email || ""}
                            onChange={handlePBusinessReferenceInputChange}
                            placeholder="EmailID"
                            disabled={
                              submittedBusinessReference &&
                              !referenceRejected.isRejected
                            }
                            className="border border-gray-300 rounded-md w-[350px] py-2 text-[13px]  px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          {formPanErrors.emailid && (
                            <p className="text-red-500 text-sm">
                              {formPanErrors.emailid}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="flex justify-center sm:justify-center md:justify-end md:mr-10 pb-6">
                        <div>
                          {referenceRejected.isRejected ? (
                            // Display a message or any other component after submission
                            <button
                              type="submit"
                              className="text-sm sm:text-md border sm:w-full md:w-[250px] text-red-600 border-red-500 font-bold py-2 px-10 rounded focus:outline-none focus:shadow-outline shadow-lg "
                              onClick={handleSubmit}
                              disabled={isLoader}
                            >
                              {isLoader ? <Loader /> : "Submit Application"}
                            </button>
                          ) : null}
                        </div>
                      </div>

                      <p>
                        {referenceRejected.isRejected ? (
                          <div className="bg-red-100 rounded-md ">
                            <div>
                              <p className="text-lg font-semibold text-red-600 px-8 pt-1 ">
                                Error
                              </p>
                            </div>
                            <div className=" flex items-center pb-2  px-6">
                              <div className="text-red-600">
                                <ErrorIcon />
                              </div>
                              <div>
                                <p className="text-[13px] text-red-500 font-semibold px-2">
                                  Your Applicant Information Rejected By Admin
                                </p>
                                <p className="text-[13px] text-red-500 font-semibold px-2">
                                  Admin Message:
                                </p>
                              </div>
                            </div>
                            <div className="h-1 rounded-lg bg-red-400"></div>
                          </div>
                        ) : null}
                      </p>
                      <p>
                        {referenceApproved.isApproved ? (
                          <div className="bg-green-100 rounded-md ">
                            <div>
                              <p className="text-lg font-semibold text-green-600 px-8 pt-1 ">
                                Success
                              </p>
                            </div>
                            <div className=" flex items-center pb-2  px-6">
                              <div className="text-green-600">
                                <VerifiedIcon />
                              </div>
                              <div>
                                <p className="text-[13px] text-green-500 font-semibold px-2">
                                  Your Applicant Information approved By Admin
                                </p>
                              </div>
                            </div>
                            <div className="h-1 rounded-lg bg-green-400"></div>
                          </div>
                        ) : null}
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div>
          <div className={`accordion ${isOpenPan ? "open" : ""}mb-6`}>
            <div
              className="accordion-header  w-[374px]  md:w-[814px] rounded-sm  shadow-lg"
              onClick={toggleAccordionPan}
            >
              <div className="flex justify-center items-center gap-x-4">
                <div className="flex  items-center gap-x-2 bg-red-500 px-1 py-1 rounded ">
                  <p className="text-white font-bold text-sm">STEP 4</p>
                </div>
                <div>
                  <p className="text-md font-semibold ">BUSINESS REFERENCE</p>
                </div>
              </div>
              <div className="flex justify-end items-end">
                <i className="fa-solid fa-angle-up" />
                <span className="icon">
                  {isOpenPan ? <RemoveIcon /> : <AddIcon />}
                </span>
              </div>
            </div>
            {isOpenPan && (
              <div className="accordion-content text-black">
                <div className="panel w-full sm:w-full md:w-[814px]  flex justify-center sm:flex sm:justify-content  md:flex-row  md:justify-center md:item-center shadow-lg">
                  <div className="py-10">
                    <form>
                      <div className=" flex-col md:flex-row flex justify-between items-center gap-x-6">
                        <div className="mb-4">
                          <label
                            htmlFor="name"
                            className="block text-black  text-sm mb-2"
                          >
                            Name Referee
                          </label>
                          <input
                            type="text"
                            id="nameReferee"
                            name="nameReferee"
                            value={formReference.nameReferee}
                            onChange={handleReferenceInputChange}
                            placeholder="Name Referee"
                            disabled={submitted}
                            className="border border-gray-300 rounded-md w-[350px] py-2 px-3 text-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          {formReferenceErrors.nameReferee && (
                            <p className="text-red-500 text-sm">
                              {formReferenceErrors.nameReferee}
                            </p>
                          )}
                        </div>
                        <div className="mb-4">
                          <label
                            htmlFor="name"
                            className="block text-black  text-sm mb-2"
                          >
                            Designation
                          </label>
                          <input
                            type="text"
                            id="designation"
                            name="designation"
                            value={formReference.designation}
                            onChange={handleReferenceInputChange}
                            placeholder="Designation"
                            disabled={submitted}
                            className="border border-gray-300 rounded-md w-[350px] py-2 text-sm  px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          {formReferenceErrors.designation && (
                            <p className="text-red-500 text-sm">
                              {formReferenceErrors.designation}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className=" flex-col md:flex-row  flex justify-between gap-x-6 ">
                        <div className="mb-4">
                          <label
                            htmlFor="name"
                            className="block text-black  text-sm mb-2"
                          >
                            Name of Institution
                          </label>
                          <input
                            type="text"
                            id="nameofInstitution"
                            name="nameofInstitution"
                            value={formReference.nameofInstitution}
                            onChange={handleReferenceInputChange}
                            placeholder=" Name of Institution"
                            disabled={submitted}
                            className="border border-gray-300 rounded-md w-[350px] py-2 text-sm  px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          {formReferenceErrors.nameofInstitution && (
                            <p className="text-red-500 text-sm">
                              {formReferenceErrors.nameofInstitution}
                            </p>
                          )}
                        </div>
                        <div className="mb-4">
                          <label
                            htmlFor="name"
                            className="block text-black  text-sm mb-2"
                          >
                            Contact Number
                          </label>
                          <input
                            type="tel"
                            id="contactnumber"
                            name="contactnumber"
                            value={formReference.contactnumber}
                            onChange={handleReferenceInputChange}
                            placeholder="Contact number"
                            disabled={submitted}
                            className="border border-gray-300 rounded-md w-[350px] py-2 text-sm  px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          {formReferenceErrors.contactnumber && (
                            <p className="text-red-500 text-sm">
                              {formReferenceErrors.contactnumber}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className=" flex-col md:flex-row  flex justify-between gap-x-6 ">
                        <div className="mb-4">
                          <label
                            htmlFor="name"
                            className="block text-black  text-sm mb-2"
                          >
                            EmailID
                          </label>
                          <input
                            type="email"
                            id="emailid"
                            name="emailid"
                            value={formReference.emailid}
                            onChange={handleReferenceInputChange}
                            placeholder="emailID"
                            disabled={submitted}
                            className="border border-gray-300 rounded-md w-[350px] py-2 text-sm  px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          {formReferenceErrors.emailid && (
                            <p className="text-red-500 text-sm">
                              {formReferenceErrors.emailid}
                            </p>
                          )}
                        </div>
                        <div className="mt-8 flex justify-center sm:justify-center md:justify-end md:mr-10">
                          <div>
                            {submitted ? (
                              // Display a message or any other component after submission
                              <button
                                type="submit"
                                className="text-sm sm:text-md border sm:w-full md:w-[250px] text-red-600 border-red-500 font-bold py-2 px-10 rounded focus:outline-none focus:shadow-outline shadow-lg hidden"
                                onClick={handleSubmit}
                                disabled={isLoader}
                              >
                                {isLoader ? <Loader /> : "Submit Application"}
                              </button>
                            ) : (
                              // Render the submit button only if the form hasn't been submitted
                              <button
                                type="submit"
                                className="text-sm sm:text-md border sm:w-full md:w-[250px] text-red-600 border-red-500 font-bold py-2 px-10 rounded focus:outline-none focus:shadow-outline shadow-lg"
                                onClick={handleSubmit}
                                disabled={isLoader}
                              >
                                {isLoader ? <Loader /> : "Submit Application"}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </form>
                    {/* <div className=" flex-col md:flex-row  flex justify-between gap-x-6 "></div> */}
                    <form>
                      <div className=" flex-col md:flex-row  flex justify-between gap-x-6 mt-10 ">
                        <div className="mb-4">
                          <label
                            htmlFor="email"
                            className="block text-gray-700 text-sm mb-2"
                          >
                            Name Referee
                          </label>
                          <input
                            type="text"
                            id="nameReferee"
                            name="nameReferee"
                            value={formBusinessReference.nameReferee}
                            onChange={handlePBusinessReferenceInputChange}
                            placeholder="Name Referee"
                            disabled={submittedBusinessReference}
                            className="border border-gray-300 rounded-md w-[350px] py-2 px-3 text-sm  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          {formPanErrors.nameReferee && (
                            <p className="text-red-500 text-sm">
                              {formPanErrors.nameReferee}
                            </p>
                          )}
                        </div>
                        <div className="mb-4">
                          <label
                            htmlFor="name"
                            className="block text-black  text-sm mb-2"
                          >
                            Designation
                          </label>
                          <input
                            type="text"
                            id="designation"
                            name="designation"
                            value={formBusinessReference.designation}
                            onChange={handlePBusinessReferenceInputChange}
                            placeholder="Designation"
                            disabled={submittedBusinessReference}
                            className="border border-gray-300 rounded-md w-[350px] py-2 text-sm  px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          {formPanErrors.designation && (
                            <p className="text-red-500 text-sm">
                              {formPanErrors.designation}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className=" flex-col md:flex-row  flex justify-between gap-x-6 ">
                        <div className="mb-4">
                          <label
                            htmlFor="name"
                            className="block text-black  text-sm mb-2"
                          >
                            Name of Institution
                          </label>
                          <input
                            type="text"
                            id="nameofInstitution"
                            name="nameofInstitution"
                            value={formBusinessReference.nameofInstitution}
                            onChange={handlePBusinessReferenceInputChange}
                            placeholder=" Name of Institution"
                            disabled={submittedBusinessReference}
                            className="border border-gray-300 rounded-md w-[350px] py-2 text-sm  px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          {formPanErrors.nameofInstitution && (
                            <p className="text-red-500 text-sm">
                              {formPanErrors.nameofInstitution}
                            </p>
                          )}
                        </div>
                        <div className="mb-4">
                          <label
                            htmlFor="name"
                            className="block text-black  text-sm mb-2"
                          >
                            Contact Number
                          </label>
                          <input
                            type="tel"
                            id="contactnumber"
                            name="contactnumber"
                            value={formBusinessReference.contactnumber}
                            onChange={handlePBusinessReferenceInputChange}
                            placeholder="Contact number"
                            disabled={submittedBusinessReference}
                            className="border border-gray-300 rounded-md w-[350px] py-2 text-sm  px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          {formPanErrors.contactnumber && (
                            <p className="text-red-500 text-sm">
                              {formPanErrors.contactnumber}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className=" flex-col md:flex-row  flex justify-between gap-x-6 ">
                        <div className="mb-4">
                          <label
                            htmlFor="name"
                            className="block text-black  text-sm mb-2"
                          >
                            EmailID
                          </label>
                          <input
                            type="email"
                            id="emailid"
                            name="emailid"
                            value={formBusinessReference.emailid}
                            onChange={handlePBusinessReferenceInputChange}
                            placeholder="EmailID"
                            disabled={submittedBusinessReference}
                            className="border border-gray-300 rounded-md w-[350px] py-2 text-sm  px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          {formPanErrors.emailid && (
                            <p className="text-red-500 text-sm">
                              {formPanErrors.emailid}
                            </p>
                          )}
                        </div>
                        <div className="mt-8 flex justify-center sm:justify-center md:justify-end md:mr-10">
                          <div>
                            {submittedBusinessReference ? (
                              // Display a message or any other component after submission
                              <button
                                type="button"
                                className="text-sm sm:text-md border sm:w-full md:w-[250px] text-red-600 border-red-500 font-bold py-2 px-10 rounded focus:outline-none focus:shadow-outline shadow-lg hidden"
                                onClick={handleBusinessReferenceSubmit}
                                disabled={isLoader}
                              >
                                {isLoader ? <Loader /> : "Submit Application"}
                              </button>
                            ) : (
                              // Render the submit button only if the form hasn't been submitted
                              <button
                                type="button"
                                className="text-sm sm:text-md border sm:w-full md:w-[250px] text-red-600 border-red-500 font-bold py-2 px-10 rounded focus:outline-none focus:shadow-outline shadow-lg"
                                onClick={handleBusinessReferenceSubmit}
                                disabled={isLoader}
                              >
                                {isLoader ? <Loader /> : "Submit Application"}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default BusinessReference;
